<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.Users') }}</h3>
            <DataTable v-model:rows="ClientsData" :headers="headers" :fields="fields" :total="total" :loading="loading"
                @page-changed="onPageChanged">
                <template v-slot:head-contant="{}">
                    <!-- <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <h3 class="table-title">{{ $t('Client.List') }}</h3>
                    </div>
                </div> -->
                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <!-- <i class="fa-solid fa-address-card color-green"></i> -->
                            <svg width="36" height="37" class="color-green" viewBox="0 0 36 37" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M23.25 11.4501C23.25 14.3496 20.8995 16.7001 18 16.7001C15.1005 16.7001 12.75 14.3496 12.75 11.4501C12.75 8.55058 15.1005 6.20007 18 6.20007C20.8995 6.20007 23.25 8.55058 23.25 11.4501Z"
                                    fill="#24A259" />
                                <path
                                    d="M27 24.95C27 27.8495 22.9706 30.2 18 30.2C13.0294 30.2 9 27.8495 9 24.95C9 22.0505 13.0294 19.7 18 19.7C22.9706 19.7 27 22.0505 27 24.95Z"
                                    fill="#24A259" />
                                <path
                                    d="M10.6831 7.69992C10.9493 7.69992 11.2091 7.72604 11.4601 7.77577C10.8487 8.86161 10.5 10.115 10.5 11.4499C10.5 12.7523 10.832 13.9771 11.4159 15.0445C11.1787 15.0887 10.9337 15.1118 10.6831 15.1118C8.56145 15.1118 6.84154 13.4526 6.84154 11.4059C6.84154 9.35913 8.56145 7.69992 10.6831 7.69992Z"
                                    fill="#24A259" />
                                <path
                                    d="M8.17101 28.6789C7.31913 27.6605 6.75 26.4109 6.75 24.9499C6.75 23.5336 7.28486 22.3159 8.09367 21.315C5.23665 21.5366 3 23.0992 3 24.994C3 26.9065 5.27595 28.4807 8.17101 28.6789Z"
                                    fill="#24A259" />
                                <path
                                    d="M25.5001 11.45C25.5001 12.7524 25.1682 13.9772 24.5842 15.0446C24.8214 15.0888 25.0664 15.1119 25.317 15.1119C27.4387 15.1119 29.1586 13.4527 29.1586 11.406C29.1586 9.35922 27.4387 7.70001 25.317 7.70001C25.0509 7.70001 24.791 7.72613 24.54 7.77586C25.1514 8.8617 25.5001 10.1151 25.5001 11.45Z"
                                    fill="#24A259" />
                                <path
                                    d="M27.8291 28.679C30.7242 28.4808 33.0001 26.9066 33.0001 24.9941C33.0001 23.0993 30.7635 21.5367 27.9064 21.3151C28.7153 22.316 29.2501 23.5337 29.2501 24.95C29.2501 26.411 28.681 27.6606 27.8291 28.679Z"
                                    fill="#24A259" />
                            </svg>
                            <h3 class="table-title">{{ $t('Sections.Clients.List') }}</h3>

                        </div>
                        <!-- <input type="search" class="form-control" placeholder="" v-model.lazy="searchQuery"
                                aria-controls="DataTables_Table_0"> -->
                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31" v-model.lazy="searchQuery">
                        </div>
                    </div>

                </template>
                <template v-slot:action-buttons="{ rowColum }">
                    <td v-if="rowColum.status == '0'">
                        <button type="button" class="btn btn-sm btn-primary" @click="onStatusRow(rowColum)">

                            {{ $t('General.Pending') }}
                        </button>
                    </td>
                    <td v-else-if="rowColum.status == '1'">
                        <button type="button" class="btn btn-sm blue-btn " @click="onStatusRow(rowColum)">
                            {{ $t('General.Approved') }}
                        </button>
                    </td>
                    <td v-else>
                        <button type="button" class="btn btn-sm bg-red-light" @click="onStatusRow(rowColum)">
                            {{ $t('General.Blocked') }}
                        </button>
                    </td>
                    <td>
                        {{ rowColum.created_at }}
                    </td>
                    <td>
                        <!-- <button type="button" class="btn btn-sm btn-primary" @click="onEditRow(rowColum)">
                       <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 13.4H2C1.795 13.4 1.625 13.23 1.625 13.025C1.625 12.82 1.795 12.65 2 12.65H11C11.205 12.65 11.375 12.82 11.375 13.025C11.375 13.23 11.205 13.4 11 13.4Z" fill="#1168FF"/>
<path d="M10.0103 4.13995C9.04028 3.16995 8.09028 3.14495 7.09528 4.13995L6.49028 4.74495C6.44028 4.79495 6.42028 4.87495 6.44028 4.94495C6.82028 6.26995 7.88028 7.32995 9.20528 7.70995C9.22528 7.71495 9.24528 7.71995 9.26528 7.71995C9.32028 7.71995 9.37028 7.69995 9.41028 7.65995L10.0103 7.05495C10.5053 6.56495 10.7453 6.08995 10.7453 5.60995C10.7503 5.11495 10.5103 4.63495 10.0103 4.13995Z" fill="#1168FF"/>
<path d="M8.30614 8.16508C8.16114 8.09508 8.02114 8.02508 7.88614 7.94508C7.77614 7.88008 7.67114 7.81008 7.56614 7.73508C7.48114 7.68008 7.38114 7.60008 7.28614 7.52008C7.27614 7.51508 7.24114 7.48508 7.20114 7.44508C7.03614 7.30508 6.85114 7.12508 6.68614 6.92508C6.67114 6.91508 6.64614 6.88008 6.61114 6.83508C6.56114 6.77508 6.47614 6.67508 6.40114 6.56008C6.34114 6.48508 6.27114 6.37508 6.20614 6.26508C6.12614 6.13008 6.05614 5.99508 5.98614 5.85508C5.89436 5.65841 5.63623 5.59999 5.48277 5.75345L2.67114 8.56508C2.60614 8.63008 2.54614 8.75508 2.53114 8.84008L2.26114 10.7551C2.21114 11.0951 2.30614 11.4151 2.51614 11.6301C2.69614 11.8051 2.94614 11.9001 3.21614 11.9001C3.27614 11.9001 3.33614 11.8951 3.39614 11.8851L5.31614 11.6151C5.40614 11.6001 5.53114 11.5401 5.59114 11.4751L8.40741 8.65881C8.5578 8.50841 8.50129 8.24965 8.30614 8.16508Z" fill="#1168FF"/>
</svg>

                    </button> -->
                        <div class="dropdown "  v-if="authStore.hasPermission('update-clients')">
                            <button class="dropdown-toggle custom-dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <svg width="4" height="16" viewBox="0 0 4 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.99984 6.16666C0.991504 6.16666 0.166504 6.99166 0.166504 7.99999C0.166504 9.00832 0.991504 9.83332 1.99984 9.83332C3.00817 9.83332 3.83317 9.00832 3.83317 7.99999C3.83317 6.99166 3.00817 6.16666 1.99984 6.16666ZM1.99984 0.666656C0.991504 0.666656 0.166504 1.49166 0.166504 2.49999C0.166504 3.50832 0.991504 4.33332 1.99984 4.33332C3.00817 4.33332 3.83317 3.50832 3.83317 2.49999C3.83317 1.49166 3.00817 0.666656 1.99984 0.666656ZM1.99984 11.6667C0.991504 11.6667 0.166504 12.4917 0.166504 13.5C0.166504 14.5083 0.991504 15.3333 1.99984 15.3333C3.00817 15.3333 3.83317 14.5083 3.83317 13.5C3.83317 12.4917 3.00817 11.6667 1.99984 11.6667Z"
                                        fill="#24A259" />
                                </svg>

                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li >
                                    <a class="dropdown-item" @click="onEditRow(rowColum)">
                                        <i class="bx bxs-edit-alt mx-3"></i> {{ $t('General.Dropdown.Edit') }}
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="onStatusRow(rowColum)">
                                        <i class="bx bxs-shield-minus mx-3 "></i> {{ $t('General.Dropdown.Status') }}
                                    </a>
                                </li>
                                <!-- <li >
                                    <a class="dropdown-item"  @click="triggerDeleteModal(rowColum)">
                                        <i class="bx bxs-trash mx-3"></i> {{ $t('General.Dropdown.Delete') }}
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                    </td>
                </template>

            </DataTable>
            <EditClient v-if="showEdit" :row="editRow" @close-edit="onCloseEdit" :onEdit="fetchData" />
            <StatusClient v-if="showStatus" :row="editRow" @close-status="onCloseStatus" :onStatusChange="fetchData" />
            <DeleteUserModal ref="deleteModal" :row="editRow" :onDelete="handleDelete"
                :title="$t('Sections.Clients.Delete')" />





        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';

import i18n from '@/i18n.js';
import { formatDateToYMDHMS } from '@/utils/dateFormatter';
import { deleteClient } from '@/services/client';
import { getClients } from '@/services/client';
import StatusClient from './componants/StatusClient.vue';
import EditClient from './componants/EditClient.vue';
import DeleteUserModal from '@/components/pages/DeleteUserModal.vue';
import { toast } from 'vue3-toastify';
import { useAuthStore } from '@/stores/auth';


// State
// Create a reference to the DeleteModal component
const ClientsData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const searchQuery = ref('');

//for Action btn
const editRow = ref({});


const authStore = useAuthStore();

// Table headers and fields
// Table headers and fields
const headers =
computed(()=>
    [
        '#',
        // i18n.global.t('General.SerialNumber'),
        i18n.global.t('General.Name'), i18n.global.t('General.Email'),
        i18n.global.t('General.Phone'), 
        i18n.global.t('General.StoreName'),
         i18n.global.t('General.Status'), i18n.global.t('General.CreatedAt'),
        ''
    ]);
const fields = ['ids', 'name', 'email', 'mobile', 'store_name'];


// Fetch users with pagination
const fetchData = async () => {

    try {
        loading.value = true;
        ClientsData.value = [];
        const response = await getClients(currentPage.value, searchQuery.value);
        let count = 1 ;
        for (const row of response.data.data.data) {

            ClientsData.value.push({
                ...row,
                ids:   (((currentPage.value??1) -1 ) *10) + count++ , 

                created_at: formatDateToYMDHMS(row.created_at),
                updated_at: formatDateToYMDHMS(row.updated_at),
            });

        }
        // ClientsData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};

// Fetch users on mount
onMounted(fetchData);

// Watch for changes on searchQuery
watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});
const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};



//edit
const showEdit = ref(false);
function onEditRow(row) {
    showEdit.value = !showEdit.value;

    editRow.value = row;

}
function onCloseEdit() {
    showEdit.value = !showEdit.value;

}

//status
const showStatus = ref(false);
function onStatusRow(row) {
    showStatus.value = !showStatus.value;

    editRow.value = row;

}
function onCloseStatus() {
    showStatus.value = !showStatus.value;

}

//delete
const deleteModal = ref(null);
const handleDelete = async () => {
    try {
        loading.value = true;
        await deleteClient(editRow.value.id);
        fetchData();
        toast.success(i18n.global.t('Messages.Success.Deleted'), {
            autoClose: 3000, // Adjust time if needed
        });

        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error('Error fetching users:', error);
    }
};
// const triggerDeleteModal = (row) => {
//     editRow.value = row;

//     deleteModal.value.openModal();
// };




</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.custom-dropdown-toggle {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
}

.custom-dropdown-toggle::after {
    display: none;
    /* Remove the default arrow */
}
</style>