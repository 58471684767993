
<template>
  <select 
    v-model="selectedCities"
    id="cityMultiSelect"
    class="form-control form-select"
    ref="cityMultiSelect"
    multiple
  >
    <optgroup 
      v-for="group in options" 
      :key="group.label" 
      :label="group.label"
    >
      <option 
        v-for="city in group.options" 
        :key="city.id" 
        :value="city.id"
      >
        {{ getname(city) }}
      </option>
    </optgroup>
  </select>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import "select2/dist/css/select2.min.css";
import $ from "jquery";
import "select2";
import { getname } from "@/utils/translate";

// Props
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: "Select cities...",
  },
});

// Emit
const emit = defineEmits(["update:modelValue"]);

// Refs
const cityMultiSelect = ref(null);

// Initialize Select2 Multi-Select
const initSelect2 = () => {
  $(cityMultiSelect.value)
    .select2({
      placeholder: props.placeholder,
      width: "100%",
      minimumResultsForSearch: 0,
    })
    .val(props.modelValue)
    .trigger("change");

  $(cityMultiSelect.value).on("change", () => {
    const selected = $(cityMultiSelect.value).val() || [];
    emit("update:modelValue", selected);
  });
};

// Watch for changes in options
watch(
  () => props.options,
  () => {
    $(cityMultiSelect.value).select2("destroy");
    initSelect2();
  }
);

// Lifecycle Hooks
onMounted(() => initSelect2());
onBeforeUnmount(() => {
  $(cityMultiSelect.value).select2("destroy");
});
</script>

<style scoped>
.select2-container--open {
  z-index: 9999 !important;
}
.select2-selection--multiple .select2-selection__rendered {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.select2-selection--multiple .select2-selection__choice {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.select2-selection__choice__remove {
  color: #999 !important;
}
</style>