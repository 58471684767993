<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.Conditions') }}</h3>
            <DataTable v-model:rows="ConditionsData" :headers="headers" :fields="fields" :total="total"
                :loading="loading" @page-changed="onPageChanged" :itemsPerPage="perPage" :heightRow="150">
                <template v-slot:head-contant="{}">

                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.332 18.6667C14.5412 18.6667 16.332 20.4575 16.332 22.6667C16.332 24.8758 14.5412 26.6667 12.332 26.6667C10.1229 26.6667 8.33203 24.8758 8.33203 22.6667C8.33203 20.4575 10.1229 18.6667 12.332 18.6667Z"
                                    fill="#24A259" />
                                <path
                                    d="M18.9987 5.33334C16.7896 5.33334 14.9987 7.1242 14.9987 9.33334C14.9987 11.5425 16.7896 13.3333 18.9987 13.3333C21.2078 13.3333 22.9987 11.5425 22.9987 9.33334C22.9987 7.1242 21.2078 5.33334 18.9987 5.33334Z"
                                    fill="#24A259" />
                                <path
                                    d="M11.6654 8.27804C12.2176 8.27804 12.6654 8.72575 12.6654 9.27804C12.6654 9.83032 12.2176 10.278 11.6654 10.278L2.33203 10.278C1.77975 10.278 1.33203 9.83032 1.33203 9.27804C1.33203 8.72575 1.77975 8.27804 2.33203 8.27804H11.6654Z"
                                    fill="#24A259" />
                                <path
                                    d="M19.6654 21.6114C19.1131 21.6114 18.6654 22.0591 18.6654 22.6114C18.6654 23.1637 19.1131 23.6114 19.6654 23.6114H28.9987C29.551 23.6114 29.9987 23.1637 29.9987 22.6114C29.9987 22.0591 29.551 21.6114 28.9987 21.6114H19.6654Z"
                                    fill="#24A259" />
                                <path
                                    d="M1.33203 22.6114C1.33203 22.0591 1.77975 21.6114 2.33203 21.6114H4.9987C5.55098 21.6114 5.9987 22.0591 5.9987 22.6114C5.9987 23.1637 5.55098 23.6114 4.9987 23.6114H2.33203C1.77975 23.6114 1.33203 23.1637 1.33203 22.6114Z"
                                    fill="#24A259" />
                                <path
                                    d="M28.9987 8.27804C29.551 8.27804 29.9987 8.72575 29.9987 9.27804C29.9987 9.83032 29.551 10.278 28.9987 10.278L26.332 10.278C25.7797 10.278 25.332 9.83032 25.332 9.27804C25.332 8.72575 25.7797 8.27804 26.332 8.27804H28.9987Z"
                                    fill="#24A259" />
                            </svg>

                            <h3 class="table-title">{{ $t('Sections.Conditions.List') }}</h3>
                        </div>

                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31"
                                v-model.lazy="searchQuery">
                        </div>
                    </div>

                    <div class="card-header flex-column flex-md-row pb-0"
                        v-if="authStore.hasPermission('create-condition')">

                        <div class="dt-action-buttons text-end pt-6 pt-md-0">
                            <div class="dt-buttons btn-group flex-wrap">
                                <router-link :to="{ name: 'ConditionsAdd' }"
                                    class="btn btn-secondary create-new btn-primary" tabindex="0"
                                    aria-controls="DataTables_Table_0" type="button"><span><i
                                            class="bx bx-plus bx-sm me-sm-2"></i> <span
                                            class="d-none d-sm-inline-block">
                                            {{ $t('Sections.Conditions.AddNew') }}
                                        </span></span>
                                </router-link>

                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:action-buttons="{ rowColum }">

                    <td class="text-center align-middle">
                        {{ rowColum.title }}

                        <button @click="toggleRow(rowColum.id)" class="arrow" v-if="rowColum.isgroup">
                            <i
                                :class="['bi', { 'bx bxs-up-arrow': expandedRows.includes(rowColum.id), 'bx bxs-down-arrow': !expandedRows.includes(rowColum.id) }]"></i>
                        </button>
                    </td>
                    
                    <td class="text-center align-middle">
                        {{ rowColum.zid_status }}
                    </td>
                    
                    <td>
                        <ul v-for="restriction in rowColum.restrictionsFilter" :key="restriction.id" class="m-0">
                            <li v-for="(value, key) in (expandedRows.includes(rowColum.id) ? restriction : Object.entries(restriction).slice(0, 3))"
                                :key="key">
                                <div class="d-flex">
                                    <span class="color-green me-3" v-if="expandedRows.includes(rowColum.id)">
                                        {{ $t('General.' + key) }} :
                                    </span>
                                    <p v-if="Array.isArray(value) && value.length > 0">
                                        <span v-if="!expandedRows.includes(rowColum.id)">
                                            <span class="color-green ">

                                                {{ $t('General.' + value[0]) }} :
                                            </span>
                                            <span v-if="Array.isArray(value[1]) && value[1].length > 0">
                                                <span class="mx-2" v-for="values in value[1] " :key="values">
                                                    {{ values }}
                                                </span>
                                            </span>
                                            <span v-else>
                                                {{ value[1] }}
                                            </span>
                                        </span>
                                        <span v-else v-for="item in value" :key="item">
                                            <span class="mx-2">
                                                {{ item }}

                                            </span>

                                        </span>
                                    </p>
                                    <p v-else>
                                        {{ value }}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </td>

                    <td class="text-center align-middle">
                        <ul v-for="event in rowColum.eventsFilter" :key="event.id" class="m-0">
                            <li v-for="(value, key) in event " :key="key">
                                <div class="d-flex">
                                    <span class="color-green me-3">
                                        {{ $t('General.' + key) }} :
                                    </span>
                                    <p v-if="Array.isArray(value) && value.length > 0">
                                        <span v-if="!expandedRows.includes(rowColum.id)">
                                            {{ $t('General.' + value[0]) }} :
                                            <span v-if="Array.isArray(value[1]) && value[1].length > 0">
                                                <span class="mx-2" v-for="values in value[1] " :key="values">
                                                    {{ values }}
                                                </span>
                                            </span>
                                            <span v-else>
                                                {{ value[1] }}
                                            </span>
                                        </span>
                                        <span v-else v-for="item in value" :key="item">
                                            <span class="mx-2">
                                                {{ item }}

                                            </span>

                                        </span>
                                    </p>
                                    <p v-else>
                                        {{ value }}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.created_at }}
                    </td>

                    <td class="">
                        <!-- {{ rowColum.status }} -->
                        <div class="form-check form-switch  toggle-large" style="justify-self: center;">
                            <input class="form-check-input" type="checkbox" :checked="rowColum.status === 1"
                                @change="toggleStatus(rowColum.id, $event.target.checked)" />
                        </div>
                    </td>
                    <td class="text-center align-middle">
                        <button @click="triggerDeleteModal(rowColum)" class="btn btn-sm delete-btn">
                            <svg width="13" height="15" viewBox="0 0 13 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.5 3.74921C0.5 3.48622 0.717806 3.27302 0.986484 3.27302H4.17858C4.18292 2.7122 4.2437 1.94336 4.80025 1.41112C5.23824 0.992261 5.83872 0.733337 6.49999 0.733337C7.16127 0.733337 7.76174 0.992261 8.19974 1.41112C8.75629 1.94336 8.81707 2.7122 8.82141 3.27302H12.0135C12.2822 3.27302 12.5 3.48622 12.5 3.74921C12.5 4.0122 12.2822 4.2254 12.0135 4.2254H0.986484C0.717806 4.2254 0.5 4.0122 0.5 3.74921Z"
                                    fill="#EE1D52" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M6.2304 14.0667H6.7696C8.62474 14.0667 9.55231 14.0667 10.1554 13.4761C10.7585 12.8854 10.8202 11.9166 10.9436 9.97901L11.1214 7.18707C11.1884 6.13574 11.2219 5.61008 10.9193 5.27697C10.6167 4.94386 10.1058 4.94386 9.08397 4.94386H3.91603C2.89418 4.94386 2.38325 4.94386 2.0807 5.27697C1.77815 5.61008 1.81163 6.13574 1.87858 7.18707L2.05639 9.979C2.1798 11.9166 2.2415 12.8854 2.84459 13.4761C3.44769 14.0667 4.37526 14.0667 6.2304 14.0667ZM5.33085 7.52566C5.30337 7.23642 5.05835 7.0254 4.78358 7.05432C4.50881 7.08325 4.30834 7.34116 4.33581 7.6304L4.66915 11.1392C4.69662 11.4284 4.94165 11.6394 5.21642 11.6105C5.49119 11.5816 5.69166 11.3237 5.66419 11.0344L5.33085 7.52566ZM8.21642 7.05432C8.49119 7.08325 8.69166 7.34116 8.66419 7.6304L8.33085 11.1392C8.30338 11.4284 8.05835 11.6394 7.78358 11.6105C7.50881 11.5816 7.30834 11.3237 7.33581 11.0344L7.66915 7.52566C7.69663 7.23642 7.94165 7.0254 8.21642 7.05432Z"
                                    fill="#EE1D52" />
                            </svg>

                        </button>
                    </td>



                </template>

            </DataTable>


            <DeleteModal ref="deleteModal" :row="editRow" :onDelete="handleDelete"
                :title="$t('Sections.Conditions.Delete')" />




        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, h } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { formatDateToYMDHMS } from '@/utils/dateFormatter';
import { deleteConditions, getConditions, toggleConditions } from '@/services/condition';
import DeleteModal from '@/components/pages/DeleteModal.vue';
import { toast } from 'vue3-toastify';
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();

// State
// Create a reference to the DeleteModal component
const ConditionsData = ref([]);
const loading = ref(true);

const currentPage = ref(1);
const total = ref(0);
const perPage = ref(10);


const searchQuery = ref('');

//for Action btn
const editRow = ref({});


// Reactive state to track expanded rows
const expandedRows = ref([]);

// Function to toggle row expansion
const toggleRow = (id) => {
    if (expandedRows.value.includes(id)) {
        expandedRows.value = expandedRows.value.filter((rowId) => rowId !== id);
    } else {
        expandedRows.value.push(id);
    }
};


// Table headers and fields
const headers = computed(() => ['#',
    t('Entities.Conditions'),
    t('General.Status'),
    t('General.Restrictions'),
    t('General.Events'),
    t('General.CreatedAt'),
    t('Sections.Conditions.Status'),
    '']);
const fields = ['ids'];

function toPascalCase(str) {
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
}

function filterRestrictionsArray(restrictions) {
    return restrictions.map(restriction =>
        Object.entries(restriction).reduce((filteredObj, [key, value]) => {
            // Filter out keys where key ends with "Id" or key is specific values to exclude
            if (!key.endsWith("id") && key !== "created_at" && key !== "updated_at" && key !== "id" && key !== "ids" && value !== null && value?.length > 0) {
                const transformedKey = toPascalCase(key); // Convert key to PascalCase

                if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object") {
                    // If the value is an array of objects, map to extract "name" properties
                    filteredObj[transformedKey] = value.map(item => item.name ?? item.ar_name);
                } else {
                    // Assign value as-is for non-array or non-object arrays
                    filteredObj[transformedKey] = value;
                }
            }
            if (key == 'is_paid') {
                const transformedKey = toPascalCase(key); // Convert key to PascalCase

                filteredObj[transformedKey] = value ? true : false;
            }
            if (
                (key == 'more_than' ||key == 'less_than') 
                && value != null
                 && value > 0
            ) {
                const transformedKey = toPascalCase(key); // Convert key to PascalCase

                filteredObj[transformedKey] = value ;

            }
            return filteredObj;
        }, {})
    );
}

const errorMessage = computed(() => t('Messages.Error.FetchData'));
const successMessage = computed(() => t('Messages.Success.Deleted'));
const editedMessage = computed(() => t('Messages.Success.Edited'));


// Fetch users with pagination
const fetchData = async () => {
    try {
        loading.value = true;
        ConditionsData.value = [];

        const response = await getConditions(currentPage.value, perPage.value, searchQuery.value);
        let count = 1;

        for (const row of response.data.data.data) {

            ConditionsData.value.push({
                ...row,
                ids:   (((currentPage.value??1) -1 ) *10) + count++ , 

                created_at: formatDateToYMDHMS(row.created_at),

                client_name: row.client && row.client.name ? row.client.name : "NAN",
                country_name: row.country && row.country.name ? row.country.name : "NAN",
                city_name: row.city && row.city.ar_name ? row.city.ar_name : "NAN",
                restrictionsFilter: filterRestrictionsArray(row.restrictions),
                eventsFilter: filterRestrictionsArray(row.events),
                isgroup: row.restrictions.length > 0 ? true : false

            });

        }
        // ConditionsData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        // console.error(errorMessage.value, error);
        toast.error(errorMessage.value, {
            timeout: 3000, // Auto-close in 3 seconds
            position: "top-center",
            icon: () => h("i", { class: "bx bx-x", style: "font-size:36px; color: red;" }),
            style: {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                position: "fixed",
            },
        });
    }
};

// Fetch users on mount
onMounted(fetchData);
// Watch for changes on searchQuery
watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});
const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};
const toggleStatus = async (id, isChecked) => {

    try {
        const newStatus = isChecked ? 1 : 0;
        const formData = {
            id,
            status: newStatus
        };
        await toggleConditions(formData)
        // toast.success(editedMessage.value, {
        //     autoClose: 2000,
        // });
        toast.success(editedMessage.value, {
            timeout: 3000, // Auto-close in 3 seconds
            position: "top-center",
            icon: () => h("i", { class: "bx bx-check", style: "font-size:36px; color: green;" }),
            style: {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                position: "fixed",
            },
        });


    } catch (error) {
        console.error(errorMessage.value, error);
    }
};


//delete
const deleteModal = ref(null);
const handleDelete = async () => {
    try {
        loading.value = true;
        await deleteConditions(editRow.value.id);
        fetchData();
        // toast.success(successMessage.value, { autoClose: 3000 });
        toast.success(successMessage.value, {
            timeout: 3000, // Auto-close in 3 seconds
            position: "top-center",
            icon: () => h("i", { class: "bx bx-check", style: "font-size:36px; color: green;" }),
            style: {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                position: "fixed",
            },
        });

        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(errorMessage.value, error);
    }
};
const triggerDeleteModal = (row) => {
    editRow.value = row;

    deleteModal.value.openModal();
};



</script>

<style scoped>
td {
    position: relative;
    transition: all 0.3s ease-in-out;
    /* Optional for smooth transitions */
    cursor: pointer;
}

.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.arrow {
    background-color: transparent;
    border: none;
    outline: none;
    color: #24a259;
}

.arrow i {
    font-size: 12px !important;
}

.toggle-large .form-check-input {
    width: 3rem;
    height: 1.5rem;
    border-radius: 1rem;
}

.text-center.align-middle {
    min-width: max-content;
}
</style>