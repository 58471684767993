import { buildUrl } from "@/utils/buildUrl";
import axiosClient from "./axiosClient"

export function getAllProducts(client_id = null) {
  const url = buildUrl("v1/dashboard/product/by-client", {
    client_id,
  });

  return axiosClient({ url });
}
export function getProducts(page = 1, searchQuery = '') {
  return axiosClient({
    url: `v1/dashboard/products?page=${page}&per_page=10&search=${searchQuery}`,
  })
}
export function getWarehouseProducts(id) {
  return axiosClient({
    url: `/v1/dashboard/product/warehouse_products?warehouse_id=${id}`,
  })
}
export function updateProduct(data, id) {
  return axiosClient({
    url: `v1/dashboard/product/update/${id}`,
    method: 'put',
    data
  })

}
export function storeProduct(data) {
  return axiosClient({
    url: `v1/dashboard/product/store`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

}
export function storeGProduct(data) {
  return axiosClient({
    url: `v1/dashboard/product/storeGroup`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

}
export function deleteProduct(id) {
  return axiosClient({
    url: `v1/dashboard/product/delete/${id}`,
    method: 'DELETE',
  })
}

export function importProducts() {
  return axiosClient({
    url: `/v1/dashboard/product/download_sample`,
  })
}
export function uploadFile(data) {
  return axiosClient({
    url: `v1/dashboard/product/import`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

}
