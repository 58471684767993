import { ref } from "vue";
import axiosClient from "./axiosClient"

export function getUsers(page = 1 , searchQuery = '') {
  const url =  ref('');
  if(searchQuery){
   url.value =  `v1/dashboard/users?page=${page}&per_page=10&search=${searchQuery}` ;
  }else{
   url.value =  `v1/dashboard/users?page=${page}&per_page=10` ;

  }
  return axiosClient({
                   url   : url.value,
                 })
}
export function editUser(data , id) {
  return axiosClient({
                   url   : `v1/dashboard/update/${id}`,
                   method: 'post',
                   data
                 })
}
export function addUser(data) {
  return axiosClient({
                   url   : `v1/dashboard/store`,
                   method: 'post',
                   data
                 })
}
export function deleteUser( id) {
  return axiosClient({
                   url   : `v1/dashboard/user/delete/${id}`,
                   method: 'DELETE',
                 })
}