<template>
    <div class="calendar p-5 shadow" :class="{ 'show-calendar': showCalendar }">
        <div class="calendar-content border rounded p-5">
            <div class="d-flex flex-col">
                <div class="d-flex flex-col">
                    <!-- First Calendar -->
                    <div class="calendar-container ms-5">
                        <Datepicker v-model="selectedDate1" :inline="true" :input-props="{ style: 'display: none' }"
                            :week-start="0" :highlight-today="true" :enable-time-picker="false"
                            :custom-week-days="weeks" class=" calendar-dates ms-5 no-border"
                            :highlight="highlightedDates" @date-update="onDateChange1" />
                    </div>

                    <!-- Second Calendar -->
                    <div class="calendar-container ">
                        <Datepicker v-model="selectedDate2" :inline="true" :input-props="{ style: 'display: none' }"
                            :week-start="0" :highlight-today="true" :enable-time-picker="false"
                            :custom-week-days="weeks" class="custom-datepicker no-border" :highlight="highlightedDates"
                            @date-update="onDateChange2" />
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-col align-items-center justify-content-between">
            <div>
                <span class="text-uppercase text-primary">{{ $t("General.StartWith") }} :</span>
                {{ formatDateToDMY(selectedDate1) }}
            </div>
            <div>
                <span class="text-uppercase text-primary">{{ $t("General.EndWith") }} :</span>
                {{ formatDateToDMY(selectedDate2) }}

            </div>
            <div class="d-flex gap-3 calender-btns">
                <button class="btn text-primary bg-green" @click="SyncCulender">{{ $t('General.Cancel') }}</button>
                <button class="btn text-white bg-primary" @click="Apply">{{ $t('General.Apply') }}</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { formatDateToDMY, formatDateToYMD } from "@/utils/dateFormatter";


const emit = defineEmits(["update:startDate", "update:endDate"]);
const weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    startDate: {
        type: Date,
        required: true,
    },
    endDate: {
        type: Date,
        required: true,
    },
    onChange: {
        type: Function,
        required: true,
    }
});


const showCalendar = ref(props.show);
const selectedDate1 = ref(props.startDate); // Store selected date for first calendar
const selectedDate2 = ref(props.endDate); // Store selected date for second calendar


const highlightedDates = computed(() => {
    if (!selectedDate1.value || !selectedDate2.value) return [];

    const start = new Date(selectedDate1.value);
    const end = new Date(selectedDate2.value);
    let dates = [];

    while (start <= end) {
        dates.push(new Date(start));
        start.setDate(start.getDate() + 1);
    }

    return dates;
});

// Function to handle date changes
const onDateChange1 = (newDate) => {

    selectedDate1.value = formatDateToYMD(newDate);
    emit("update:startDate", formatDateToYMD(newDate));

};
const onDateChange2 = (newDate) => {

    selectedDate2.value = formatDateToYMD(newDate);
    emit("update:endDate", formatDateToYMD(newDate));

};

const Apply = () => {
    props.onChange();
    showCalendar.value = !showCalendar.value;



}



const SyncCulender = () => {
    showCalendar.value = !showCalendar.value;
};

const isShowCalendar = () => {
    return showCalendar.value
}



defineExpose({
    SyncCulender,
    isShowCalendar

});

</script>

<style>
.dp__action_select {
    display: none;
}

.dp__menu {
    border: none !important;
}
</style>
<style scoped>
[dir="ltr"] .calendar {
    position: absolute;
    top: 50%;
    right: -90vw;
    display: none;
    background-color: #fff;
    transform: translateY(10%);
    transition: right 3s ease;
    z-index: 1000;
}

[dir="rtl"] .calendar {
    position: absolute;
    top: 50%;
    right: -48vw;
    width: min-content;
    display: none;
    background-color: #fff;
    transform: translateY(10%);
    transition: right 3s ease;
    z-index: 1000;
}

.calendar button {
    width: 120px;
    display: block;
    text-transform: capitalize;
}


[dir="ltr"] .show-calendar {
    right: 0;
    display: block;
}

[dir="rtl"] .show-calendar {
    right: auto !important;
    left: 0px !important;
    display: block;
}



.calendar-navigation {
    display: flex;
    justify-content: center;
    align-items: center;

}

.calendar-navigation span {
    height: 38px;
    width: 38px;
    margin: 0 1px;
    cursor: pointer;
    text-align: center;
    line-height: 38px;
    border-radius: 12px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aeabab;
    font-size: 1.9rem;
    background: #f2f2f2;
}

.calendar-current-date {
    color: #000336;
    padding: 10px;
    margin-top: 20px;
}

.calendar-body ul {
    list-style: none;
    flex-wrap: wrap;
    display: flex;
    text-align: center;
}

.calendar-body .calendar-dates {
    margin-bottom: 20px;
}

.calendar-body li {
    width: calc(100% / 7);
    font-size: 1.07rem;
    color: #414141;
}

.calendar-body .calendar-weekdays li {
    cursor: default;
    font-weight: 500;
}

.calendar-body .calendar-dates li {
    margin-top: 10px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.calendar-dates li.inactive {
    color: #aaa;
}

.calendar-dates li.active {
    color: #fff;
}

[dir="ltr"].calendar-dates li::before {
    position: absolute;
    content: "";
    z-index: -1;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    transform: translate(-50%, -50%);
}



.calendar-dates li.active::before {
    background: #24A259;
}

.calendar-container {
    width: 300px;
}

.calendar-dates li:not(.active):hover::before {
    background: #e4e1e1;
}

.calendar {
    position: absolute;
    top: 50%;
    right: -90vw;
    display: none;
    background-color: #fff;
    transform: translateY(10%);
    transition: right 3s ease;
    z-index: 1000;
}

.calendar button {
    text-transform: capitalize;
}

.show-calendar {
    right: 0;
    display: block;
}

.calendar-container {
    width: 300px;
}

.custom-datepicker {
    --dp-highlight-color: #24A259;
    --dp-day-hover-color: #e4e1e1;
    --dp-selected-day-color: #fff;
    --dp-selected-day-bg: #24A259;
}

@media screen and (max-width:1024px) {
    .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .calendar-dates.ms-5,
    .calendar-container.ms-5 {
        margin-left: 0px !important;
    }

    .calender-btns,
    .calendar-container {
        margin-top: 20px !important;
    }

    .calendar {
        max-width: 90vw !important;
        top: 0 !important;
        transform: translateY(5%) !important;
    }

    .border-l-primary button {
        width: 100%;
    }

    [dir="rtl"] .calendar {
        right: 0px !important;
        top: 5px !important;
        width: 90vw !important;
    }

    [dir=rtl] .ms-5 {
        margin-right: 0 !important;
    }
}

.dp__range_between {
    background-color: rgba(36, 162, 89, 0.08) !important;
    color: white !important;
}
</style>
