<template>
    <div class="modal fade" :class="{ 'show': showEdit }" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;" >
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.City.Edit') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <!-- Referance ID -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="reference_id">{{ $t('General.ReferenceId') }}</label>
                            <input :class="{ 'border-danger': errors.reference_id }" type="text" id="reference_id"
                                v-model="reference_id" class="form-control" placeholder="Enter reference ID">
                            <span class="text-danger">{{ errors.reference_id }}</span>
                        </div>

                        <!-- Arabic Name -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="ar_name">{{ $t('General.ArabicName') }}</label>
                            <input :class="{ 'border-danger': errors.ar_name }" type="text" id="ar_name"
                                v-model="ar_name" class="form-control" placeholder="Enter Arabic name">
                            <span class="text-danger">{{ errors.ar_name }}</span>
                        </div>

                        <!-- English Name -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="en_name">{{ $t('General.EnglishName') }}</label>
                            <input :class="{ 'border-danger': errors.en_name }" type="text" id="en_name"
                                v-model="en_name" class="form-control" placeholder="Enter English name">
                            <span class="text-danger">{{ errors.en_name }}</span>
                        </div>

                        <!-- Country Selection -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="country_id">{{ $t('General.Country') }}</label>
                            <select :class="{ 'border-danger': errors.country_id }" id="country_id" v-model="country_id"
                                class="form-select">
                                <option value="" disabled>{{ $t('General.Select.Country') }}</option>
                                <option v-for="country in countriesData" :key="country.id" :value="country.id">{{
                                    country.name }}</option>
                            </select>
                            <span class="text-danger">{{ errors.country_id }}</span>
                        </div>

                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-primary me-3" :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    {{ $t('General.Saving') }}
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>
                            <button type="button" class="btn btn-secondary" @click="closeModel">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n';
import { toast } from 'vue3-toastify';
import { editCities, getcountries } from '@/services/setting';

const emit = defineEmits(['close-edit']);

const props = defineProps({
    row: {
        type: Object,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true,
    },
});

const countriesData = ref([]);
const showEdit = ref(true);
const loading = ref(false);
const eRow = ref(props.row);

const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        reference_id: yup.string().required(i18n.global.t('Validation.Required.ReferenceID')),
        ar_name: yup.string().required(i18n.global.t('Validation.Required.ArabicName')),
        en_name: yup.string().required(i18n.global.t('Validation.Required.EnglishName')),
        country_id: yup.string().required(i18n.global.t('Validation.Required.Country')),
    }),
});

const { value: reference_id } = useField('reference_id');
reference_id.value = eRow.value.reference_id;

const { value: ar_name } = useField('ar_name');
ar_name.value = eRow.value.ar_name;

const { value: en_name } = useField('en_name');
en_name.value = eRow.value.en_name;

const { value: country_id } = useField('country_id');
country_id.value = eRow.value.country_id;

const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        await editCities(values, eRow.value.id);
        emit('close-edit', false);
        toast.success(i18n.global.t('Messages.Success.Edited'), { autoClose: 3000 });
        props.onEdit();
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.SubmitError'), error);
    } finally {
        loading.value = false;
    }
});

function closeModel() {
    emit('close-edit', false);
}

const fetchData = async () => {
    try {
        loading.value = true;
        const response = await getcountries();
        countriesData.value = response.data.data;
    } catch (error) {
        console.error(i18n.global.t('fetching.data.Error'), error);
    } finally {
        loading.value = false;
    }
};

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
  if (modal.value && !modal.value.contains(event.target) ) {
    closeModel();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});


onMounted(fetchData);
</script>
