<template>
    <div class="dropdown">
        <!-- Dropdown Trigger -->
        <button v-if="selectedStatus || defaultId === null" style="width: 100%;" class="btn dropdown-toggle text-white " id="dropdownMenuButton" data-bs-toggle="dropdown"
            aria-expanded="false" :style="{ backgroundColor: selectedStatus?.bg_color || '#ccc' }" >
                {{ selectedStatus?.name || "Select Status" }}
        </button>

        <!-- Dropdown Menu -->
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 100%;">
            <li v-for="item in formattedData" :key="item.id">
                <button class="dropdown-item" @click="selectStatus(item.id)" >
                    {{ item.name }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
    data: {
        type: Object,
        required: true, // Pass the dropdown data as a prop
    },
    defaultId: {
        type: Number,
        default: null, // Optional default ID
    },
    

});

const emit = defineEmits(["item-selected"]);


// Transform the `data` prop into a list for easier iteration
const formattedData = Object.values(props.data);

// Find the default item based on `defaultId`
const id = ref(props.defaultId);

const selectedStatus = computed(() =>

formattedData.find((status) => status.id === id.value)
);




const selectStatus = (status) => {

    if (id.value != status) {
        id.value = status
    }
    emit("status-changed", status);
    closeDropdown();
};
const closeDropdown = () => {
    const dropdownButton = document.getElementById("dropdownMenuButton");
    dropdownButton?.click(); // Toggles the dropdown off
};

</script>
<style scoped>
/* .btn:hover {
    background-color: rgba(128, 128, 128, 0.5);
} */

.blue-btn {
    color: #1168FF !important;
    background-color: rgba(17, 104, 255, 0.1) !important;
}

.col-lg-3 .dropdown button {
    min-width: 100% !important;
    display: flex;
    justify-content: space-between !important;
}
</style>