<template>
    <div class="modal fade" :class="{ 'show': showEdit }" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Packages.Edit') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <!-- Receiver Name -->
                        <div class="col-12">
                            <label class="form-label" for="receiverName">{{ $t('General.ReceiverName') }}</label>
                            <input :class="{ 'border-danger': errors.receiver_name }" type="text" id="receiverName"
                                v-model="receiver_name" class="form-control" placeholder="Enter receiver name" />
                            <span class="text-danger">{{ errors.receiver_name }}</span>
                        </div>

                        <!-- Notes -->
                        <div class="col-12">
                            <label class="form-label" for="notes">{{ $t('General.Notes') }}</label>
                            <textarea :class="{ 'border-danger': errors.notes }" id="notes" v-model="notes"
                                class="form-control form-textarea" rows="4"
                                placeholder="Enter additional notes"></textarea>
                            <span class="text-danger">{{ errors.notes }}</span>
                        </div>

                        <!-- Buttons -->
                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>
                            <button type="button" class="btn cancel-btn bg-green text-primary px-5" @click="closeModel"
                                aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n.js';
import { toast } from 'vue3-toastify';
import { editPackage } from '@/services/shipping';

const emit = defineEmits(['close-edit']);
const props = defineProps({
    row: {
        type: Object,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true,
    },
});

const showEdit = ref(true);
const loading = ref(false);
const eRow = ref(props.row);

const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        receiver_name: yup.string().required(i18n.global.t('Validation.Required.ReceiverName')),
        notes: yup.string().notRequired(),
    }),
});

const { value: receiver_name } = useField('receiver_name');
receiver_name.value = eRow.value.receiver_name;

const { value: notes } = useField('notes');
notes.value = eRow.value.notes;

const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        await editPackage(values, eRow.value.id);
        emit('close-edit', false);
        toast.success(i18n.global.t('Messages.Success.Edited'), { autoClose: 3000 });

        props.onEdit();
    } catch (e) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-edit', false);
}

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
  if (modal.value && !modal.value.contains(event.target) ) {
    closeModel();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});

</script>
<style scoped>
.form-textarea {
    min-height: 120px !important;
}
</style>