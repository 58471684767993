import { buildUrl } from "@/utils/buildUrl";
import axiosClient from "./axiosClient";


export function getStatistics(start_date, end_date) {
  const url = buildUrl("v1/dashboard/statistics", {
    start_date,
    end_date
  });

  return axiosClient({ url });
}

export function getBestProducts(start_date, end_date) {
  const url = buildUrl("v1/dashboard/bestSellProducts", {
    start_date,
    end_date
  });

  return axiosClient({ url });
}
