import axiosClient from "./axiosClient";

export function getServices() {
  return axiosClient({
    url   : `v1/dashboard/services`,
    method: 'get',
  })
}
export function addDeliveryIntegration(data) {
  return axiosClient({
    url   : `v1/dashboard/delivery-integrations/add`,
    method: 'POST',
    data
  })
}
export function editDeliveryIntegration(id , data) {
  return axiosClient({
    url   : `v1/dashboard/delivery-integrations/update/${id}`,
    method: 'POST',
    data
  })
}