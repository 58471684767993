<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
                <div class="col-xxl">
                    <!-- Unified Card for Tabs and Content -->
                    <div class="card">
                        <!-- Navigation Tabs -->
                        <div class="card-header pb-6">
                            <ul class="nav nav-pills mb-4 nav-fill" id="settingsTabs" role="tablist">
                                <li class="nav-item" role="presentation"
                                    v-if="authStore.hasPermission('admin-settings')">
                                    <button class="nav-link active" id="dqq-settings-tab" data-bs-toggle="tab"
                                        data-bs-target="#dqq-settings" type="button" role="tab"
                                        aria-controls="dqq-settings" aria-selected="true">
                                        {{ $t('Sections.Settings.DQQSettings') }}
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation"
                                    v-if="authStore.hasPermission('admin-settings')">
                                    <button class="nav-link" id="static-form-tab" data-bs-toggle="tab"
                                        data-bs-target="#static-form" type="button" role="tab"
                                        aria-controls="static-form" aria-selected="false">
                                        {{ $t('Sections.Settings.Sync_status_orders') }}
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation"
                                    v-if="authStore.hasPermission('view-notify-setting')">
                                    <button class="nav-link "
                                        :class="!authStore.hasPermission('admin-settings') ? 'active' : ''"
                                        id="notification-settings-tab" data-bs-toggle="tab"
                                        data-bs-target="#notification-settings" type="button" role="tab"
                                        aria-controls="notification-settings" aria-selected="true">
                                        {{ $t('Sections.Settings.NotificationSetting') }}
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation"
                                    v-if="authStore.hasPermission('view-order-settings')">
                                    <button class="nav-link"
                                        :class="!authStore.hasPermission('admin-settings') && !authStore.hasPermission('view-notify-setting') ? 'active' : ''"
                                        id="order-settings-tab" data-bs-toggle="tab" data-bs-target="#order-settings"
                                        type="button" role="tab" aria-controls="order-settings" aria-selected="true">
                                        {{ $t('Sections.Settings.OrderSettings') }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <hr>

                        <!-- Tab Contents -->
                        <div class="card-body">

                            <div class="tab-content" id="settingsTabsContent">

                                <!-- DQQ Settings Form -->
                                <div class="tab-pane fade show active" id="dqq-settings" role="tabpanel"
                                    v-if="authStore.hasPermission('admin-settings')" aria-labelledby="dqq-settings-tab">
                                    <form @submit.prevent="onSubmit">
                                        <div v-if="settingsData.length < 1" class="d-flex justify-content-center"
                                            style="    height: 50px;">
                                            <span class="spinner-border spinner-border-bg d-flex m-auto" role="status"
                                                aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div v-else class="row mb-6" v-for="(item, index) in settingsData" :key="index">
                                            <label class="col-sm-4 col-form-label" :for="'setting-' + index">
                                                {{ $t(item.key) }}
                                            </label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" :id="'setting-' + index"
                                                    v-model="item.value"
                                                    :placeholder="$t('General.Enter') + ' ' + $t(item.key)" />
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-end py-6" v-if="settingsData.length > 1">
                                            <button type="submit" class="btn btn-primary me-3" :disabled="loading">
                                                <span v-if="loading">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                    <span class="visually-hidden">Loading...</span>
                                                </span>
                                                <span v-else>{{ $t('General.Submit') }}</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                <!-- Static Form -->
                                <div class="tab-pane fade" id="static-form" role="tabpanel"
                                    v-if="authStore.hasPermission('admin-settings')" aria-labelledby="static-form-tab">
                                    <form @submit.prevent="onStaticSubmit">
                                        <!-- Select Client -->
                                        <div class="row mb-6" v-if="authStore.hasPermission('view-clients')">
                                            <label class="col-sm-4 col-form-label" for="select-client">
                                                {{ $t('General.Select.Client') }}
                                            </label>
                                            <div class="col-sm-8">
                                                <select id="selectClient" v-model="staticform.client_id"
                                                    class="form-select">
                                                    <option value="" disabled>Select a client</option>
                                                    <option disabled v-if="ClientsData.length < 1">Loading ...</option>
                                                    <option v-for="client in ClientsData" :key="client.id"
                                                        :value="client.id">
                                                        {{ client.client_name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- Select Status -->
                                        <div class="row mb-6">
                                            <label class="col-sm-4 col-form-label" for="select-status">
                                                {{ $t('General.Select.Status') }}
                                            </label>
                                            <div class="col-sm-8">
                                                <select id="selectStatus" v-model="staticform.status_id"
                                                    class="form-select">
                                                    <option value="" disabled>Select a status</option>
                                                    <option disabled v-if="StatusData.length < 1">Loading ...</option>
                                                    <option v-for="status in StatusData" :key="status.id"
                                                        :value="status.id">
                                                        {{ status.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- Submit Button -->
                                        <div class="d-flex justify-content-end py-6">
                                            <button type="submit" class="btn btn-primary me-3" :disabled="loading">
                                                <span v-if="loading">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                    <span class="visually-hidden">Loading...</span>
                                                </span>
                                                <span v-else>{{ $t('General.Submit') }}</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <!-- Notification Form -->
                                <div class="tab-pane fade " id="notification-settings" role="tabpanel"
                                    :class="!authStore.hasPermission('admin-settings') ? 'show active' : ''"
                                    v-if="authStore.hasPermission('view-notify-setting')"
                                    aria-labelle0dby="notification-settings-tab">
                                    <form @submit.prevent="onNotificationSubmit">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="row mb-6">
                                                    <label class="col-sm-8 col-form-label" for="new-notify">
                                                        {{ $t('General.NewNotify') }}
                                                        اشعار الطلبات المتأخره من جديد
                                                    </label>
                                                    <div class="col-sm-4">
                                                        <input class="form-check-input" type="checkbox"
                                                            v-model="form.new_notify" id="new-notify" />
                                                    </div>
                                                </div>
                                                <div class="row mb-6">
                                                    <label class="col-sm-8 col-form-label" for="preparing-notify">
                                                        {{ $t('General.ReparingNotify') }}
                                                        اشعار الطلبات المتأخره من جانب التجهيز
                                                    </label>
                                                    <div class="col-sm-4">
                                                        <input class="form-check-input" type="checkbox"
                                                            v-model="form.preparing_notify" id="preparing-notify" />
                                                    </div>
                                                </div>
                                                <div class="row mb-6">
                                                    <label class="col-sm-8 col-form-label" for="ready-notify">
                                                        {{ $t('General.ReadyNotify') }}
                                                        اشعار الطلبات المتخره من جاهز
                                                    </label>
                                                    <div class="col-sm-4">
                                                        <input class="form-check-input" type="checkbox"
                                                            v-model="form.ready_notify" id="ready-notify" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="row mb-6">
                                                    <label class="col-sm-4 col-form-label" for="days-notify">
                                                        {{ $t('General.DaysNotify') }}
                                                        عدد ايام التأخير
                                                    </label>
                                                    <div class="col-sm-8">
                                                        <input class="form-control" type="number"
                                                            v-model="form.days_notify" id="days-notify" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-end py-6">
                                            <button type="submit" class="btn btn-primary me-3" :disabled="Syncloading">
                                                <span v-if="Syncloading">
                                                    <span class="spinner-border spinner-border-sm"
                                                        aria-hidden="true"></span>
                                                    <span class="visually-hidden">Loading...</span>
                                                </span>
                                                <span v-else>{{ $t('General.Submit') }}</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                <!-- order Form -->
                                <div class="tab-pane fade" id="order-settings" role="tabpanel"
                                    :class="!authStore.hasPermission('admin-settings') && !authStore.hasPermission('view-notify-setting') ? 'show active' : ''"
                                    v-if="authStore.hasPermission('view-order-settings')"
                                    aria-labelledby="order-settings-tab">
                                    <form @submit.prevent="onOrderSubmit">
                                        <div class="row mb-6" v-if="authStore.hasPermission('view-clients')">
                                            <label class="col-sm-4 col-form-label" for="select-client">
                                                {{ $t('General.Select.Client') }}
                                            </label>
                                            <div class="col-sm-8">
                                                <select id="selectClient" v-model="formOrder.selected_client"
                                                    class="form-select">
                                                    <option value="" disabled>Select a client</option>
                                                    <option disabled v-if="ClientsData.length < 1">Loading ...</option>
                                                    <option v-for="client in ClientsData" :key="client.id"
                                                        :value="client.id">
                                                        {{ client.client_name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <!-- Radio Buttons for Status -->
                                            <hr>

                                            <div class="row mb-6">
                                                <label class="col-sm-4 col-form-label">
                                                    {{ $t('General.Status') }}
                                                </label>
                                                <div class="col-sm-8 row  mb-6">

                                                    <div class="form-check col-4 col-auto"
                                                        v-for="(value, key) in formOrder.status" :key="key">
                                                        <input class="form-check-input" type="radio"
                                                            v-model="formOrder.user_view_order_in_application"
                                                            :value="value" :id="`status-${key}`" />
                                                        <label class="form-check-label" :for="`status-${key}`">
                                                            {{ key }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Radio Buttons for Scan Column -->
                                            <hr>

                                            <div class="row mb-6">
                                                <label class="col-sm-4 col-form-label">
                                                    {{ $t('General.ScanColumn') }}
                                                </label>
                                                <div class="col-sm-8 row mb-6">
                                                    <div class="form-check col-6 col-auto"
                                                        v-for="(value, key) in formOrder.scan_column" :key="key">
                                                        <input class="form-check-input" type="radio"
                                                            v-model="formOrder.user_scan_column" :value="value"
                                                            :id="`scan-column-${key}`" />
                                                        <label class="form-check-label" :for="`scan-column-${key}`">
                                                            {{ key }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="col-6">
                                                <!-- Checkbox for Increase Inventory -->
                                                <div class="row mb-6">
                                                    <label class="col-sm-8 col-form-label" for="increase-inventory">
                                                        {{ $t('General.IncreaseInventory') }}
                                                    </label>
                                                    <div class="col-sm-4">
                                                        <input class="form-check-input" type="checkbox"
                                                            v-model="formOrder.increase_inventory_when_the_order_is_returned_or_cancelled"
                                                            id="increase-inventory" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-end py-6">
                                            <button type="submit" class="btn btn-primary me-3" :disabled="Syncloading">
                                                <span v-if="Syncloading">
                                                    <span class="spinner-border spinner-border-sm"
                                                        aria-hidden="true"></span>
                                                    <span class="visually-hidden">Loading...</span>
                                                </span>
                                                <span v-else>{{ $t('General.Submit') }}</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Unified Card -->
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { getDqqSetting, updateDqqSetting, updateDqqSettingSync, updateNotificationSetting, updateOrderSetting } from "@/services/setting";
import { onMounted, ref, computed } from "vue";
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from "@/stores/auth";
const { t } = useI18n();

const errorMessage = computed(() => t('Messages.Error.SubmitError'));
const successMessage = computed(() => t('Messages.Success.Edited'));
const missingFieldsMessage = computed(() => t('Messages.Error.MissingFields'));

const settingsData = ref([]);
const ClientsData = ref([]);
const StatusData = ref([]);
const NotifyData = ref([]);
const loading = ref(false);
const Syncloading = ref(false);

const authStore = useAuthStore();

const form = ref({
    new_notify: false,
    preparing_notify: false,
    ready_notify: false,
    days_notify: 0,
});
const staticform = ref({
    client_id: '', // Selected client
    status_id: '', // Selected status
});
const formOrder = ref({
    selected_client: null,
    user_view_order_in_application: null,
    user_scan_column: null,
    increase_inventory_when_the_order_is_returned_or_cancelled: false,
    status: {},
    scan_column: {},
});


const onSubmit = async () => {
    try {
        loading.value = true;

        // Validate data
        if (settingsData.value.some(setting => !setting.value)) {
            toast.error(missingFieldsMessage.value, {
                autoClose: 3000,
            });
            return;
        }

        // Transform the array back into an object
        const updatedSettings = settingsData.value.reduce((acc, setting) => {
            acc[setting.key] = setting.value;
            return acc;
        }, {});

        // Send the transformed data to the API
        await updateDqqSetting(updatedSettings);

        toast.success(successMessage.value, {
            autoClose: 3000, // Adjust time if needed
        });
    } catch (e) {
        console.error(errorMessage.value, e);
    } finally {
        loading.value = false;
    }
};
const onStaticSubmit = async () => {
    // Prepare the payload
    const user = JSON.parse(localStorage.getItem('user'));
    const payload = {
        client_id: staticform.value.client_id || user.client_id,
        zid_status: staticform.value.status_id,
    };
    try {
        loading.value = true;

        // Send the transformed data to the API

        await updateDqqSettingSync(payload);

        toast.success(successMessage.value, {
            autoClose: 3000, // Adjust time if needed
        });
    } catch (e) {
        console.error(errorMessage.value, e);
    } finally {
        loading.value = false;
    }
};
const onNotificationSubmit = async () => {
    const payload = {
        new_notify: form.value.new_notify ? 1 : 0,
        preparing_notify: form.value.preparing_notify ? 1 : 0,
        ready_notify: form.value.ready_notify ? 1 : 0,
        days_notify: form.value.days_notify,
    };
    try {
        Syncloading.value = true;
        // Send the transformed data to the API

        await updateNotificationSetting(payload);

        toast.success(successMessage.value, {
            autoClose: 3000, // Adjust time if needed
        });
    } catch (e) {
        console.error(errorMessage.value, e);
    } finally {
        Syncloading.value = false;
    }
};
const onOrderSubmit = async () => {
    const payload = {
        increase_inventory_when_the_order_is_returned_or_cancelled:
            formOrder.value.increase_inventory_when_the_order_is_returned_or_cancelled ? 1 : 0,
        scan_column: formOrder.value.user_scan_column,
        recive_order_status: formOrder.value.user_view_order_in_application,
        client_id: authStore.isClient?authStore.user?.id : formOrder.value.selected_client,
    };
    try {
        Syncloading.value = true;
        // Send the transformed data to the API

        await updateOrderSetting(payload);

        toast.success(successMessage.value, {
            autoClose: 3000, // Adjust time if needed
        });
    } catch (e) {
        console.error(errorMessage.value, e);
    } finally {
        Syncloading.value = false;
    }
};


const fetchData = async () => {
    try {
        const response = await getDqqSetting()

        settingsData.value = Object.entries(response.data.data.settings).map(([key, setting]) => ({
            key,
            value: setting.value,
        }));
        const notificatinSettingsResponse = response.data.data.notify
        form.value.new_notify = notificatinSettingsResponse.new_notify == 1 ? true :false
        form.value.preparing_notify = notificatinSettingsResponse.preparing_notify== 1 ? true :false
        form.value.ready_notify = notificatinSettingsResponse.ready_notify== 1 ? true :false
        form.value.days_notify = notificatinSettingsResponse.days_notify

        ClientsData.value = response.data.data.clients
        StatusData.value = Object.values(response.data.data.statuses)


        NotifyData.value = response.data.data.notify



        const orderSettingsResponse = response.data.data.order_settings
        formOrder.value.status = orderSettingsResponse.status
        formOrder.value.scan_column = orderSettingsResponse.scan_column

        formOrder.value.user_scan_column = orderSettingsResponse.user_scan_column
        formOrder.value.user_view_order_in_application = orderSettingsResponse.user_view_order_in_application
        formOrder.value.increase_inventory_when_the_order_is_returned_or_cancelled
            = orderSettingsResponse.increase_inventory_when_the_order_is_returned_or_cancelled == 1 ? true :false



    } catch (error) {
        console.error(t('Messages.Error.FetchData'), error);
    }
}

onMounted(fetchData)
</script>
