<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.Users') }}</h3>
            <DataTable v-model:rows="EmployeesData" :headers="headers" :fields="fields" :total="total"
                :loading="loading" @page-changed="onPageChanged">
                <template v-slot:head-contant="{}">

                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <svg width="36" height="37" class="color-green" viewBox="0 0 36 37" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M23.25 11.4501C23.25 14.3496 20.8995 16.7001 18 16.7001C15.1005 16.7001 12.75 14.3496 12.75 11.4501C12.75 8.55058 15.1005 6.20007 18 6.20007C20.8995 6.20007 23.25 8.55058 23.25 11.4501Z"
                                    fill="#24A259" />
                                <path
                                    d="M27 24.95C27 27.8495 22.9706 30.2 18 30.2C13.0294 30.2 9 27.8495 9 24.95C9 22.0505 13.0294 19.7 18 19.7C22.9706 19.7 27 22.0505 27 24.95Z"
                                    fill="#24A259" />
                                <path
                                    d="M10.6831 7.69992C10.9493 7.69992 11.2091 7.72604 11.4601 7.77577C10.8487 8.86161 10.5 10.115 10.5 11.4499C10.5 12.7523 10.832 13.9771 11.4159 15.0445C11.1787 15.0887 10.9337 15.1118 10.6831 15.1118C8.56145 15.1118 6.84154 13.4526 6.84154 11.4059C6.84154 9.35913 8.56145 7.69992 10.6831 7.69992Z"
                                    fill="#24A259" />
                                <path
                                    d="M8.17101 28.6789C7.31913 27.6605 6.75 26.4109 6.75 24.9499C6.75 23.5336 7.28486 22.3159 8.09367 21.315C5.23665 21.5366 3 23.0992 3 24.994C3 26.9065 5.27595 28.4807 8.17101 28.6789Z"
                                    fill="#24A259" />
                                <path
                                    d="M25.5001 11.45C25.5001 12.7524 25.1682 13.9772 24.5842 15.0446C24.8214 15.0888 25.0664 15.1119 25.317 15.1119C27.4387 15.1119 29.1586 13.4527 29.1586 11.406C29.1586 9.35922 27.4387 7.70001 25.317 7.70001C25.0509 7.70001 24.791 7.72613 24.54 7.77586C25.1514 8.8617 25.5001 10.1151 25.5001 11.45Z"
                                    fill="#24A259" />
                                <path
                                    d="M27.8291 28.679C30.7242 28.4808 33.0001 26.9066 33.0001 24.9941C33.0001 23.0993 30.7635 21.5367 27.9064 21.3151C28.7153 22.316 29.2501 23.5337 29.2501 24.95C29.2501 26.411 28.681 27.6606 27.8291 28.679Z"
                                    fill="#24A259" />
                            </svg>
                            <h3 class="table-title">{{ $t('Sections.Employees.List') }}</h3>
                        </div>
                        <!-- <input type="search" class="form-control" placeholder="" v-model.lazy="searchQuery"
                                aria-controls="DataTables_Table_0"> -->
                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31" v-model.lazy="searchQuery">
                        </div>
                    </div>
                    <div class="card-header flex-column flex-md-row pb-0">

                        <div class="dt-action-buttons text-end pt-6 pt-md-0">
                            <div class="dt-buttons btn-group flex-wrap">

                                <button class="btn btn-secondary create-new btn-primary" tabindex="0"
                                    @click="onAddRow()" aria-controls="DataTables_Table_0" type="button"><span><i
                                            class="bx bx-plus bx-sm me-sm-2"></i> <span
                                            class="d-none d-sm-inline-block">
                                            {{ $t('Sections.Employees.AddNew') }}
                                        </span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:action-buttons="{ rowColum }">
                    <td class="text-center align-middle" v-if="authStore.hasPermission('view-clients')">
                        {{ rowColum.client_name }}
                    </td>
                    <td v-if="rowColum.status == '0'" class="text-center align-middle">
                        <button type="button" class="btn btn-sm succ-btn m-0" @click="onStatusRow(rowColum)">

                            {{ $t('General.Pending') }}
                        </button>
                    </td>
                    <td v-else-if="rowColum.status == '1'" class="text-center align-middle">
                        <button type="button" class="btn btn-sm blue-btn" @click="onStatusRow(rowColum)">
                            {{ $t('General.Approved') }}
                        </button>
                    </td>
                    <td v-else class="text-center align-middle">
                        <button type="button" class="btn btn-sm bg-red-light" @click="onStatusRow(rowColum)">
                            {{ $t('General.Blocked') }}
                        </button>
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.created_at }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.updated_at }}
                    </td>
                    <td class="text-center align-middle">
                        <div class="dropdown ">
                            <button class="dropdown-toggle custom-dropdown-toggle green-color" type="button"
                                id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                <svg width="4" height="16" viewBox="0 0 4 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.99984 6.16666C0.991504 6.16666 0.166504 6.99166 0.166504 7.99999C0.166504 9.00832 0.991504 9.83332 1.99984 9.83332C3.00817 9.83332 3.83317 9.00832 3.83317 7.99999C3.83317 6.99166 3.00817 6.16666 1.99984 6.16666ZM1.99984 0.666656C0.991504 0.666656 0.166504 1.49166 0.166504 2.49999C0.166504 3.50832 0.991504 4.33332 1.99984 4.33332C3.00817 4.33332 3.83317 3.50832 3.83317 2.49999C3.83317 1.49166 3.00817 0.666656 1.99984 0.666656ZM1.99984 11.6667C0.991504 11.6667 0.166504 12.4917 0.166504 13.5C0.166504 14.5083 0.991504 15.3333 1.99984 15.3333C3.00817 15.3333 3.83317 14.5083 3.83317 13.5C3.83317 12.4917 3.00817 11.6667 1.99984 11.6667Z"
                                        fill="#24A259" />
                                </svg>

                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>
                                    <a class="dropdown-item" @click="onEditRow(rowColum)">
                                        <i class="bx bxs-edit-alt mx-3"></i> {{ $t('General.Dropdown.Edit') }}
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="onStatusRow(rowColum)">
                                        <i class="bx bxs-shield-minus mx-3 "></i> {{ $t('General.Dropdown.Status') }}
                                    </a>
                                </li>
                                <li  v-if="authStore.hasPermission('delete-employer')">
                                    <a class="dropdown-item" @click="triggerDeleteModal(rowColum)">
                                        <i class="bx bxs-trash mx-3"></i> {{ $t('General.Dropdown.Delete') }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </td>
                </template>

            </DataTable>
            <AddEmployee v-if="showAdd" @close-add="onCloseAdd" :onAdd="fetchData" />
            <EditEmployee v-if="showEdit" :row="editRow" @close-edit="onCloseEdit" :onEdit="fetchData" />
            <StatusEmployee v-if="showStatus" :row="editRow" @close-status="onCloseStatus"
                :onStatusChange="fetchData" />
            <DeleteUserModal ref="deleteModal" :row="editRow" :onDelete="handleDelete"
                :title="$t('Sections.Employees.Delete')" />





        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';

import i18n from '@/i18n.js';
import { formatDateToYMDHMS } from '@/utils/dateFormatter';
import { deleteEmployee, getEmployees } from '@/services/employee';
import EditEmployee from './componants/EditEmployee.vue';
import AddEmployee from './componants/AddEmployee.vue';
import StatusEmployee from './componants/StatusEmployee.vue';
import { useAuthStore } from '@/stores/auth';
import DeleteUserModal from '@/components/pages/DeleteUserModal.vue';
import { toast } from 'vue3-toastify';

const authStore = useAuthStore();

// State
// Create a reference to the DeleteModal component
const EmployeesData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const searchQuery = ref('');

//for Action btn
const editRow = ref({});




// Table headers an
// d fields
const headers =
computed(()=>
    [
        '#', i18n.global.t('Auth.Username'),
        i18n.global.t('General.Email'), i18n.global.t('Sections.Employees.Name'),
        authStore.hasPermission('view-clients') ? i18n.global.t('Sections.Clients.Name') : null,
         i18n.global.t('General.Status'),
          i18n.global.t('General.CreatedAt'),
        i18n.global.t('General.UpdatedAt'), ''
    ]);
const fields = ['ids', 'user_name', 'user_email', 'user_userName'];

// Fetch users with pagination
const fetchData = async () => {

    try {
        loading.value = true;
        EmployeesData.value = [];
        const response = await getEmployees(currentPage.value, searchQuery.value);
        let count = 1 ; 

        for (const row of response.data.data.data) {

            EmployeesData.value.push({
                ...row,
                ids:   (((currentPage.value??1) -1 ) *10) + count++ , 

                created_at: formatDateToYMDHMS(row.created_at),
                updated_at: formatDateToYMDHMS(row.updated_at),
                // client_name: row.client && row.client.name ? row.client.name : "NAN",
                client_name: row.client && row.client.name ? row.client.name : "NAN",
                user_email: row.user && row.user.email ? row.user.email : "NAN",
                user_name: row.user && row.user.name ? row.user.name : "NAN",
                user_userName: row.user && row.user.user_name ? row.user.user_name : "NAN",
                warehouse_name: row.warehouse && row.warehouse.name ? row.warehouse.name : "NAN",
                product_name: row.product && row.product.name ? row.product.name : "NAN",

            });

        }
        // EmployeesData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};

// Fetch users on mount
onMounted(fetchData);

// Watch for changes on searchQuery
watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});

const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};


//add
const showAdd = ref(false);
function onAddRow() {
    showAdd.value = !showAdd.value;
}
function onCloseAdd() {
    showAdd.value = !showAdd.value;

}


//edit
const showEdit = ref(false);
function onEditRow(row) {
    showEdit.value = !showEdit.value;

    editRow.value = row;

}
function onCloseEdit() {
    showEdit.value = !showEdit.value;

}

//status
const showStatus = ref(false);
function onStatusRow(row) {
    showStatus.value = !showStatus.value;

    editRow.value = row;

}
function onCloseStatus() {
    showStatus.value = !showStatus.value;

}

//delete
const deleteModal = ref(null);
const handleDelete = async () => {
    try {
        loading.value = true;
        await deleteEmployee(editRow.value.id);
        fetchData();
        toast.success(i18n.global.t('Messages.Success.Deleted'), {
            autoClose: 3000, // Adjust time if needed
        });

        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error('Error fetching users:', error);
    }
};
const triggerDeleteModal = (row) => {
    
    editRow.value = row;

    deleteModal.value.openModal();
};




</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.custom-dropdown-toggle {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
}

.custom-dropdown-toggle::after {
    display: none;
    /* Remove the default arrow */
}

button {
  min-width: max-content;
} 

</style>