<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.DeleveryCompany') }}</h3>
            <DataTable v-model:rows="DeleveryCompanyData" :headers="headers" :fields="fields" :total="total"
                :loading="loading" @page-changed="onPageChanged">
                <template v-slot:head-contant="{}">

                    <!-- <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <h3 class="table-title">{{ $t('Sections.DeliveryCompany.List') }}</h3>
                        </div>
                        <div
                            class="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-end mt-n6 mt-md-0">
                            <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                <label>{{ $t('General.Search') }}:<input type="search" class="form-control"
                                        placeholder="" v-model.lazy="searchQuery" aria-controls="DataTables_Table_0"></label>
                            </div>
                        </div>
                    </div> -->
                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <i class="fa-solid fa-truck color-green"></i>
                            <h3 class="table-title">{{ $t('Sections.DeliveryCompany.List') }}</h3>
                        </div>
                        <!-- <input type="search" class="form-control" placeholder="" v-model.lazy="searchQuery"
                                aria-controls="DataTables_Table_0"> -->
                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31" v-model.lazy="searchQuery">
                        </div>
                    </div>

                </template>

                <template v-slot:action-buttons="{ rowColum }">

                    <td class="text-center align-middle">
                        <img :src="rowColum.image_url ?? img" width="75px" @error="replaceByDefault">
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.name }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.code }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.delivery_cost }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.created_at }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.updated_at }}
                    </td>


                    <!-- <td>
                    <button @click="triggerDeleteModal(rowColum)" class="btn btn-sm delete-btn">
                        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 3.74921C0.5 3.48622 0.717806 3.27302 0.986484 3.27302H4.17858C4.18292 2.7122 4.2437 1.94336 4.80025 1.41112C5.23824 0.992261 5.83872 0.733337 6.49999 0.733337C7.16127 0.733337 7.76174 0.992261 8.19974 1.41112C8.75629 1.94336 8.81707 2.7122 8.82141 3.27302H12.0135C12.2822 3.27302 12.5 3.48622 12.5 3.74921C12.5 4.0122 12.2822 4.2254 12.0135 4.2254H0.986484C0.717806 4.2254 0.5 4.0122 0.5 3.74921Z" fill="#EE1D52"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.2304 14.0667H6.7696C8.62474 14.0667 9.55231 14.0667 10.1554 13.4761C10.7585 12.8854 10.8202 11.9166 10.9436 9.97901L11.1214 7.18707C11.1884 6.13574 11.2219 5.61008 10.9193 5.27697C10.6167 4.94386 10.1058 4.94386 9.08397 4.94386H3.91603C2.89418 4.94386 2.38325 4.94386 2.0807 5.27697C1.77815 5.61008 1.81163 6.13574 1.87858 7.18707L2.05639 9.979C2.1798 11.9166 2.2415 12.8854 2.84459 13.4761C3.44769 14.0667 4.37526 14.0667 6.2304 14.0667ZM5.33085 7.52566C5.30337 7.23642 5.05835 7.0254 4.78358 7.05432C4.50881 7.08325 4.30834 7.34116 4.33581 7.6304L4.66915 11.1392C4.69662 11.4284 4.94165 11.6394 5.21642 11.6105C5.49119 11.5816 5.69166 11.3237 5.66419 11.0344L5.33085 7.52566ZM8.21642 7.05432C8.49119 7.08325 8.69166 7.34116 8.66419 7.6304L8.33085 11.1392C8.30338 11.4284 8.05835 11.6394 7.78358 11.6105C7.50881 11.5816 7.30834 11.3237 7.33581 11.0344L7.66915 7.52566C7.69663 7.23642 7.94165 7.0254 8.21642 7.05432Z" fill="#EE1D52"/>
</svg>

                    </button>
                </td> -->



                </template>

            </DataTable>

            <!-- <AddWarehouse v-if="showAdd" @close-add="onCloseAdd" :onAdd="fetchData" /> -->
            <DeleteModal ref="deleteModal" :row="editRow" :onDelete="handleDelete" />




        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { formatDateToYMDHMS } from '@/utils/dateFormatter';
import DeleteModal from '@/components/pages/DeleteModal.vue';
import { getDeleveryCompany } from '@/services/setting';

import img from "@/assets/dashboard/img/dqqicon.png"
import { toast } from 'vue3-toastify';
// State
// Create a reference to the DeleteModal component
const DeleveryCompanyData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const searchQuery = ref('');

//for Action btn
const editRow = ref({});

const errorMessage = computed(() => t('Messages.Error.FetchData'));
const successMessage = computed(() => t('Messages.Success.Deleted'));

// Table headers and fields
const headers = computed(() => [
    '#', 
    t('General.RefernceId'),
    t('General.Image'), 
    t('General.Name'),
    t('General.Code'), 
    t('Sections.DeleveryCompany.DeliveryCost'),
    t('General.CreatedAt'), 
    t('General.UpdatedAt'), 
    ''
]);
const fields = ['ids', 'reference_id'];

// {
//                 "id": 603954,
//                 "reference_id": "603954",
//                 "name": "Torod United Arab Emirates",
//                 "code": "none",
//                 "image": "storage/uploads/DeliveryCompany/wXGL6l8Ffyel1725701502.png",
//                 "delivery_cost": 0,
//                 "created_at": "2024-08-01T07:31:21.000000Z",
//                 "updated_at": "2024-09-07T09:31:42.000000Z",
//                 "image_url": "https://sub.dqq.app/storage/uploads/DeliveryCompany/wXGL6l8Ffyel1725701502.png"
//             },

// Fetch users with pagination
const fetchData = async () => {

    try {
        loading.value = true;
        DeleveryCompanyData.value = [];

        const response = await getDeleveryCompany(currentPage.value, searchQuery.value);
        let count = 1;

        for (const row of response.data.data.data) {

            DeleveryCompanyData.value.push({
                ...row,
                ids:   (((currentPage.value??1) -1 ) *10) + count++ , 

                created_at: formatDateToYMDHMS(row.created_at),
                updated_at: formatDateToYMDHMS(row.updated_at),
            });

        }
        // DeleveryCompanyData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(errorMessage.value, error);
    }
};

// Fetch users on mount
onMounted(fetchData);
// Watch for changes on searchQuery
watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});
const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};
const replaceByDefault = (e) => {
    e.target.src = img
    // You can now perform actions based on the page change, like refetching data.
};



//delete
const deleteModal = ref(null);
const handleDelete = async () => {
    try {
        loading.value = true;
        // await deleteEmployee(editRow.value.id);
        fetchData();
        toast.success(successMessage.value, {
            autoClose: 3000, // Adjust time if needed
        });
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(errorMessage.value, error);
    }
};
// const triggerDeleteModal = (row) => {
//     editRow.value = row;

//     deleteModal.value.openModal();
// };




</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>