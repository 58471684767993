<template>
    <div class="modal fade" :class="{ 'show': showEdit }" id="editUser" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;" >
        <div class="modal-dialog modal-lg modal-simple modal-edit-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <div class="text-center pb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Users.Edit') }}</h4>
                    </div>
                    <form class="row g-6 pt-4" @submit.prevent="onSubmit">
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="modalEditUserName">{{ $t('General.Name') }}</label>
                            <input type="text" id="modalEditUserName" v-model="name" class="form-control"
                                :class="{ 'border-danger': errors.name }" placeholder="John">
                            <span class="text-danger">{{ errors.name }}</span>
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="modalEditUserName">{{ $t('Auth.Username') }}</label>
                            <input type="text" id="modalEditUserName" v-model="user_name" class="form-control"
                                :class="{ 'border-danger': errors.user_name }" placeholder="John">
                            <span class="text-danger">{{ errors.user_name }}</span>
                        </div>
                        <div class="col-12">
                            <label class="form-label" for="modalEditUserEmail">{{ $t('General.Email') }}</label>
                            <input type="email" id="modalEditUserEmail" v-model="email" class="form-control"
                                :class="{ 'border-danger': errors.email }" placeholder="example@domain.com">
                            <span class="text-danger">{{ errors.email }}</span>
                        </div>

                        <div class="col-12">
                            <label for="defaultSelect" class="form-label">{{ $t('General.Roles') }}</label>
                            <select id="defaultSelect" v-model="role" class="form-select select-w  form-custom-select-control select-w"
                                :class="{ 'border-danger': errors.role }">
                                <option disabled v-if="rolesData.length < 1">loading ...</option>
                                <option v-else v-for="role in rolesData" :key="role.id" :value="role.id">{{ role.name }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.role }}</span>
                        </div>
                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5"
                                data-bs-dismiss="modal" @click="closeModel" aria-label="Close">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getRoles } from '@/services/role';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { editUser } from '@/services/user';
import { toast } from 'vue3-toastify';
import i18n from '@/i18n.js';

const emit = defineEmits(['close-edit']);

const props = defineProps({
    showEdit: {
        type: Boolean,
        default: true,
    },
    row: {
        type: Object,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true
    },

});

const rolesData = ref([]);
const loading = ref(false);
const eRow = ref(props.row);

// Watch props.row and update form values accordingly
watch(
    () => props.row,
    (newRow) => {
        name.value = newRow.name;
        email.value = newRow.email;
        user_name.value = newRow.user_name;
        role.value = newRow.role;
    },
    { deep: true }
);

// Form fields and validation schema using VeeValidate and yup
const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        name: yup
            .string()
            .required(i18n.global.t('Validation.Required.Name'))
            .min(3, i18n.global.t('Validation.Length.Min3'))
            .max(50, i18n.global.t('Validation.Length.Max50')),
        user_name: yup
            .string()
            .required(i18n.global.t('Validation.Required.Username'))
            .min(3, i18n.global.t('Validation.Length.Min3'))
            .max(50, i18n.global.t('Validation.Length.Max50')),
        email: yup
            .string()
            .email(i18n.global.t('Validation.Invalid.Email'))
            .required(i18n.global.t('Validation.Required.Email')),
        role: yup
            .string()
            .required(i18n.global.t('Validation.Required.Roles'))
    })
});

// Use `useField` to bind individual form fields
const { value: name } = useField('name');
name.value = eRow.value.name;
const { value: user_name } = useField('user_name', props.row.user_name);
user_name.value = eRow.value.user_name;
const { value: email } = useField('email', props.row.email);
email.value = eRow.value.email;
const { value: role } = useField('role', props.row.role);
role.value = eRow.value.roles.length > 0 ? eRow.value.roles[0].id : null;

const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        await editUser(values, props.row.id);
        emit('close-edit', false);
        props.onEdit();
        toast.success(i18n.global.t('Messages.Success.Edited')
            , {
                autoClose: 3000, // Adjust time if needed
            });
    } catch (e) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-edit', false);
}

const fetchRoles = async () => {
    try {
        loading.value = true;
        const response = await getRoles();
        // const selected = response.data.data;
        // rolesData.value = response.data.data.filter(role => role.id !== props.row.roles.id);
        rolesData.value = response.data.data;
        // rolesData.value = response.data.data.filter(role => role.id !== props.row.role);
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};

onMounted(fetchRoles);

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
  if (modal.value && !modal.value.contains(event.target) ) {
    closeModel();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});

</script>

<style scoped>
.modal.show {
    display: block !important;
    /* Force the modal to be displayed when `show` is true */
}
</style>