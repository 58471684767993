import { ref } from "vue";
import axiosClient from "./axiosClient"

export function getConditions(page = 1 , perPage = 10, searchQuery = '') {
  const url =  ref('');
  if(searchQuery){
   url.value =  `v1/dashboard/settings/conditions?page=${page}&per_page=${perPage}&search=${searchQuery}` ;
  }else{
   url.value =  `v1/dashboard/settings/conditions?page=${page}&per_page=${perPage}` ;

  }
  return axiosClient({
                   url   : url.value,
                 })
}
export function storeConditions(data) {

  return axiosClient({
                   url   : `v1/dashboard/settings/store-condition`,
                   method : 'post',
                   data
                 })
}
export function deleteConditions( id) {
  return axiosClient({
                   url   : `v1/dashboard/settings/delete-condition/${id}`,
                   method: 'DELETE',
                 })
}
export function toggleConditions(data) {
  return axiosClient({
                   url   : `v1/dashboard/settings/condition/toggle-status`,
                   method: 'post',
                   data
                 })
}