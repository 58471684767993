<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <form @submit.prevent="onSubmit">
                <div class="app-ecommerce">
                    <div
                        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
                        <div class="d-flex flex-column justify-content-center">
                            <h4 class="mb-1">{{ t('General.AddNewProduct') }}</h4>
                        </div>
                        <div class="d-flex align-content-center flex-wrap gap-4">
                            <div class="d-flex gap-4">
                                <button class="btn btn-label-secondary">{{ t('General.Discard') }}</button>
                                <!-- <button class="btn btn-label-primary">{{ t('General.SaveDraft') }}</button> -->
                            </div>
                            <button type="submit" class="btn btn-primary">{{ t('General.PublishProduct') }}</button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-8">

                            <div class="card mb-6">
                                <div class="card-header">
                                    <h5 class="card-title mb-0">{{ t('General.Products') }}
                                        <span class="required text-danger fw-bold ">*</span>

                                    </h5>

                                </div>
                                <div class="card-body">
                                    <div class="row g-6 mb-6">
                                        <div class="col-6">
                                            <label class="form-label">{{ t('General.Select.Warehouse') }}</label>
                                            <select v-model="warehouse_id" class="form-select">
                                                <option v-for="warehouse in warehousesData" :key="warehouse.id"
                                                    :value="warehouse.id">
                                                    {{ warehouse.name }}</option>

                                            </select>
                                            <span class="text-danger">{{ errors.warehouse_id
                                                }}</span>
                                        </div>
                                        <div class="col-6">
                                            <div v-if="productLoading" class="loading-screen">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </div>
                                            <label class="form-label">{{ t('General.Products') }}</label>
                                            <div class="input-group" @click="showProductsFunction()">
                                                <button class="btn btn-outline-primary" type="button"
                                                    id="button-addon1">choices</button>
                                                <input type="text" class="form-control" placeholder=""
                                                    aria-describedby="button-addon1">
                                            </div>
                                        </div>


                                    </div>
                                    <div class="row g-6 mb-6">
                                        <table class="table table-hover">
                                            <thead class="bg-green">
                                                <tr>
                                                    <th class="text-center action-cells ">
                                                        <!-- <div class="checkbox-wrapper  text-center">
                                                            <input type="checkbox" class="form-check-input" />
                                                        </div> -->
                                                    </th>
                                                    <th class="text-center ">#</th>
                                                    <th class="text-center ">{{ $t('General.Name') }}</th>
                                                    <th class="text-center ">{{ $t('General.Quantity') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-border-bottom-0">
                                                <tr v-for="product in selectedProducts" :key="product.id">
                                                    <td class="action-cell text-center">
                                                        <div class="checkbox-wrapper">
                                                            <input type="checkbox" class="form-check-input"
                                                                :value="product" v-model="selectedProducts" />
                                                        </div>
                                                    </td>
                                                    <td class="text-center ">
                                                        {{ product.id }}
                                                    </td>
                                                    <td class="text-center ">
                                                        {{ product.name }}
                                                    </td>

                                                    <td class="text-center ">
                                                        <input v-model="submitedProduct[product.id]" type="number"
                                                        min="1"
                                                            class="form-control"
                                                            :placeholder="t('General.QuantityPlaceholder')">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div class="card mb-6">
                                <div class="card-header">
                                    <h5 class="card-title mb-0">{{ t('General.ProductInformation') }}</h5>
                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <div class="mb-6 col-6">
                                            <label class="form-label" for="ecommerce-product-name">{{
                                                t('General.Name') }}</label>
                                            <span class="required text-danger fw-bold ">*</span>
                                            <input v-model="name" type="text" class="form-control"
                                                id="ecommerce-product-name" :placeholder="t('General.ProductTitle')">
                                            <span class="text-danger">{{ errors.name }}</span>
                                        </div>
                                        <div class="mb-6  col-6">
                                            <label class="form-label" for="ecommerce-product-nameEn">{{
                                                t('General.EnglishName') }}</label>
                                            <input v-model="name_en" type="text" class="form-control"
                                                id="ecommerce-product-nameEn" :placeholder="t('General.ProductTitle')">
                                            <span class="text-danger">{{ errors.name_en }}</span>
                                        </div>
                                    </div>
                                    <div class="row mb-6">
                                        <div class="col">
                                            <label class="form-label" for="ecommerce-product-reference_number">{{
                                                t('General.ReferenceNumber') }}</label>
                                            <input v-model="reference_number" type="text" class="form-control"
                                                id="ecommerce-product-reference_number"
                                                :placeholder="t('General.ReferenceNumber')">
                                            <span class="text-danger">{{ errors.reference_number }}</span>
                                        </div>
                                        <!-- <div class="col">
                                            <label class="form-label" for="ecommerce-product-quantity">{{ t('General.Quantity') }}</label>
                                            <input v-model="quantity" type="number" class="form-control"
                                                id="ecommerce-product-quantity" :placeholder="t('General.QuantityPlaceholder')">
                                            <span class="text-danger">{{ errors.quantity }}</span>
                                        </div> -->
                                    </div>
                                    <div class="row mb-6">
                                        <div class="col">
                                            <label class="form-label" for="ecommerce-product-sku">{{ t('General.Sku')
                                                }}</label>
                                            <span class="required text-danger fw-bold ">*</span>

                                            <input v-model="sku" type="text" class="form-control"
                                                id="ecommerce-product-sku" :placeholder="t('General.SKUPlaceholder')">
                                            <span class="text-danger">{{ errors.sku }}</span>
                                        </div>
                                        <div class="col">
                                            <label class="form-label" for="ecommerce-product-barcode">{{
                                                t('Sections.Product.Barcode') }}</label>
                                            <span class="required text-danger fw-bold ">*</span>

                                            <input v-model="barcode" type="text" class="form-control"
                                                id="ecommerce-product-barcode"
                                                :placeholder="t('General.BarcodePlaceholder')">
                                            <span class="text-danger">{{ errors.barcode }}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>



                        </div>

                        <div class="col-12 col-lg-4">
                            <div class="card mb-6">
                                <div class="card-header">
                                    <h5 class="card-title mb-0">{{ t('General.Pricing') }}</h5>
                                </div>
                                <div class="card-body">
                                    <div class="mb-6">
                                        <label class="form-label" for="ecommerce-product-price">{{ t('General.Price')
                                            }}</label>
                                        <span class="required text-danger fw-bold ">*</span>

                                        <input v-model="price" type="number" class="form-control"
                                            id="ecommerce-product-price" :placeholder="t('General.Price')">
                                        <span class="text-danger">{{ errors.price }}</span>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center border-top pt-2 mb-6">
                                        <span class="mb-0">{{ t('General.HasSalePrice') }}</span>
                                        <div class="w-25 d-flex justify-content-end">
                                            <input v-model="has_sale_price" type="checkbox" class="form-check-input">
                                        </div>
                                    </div>
                                    <div class="mb-6" v-if="has_sale_price">
                                        <label class="form-label" for="ecommerce-product-discount-price">
                                            {{ t('General.SalePrice') }}
                                        </label>
                                        <input v-model="sale_price" type="number" class="form-control"
                                            id="ecommerce-product-discount-price"
                                            :placeholder="t('General.DiscountedPrice')">
                                        <span class="text-danger">{{ errors.sale_price }}</span>
                                    </div>

                                    <div class="d-flex justify-content-between align-items-center border-top pt-2">
                                        <span class="mb-0">{{ t('General.HasSerialNumber') }}</span>

                                        <div class="w-25 d-flex justify-content-end">
                                            <input v-model="has_serial_number" type="checkbox" class="form-check-input">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DragDropUpload v-model="images" />

                            <ShowWarehouseProducts v-if="showProduct" :row="productData" :onEdit="handleEdit"
                                @update-selected="updateSelectedProducts" @close-edit="onCloseProducts" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import DragDropUpload from '@/components/forms/DragDropUpload.vue';
import { SelectWarehouses } from '@/services/warehouse';
import { onMounted, ref, watch } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import { getWarehouseProducts, storeGProduct } from '@/services/product';
import ShowWarehouseProducts from './componants/ShowWarehouseProducts.vue';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const router = useRouter();

const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        name: yup.string().required(t('Validation.IsRequired')),
        name_en: yup.string().nullable(),
        reference_number: yup.string().nullable(),
        sku: yup.string().required(t('Validation.IsRequired')),
        barcode: yup.string().required(t('Validation.IsRequired')),
        price: yup.number().required(t('Validation.IsRequired')).min(0, t('Validation.PriceMustBeAtLeast0')),
        sale_price: yup.number().min(0, t('Validation.SalePriceMustBeAtLeast0')).nullable(),
        has_serial_number: yup.boolean().nullable(),
        images: yup.array().of(
            yup.mixed().test(
                'fileType',
                t('Validation.FileType', { types: 'png, jpg, jpeg' }),
                (value) => value && value.file && ['image/png', 'image/jpeg'].includes(value.file.type)
            ).required(t('Validation.IsRequired'))
        ).required(t('Validation.IsRequired')),
    }),

});

const loading = ref(false);
const has_sale_price = ref(false);
const selectedProducts = ref([]);
const { value: warehouse_id } = useField('warehouse_id');

const { value: name } = useField('name');
const { value: name_en } = useField('name_en');
const { value: reference_number } = useField('reference_number');
const { value: sku } = useField('sku');
const { value: barcode } = useField('barcode');
const { value: price } = useField('price');
price.value = 0;
const { value: sale_price } = useField('sale_price');
const { value: has_serial_number } = useField('has_serial_number');
has_serial_number.value = false;
const { value: images } = useField('images');
images.value = [];

const submitedProduct = ref({});




const warehousesData = ref([]);
const productData = ref([]);


// Function to handle the updated selection from child
const updateSelectedProducts = (selected) => {
    // console.log("Selected Products in Parent:", selected);
    selectedProducts.value = selected;
};

// Function to submit selected products
// const submitSelectedProducts = () => {
//     console.log("Final Selected Products:", selectedProducts.value);
//     alert("Selected Products: " + JSON.stringify(selectedProducts.value));
// };


const fetchWarehouse = async () => {
    try {
        const warehousesResponse = await SelectWarehouses();
        warehousesData.value = warehousesResponse.data.data;
    } catch (error) {
        console.error(error);
    }
};
const productLoading = ref(false);

watch(() => warehouse_id.value, (newVal, oldVal) => {
    if (newVal != oldVal) {
        productData.value = [];
        selectedProducts.value = [];
        fetchProduct(newVal);
    } else {
        productData.value = [];
    }
});
const fetchProduct = async (id) => {
    try {
        productLoading.value = true;
        const productsResponse = await getWarehouseProducts(id);

        productData.value = productsResponse.data.data;
    } catch (error) {
        console.error(error);
    } finally {
        productLoading.value = false;
    }
};

onMounted(() => {
    fetchWarehouse();
});



//Product
const showProduct = ref(false);
function showProductsFunction() {
    showProduct.value = !showProduct.value;


}
function onCloseProducts() {
    showProduct.value = !showProduct.value;

}
watch(price, () => {
  if (price.value && price.value >= 0) {
    console.log('price must be positive');

  } else {
    price.value = 0;

  }
});


const onSubmit = handleSubmit(async (values) => {
    try {
        values.type_id = 1;
        values.has_serial_number =  has_serial_number ? 1 : 0;
        values.images = images.value.map(image => image.file);
        const subProducts = Object.entries(submitedProduct.value).map(([key, value]) => ({
            product_id: key,
            quantity: value
        }));

        values.sub_products = subProducts ;
        loading.value = true;
        await storeGProduct(values);
        router.push({ name: 'Products' });

        toast.success(t('Messages.Success.Created')
            , {
                autoClose: 3000, // Adjust time if needed
            });
    } catch (e) {
        loading.value = false;
        console.error(t('Messages.Error.SubmitError'), e);
    }
});
</script>