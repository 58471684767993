<template>
  <div class="content-wrapper">
    <div class="container-xxl flex-grow-1 container-p-y">

      <div class="dashboard-buttons  d-none d-md-block ">
        <h2 class="title text-primary">{{ $t('App.Dashboard') }}</h2>
        <div class="d-flex gap-3" style="position: relative;">
          <button class="text-capitalize btn  rounded-md" @click="setToday" :class="{
            'bg-primary text-white': selectedButton === 'today',
            'bg-green text-primary': selectedButton !== 'today'
          }">{{ $t('General.Today') }}</button>
          <button class="text-capitalize btn  rounded-md" @click="setYesterday" :class="{
            'bg-primary text-white': selectedButton === 'yesterday',
            'bg-green text-primary': selectedButton !== 'yesterday'
          }">{{ $t("General.Yesterday") }}</button>
          <button class="text-capitalize btn  rounded-md" @click="setThisWeek" :class="{
            'bg-primary text-white': selectedButton === 'thisWeek',
            'bg-green text-primary': selectedButton !== 'thisWeek'
          }"> {{ $t("General.ThisWeek") }}</button>
          <button class="text-capitalize btn  rounded-md" @click="setThisMonth" :class="{
            'bg-primary text-white': selectedButton === 'thisMonth',
            'bg-green text-primary': selectedButton !== 'thisMonth'
          }"> {{ $t("General.ThisMonth") }}</button>
          <button class="text-capitalize btn  rounded-md" @click="setThisYear" :class="{
            'bg-primary text-white': selectedButton === 'thisYear',
            'bg-green text-primary': selectedButton !== 'thisYear'
          }"> {{ $t("General.ThisYear") }}</button>
          <button class="text-capitalize btn  rounded-md" @click="setThisHalfYear" :class="{
            'bg-primary text-white': selectedButton === 'thisHalfYear',
            'bg-green text-primary': selectedButton !== 'thisHalfYear'
          }"> {{ $t("General.ThisHalfYear") }}</button>
          <section ref="modal">
            <button class="text-capitalize btn   rounded-md" :class="{
              'bg-primary text-white': selectedButton === 'custom',
              'bg-green text-primary': selectedButton !== 'custom'
            }" @click.prevent="toggleCalendar">
              {{ $t('General.More') }} <i class='bx bxs-chevron-down'></i></button>
            <DashboardDatePicker :show="showCalendar" ref="pickDate" v-model:startDate="startDate"
              v-model:endDate="endDate" :onChange="fetchData" />
          </section>
        </div>
      </div>

      <div class="dropdown d-flex justify-content-between align-items-center d-md-none ">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false">
          Select Date
        </button>

        <section ref="modal1">
          <button class="text-capitalize btn   rounded-md" :class="{
            'bg-primary  text-primary': selectedButton === 'custom',
            'bg-green text-primary': selectedButton !== 'custom'
          }" @click.prevent="toggleCalendar2">
            more <i class='bx bxs-chevron-down'></i></button>
          <DashboardDatePicker :show="showCalendar" ref="pickDate1" v-model:startDate="startDate"
            v-model:endDate="endDate" :onChange="fetchData" />
        </section>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <button class="text-capitalize text-primary dropdown-item btn  rounded-md" @click="setToday" :class="{
            'bg-primary text-white': selectedButton === 'today',
            'bg-green text-primary': selectedButton !== 'today'
          }">today</button>
          <button class="text-capitalize btn dropdown-item  rounded-md" @click="setYesterday" :class="{
            'bg-primary text-white': selectedButton === 'yesterday',
            'bg-green text-primary': selectedButton !== 'yesterday'
          }">yesterday</button>
          <button class="text-capitalize btn dropdown-item rounded-md" @click="setThisWeek" :class="{
            'bg-primary text-white': selectedButton === 'thisWeek',
            'bg-green text-primary': selectedButton !== 'thisWeek'
          }">this week</button>
          <button class="text-capitalize btn dropdown-item rounded-md" @click="setThisMonth" :class="{
            'bg-primary text-white': selectedButton === 'thisMonth',
            'bg-green text-primary': selectedButton !== 'thisMonth'
          }">this month</button>
          <button class="text-capitalize btn dropdown-item rounded-md" @click="setThisYear" :class="{
            'bg-primary text-white': selectedButton === 'thisYear',
            'bg-green text-primary': selectedButton !== 'thisYear'
          }">this year</button>
          <button class="text-capitalize btn dropdown-item  rounded-md" @click="setThisHalfYear" :class="{
            'bg-primary text-white': selectedButton === 'thisHalfYear',
            'bg-green text-primary': selectedButton !== 'thisHalfYear'
          }">last year half</button>
        </div>
      </div>
      <!--Order Numbers-->
      <div class=" order-numbers bg-white shadow-lg rounded p-4 mx-5">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="orders-shipped p-2 d-flex justify-content-between align-items-start">
              <div>
                <div class="sub-title text-primary">{{ $t('Sections.Orders.Shipped') }} </div>
                <span class="order-number">{{ state.statistics.order_shipped }}</span>
              </div>
              <div class="icon d-flex justify-content-center align-items-center">
                <img :src="ordersShippedIcon" alt="Orders Shipped Icon" />
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="processed-orders p-2 d-flex justify-content-between align-items-start">
              <div>
                <div class="sub-title text-primary">{{ $t('Sections.Orders.Processed') }} </div>
                <span class="order-number">{{ state.statistics.processed_orders }}</span>
              </div>
              <div class="icon d-flex justify-content-center align-items-center">
                <img :src="processedOrdersIcon" alt="Processed Orders Icon" />
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="remaining-orders p-2 d-flex justify-content-between align-items-start">
              <div>
                <div class="sub-title">{{ $t('Sections.Orders.Remaining') }} </div>
                <span class="order-number">{{ state.statistics.reminding_orders }}</span>
              </div>
              <div class="icon d-flex justify-content-center align-items-center">
                <img :src="remainingOrdersIcon" alt="Remaining orders  Icon" />
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="late-orders p-2 d-flex justify-content-between align-items-start">
              <div>
                <div class="sub-title">{{ $t('Sections.Orders.Late') }} </div>
                <span class="order-number">{{ state.statistics.late_orders }}</span>
              </div>
              <div class="icon d-flex justify-content-center align-items-center">
                <img :src="lateOrdersIcon" alt="Late orders  Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Shipping Companies -->
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="shippingCompanies">
            <button
              class="accordion-button px-5  px-2 pt-4 pb-0 rounded mt-5 flex-column justify-content-end align-items-start collapsed"
              type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
              aria-controls="collapseTwo">
              <span class="sub-title text-white fw-light">{{ $t('General.ShippingCompanies') }}</span>
              <p class="text-white fw-bold fs-2">{{ state.statistics.deliveryCompaniesStatistics?.length }}</p>
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="shippingCompanies"
            data-bs-parent="#accordionExample">
            <div class="accordion-body p-4">
              <div class="row company-card p-4 mx-2 rounded bg-white d-flex align-items-end "
                v-for="company in state.statistics.deliveryCompaniesStatistics" :key="company.delivery_company_id">
                <div class="col-lg-6 co-sm-12">
                  <img v-if="company.image_url" :src="company.image_url" width="40px" alt="Company Image"
                    @error="(event) => event.target.src = companyImage" />
                  <p v-else>
                    {{ company.name }}
                  </p>
                </div>
                <div class="col-lg-6 co-sm-12 text-end text-primary text-capitalize">
                  {{ $t('General.Orders') }}: <span class="text-secondary fw-bold">{{ company.total_orders }}</span>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>

      <!--Stock-->
      <div class="bg-white p-3 mt-5 rounded shadow-sm">
        <div class="d-flex stock-buttons border-bottom pb-3 align-items-center justify-content-between">
          <h4 class="pt-2">{{ $t('General.Stock') }}</h4>
          <ul class="nav nav-pills text-capitalize bg-white rounded-none" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active text-primary" id="pills-total-tab" data-bs-toggle="pill"
                data-bs-target="#pills-total" type="button" role="tab" aria-controls="pills-total"
                aria-selected="true">{{
                  $t('General.Total') }}</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link text-primary" id="pills-bestSelling-tab" data-bs-toggle="pill"
                data-bs-target="#pills-bestSelling" type="button" role="tab" aria-controls="pills-bestSelling"
                aria-selected="false">{{ $t('Sections.Dashboard.BestSelling') }}</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link text-primary" id="pills-soldOut-tab" data-bs-toggle="pill"
                data-bs-target="#pills-soldOut" type="button" role="tab" aria-controls="pills-soldOut"
                aria-selected="false">{{ $t('Sections.Dashboard.RecentlySold') }} </button>
            </li>
          </ul>
        </div>
        <div class="tab-content pt-0 bg-white" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-total" role="tabpanel" aria-labelledby="pills-total-tab">
            <div class="text-primary text-center pt-5">
              <p class="text-primary title">{{ $t('Sections.Dashboard.TotalProduct') }}</p>
              <h2 class="text-primary">{{ state.statistics.total_products }}</h2>
            </div>
          </div>
          <div class="tab-pane fade " id="pills-bestSelling" role="tabpanel" aria-labelledby="pills-bestSelling-tab">

            <div class="table-responsive text-nowrap">
              <table class="table table-hover">
                <thead class="bg-green">
                  <tr>
                    <!-- <th class="text-center" v-for="header in headers" :key="header">{{ header }}</th> -->

                    <th class="text-center">#</th>
                    <th class="text-center">
                      {{ $t('General.Image') }}
                    </th>
                    <th class="text-center">
                      {{ $t('Sections.Product.Name') }}
                    </th>
                
                    <th class="text-center">
                      {{ $t('Sections.Product.PriceSar') }}
                    </th>
                  
                    <th class="text-center">
                      {{ $t('General.Type') }}
                    </th>
                  

                  </tr>
                </thead>
                <tbody class="table-border-bottom-0">
                  <tr v-if="loading">
                    <td colspan="5" class="text-center">{{ $t('General.Loading') }}</td>
                  </tr>
                  <tr v-else v-for="(product, index) in state.best" :key="product.id">
                    <td class="text-center align-middle">{{ index + 1 }}</td>
                    <td class="text-center align-middle"><img :src="product.first_image?.url ?? img"
                        @error="replaceByDefault" class="img-fluid" width="40px"></td>
                    <td class="text-center align-middle">{{ product.name }}</td>
                    <td class="text-center align-middle">{{ product.price }}</td>
                    <td class="text-center align-middle">
                      <button type="button" class="btn rounded"
                        :class="[product.type_name?.id != 0 ? 'succ-btn ' : 'blue-btn',]">
                        {{ product.type_name?.name }}
                      </button>
                    </td>

                  </tr>

                </tbody>
              </table>
            </div>

          </div>
          <div class="tab-pane fade " id="pills-soldOut" role="tabpanel" aria-labelledby="pills-soldOut-tab">

            <div class="table-responsive text-nowrap">
              <table class="table table-hover">
                <thead class="bg-green">
                  <tr>
                    <th class="text-center" v-for="header in headers" :key="header">{{ header }}</th>

                  </tr>
                </thead>
                <tbody class="table-border-bottom-0">
                  <tr v-for="product in paginatedSold" :key="product.id">
                    <td class="text-center align-middle"></td>
                    <td class="text-center align-middle">{{ product.name }}</td>
                    <td class="text-center align-middle">{{ product.barcode }}</td>
                    <td class="text-center align-middle">{{ product.sku }}</td>
                    <td class="text-center align-middle">{{ product.price }}</td>
                    <td class="text-center align-middle">
                      <button type="button" class="btn rounded"
                        :class="[product.type_name?.id != 0 ? 'succ-btn ' : 'blue-btn',]">
                        {{ product.type_name?.name }}
                      </button>
                    </td>
                    <td class="text-center align-middle"></td>

                  </tr>

                </tbody>
              </table>
              <div class="row pt-5">
                <div class="col-sm-12 col-md-6">
                  <div role="status" aria-live="polite" class="table-pages">
                    Showing {{ end }} of {{ total }} entries
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <ul class="pagination justify-content-end">
                    <li :class="['paginate_button page-item ', { disabled: currentPageItem === 1 }]"
                      @click="prevPage(1)">
                      <a role="button" class="page-link"><i class="bx bx-chevrons-left bx-18px"></i></a>
                    </li>
                    <li :class="['paginate_button page-item', { disabled: currentPageItem === 1 }]"
                      @click="prevPage(currentPageItem - 1)">
                      <a role="button" class="page-link"><i class="bx bx-chevron-left bx-18px"></i></a>
                    </li>
                    <li :class="['paginate_button page-item', { disabled: currentPageItem - 2 === currentPageItem }]"
                      @click="prevPage(currentPageItem - 1)"
                      v-if="currentPageItem < currentPageItem + 2 && currentPageItem > 2">
                      <a role="button" class="page-link">
                        {{ currentPageItem - 2 }}
                      </a>
                    </li>
                    <li :class="['paginate_button page-item', { disabled: currentPageItem - 1 === currentPageItem }]"
                      @click="prevPage(currentPageItem - 1)"
                      v-if="currentPageItem < currentPageItem + 1 && currentPageItem > 1">
                      <a role="button" class="page-link">
                        {{ currentPageItem - 1 }}
                      </a>
                    </li>
                    <li
                      :class="['paginate_button page-item bg-green text-white', { disabled: currentPageItem === currentPageItem }]"
                      @click="prevPage()" v-if="currentPageItem <= totalPages">
                      <a role="button" class="page-link">
                        {{ currentPageItem }}
                      </a>
                    </li>
                    <li :class="['paginate_button page-item', { disabled: currentPageItem === currentPageItem + 1 }]"
                      @click="nextPage(currentPageItem + 1)" v-if="currentPageItem + 1 <= totalPages">
                      <a role="button" class="page-link">
                        {{ currentPageItem + 1 }}
                      </a>
                    </li>
                    <li :class="['paginate_button page-item', { disabled: currentPageItem === currentPageItem + 2 }]"
                      @click="nextPage(currentPageItem + 2)" v-if="currentPageItem + 2 <= totalPages">
                      <a role="button" class="page-link">
                        {{ currentPageItem + 2 }}
                      </a>
                    </li>
                    <li :class="['paginate_button page-item', { disabled: currentPageItem === totalPages }]"
                      @click="nextPage(currentPageItem + 1)">
                      <a role="button" class="page-link"><i class="bx bx-chevron-right bx-18px"></i></a>
                    </li>
                    <li :class="['paginate_button page-item', { disabled: currentPageItem === totalPages }]"
                      @click="nextPage(totalPages)">
                      <a role="button" class="page-link"><i class="bx bx-chevrons-right bx-18px"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>






    </div>
  </div>
  <div class="content-wrapper">
    <div class="container-xxl flex-grow-1 container-p-y">
      <h3 class="page-head">{{ $t('Entities.NewOrders') }}</h3>
      <div class="card p-3">
        <div class="card-datatable">


          <div class="d-flex  table-custom-head">
            <div class="d-flex table-h  ">

              <svg width="30" height="30" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.1061 4.608L13.3873 3.6455C11.8783 2.80125 11.1237 2.37866 10.3125 2.37866C9.50125 2.37866 8.74672 2.80033 7.23766 3.6455L6.9618 3.80041L14.63 8.4745L18.0813 6.632C17.5261 5.961 16.6306 5.45958 15.1061 4.60616V4.608ZM18.6897 7.84566L15.2539 9.679V12.462C15.2539 12.6443 15.186 12.8192 15.0651 12.9481C14.9443 13.0771 14.7803 13.1495 14.6094 13.1495C14.4384 13.1495 14.2745 13.0771 14.1536 12.9481C14.0327 12.8192 13.9648 12.6443 13.9648 12.462V10.3656L10.957 11.9697V20.624C11.5741 20.4599 12.2762 20.0676 13.3873 19.4452L15.1061 18.4827C16.9546 17.4477 17.8793 16.9307 18.3932 16.0003C18.9062 15.0708 18.9062 13.9131 18.9062 11.6003V11.4931C18.9062 9.75783 18.9062 8.6725 18.6897 7.84566ZM9.66797 20.624V11.9707L1.93531 7.84566C1.71875 8.6725 1.71875 9.75783 1.71875 11.4912V11.5985C1.71875 13.9131 1.71875 15.0708 2.2318 16.0003C2.7457 16.9307 3.67039 17.4487 5.51891 18.4836L7.23766 19.4452C8.34883 20.0676 9.05094 20.4599 9.66797 20.624ZM2.54375 6.63291L10.3125 10.7762L13.2438 9.21333L5.60742 4.5585L5.51891 4.608C3.99523 5.4605 3.09891 5.96191 2.54375 6.63383V6.63291Z"
                  fill="#24A259" />
              </svg>


              <h3 class="table-title">{{ $t('Sections.Orders.New') }}</h3>
            </div>
          </div>

          <div class="table-responsive text-nowrap">
            <table class="table table-hover">
              <thead class="bg-green">
                <tr>
                  <th class="text-center">
                  </th>
                  <th class="text-center">{{ $t('Sections.Orders.NumberOrder') }}</th>
                  <th class="text-center" v-if="authStore.hasPermission('view-clients')">
                    {{ $t('Entities.Clients') }}
                  </th>
                  <th class="text-center">{{ $t('Entities.Shipping') }}</th>
                  <th class="text-center">{{ $t('Sections.Orders.Date') }}</th>
                  <th class="text-center">{{ $t('General.Total') }} </th>
                  <th class="text-center">{{ $t('General.DqqStatus') }}</th>
                  <th class="text-center">{{ $t('General.ZidStatus') }}</th>
                  <th class="text-center"> </th>

                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr v-if="loading">
                  <td colspan="9" class="text-center">{{ $t('General.Loading') }}</td>
                </tr>
                <tr v-else v-for="rowColum in OrdersData" :key="rowColum.id">
                  <td class="text-center align-middle">
                    <i v-if="rowColum.is_gift_order" class='bx bxs-gift'></i>
                  </td>
                  <td class="text-center align-middle">
                    {{ rowColum.reference_number }}
                  </td>
                  <td class="text-center align-middle" v-if="authStore.hasPermission('view-clients')">
                    {{ rowColum.client?.name }}
                  </td>
                  <td class="text-center align-middle">
                    {{ rowColum.delivery?.name }}
                  </td>
                  <td class="text-center align-middle">
                    {{ rowColum.created_at_actually }}
                  </td>
                  <td class="text-center align-middle color-green">
                    {{ rowColum.total_cost_with_currency }}
                  </td>
                  <td class="text-center align-middle">
                    <button style="width: 100%;" class="btn text-white "
                      :style="{ backgroundColor: rowColum.status_dqq_name?.bg_color || '#ccc' }">
                      {{ rowColum.status_dqq_name?.name }}
                    </button>
                  </td>
                  <td class="text-center align-middle ">
                    <button style="width: 100%;" class="btn text-white "
                      :style="{ backgroundColor: rowColum.status_name?.bg_color || '#ccc' }">
                      {{ rowColum.status_name?.name }}
                    </button>
                  </td>
                  <td class="text-center align-middle">
                    <router-link :to="{ name: 'OrderView', params: { id: rowColum.id } }">
                      <button class="btn text-primary bg-green rounded-pill" style="min-width: max-content">
                        {{ $t('General.ViewOrder') }}
                      </button>
                    </router-link>
                  </td>
                </tr>

              </tbody>
            </table>

          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import ordersShippedIcon from '@/assets/dashboard/img/icons/dashboards/orders-shipped.svg';
import processedOrdersIcon from '@/assets/dashboard/img/icons/dashboards/processed-orders.svg';
import remainingOrdersIcon from '@/assets/dashboard/img/icons/dashboards/remaining-orders.svg';
import lateOrdersIcon from '@/assets/dashboard/img/icons/dashboards/late-orders.svg';
import companyImage from '@/assets/dashboard/img/icons/payments/visa.png';
import DashboardDatePicker from '@/components/forms/DshboardDatePicker.vue';
import img from "@/assets/dashboard/img/dqqicon.png"


import { getBestProducts, getStatistics } from '@/services/statistic';
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, watch } from 'vue';
import { ref } from 'vue';

import { useAuthStore } from '@/stores/auth';

import { useI18n } from 'vue-i18n';
import { formatDateToYMD } from '@/utils/dateFormatter';
const { t } = useI18n();
const authStore = useAuthStore();

const replaceByDefault = (e) => {
  e.target.src = img
  // You can now perform actions based on the page change, like refetching data.
};


const today = new Date();

const startDate = ref(formatDateToYMD(today)); // Call the function to get its return value
const endDate = ref(formatDateToYMD(today)); // Call the function to get its return value
const selectedButton = ref('today'); // Call the function to get its return value



// Function to handle date changes
const onDateChange1 = (newDate) => {

  startDate.value = formatDateToYMD(newDate);

};
const onDateChange2 = (newDate) => {

  endDate.value = formatDateToYMD(newDate);

};



// Set selected dates to today
const setToday = () => {
  selectedButton.value = 'today';

  onDateChange1(today);
  onDateChange2(today);
};

// Set selected dates to yesterday
const setYesterday = () => {
  selectedButton.value = 'yesterday';
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  onDateChange1(yesterday);
  onDateChange2(yesterday);
};

// Set selected dates to this week (starting Sunday to Saturday)
const setThisWeek = () => {
  selectedButton.value = 'thisWeek';
  const today = new Date();
  const dayOfWeek = today.getDay();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek); // Sunday

  const endOfWeek = new Date(today);
  endOfWeek.setDate(today.getDate() + (6 - dayOfWeek)); // Saturday
  onDateChange1(startOfWeek);
  onDateChange2(endOfWeek);

};

// Set selected dates to this month (1st of the month to current date)
const setThisMonth = () => {
  selectedButton.value = 'thisMonth';
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  onDateChange1(startOfMonth);
  onDateChange2(today);

};
// Set selected dates to the last half year (6 months ago to current date)
const setThisHalfYear = () => {
  selectedButton.value = 'thisHalfYear';
  const today = new Date();
  const sixMonthsAgo = new Date(today);
  sixMonthsAgo.setMonth(today.getMonth() - 6);
  onDateChange1(sixMonthsAgo);
  onDateChange2(today);

};

// Set selected dates to this year (1st January to current date)
const setThisYear = () => {
  selectedButton.value = 'thisYear';
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  onDateChange1(startOfYear);
  onDateChange2(today);

};
watch([startDate, endDate], () => {
  fetchData();
});





const state = reactive({
  statistics: {},
  best: [],
  sold: [],
});


const loading = ref(false);


// Table headers and fields
const headers = computed(() => [
  '#', t('Sections.Product.Name'),
  t('Sections.Product.Barcode'),
  t('Sections.Product.SKU'),
  t('Sections.Product.PriceSar'),
  t('General.Type'), '']);
const fetchData = async () => {

  try {
    loading.value = true;
    const [response,
      activites
    ] = await Promise.all([
      getStatistics(startDate.value, endDate.value),
      getBestProducts(startDate.value, endDate.value),

    ]);



    state.statistics = response.data.data;
    state.best = activites.data.data.bestSellProducts;
    state.sold = activites.data.data.recentlySoldOutProducts;
    total.value = state.sold.length;
    end.value = 5;



    OrdersData.value = response.data.data.new_orders;
    loading.value = false;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;

  }
};
// watchEffect(() => {
//   if (authStore.user) {
//     fetchData();
//   }
// });

const OrdersData = ref([]);
const total = ref(0);



const currentPage = ref(1);
const currentPageItem = ref(1);
const itemsPerPage = ref(5);
const end = ref(0);


const paginatedSold = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return state.sold.slice(start, end);
});


const onPageChanged = (pageNumber) => {
  currentPage.value = pageNumber;
  const all = currentPageItem.value * itemsPerPage.value
  end.value = all > total.value ? total.value : all;
  // You can now perform actions based on the page change, like refetching data.
};



const totalPages = computed(() => Math.ceil(state.sold.length / itemsPerPage.value));

function nextPage(page = null) {
  if (page != null && currentPageItem.value < totalPages.value) {
    currentPageItem.value = page;
    onPageChanged(page) // Emit the page number to parent

  }

  else if (currentPageItem.value < totalPages.value) {
    currentPageItem.value += 1;
    onPageChanged(currentPageItem.value); // Emit the page number to parent
  }
}


function prevPage(page = null) {
  if (page != null && currentPageItem.value > 1) {
    currentPageItem.value = page;
    onPageChanged(page); // Emit the page number to parent
  }

  else if (currentPageItem.value > 1) {

    currentPageItem.value = currentPageItem.value - 1;
    onPageChanged(currentPageItem.value); // Emit the page number to parent
  }
}

onMounted(() => {
  fetchData();
});

const showCalendar = ref(false);
const pickDate = ref(null);
const modal = ref(null);
const pickDate1 = ref(null);
const modal1 = ref(null);

const toggleCalendar = async () => {
  // showCalendar.value = !showCalendar.value;
  pickDate.value.SyncCulender();
  selectedButton.value = 'custom';

  await nextTick(); // Ensure DOM updates before accessing pickDate

};
const toggleCalendar2 = async () => {
  // showCalendar.value = !showCalendar.value;
  pickDate1.value.SyncCulender();
  selectedButton.value = 'custom';

  await nextTick(); // Ensure DOM updates before accessing pickDate

};

// Function to handle click outside the modal
const handleClickOutside = () => {
  if (modal.value && !modal.value.contains(event.target) && pickDate.value?.isShowCalendar()) {
    pickDate.value.SyncCulender();
  }
  if (modal1.value && !modal1.value.contains(event.target) && pickDate1.value?.isShowCalendar()) {
    pickDate1.value.SyncCulender();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
  setTimeout(() => {
    window.addEventListener('click', handleClickOutside);

  }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});


</script>


<style scoped>
.title {
  font-size: 24px;
  font-family: "Public Sans";
  line-height: 22px;
}

.sub-title {
  font-family: "Public Sans";
  font-weight: 600;
  line-height: 24px;
  font-size: 15px;
  text-transform: capitalize;

}

.order-numbers {
  margin-top: 40px;
}

.order-number {
  font-weight: 700;
  color: #000336;
}

.icon {
  border-radius: 6px;
  width: 42px;
  height: 42px;
}

.orders-shipped .icon {
  background-color: rgba(36, 162, 89, 0.1);
}

.processed-orders .icon {
  background-color: rgba(47, 205, 114, 0.1);
}


.remaining-orders .sub-title {
  color: #FF810B;
}

.remaining-orders .icon {
  background-color: rgba(255, 129, 11, 0.1);
}

.late-orders .sub-title {
  color: #ED284F;
}

.late-orders .icon {
  background-color: rgba(237, 40, 79, 0.1);
}

.remaining-orders,
.orders-shipped,
.processed-orders {
  border-right: 1px solid #e4e6e8;
}

.accordion-button {
  position: relative;
  background: linear-gradient(0deg, #24A259, #24A259),
    linear-gradient(0deg, #24A259, #24A259);

}

.accordion-button::after {
  max-width: calc(min-content +5px);
}


.accordion-collapse {
  background-color: rgba(84, 198, 132, 1);
}

.nav-pills .nav-link.active:hover,
.nav-pills .nav-link:hover {
  box-shadow: none !important;
  color: #24A259 !important;
  background-color: #fff !important;
}

.nav-pills .nav-link.active {
  color: #fff !important;
}

.tab-content {
  min-height: 250px;
}

.search-order h3 {
  color: #22303E;
  font-size: 24px;
  font-family: "Public Sans";
}

.badge-btn {
  display: inline;
  width: max-content;
}

.badge-btn button {
  background-color: transparent;
  outline: none;
  border: none;
}

.badge-btn,
.search-order button {
  outline: none;
  border: none;
  color: #24A259;
  padding: 10px 16px;
  border-radius: 6px;
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: rgba(36, 162, 89, 0.1);
}

.dropdown button {
  background-color: #fff !important;
  font-size: 16px;
  color: #24A259;
  border: 1px solid #24A259 !important;
}

.show-result h4 {
  font-size: 24px;
  color: #22303E;
}

@media screen and (max-width:1024px) {

  .remaining-orders,
  .orders-shipped,
  .late-orders,
  .processed-orders {
    border-right: none;
    border-bottom: 1px solid #e4e6e8;
  }

  .badges,
  .stock-buttons ul,
  .stock-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .show-result h4 {
    font-size: 18px;
  }
}

[dir="ltr"] .accordion-button::after {
  right: 20px !important;
  bottom: 20px !important;
  position: absolute !important;
  border-radius: 4px;
  color: #fff !important;
  filter: brightness(0) invert(1) !important;
  background-color: rgba(255, 255, 255, 0.08);
}

[dir="rtl"] .accordion-button::after {
  position: absolute !important;
  left: 20px !important;
  bottom: 20px !important;
  border-radius: 4px;
  color: #fff !important;
  filter: brightness(0) invert(1) !important;
  background-color: rgba(255, 255, 255, 0.08);

}

button {
  min-width: max-content;
}
</style>
