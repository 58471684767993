<template>
    <div class="modal-backdrop fade" :class="{ 'show': showEdit }"
        style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 1040;">
    </div>
    <div class="modal fade" :class="{ 'show': showEdit }" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close " data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2">{{ $t('Sections.Fulfilment.Details') }}</h4>
                    </div>
                    <div class="table-responsive text-nowrap">
                        <table class="table table-hover">
                            <thead class="bg-green">
                                <tr>
                                    <th class="text-center ">{{ $t('Sections.Product.Name') }}</th>
                                    <th class="text-center ">{{ $t('General.Sku') }}</th>
                                    <th class="text-center ">{{ $t('General.VerifyQuantity') }}</th>
                                    <th class="text-center ">{{ $t('General.Status') }}</th>
                                    <th class="text-center ">{{ $t('General.TypeVerify') }}</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr v-for="details in row.details" :key="details.id">
                                    <td class="text-center ">{{ details.product.name }}</td>
                                    <td class="text-center ">{{ details.product.sku }}</td>
                                    <td class="text-center ">{{ details.verify_quantity }}</td>
                                    <td class="text-center ">{{ details.status_name.name }}</td>
                                    <td class="text-center ">{{ details.type_name }}</td>


                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';

const emit = defineEmits(['close-add']);

 defineProps({
    showEdit: {
        type: Boolean,
        default: true,
    },
    row: {
        type: Object,
        required: true,
    },

});


function closeModel() {
    emit('close-edit', false);
}

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
  if (modal.value && !modal.value.contains(event.target) ) {
    closeModel();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});



</script>
<style scoped>
label {
    text-transform: uppercase;
}
</style>
