<template>
  <div v-if="isVisible" class="loading-screen">
    <span class="app-brand-logo demo p-3 " alt="Loading">
      <!-- <img src="@/assets/dashboard/img/dqqicon.png" class="rotating-logo" alt="dqqlogo" > -->
      <div class="loading-screen">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </div>
    </span>
    <p>Loading...</p>
    <!-- Add a spinner or animation -->
  </div>
</template>

<script>
import { useLoadingStore } from "@/stores/loading";
import { computed } from "vue";

export default {
  setup() {
    const loadingStore = useLoadingStore();
    const isVisible = computed(() => loadingStore.isLoading);
    return { isVisible };
  },
};
</script>


<style>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.rotating-logo {
  width: 100px;
  height: 100px;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>