import axiosClient from "./axiosClient";
import { buildUrl } from "@/utils/buildUrl";

export function getShipment(page = 1, searchQuery = null) {
  const url = buildUrl("v1/dashboard/created/shipment", {
    page,
    per_page: 10,
    all: searchQuery,
  });

  return axiosClient({ url });
}
export function searchByClient(id) {

  return axiosClient({
    url: `v1/dashboard/customer/search/${id}`,
  })

}
export function searchByOrder(referanceNumber) {
  const url = buildUrl(`v1/dashboard/order/search/${referanceNumber}`, {

  });

  return axiosClient({ url });
}





export function warehousesByClientId(clientId = null) {
  const url = buildUrl("v1/dashboard/warehouses/by-client", {

    client_id: clientId,
  });

  return axiosClient({ url });
}
export function getDeliveryIntegrations() {
  const url = buildUrl("v1/dashboard/delivery/company-integrations", {

  });

  return axiosClient({ url });
}

export function editShipment(data) {
  return axiosClient({
    url: `v1/dashboard/store/new/shipment`,
    method: 'post',
    data,
  })
}
export function getDeliveryCompanyIntegrations() {
  const url = buildUrl("v1/dashboard/delivery/company-integrations", {});

  return axiosClient({ url });
}