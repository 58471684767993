<template>

  <!-- Menu -->

  <aside ref="menuRef" id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme" v-if="authStore.isLoggedIn"
    @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <div class="app-brand demo">
      <router-link :to="{ name: 'Dashboard' }" class="app-brand-link">

        <img src="@/assets/dashboard/img/dqqlogo.png" alt="dqqlogo">
      </router-link>

      <a ref="menuToggle" href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block  "
        v-show="iscollapseNav" @click.prevent="collapseNav()">
        <i class="bx bx-chevron-left bx-sm d-flex align-items-center justify-content-center"></i>

      </a>
    </div>

    <div class="menu-inner-shadow"></div>

    <ul class="menu-inner py-1 pt-3 overflow-auto ps ps--active-y ps--socrolling-y">
      <!-- Dashboards -->


      <li class="menu-item pb-4" :class="{ 'active': $route.name === 'Dashboard' }">
        <router-link :to="{ name: 'Dashboard' }" class="menu-link">
          <!-- <i class="menu-icon tf-icons bx bx-home-smile"></i>
            -->
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.30943 7.71613C1.8335 8.58562 1.8335 9.63448 1.8335 11.7322V13.1265C1.8335 16.7023 1.8335 18.4902 2.90744 19.6011C3.98138 20.712 5.70986 20.712 9.16683 20.712H12.8335C16.2905 20.712 18.0189 20.712 19.0929 19.6011C20.1668 18.4902 20.1668 16.7023 20.1668 13.1265V11.7322C20.1668 9.63448 20.1668 8.58562 19.6909 7.71613C19.215 6.84664 18.3455 6.307 16.6065 5.22773L14.7731 4.08991C12.9349 2.94905 12.0158 2.37862 11.0002 2.37862C9.98456 2.37862 9.06543 2.94905 7.22719 4.08991L5.39386 5.22773C3.65486 6.307 2.78536 6.84664 2.30943 7.71613ZM8.65956 14.6596C8.35452 14.4335 7.92395 14.4975 7.69785 14.8026C7.47175 15.1076 7.53573 15.5382 7.84077 15.7643C8.73214 16.425 9.82227 16.8161 11.0002 16.8161C12.1781 16.8161 13.2682 16.425 14.1596 15.7643C14.4646 15.5382 14.5286 15.1076 14.3025 14.8026C14.0764 14.4975 13.6458 14.4335 13.3408 14.6596C12.6731 15.1545 11.8672 15.4411 11.0002 15.4411C10.1331 15.4411 9.3272 15.1545 8.65956 14.6596Z"
              fill="#22303E" />
          </svg>

          <div class="text-truncate p-3 " data-i18n="Dashboard">{{ $t('App.Dashboard') }}</div>
        </router-link>
      </li>


      <!-- Users -->

      <li class="menu-item" @click.prevent="(event) => toggleClick(event, 'user')" :class="{ 'open': userMenuOpen }"
        v-if="filteredUserItems.some(item => authStore.hasPermission(item.permission))">
        <a href="#" class="menu-link menu-toggle">
          <!-- <i class="menu-icon tf-icons bx bxs-user"></i> -->
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.2082 7.42029C14.2082 9.19221 12.7718 10.6286 10.9998 10.6286C9.22792 10.6286 7.7915 9.19221 7.7915 7.42029C7.7915 5.64838 9.22792 4.21196 10.9998 4.21196C12.7718 4.21196 14.2082 5.64838 14.2082 7.42029Z"
              fill="#22303E" />
            <path
              d="M16.5 15.6703C16.5 17.4422 14.0376 18.8786 11 18.8786C7.96243 18.8786 5.5 17.4422 5.5 15.6703C5.5 13.8984 7.96243 12.462 11 12.462C14.0376 12.462 16.5 13.8984 16.5 15.6703Z"
              fill="#22303E" />
            <path
              d="M6.52871 5.12862C6.69138 5.12862 6.85019 5.14458 7.00355 5.17497C6.62995 5.83853 6.41683 6.60452 6.41683 7.42028C6.41683 8.21618 6.6197 8.9647 6.97654 9.61695C6.83157 9.64396 6.68185 9.65811 6.52871 9.65811C5.23216 9.65811 4.1811 8.64415 4.1811 7.39336C4.1811 6.14258 5.23216 5.12862 6.52871 5.12862Z"
              fill="#22303E" />
            <path
              d="M4.99355 17.9491C4.47297 17.3268 4.12516 16.5631 4.12516 15.6703C4.12516 14.8047 4.45202 14.0606 4.94629 13.449C3.20034 13.5844 1.8335 14.5393 1.8335 15.6972C1.8335 16.866 3.22436 17.828 4.99355 17.9491Z"
              fill="#22303E" />
            <path
              d="M15.5833 7.42028C15.5833 8.21618 15.3804 8.9647 15.0236 9.61695C15.1686 9.64396 15.3183 9.65811 15.4714 9.65811C16.768 9.65811 17.819 8.64415 17.819 7.39336C17.819 6.14258 16.768 5.12862 15.4714 5.12862C15.3087 5.12862 15.1499 5.14458 14.9966 5.17497C15.3702 5.83853 15.5833 6.60452 15.5833 7.42028Z"
              fill="#22303E" />
            <path
              d="M17.0066 17.9491C18.7758 17.828 20.1666 16.866 20.1666 15.6972C20.1666 14.5393 18.7998 13.5844 17.0538 13.449C17.5481 14.0606 17.875 14.8047 17.875 15.6703C17.875 16.5631 17.5272 17.3268 17.0066 17.9491Z"
              fill="#22303E" />
          </svg>

          <div class="text-truncate p-3" data-i18n="Users">{{ $t("Entities.Users") }}</div>
        </a>
        <transition name="fade">
          <ul class="menu-sub">
            <li v-for="item in filteredUserItems" :key="item.name" class="menu-item"
              :class="{ 'active': $route.name === item.name }">
              <router-link :to="{ name: item.name }" class="menu-link ">
                <div class="text-truncate" :data-i18n="item.label">{{ $t(item.label) }}</div>
              </router-link>
            </li>
          </ul>
        </transition>
      </li>
      <!-- Stores -->
      <li class="menu-item" @click.prevent="(event) => toggleClick(event, 'store')" :class="{ 'open': storeMenuOpen }"
        v-if="storeItems.some(item => authStore.hasPermission(item.permission))">
        <a href="#" class="menu-link menu-toggle">
          <!-- <i class="menu-icon tf-icons bx bxs-store"></i> -->
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.46305 3.8956C3.29709 4.22642 3.21547 4.63452 3.05223 5.45071L2.50414 8.19118C2.1571 9.9264 3.4843 11.5453 5.25388 11.5453C6.69454 11.5453 7.90081 10.4537 8.04416 9.02016L8.10742 8.38755C7.95792 10.0822 9.29361 11.5453 10.9998 11.5453C12.7175 11.5453 14.0598 10.0622 13.8889 8.35292L13.9558 9.02016C14.0991 10.4537 15.3054 11.5453 16.7461 11.5453C18.5156 11.5453 19.8428 9.9264 19.4958 8.19118L18.9477 5.45071C18.7845 4.63453 18.7028 4.22641 18.5369 3.8956C18.1872 3.19852 17.558 2.6827 16.8059 2.47651C16.4489 2.37866 16.0328 2.37866 15.2004 2.37866H13.2914H6.79953C5.96717 2.37866 5.55099 2.37866 5.19405 2.47651C4.44193 2.6827 3.81273 3.19852 3.46305 3.8956Z"
              fill="#22303E" />
            <path
              d="M16.7463 12.9203C17.4953 12.9203 18.1912 12.7257 18.7918 12.3877V13.3787C18.7918 16.8356 18.7918 18.5641 17.7179 19.638C16.8534 20.5025 15.5648 20.6711 13.2918 20.704V17.5037C13.2918 16.647 13.2918 16.2186 13.1076 15.8995C12.9869 15.6905 12.8134 15.5169 12.6043 15.3962C12.2853 15.212 11.8569 15.212 11.0002 15.212C10.1434 15.212 9.71507 15.212 9.396 15.3962C9.18697 15.5169 9.01339 15.6905 8.89271 15.8995C8.7085 16.2186 8.7085 16.647 8.7085 17.5037V20.704C6.43556 20.6711 5.14693 20.5025 4.28244 19.638C3.2085 18.5641 3.2085 16.8356 3.2085 13.3787V12.3877C3.80913 12.7257 4.50502 12.9203 5.2541 12.9203C6.35142 12.9203 7.35748 12.4954 8.10748 11.7946C8.86587 12.4914 9.87861 12.9203 11 12.9203C12.1214 12.9203 13.1343 12.4913 13.8927 11.7944C14.6427 12.4953 15.6489 12.9203 16.7463 12.9203Z"
              fill="#22303E" />
          </svg>

          <div class="text-truncate p-3" data-i18n="Stores">{{ $t('Entities.Stores') }}</div>
        </a>
        <transition name="fade">
          <ul class="menu-sub">
            <li v-for="item in filteredstoreItems" :key="item.name" class="menu-item"
              :class="{ 'active': $route.name === item.name }">
              <router-link :to="{ name: item.name }" class="menu-link" v-if="authStore.hasPermission(item.permission)">
                <div class="text-truncate" :data-i18n="item.label">{{ $t(item.label) }}</div>
              </router-link>
            </li>
          </ul>
        </transition>
      </li>


      <!-- Orders -->
      <li class="menu-item" :class="{ 'active': $route.name === 'Orders' }">
        <router-link :to="{ name: 'Orders' }" class="menu-link" v-if="authStore.hasPermission('view-orders')">
          <!-- <i class="menu-icon tf-icons bx bxs-package"></i> -->
          <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.1061 4.608L13.3873 3.6455C11.8783 2.80125 11.1237 2.37866 10.3125 2.37866C9.50125 2.37866 8.74672 2.80033 7.23766 3.6455L6.9618 3.80041L14.63 8.4745L18.0813 6.632C17.5261 5.961 16.6306 5.45958 15.1061 4.60616V4.608ZM18.6897 7.84566L15.2539 9.679V12.462C15.2539 12.6443 15.186 12.8192 15.0651 12.9481C14.9443 13.0771 14.7803 13.1495 14.6094 13.1495C14.4384 13.1495 14.2745 13.0771 14.1536 12.9481C14.0327 12.8192 13.9648 12.6443 13.9648 12.462V10.3656L10.957 11.9697V20.624C11.5741 20.4599 12.2762 20.0676 13.3873 19.4452L15.1061 18.4827C16.9546 17.4477 17.8793 16.9307 18.3932 16.0003C18.9062 15.0708 18.9062 13.9131 18.9062 11.6003V11.4931C18.9062 9.75783 18.9062 8.6725 18.6897 7.84566ZM9.66797 20.624V11.9707L1.93531 7.84566C1.71875 8.6725 1.71875 9.75783 1.71875 11.4912V11.5985C1.71875 13.9131 1.71875 15.0708 2.2318 16.0003C2.7457 16.9307 3.67039 17.4487 5.51891 18.4836L7.23766 19.4452C8.34883 20.0676 9.05094 20.4599 9.66797 20.624ZM2.54375 6.63291L10.3125 10.7762L13.2438 9.21333L5.60742 4.5585L5.51891 4.608C3.99523 5.4605 3.09891 5.96191 2.54375 6.63383V6.63291Z"
              fill="#22303E" />
          </svg>


          <div class="text-truncate p-3" data-i18n="Email">{{ $t('Entities.Orders') }}</div>
        </router-link>
      </li>
      <!-- Connect services -->
      <li class="menu-item" :class="{ 'active': $route.name === 'Services' }">
        <router-link :to="{ name: 'Services' }" class="menu-link"
          v-if="authStore.hasPermission(['view-delivery-services', 'view-store-services'])">
          <!-- <i class="menu-icon tf-icons bx bxs-package"></i> -->
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.4181 11.6771L17.595 9.9409C17.6894 9.01452 17.7518 8.40281 17.7029 8.0174L17.7199 8.01751C18.5193 8.01751 19.1673 7.3335 19.1673 6.48973C19.1673 5.64596 18.5193 4.96195 17.7199 4.96195C16.9206 4.96195 16.2726 5.64596 16.2726 6.48973C16.2726 6.87133 16.4051 7.22025 16.6243 7.488C16.3097 7.69297 15.8983 8.12546 15.2792 8.77642L15.2792 8.77645C14.8022 9.27797 14.5637 9.52872 14.2977 9.56756C14.1503 9.58907 14.0001 9.56695 13.864 9.50368C13.6183 9.38949 13.4545 9.0795 13.1269 8.4595L11.4002 5.19152C11.1981 4.80904 11.029 4.48892 10.8765 4.23132C11.5021 3.89441 11.9305 3.20784 11.9305 2.41565C11.9305 1.29063 11.0665 0.378616 10.0007 0.378616C8.93484 0.378616 8.07083 1.29063 8.07083 2.41565C8.07083 3.20784 8.49923 3.89441 9.12483 4.23132C8.97232 4.48894 8.8032 4.80901 8.60109 5.19152L6.87439 8.4595C6.54681 9.07949 6.38301 9.38949 6.13734 9.50368C6.00122 9.56695 5.85102 9.58907 5.70361 9.56756C5.43755 9.52872 5.19907 9.27797 4.72209 8.77645C4.10298 8.12548 3.69163 7.69297 3.37704 7.488C3.59618 7.22025 3.72872 6.87133 3.72872 6.48973C3.72872 5.64596 3.08071 4.96195 2.28135 4.96195C1.48199 4.96195 0.833984 5.64596 0.833984 6.48973C0.833984 7.3335 1.48199 8.01751 2.28135 8.01751L2.29844 8.0174C2.24953 8.40281 2.31186 9.01452 2.40626 9.9409L2.58318 11.6771C2.68139 12.6409 2.76305 13.5579 2.86307 14.3832H17.1382C17.2383 13.5579 17.3199 12.6409 17.4181 11.6771Z"
              fill="#22303E" />
            <path
              d="M8.95089 18.712H11.0504C13.7868 18.712 15.155 18.712 16.0679 17.8494C16.4664 17.473 16.7187 16.7943 16.9008 15.911H3.10052C3.28259 16.7943 3.5349 17.473 3.93336 17.8494C4.84625 18.712 6.21446 18.712 8.95089 18.712Z"
              fill="#22303E" />
          </svg>



          <div class="text-truncate p-3" data-i18n="Email">{{ $t('Entities.Services') }}</div>
        </router-link>
      </li>
      <!-- Contact -->
      <li class="menu-item" :class="{ 'active': $route.name === 'ContactRequest' }">
        <router-link :to="{ name: 'ContactRequest' }" class="menu-link"
        v-if="authStore.isAdmin">
          <!-- <i class="menu-icon tf-icons bx bxs-package"></i> -->
          <i class='bx bx-id-card'  width="20" height="19"></i>



          <div class="text-truncate p-3" data-i18n="Email">{{ $t('Entities.Contact') }}</div>
        </router-link>
      </li>
      <!-- Shipment -->
      <li class="menu-item" :class="{ 'active': $route.name === 'Shipment' }">
        <router-link :to="{ name: 'Shipment' }" class="menu-link" v-if="authStore.hasPermission('view-shipments')">
          <i class='bx bxs-ship'></i>
          <div class="text-truncate p-3" data-i18n="Email">{{ $t('Entities.Shipments') }}</div>
        </router-link>
      </li>

      <!-- Subscriptions -->
      <li class="menu-item" @click.prevent="(event) => toggleClick(event, 'subscription')"
        :class="{ 'open': subscriptionMenuOpen }"
        v-if="subscriptionItems.some(item => authStore.hasPermission(item.permission))">
        <a href="#" class="menu-link menu-toggle">
          <!-- <i class="menu-icon tf-icons bx bxs-purchase-tag"></i> -->
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14.7923 4.87977L16.4695 6.55694C18.9344 9.02184 20.1668 10.2543 20.1668 11.7858C20.1668 13.3173 18.9344 14.5497 16.4695 17.0146C14.0046 19.4795 12.7721 20.712 11.2406 20.712C9.70913 20.712 8.47668 19.4795 6.01177 17.0146L4.33461 15.3375C2.91787 13.9207 2.2095 13.2124 1.94592 12.2933C1.68233 11.3742 1.90759 10.3981 2.35812 8.44585L2.61792 7.32002C2.99695 5.67757 3.18646 4.85634 3.74882 4.29399C4.31118 3.73163 5.1324 3.54212 6.77485 3.16309L7.90069 2.90328C9.85295 2.45276 10.8291 2.2275 11.7481 2.49108C12.6672 2.75466 13.3756 3.46303 14.7923 4.87977ZM10.2179 13.6797C9.60106 13.0628 9.60629 12.1777 9.9752 11.4763C9.79084 11.2085 9.81774 10.839 10.0559 10.6009C10.2932 10.3636 10.6608 10.336 10.9284 10.5182C11.2399 10.3526 11.5822 10.265 11.9233 10.2683C12.303 10.2718 12.6079 10.5825 12.6043 10.9622C12.6007 11.3419 12.29 11.6468 11.9103 11.6432C11.7482 11.6417 11.535 11.7144 11.3523 11.8972C10.997 12.2525 11.0905 12.6077 11.1902 12.7075C11.29 12.8072 11.6452 12.9007 12.0004 12.5454C12.7191 11.8267 13.96 11.5882 14.7552 12.3834C15.3721 13.0003 15.3669 13.8854 14.998 14.5868C15.1823 14.8546 15.1554 15.2241 14.9173 15.4622C14.6799 15.6996 14.3122 15.7271 14.0446 15.5448C13.6258 15.7674 13.1493 15.8508 12.6951 15.7575C12.3232 15.681 12.0836 15.3176 12.1601 14.9456C12.2365 14.5737 12.6 14.3342 12.9719 14.4106C13.1343 14.444 13.3961 14.3907 13.6209 14.1659C13.9761 13.8106 13.8827 13.4554 13.7829 13.3556C13.6832 13.2559 13.328 13.1624 12.9727 13.5177C12.254 14.2364 11.0131 14.4749 10.2179 13.6797ZM9.18604 9.98069C9.90201 9.26473 9.90201 8.10393 9.18604 7.38796C8.47008 6.672 7.30928 6.672 6.59332 7.38796C5.87736 8.10393 5.87736 9.26473 6.59332 9.98069C7.30928 10.6967 8.47008 10.6967 9.18604 9.98069Z"
              fill="#22303E" />
          </svg>

          <div class="text-truncate p-3" data-i18n="Subscription">{{ $t('Entities.Subscription') }}</div>
        </a>
        <transition name="fade">
          <ul class="menu-sub">
            <li v-for="item in filteredsubscriptionItems" :key="item.name" class="menu-item"
              :class="{ 'active': $route.name === item.name }">
              <router-link :to="{ name: item.name }" class="menu-link" v-if="authStore.hasPermission(item.permission)">
                <div class="text-truncate" :data-i18n="item.label">{{ $t(item.label) }}</div>
              </router-link>
            </li>
          </ul>
        </transition>
      </li>
      <!-- Settings -->
      <li class="menu-item" @click.prevent="(event) => toggleClick(event, 'setting')"
        :class="{ 'open': settingMenuOpen }" v-if="settingItems.some(item => authStore.hasPermission(item.permission))">
        <a href="#" class="menu-link menu-toggle">
          <!-- <i class="menu-icon tf-icons bx bxs-cog"></i> -->
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.3617 5.51357L12.6226 2.77273C11.7151 2.25023 10.2942 2.25023 9.38674 2.77273L4.60174 5.5319C2.70424 6.81523 2.59424 7.00773 2.59424 9.0519V14.0294C2.59424 16.0736 2.70424 16.2752 4.6384 17.5769L9.37757 20.3177C9.8359 20.5836 10.4226 20.7119 11.0001 20.7119C11.5776 20.7119 12.1642 20.5836 12.6134 20.3177L17.3984 17.5586C19.2959 16.2752 19.4059 16.0827 19.4059 14.0386V9.0519C19.4059 7.00773 19.2959 6.81523 17.3617 5.51357ZM11.0001 14.5244C9.35924 14.5244 8.02091 13.1861 8.02091 11.5452C8.02091 9.9044 9.35924 8.56607 11.0001 8.56607C12.6409 8.56607 13.9792 9.9044 13.9792 11.5452C13.9792 13.1861 12.6409 14.5244 11.0001 14.5244Z"
              fill="#22303E" />
          </svg>

          <div class="text-truncate p-3" data-i18n="setting">{{ $t('Entities.Setting') }}</div>
        </a>
        <transition name="fade">
          <ul class="menu-sub">
            <li v-for="item in filteredsettingItems" :key="item.name" class="menu-item"
              :class="{ 'active': $route.name === item.name }">
              <router-link :to="{ name: item.name }" class="menu-link" v-if="authStore.hasPermission(item.permission)">
                <div class="text-truncate" :data-i18n="item.label">{{ $t(item.label) }}</div>
              </router-link>
            </li>
          </ul>
        </transition>
      </li>
      <!-- Shipping -->
      <li class="menu-item" @click.prevent="(event) => toggleClick(event, 'shipping')"
        :class="{ 'open': shippingMenuOpen }"
        v-if="shippingItems.some(item => authStore.hasPermission(item.permission))">
        <a href="#" class="menu-link menu-toggle">
          <!-- <i class="menu-icon tf-icons bx bxs-ship"></i> -->
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.76739 8.54824L9.23895 10.2551C9.68354 11.8644 9.90584 12.669 10.5594 13.035C11.213 13.401 12.0426 13.1854 13.7018 12.7542L15.4617 12.2968C17.1209 11.8656 17.9506 11.65 18.3279 11.0161C18.7052 10.3822 18.4829 9.57761 18.0384 7.96834L17.5668 6.26144C17.1222 4.65217 16.8999 3.84753 16.2463 3.48155C15.5928 3.11557 14.7631 3.33117 13.1039 3.76238L11.344 4.21974C9.68481 4.65094 8.85519 4.86654 8.47785 5.50044C8.10051 6.13433 8.32281 6.93897 8.76739 8.54824Z"
              fill="#22303E" />
            <path
              d="M2.0877 5.35499C2.18921 4.98911 2.5681 4.7748 2.93398 4.87631L4.4957 5.30961C5.33618 5.5428 6.00133 6.19072 6.23336 7.03061L8.20514 14.1678L8.35023 14.6688C8.93062 14.8854 9.42911 15.2914 9.74548 15.8305L10.0296 15.7427L18.1606 13.6297C18.5281 13.5342 18.9034 13.7546 18.9989 14.1221C19.0944 14.4896 18.8739 14.8649 18.5064 14.9605L10.4056 17.0657L10.1042 17.1587C10.0981 18.3238 9.2943 19.3878 8.078 19.7039C6.62007 20.0828 5.1215 19.2436 4.73085 17.8296C4.34019 16.4155 5.20539 14.9621 6.66333 14.5832C6.73557 14.5644 6.80792 14.5486 6.88023 14.5358L4.90801 7.39676C4.80876 7.03751 4.51823 6.7428 4.12809 6.63456L2.56637 6.20126C2.2005 6.09975 1.98619 5.72086 2.0877 5.35499Z"
              fill="#22303E" />
          </svg>


          <div class="text-truncate p-3" data-i18n="setting">{{ $t('Entities.Shipping') }}</div>
        </a>
        <transition name="fade">
          <ul class="menu-sub">
            <li v-for="item in filteredshippingItems" :key="item.name" class="menu-item"
              :class="{ 'active': $route.name === item.name }">
              <router-link :to="{ name: item.name }" class="menu-link" v-if="authStore.hasPermission(item.permission)">
                <div class="text-truncate" :data-i18n="item.label">{{ $t(item.label) }}</div>
              </router-link>
            </li>
          </ul>
        </transition>
      </li>







    </ul>
  </aside>
  <!-- / Menu -->


</template>
<script setup>

import { useAuthStore } from '@/stores/auth';
import { ref, onMounted, watch, computed, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
// import i18n from '@/i18n.js';
import { useLoadingStore } from '@/stores/loading';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const route = useRoute();
const userMenuOpen = ref(false);
const storeMenuOpen = ref(false);
const subscriptionMenuOpen = ref(false);
const settingMenuOpen = ref(false);
const shippingMenuOpen = ref(false);

const menuRef = ref(null);
const menuToggle = ref(null);

const userItems = computed(() => [
  {
    name: 'Users', label: t('Entities.Users'),
    permission: 'view-users'
  },
  {
    name: 'Employees', label: t('Entities.Employees')
    , permission: 'view-employees'

  },
  {
    name: 'Clients', label: t('Entities.Clients')
    , permission: 'view-clients'
  },
  {
    name: 'Customers', label: t('Entities.Customers')
    , permission: 'view-customers'
  }
]);
const filteredUserItems = computed(() =>
  userItems.value.filter(item => authStore.hasPermission(item.permission))
);


const storeItems = computed(() => [
  {
    name: 'Warehouse', label: t('Entities.Warehouses')
    , permission: 'view-warehouse'
  },
 // {
 //   name: 'Inventory', label: t('Entities.Inventory')
 //   , permission: 'view-inventory'
 // },
  {
    name: 'Products', label: t('Entities.Products')
    , permission: 'view-products'
  }
]);
const filteredstoreItems = computed(() =>
  storeItems.value.filter(item => authStore.hasPermission(item.permission))
);

const subscriptionItems = computed(() => [
  {
    name: 'Subscriptions', label: t('Entities.Subscriptions')
    , permission: 'view-subscriptions'
  },
  {
    name: 'Plans', label: t('Entities.Plans')
    , permission: 'view-plans'
  },
]);
const filteredsubscriptionItems = computed(() =>
  subscriptionItems.value.filter(item => authStore.hasPermission(item.permission))
);
const settingItems = computed(() => [
  {
    name: 'Conditions', label: t('Entities.Conditions')
    , permission: 'view-condition'
  },
  {
    name: 'Countries', label: t('Entities.Countries')
    , permission: 'view-countries'
  },
  {
    name: 'Cities', label: t('Entities.Cities')
    , permission: 'view-cities'
  },
  {
    name: 'PrintNode', label: t('Entities.PrintNode')
    , permission: 'view-printnode'
  },
  {
    name: 'DeliveryCompany', label: t('Entities.DeliveryCompany')
    , permission: 'view-deliveryCompanies'
  },
  {
    name: 'PaymentMethod', label: t('Entities.PaymentMethod')
    , permission: 'view-paymentMethods'
  },
  {
    name: 'Webhooks', label: t('Entities.Webhooks')
    , permission: 'view-webhooks'
  },
  {
    name: 'DqqSetting', label: t('Entities.DqqSetting')
    , permission: ['admin-settings', 'view-order-settings', 'view-notify-setting']
  },
  {
    name: 'SendNotification', label: t('Entities.SendNotification')
    , permission: 'send-notification'
  },
  {
    name: 'ZidDataInfo', label: t('Entities.ZidDataInfo')
    , permission: 'view-zid-data'
  },
  {
    name: 'Roles', label: t('Entities.Roles')
    , permission: 'view-roles'
  },
]);
const filteredsettingItems = computed(() =>
  settingItems.value.filter(item => authStore.hasPermission(item.permission))
);
const shippingItems = computed(() => [
  {
    name: 'fulfilments',
    label: t('Entities.Fulfilment'),
    permission: "view-fulfilment"
  },
  {
    name: 'reOrder', label: t('Entities.ReOrders'),
    permission: 'view-reorder'
  },
  {
    name: 'packages', label: t('Entities.Packages'),
    permission: "view-packages"
  },
  {
    name: 'returnOrder', label: t('Entities.ReturnOrders'),
    permission: "view-returns"
  },
]);
const filteredshippingItems = computed(() =>
  shippingItems.value.filter(item => authStore.hasPermission(item.permission))
);


function initialize() {
  userMenuOpen.value = userItems.value.some(item => item.name === route.name);
  storeMenuOpen.value = storeItems.value.some(item => item.name === route.name);
  subscriptionMenuOpen.value = subscriptionItems.value.some(item => item.name === route.name);
  settingMenuOpen.value = settingItems.value.some(item => item.name === route.name);
  shippingMenuOpen.value = shippingItems.value.some(item => item.name === route.name);
}

onMounted(() => {
  if (authStore.isLoggedIn) {
    getProfile();
    initialize();
  }

  document.addEventListener('click', handleClickOutside);
});
const authStore = useAuthStore();
const loadingStore = useLoadingStore();

const getProfile = async () => {
  try {

    await authStore.profile();

    loadingStore.setLoading(false)
  } catch (e) {
    console.log(e);
  }
};




watch(
  () => route.name,
  (newRouteName) => {
    userMenuOpen.value = userItems.value.some(item => item.name === newRouteName);
    storeMenuOpen.value = storeItems.value.some(item => item.name === newRouteName);
    subscriptionMenuOpen.value = subscriptionItems.value.some(item => item.name === newRouteName);
    settingMenuOpen.value = settingItems.value.some(item => item.name === newRouteName);
    shippingMenuOpen.value = shippingItems.value.some(item => item.name === newRouteName);
  }
);


function toggleClick(event, type) {
  const isInMenuSub = !event.target.closest('.menu-sub');
  if (isInMenuSub && type == 'user') {
    userMenuOpen.value = !userMenuOpen.value;

  }
  if (isInMenuSub && type == 'store') {
    storeMenuOpen.value = !storeMenuOpen.value;


  }
  if (isInMenuSub && type == 'subscription') {
    subscriptionMenuOpen.value = !subscriptionMenuOpen.value;
  }
  if (isInMenuSub && type == 'setting') {
    settingMenuOpen.value = !settingMenuOpen.value;
  }
  if (isInMenuSub && type == 'shipping') {
    shippingMenuOpen.value = !shippingMenuOpen.value;
  }
}

//navbar
const iscollapseNav = ref(false);
function collapseNav() {

  if (iscollapseNav.value) {

    document.documentElement.classList.remove('layout-menu-collapsed');
  } else {

    document.documentElement.classList.add('layout-menu-collapsed');

  }
  iscollapseNav.value = !iscollapseNav.value;

  // light-style layout-navbar-fixed layout-compact layout-menu-fixed layout-menu-collapsed
}

// Method to handle mouse enter
const handleMouseEnter = () => {
  if (iscollapseNav.value)
    document.documentElement.classList.add('layout-menu-hover');

};

// Method to handle mouse leave
const handleMouseLeave = () => {
  if (iscollapseNav.value)
    document.documentElement.classList.remove('layout-menu-hover');

};

const handleClickOutside = (event) => {


  if (menuRef.value && !menuRef.value.contains(event.target) && iscollapseNav.value && document.documentElement.classList.contains('layout-menu-expanded')) {
    document.documentElement.classList.remove('layout-menu-expanded');
    collapseNav()
  }
  if (document.documentElement.classList.contains('layout-menu-expanded')) {
    collapseNav()
  }
};


onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

const isSmallScreen = ref(window.innerWidth < 768);

const updateHtmlClass = () => {
  if (isSmallScreen.value) {
    // light-style layout-navbar-fixed layout-compact layout-menu-100vh layout-menu-fixed layout-menu-expanded
    document.documentElement.classList.add(
      'light-style',
      'layout-navbar-fixed',
      'layout-compact',
      'layout-menu-100vh',
      // 'layout-menu-expanded',
      'layout-menu-fixed'
    );
  } else {
    document.documentElement.classList.remove(
      'light-style',
      'layout-navbar-fixed',
      'layout-compact',
      'layout-menu-100vh',
      // 'layout-menu-expanded',
      'layout-menu-fixed'
    );
  }
  if (menuToggle.value) {
    if (isSmallScreen.value) {
      // Small screen: remove 'd-block' and add 'd-none'
      menuToggle.value.classList.remove('d-block');
      menuToggle.value.classList.add('d-none');
    } else {
      // Large screen: remove 'd-none' and add 'd-block'
      menuToggle.value.classList.remove('d-none');
      menuToggle.value.classList.add('d-block');
    }
  }
};

const handleResize = () => {
  isSmallScreen.value = window.innerWidth < 768;
};

// Watch for changes and update classes
watch(isSmallScreen, updateHtmlClass);

onMounted(() => {
  window.addEventListener('resize', handleResize);
  updateHtmlClass(); // Initial update
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});


</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.open {
  color: #24A259 !important;
}

.open>a {
  color: #24A259 !important;
}

.active>a>svg {
  filter: brightness(0) saturate(100%) invert(38%) sepia(50%) saturate(2059%) hue-rotate(80deg) brightness(94%) contrast(91%);
}

.open>a>svg {
  filter: brightness(0) saturate(100%) invert(38%) sepia(50%) saturate(2059%) hue-rotate(80deg) brightness(94%) contrast(91%);
}

.layout-menu {
  position: sticky;
  top: 0;
  max-height: 100vh;
}

.menu-inner {
  max-height: max-content !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.menu-inner::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.menu-inner::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.menu-inner::-webkit-scrollbar-thumb {
  background: #d7d7d7;
  border-radius: 10px;
}

/* Handle on hover */
.menu-inner::-webkit-scrollbar-thumb:hover {
  background: #24A259;
}
</style>