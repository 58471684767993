<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.Warehouses') }}</h3>
            <DataTable v-model:rows="WarehouseData" :headers="headers.filter((value) => value != null)"
                :fields="fields.filter((value) => value != null)" :total="total" :loading="loading"
                @page-changed="onPageChanged">
                <template v-slot:head-contant="{}">

                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M26.6673 24C26.6673 26.9455 21.8917 29.3333 16.0007 29.3333C10.1096 29.3333 5.33398 26.9455 5.33398 24V18.6319C6.0036 19.4541 6.95392 20.1371 8.01097 20.6657C10.1074 21.7139 12.9335 22.3333 16.0007 22.3333C19.0678 22.3333 21.8939 21.7139 23.9903 20.6657C25.0474 20.1371 25.9977 19.4541 26.6673 18.6319V24Z"
                                    fill="#24A259" />
                                <path
                                    d="M16.0007 14.3333C19.0678 14.3333 21.8939 13.7139 23.9903 12.6657C25.0474 12.1371 25.9977 11.4541 26.6673 10.6319V16C26.6673 16.6667 24.2864 18.1215 23.0959 18.8768C21.3317 19.7589 18.8246 20.3333 16.0007 20.3333C13.1767 20.3333 10.6696 19.7589 8.90539 18.8768C7.33398 18.0911 5.33398 16.6667 5.33398 16V10.6319C6.0036 11.4541 6.95392 12.1371 8.01097 12.6657C10.1074 13.7139 12.9335 14.3333 16.0007 14.3333Z"
                                    fill="#24A259" />
                                <path
                                    d="M23.0959 10.8768C21.3317 11.7589 18.8246 12.3333 16.0007 12.3333C13.1767 12.3333 10.6696 11.7589 8.90539 10.8768C8.2041 10.6021 6.60791 9.71556 5.53294 8.28113C5.3991 8.10253 5.3409 7.8788 5.37073 7.65762C5.40169 7.42808 5.44979 7.16705 5.51503 7.01634C6.43819 4.54071 10.7817 2.66666 16.0007 2.66666C21.2196 2.66666 25.5631 4.54071 26.4863 7.01634C26.5515 7.16705 26.5996 7.42808 26.6306 7.65762C26.6604 7.8788 26.6022 8.10253 26.4684 8.28113C25.3934 9.71556 23.7972 10.6021 23.0959 10.8768Z"
                                    fill="#24A259" />
                            </svg>

                            <h3 class="table-title">{{ $t('Sections.Warehouses.List') }}</h3>

                        </div>
                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31"
                                v-model.lazy="searchQuery">
                        </div>
                    </div>
                    <div class="card-header flex-column flex-md-row pb-0"
                        v-if="authStore.hasPermission('create-warehouse')">

                        <div class="dt-action-buttons text-end pt-6 pt-md-0">
                            <div class="dt-buttons btn-group flex-wrap">

                                <button class="btn btn-secondary create-new btn-primary" tabindex="0"
                                    @click="onAddRow()" aria-controls="DataTables_Table_0" type="button"><span><span
                                            class="d-none d-sm-inline-block">
                                            {{ $t('Sections.Warehouses.AddNew') }}
                                        </span> <i class="bx bx-plus bx-sm me-sm-2"></i> </span>
                                </button>
                            </div>
                        </div>
                    </div>

                </template>

                <template v-slot:action-buttons="{ rowColum }">
                    <td class="p-0 col-auto text-center align-middle">
                        <div class="d-flex me-2 justify-content-center">
                            <button @click="onAssignRow(rowColum)" class="btn btn-sm mx-1 warining-btn">
                                <!-- <i class='bx bx-compass'></i> -->
                                <img src="@/assets/dashboard/svg/figma/city.svg" alt="country">

                            </button>
                            <button type="button" class="btn edit-btn mx-1"
                                v-if="authStore.hasPermission('update-warehouse')" @click="onEditRow(rowColum)">
                                <i class='bx bxs-edit-alt'></i>

                            </button>
                            <button v-if="authStore.hasPermission('delete-warehouse')"
                                @click="triggerDeleteModal(rowColum)" class="btn btn-sm mx-1 delete-btn">
                                <svg width="13" height="15" viewBox="0 0 13 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.5 3.74921C0.5 3.48622 0.717806 3.27302 0.986484 3.27302H4.17858C4.18292 2.7122 4.2437 1.94336 4.80025 1.41112C5.23824 0.992261 5.83872 0.733337 6.49999 0.733337C7.16127 0.733337 7.76174 0.992261 8.19974 1.41112C8.75629 1.94336 8.81707 2.7122 8.82141 3.27302H12.0135C12.2822 3.27302 12.5 3.48622 12.5 3.74921C12.5 4.0122 12.2822 4.2254 12.0135 4.2254H0.986484C0.717806 4.2254 0.5 4.0122 0.5 3.74921Z"
                                        fill="#EE1D52" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M6.2304 14.0667H6.7696C8.62474 14.0667 9.55231 14.0667 10.1554 13.4761C10.7585 12.8854 10.8202 11.9166 10.9436 9.97901L11.1214 7.18707C11.1884 6.13574 11.2219 5.61008 10.9193 5.27697C10.6167 4.94386 10.1058 4.94386 9.08397 4.94386H3.91603C2.89418 4.94386 2.38325 4.94386 2.0807 5.27697C1.77815 5.61008 1.81163 6.13574 1.87858 7.18707L2.05639 9.979C2.1798 11.9166 2.2415 12.8854 2.84459 13.4761C3.44769 14.0667 4.37526 14.0667 6.2304 14.0667ZM5.33085 7.52566C5.30337 7.23642 5.05835 7.0254 4.78358 7.05432C4.50881 7.08325 4.30834 7.34116 4.33581 7.6304L4.66915 11.1392C4.69662 11.4284 4.94165 11.6394 5.21642 11.6105C5.49119 11.5816 5.69166 11.3237 5.66419 11.0344L5.33085 7.52566ZM8.21642 7.05432C8.49119 7.08325 8.69166 7.34116 8.66419 7.6304L8.33085 11.1392C8.30338 11.4284 8.05835 11.6394 7.78358 11.6105C7.50881 11.5816 7.30834 11.3237 7.33581 11.0344L7.66915 7.52566C7.69663 7.23642 7.94165 7.0254 8.21642 7.05432Z"
                                        fill="#EE1D52" />
                                </svg>

                            </button>

                        </div>
                    </td>




                </template>

            </DataTable>

            <AddWarehouse v-if="showAdd" @close-add="onCloseAdd" :onAdd="fetchData" />
            <AssignWarehouseCities v-if="assignCity" :row="editRow" @close-edit="onCloseAssign" :onEdit="fetchData" />
            <EditWarehouse v-if="showEdit" :row="editRow" @close-edit="onCloseEdit" :onEdit="fetchData" />
            <DeleteModal ref="deleteModal" :row="editRow" :onDelete="handleDelete"
                :title="$t('Sections.Warehouses.Delete')" />




        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';

import i18n from '@/i18n.js';
import { deleteWarehouse, getWarehouses } from '@/services/warehouse';
import { formatDateToYMDHMS } from '@/utils/dateFormatter';
import AddWarehouse from './componants/AddWarehouse.vue';
import EditWarehouse from './componants/EditWarehouse.vue';
import { useAuthStore } from '@/stores/auth';
import DeleteModal from '@/components/pages/DeleteModal.vue';
import { toast } from 'vue3-toastify';
import AssignWarehouseCities from './componants/AssignWarehouseCities.vue';
import { getname } from '@/utils/translate';


const authStore = useAuthStore();
// State
// Create a reference to the DeleteModal component
const WarehouseData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const searchQuery = ref('');

//for Action btn
const editRow = ref({});





// Table headers and fields
const headers = computed(() => ['#',
    authStore.hasPermission('view-clients') ? i18n.global.t('General.Client') : null,
    i18n.global.t('General.Name'),
    i18n.global.t('General.Country'),
    i18n.global.t('General.City'),
    i18n.global.t('General.Address_1'),
    i18n.global.t('General.CreatedAt'),
    i18n.global.t('General.UpdatedAt'),
    i18n.global.t('General.Edit')
]);
const fields = computed(() => ['ids',
    authStore.hasPermission('view-clients') ? 'client_name' : null
    , 'name', 'country_name', 'city_name', 'address_1', 'created_at', 'updated_at']);





// Fetch users with pagination
const fetchData = async () => {

    try {
        loading.value = true;
        WarehouseData.value = [];

        const response = await getWarehouses(currentPage.value, searchQuery.value);

        let count = 1;
        for (const row of response.data.data.data) {

            WarehouseData.value.push({
                ...row,
                ids:   (((currentPage.value??1) -1 ) *10) + count++ , 

                created_at: formatDateToYMDHMS(row.created_at),
                updated_at: formatDateToYMDHMS(row.updated_at),
                client_name: row.client && row.client.name ? row.client.name : "NAN",
                country_name: row.country && row.country.name ? row.country.name : "NAN",
                city_name: row.city  ? getname(row.city) : "NAN",

            });

        }
        // WarehouseData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};

// Fetch users on mount
onMounted(fetchData);
// Watch for changes on searchQuery
watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});

const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};


//add
const showAdd = ref(false);
function onAddRow() {
    console.log(1);

    showAdd.value = !showAdd.value;
}
function onCloseAdd() {
    showAdd.value = !showAdd.value;

}

//edit
const showEdit = ref(false);
function onEditRow(row) {
    showEdit.value = !showEdit.value;

    editRow.value = row;

}
function onCloseEdit() {
    showEdit.value = !showEdit.value;

}
//edit
const assignCity = ref(false);
function onAssignRow(row) {
    assignCity.value = !assignCity.value;

    editRow.value = row;

}
function onCloseAssign() {
    assignCity.value = !assignCity.value;

}

//delete
const deleteModal = ref(null);
const handleDelete = async () => {
    try {
        loading.value = true;
        await deleteWarehouse(editRow.value.id);
        fetchData();
        toast.success(i18n.global.t('Messages.Success.Deleted'), {
            autoClose: 3000, // Adjust time if needed
        });

        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error('Error fetching users:', error);
    }
};
const triggerDeleteModal = (row) => {
    editRow.value = row;

    deleteModal.value.openModal();
};





</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>