<template>
    <div class="modal fade d-flex align-items-center justify-content-center" :class="{ 'show': showWarehouse }"
        tabindex="-1" aria-modal="true" role="dialog" style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-status ">
            <div class="modal-content" ref="modal">


                <div class="modal-header d-flex justify-content-center  align-items-center text-center ">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M26.6673 24C26.6673 26.9455 21.8917 29.3333 16.0007 29.3333C10.1096 29.3333 5.33398 26.9455 5.33398 24V18.6319C6.0036 19.4541 6.95392 20.1371 8.01097 20.6657C10.1074 21.7139 12.9335 22.3333 16.0007 22.3333C19.0678 22.3333 21.8939 21.7139 23.9903 20.6657C25.0474 20.1371 25.9977 19.4541 26.6673 18.6319V24Z"
                            fill="#24A259" />
                        <path
                            d="M16.0007 14.3333C19.0678 14.3333 21.8939 13.7139 23.9903 12.6657C25.0474 12.1371 25.9977 11.4541 26.6673 10.6319V16C26.6673 16.6667 24.2864 18.1215 23.0959 18.8768C21.3317 19.7589 18.8246 20.3333 16.0007 20.3333C13.1767 20.3333 10.6696 19.7589 8.90539 18.8768C7.33398 18.0911 5.33398 16.6667 5.33398 16V10.6319C6.0036 11.4541 6.95392 12.1371 8.01097 12.6657C10.1074 13.7139 12.9335 14.3333 16.0007 14.3333Z"
                            fill="#24A259" />
                        <path
                            d="M23.0959 10.8768C21.3317 11.7589 18.8246 12.3333 16.0007 12.3333C13.1767 12.3333 10.6696 11.7589 8.90539 10.8768C8.2041 10.6021 6.60791 9.71556 5.53294 8.28113C5.3991 8.10253 5.3409 7.8788 5.37073 7.65762C5.40169 7.42808 5.44979 7.16705 5.51503 7.01634C6.43819 4.54071 10.7817 2.66666 16.0007 2.66666C21.2196 2.66666 25.5631 4.54071 26.4863 7.01634C26.5515 7.16705 26.5996 7.42808 26.6306 7.65762C26.6604 7.8788 26.6022 8.10253 26.4684 8.28113C25.3934 9.71556 23.7972 10.6021 23.0959 10.8768Z"
                            fill="#24A259" />
                    </svg>

                    <h5 class="title mt-3 me-3">{{ $t('Sections.Warehouses.Choice') }}</h5>
                    <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                </div>

                <form class="row" @submit.prevent="onSubmit">
                    <div class="modal-body text-center">
                        <div class="col-12 mb-3">

                            <FormSelect :options="warehousesData" v-model="localWarehouseId"
                                v-if="!warehouseIsVisibile" />

                        </div>

                        <!-- Submit Button -->
                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn btn-primary  done-btn" :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Done') }}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch, ref, onBeforeUnmount, onMounted } from 'vue';

import i18n from '@/i18n.js';
import { warehousesByClientId } from '@/services/shipment';
import { useAuthStore } from '@/stores/auth';
import FormSelect from '@/components/forms/FormSelect.vue';
import { toast } from 'vue3-toastify';

const emit = defineEmits(['close-status', 'update:modelValue']);
const props = defineProps({
    onWarehoseSubmit: { type: Function, required: true },
    modelValue: String,
});

const showWarehouse = ref(true);
const loading = ref(false);

const localWarehouseId = ref(null);
const warehousesData = ref([]);
const authStore = useAuthStore();

const searchWarehouse = async () => {
    try {
        const response = await warehousesByClientId(authStore.user?.id);
        warehousesData.value = response.data.data;
    } catch (error) {
        console.error(i18n.global.t('Messages.Error.SubmitError'), error);
    }
};

// Watch for changes and emit an event when warehouse is selected
// Watch for model updates and emit changes
watch(localWarehouseId, (newValue) => {
    emit('update:modelValue', newValue);
});
const onSubmit = async () => {
    if (!localWarehouseId.value) {
        toast.error("Please select a warehouse.", { autoClose: 3000 });
        return;
    }

    loading.value = true;
    props.onWarehoseSubmit();
    closeModal();

};

function closeModal() {
    emit('close-status');
}

const modal = ref(null);
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModal();
    }
};

onMounted(() => {
    searchWarehouse();
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);
    }, 500);
});

onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.modal-content {
    border-radius: 8px;
}


.title {
    font-family: Public Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 40.8px;
    text-align: center;
    color: #1168FF;
}


.select-border-succ {
    border: 2px solid #28a745 !important;
    /* Default border to avoid collapse */

}

.select-border-edit {
    border: 2px solid #1168FF !important
        /*  border for "Approved" */
}

.select-border-danger {
    border: 2px solid #EE1D52 !important;
    /* Red border for "Blocked" */
}

.btn {
    width: 30%;
    height: 60px;
}

.done-btn {
    width: 60%;
    padding: 13px 24px;
    border-radius: 16px;
    background-color: #24A259;
}

form {
    margin-right: 5rem;
    margin-left: 5rem;
}
</style>
