<template>

  <div class="layout-wrapper layout-content-navbar">
    <div class="layout-container">


      <prime-header  />

      <!-- Layout container -->
      <div class="layout-page">
        <!-- Navbar -->

        <page-nav  />
        <!-- / Navbar -->

        <!-- Content wrapper -->
        <LoadingScreen />
        <router-view v-if="isLoading" :key="reloadStore.reloadKey" />

        <!-- Content wrapper -->
      </div>
      <!-- / Layout page -->
    </div>

    <!-- Overlay -->
    <div class="layout-overlay layout-menu-toggle"></div>

    <!-- Drag Target Area To SlideIn Menu On Small Screens -->
    <div class="drag-target"></div>
  </div>
</template>

<script setup>
import PrimeHeader from './components/PrimeHeader.vue';
import PageNav from './components/PageNav.vue';
import LoadingScreen from './components/LoadingScreen.vue';
import { useLoadingStore } from './stores/loading';
import { ref } from 'vue';
import { useReloadStore } from './stores/reload';

const loadingStore = useLoadingStore();
const reloadStore = useReloadStore();

// Hide the loader after a delay (simulating a load)
// setTimeout(() => loadingStore.setLoading(false), 2000);
const isLoading = ref(loadingStore.isLoading)


</script>

<style></style>
