<template>
    <div class="content d-flex flex-column justify-content-center align-items-center">
        <div class="row login-wrapper">
            <div class="card-content d-flex flex-column justify-content-center align-items-center text-nowrap">
                <div class="row">
                    <h2 class="fw-bold text-primary py-3 my-3 fs-3 ">{{ $t('SignUp') }}</h2>
                </div>
                <form @submit.prevent="onSubmit" autocomplete="off">
                    <div class="col-auto mb-4">
                        <label class="mb-1" for="name">{{ $t('General.Name') }}</label>
                        <input type="text" v-model="name" :class="{ 'border-danger': errors.name }" class="form-control"
                            id="name">
                        <div v-if="errors.name" class="text-danger">{{ errors.Name }}</div>
                    </div>
                    <div class="col-auto mb-4">
                        <label class="mb-1" for="username">{{ $t('Auth.Username') }}</label>
                        <input type="text" v-model="user_name" :class="{ 'border-danger': errors.user_name }"
                            class="form-control" id="username" autocomplete="off">
                        <div v-if="errors.user_name" class="text-danger">{{ errors.user_name }}</div>
                    </div>
                    <div class="col-auto mb-4">
                        <label class="mb-1" for="email">{{ $t('General.Email') }}</label>
                        <input type="email" v-model="email" :class="{ 'border-danger': errors.email }"
                            class="form-control" id="email" placeholder="email@example.com">
                        <div v-if="errors.email" class="text-danger">{{ errors.email }}</div>
                    </div>

                    <div class="col-auto mb-4 position-relative">
                        <div class="d-flex justify-content-between align-items-center ">
                            <label class="mb-1" for="password">{{ $t('Auth.Password') }}</label>
                            <span class=" text-capitalize curser-pointer text-primary" @click="togglePasswordVisibility"> {{ !showPassword ? $t('General.Show' ): $t('General.Hide') }}

                                <button type="button" class="border-none text-primary outline-none bg-transparent"
                                    >
                                    <i :class="showPassword ? 'bx bx-hide' : 'bx bx-show'"></i>
                                </button>

                            </span>
                        </div>
                        <input :type="showPassword ? 'text' : 'password'" v-model="password"
                            :class="{ 'border-danger': errors.password }" class="form-control" id="password"
                            autocomplete="current-password">

                        <div v-if="errors.password" class="text-danger">{{ errors.password }}</div>
                    </div>
                    <div class="col-auto mb-4 position-relative">
                        <div class="d-flex justify-content-between align-items-center ">
                            <label class="mb-1" for="password_confirmation">{{ $t('Auth.PasswordConfirmation')
                                }}</label>
                            <span class=" text-capitalize curser-pointer text-primary" @click="togglePasswordConfirmationVisibility"> {{ !showPasswordConfirmation ? $t('General.Show' ): $t('General.Hide')
                                }}

                                <button type="button" class="border-none text-primary outline-none bg-transparent"
                                    >
                                    <i :class="showPasswordConfirmation ? 'bx bx-hide' : 'bx bx-show'"></i>
                                </button>

                            </span>
                        </div>
                        <input :type="showPasswordConfirmation ? 'text' : 'password'" v-model="password_confirmation"
                            :class="{ 'border-danger': errors.password_confirmation }" class="form-control"
                            id="password_confirmation" autocomplete="current-password">

                        <div v-if="errors.password_confirmation" class="text-danger">{{ errors.password_confirmation }}
                        </div>
                    </div>

                    <!-- <div class="col-auto mb-4 position-relative">
                        <label class="mb-1" for="password_confirmation">{{ $t('Auth.PasswordConfirmation') }}</label>
                        <div class="input-group">
                            <input :type="showPasswordConfirmation ? 'text' : 'password'"
                                v-model="password_confirmation"
                                :class="{ 'border-danger': errors.password_confirmation }" class="form-control"
                                id="password_confirmation" :placeholder="$t('Auth.PasswordConfirmation')">
                            <button type="button" class="btn btn-outline-secondary "
                                @click="togglePasswordConfirmationVisibility">
                                <i :class="showPasswordConfirmation ? 'bx bx-hide' : 'bx bx-show'"></i>
                            </button>
                        </div>
                        <div v-if="errors.password_confirmation" class="text-danger">{{ errors.password_confirmation }}
                        </div>
                    </div> -->

                    <div class="el-form-item__content">
                        <button type="submit" class="login-button" :disabled="loading">
                            <span v-if="loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span class="visually-hidden">Loading...</span>
                            </span>
                            <span v-else>{{ $t('Create.Account') }}</span>
                        </button>
                    </div>
                </form>
                <div class="row d-flex justify-content-center   text-wrap mb-6">
                    <p class="m-auto"> <span class="underline">{{ $t('Auth.ToLogin') }} ?</span> <router-link
                            :to="{ name: 'Login' }" class="menu-link">

                            <span class="color-green ">{{ $t('Auth.SignIn') }}</span>
                        </router-link> </p>
                </div>
            </div>
        </div>
        <div class="language-switcher position-fixed top-0 start-0 m-3">
            <button class="bg-transparent border-none fs-6 text-secondary dropdown-toggle" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src="@/assets/dashboard/img/en.svg" class="px-2" alt="en icon">
                <span>English</span>
            </button>
            <ul class="dropdown-menu">
                <li v-show="$i18n.locale != 'en'">
                    <button class="dropdown-item" @click="changeLanguage" data-language="en" data-text-direction="ltr">
                        <img src="@/assets/dashboard/img/en.svg" class="px-2" alt="en icon">
                        <span>English</span>
                    </button>
                </li>
                <li v-show="$i18n.locale != 'ar'">
                    <button class="dropdown-item" @click="changeLanguage" data-language="ar" data-text-direction="rtl">
                        <img src="@/assets/dashboard/img/ar.svg" class="px-2" alt="en icon">
                        <span>العربيه</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/auth";

import { computed, onMounted, ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

import { toast } from "vue3-toastify";
import { useRouter } from "vue-router";
import { useLoadingStore } from "@/stores/loading";
const router = useRouter();
// i18n setup
const { t, locale } = useI18n();
const currentLang = ref(locale.value);

// Form schema
const validationSchema = computed(() => yup.object({
    name: yup.string().required(t('Validation.Required.Name')),
    user_name: yup.string().required(t('Validation.Required.Username')),
    email: yup
        .string()
        .email(t('Validation.Invalid.Email'))
        .required(t('Validation.Required.Email')),
    password: yup
        .string()
        .min(12, t('Validation.Password.MinLength'))
        .required(t('Validation.Required.Password')),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], t('Validation.Password.ConfirmationMismatch'))
        .required(t('Validation.Required.PasswordConfirmation'))
}));

// VeeValidate setup
const { handleSubmit, errors } = useForm({
    validationSchema
});
const { value: name } = useField('name');
const { value: user_name } = useField('user_name');
const { value: email } = useField('email');
const { value: password } = useField('password');
const { value: password_confirmation } = useField('password_confirmation');

const loading = ref(false);
const authStore = useAuthStore();

const successMessage = computed(() => t('Messages.Success.Created'));

const onSubmit = handleSubmit(async (values) => {

    try {
        loading.value = true;
        await authStore.handleRegister(values);

        toast.success(successMessage.value);
        // window.location.href = '/login';
        router.push('/login');
    } catch (e) {
        loading.value = false;
    }
});
// Show password toggle
const showPassword = ref(false);
const showPasswordConfirmation = ref(false);
const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
};
const togglePasswordConfirmationVisibility = () => {
    showPasswordConfirmation.value = !showPasswordConfirmation.value;
};

const changeLanguage = () => {
    currentLang.value = currentLang.value === 'en' ? 'ar' : 'en';
    locale.value = currentLang.value;
    localStorage.setItem('lang', locale.value);


};

onMounted(() => {
    const loadingStore = useLoadingStore();
    loadingStore.setLoading(false)
});
</script>


<style scoped>
.content {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    flex: 1;
    height: calc(100% - 10px);
    border-radius: 30px;
    /* background-color: white; */
}

label {
    color: #22303E;
    text-transform: capitalize;
    font-weight: 500;
}

.head-content {
    width: 70%;
    max-width: 715px;
}

.login-wrapper {
    width: 620px;
    min-height: 80vh;
    border-radius: 20px;
    /* box-shadow: 8px 8px rgba(124, 124, 124, 0.69); */
}

.login-wrapper>div:first-of-type {
    padding-left: 0;
    padding-right: 0;
}

.login-wrapper>div:last-of-type {
    padding-right: 0;
}

.img {
    background-image: url(../../assets/logo.png);
    background-size: cover;
}

.font {
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 20px;
    padding: 1rem 0;
}

.color-green {

    color: #3aab6a;
}




.language-switcher {
    z-index: 1050;
    /* Ensures it stays above other elements */
}

.card-content {
    background-color: #ffffff;
    border-radius: inherit;


}

form {
    width: 70%;
    margin: 0 auto;
    /* text-align: end; */
}

form>div:first-of-type {
    margin-bottom: 15px;
}

form>div:last-of-type {
    margin-bottom: 22px;
}

.login-button {
    width: 100%;
    display: block;
    margin: 30px auto;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 8px;
    font-weight: 500;
    line-height: 1;
    background-color: #3aab6a;
    ;
    border: 1px solid #3aab6a;
    ;
    color: #FFF;
}

.login-button:hover {
    background-color: #077736;
    ;
}

.login-button:disabled {
    opacity: .5;
}

@media (max-width: 768px) {
    .content {
        margin-top: 0;
        height: unset;
    }


    .login-wrapper {
        margin-top: 5rem;
        height: unset;
        width: unset;
    }

    .card-content {
        width: 300px;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    }
}

@media screen and (max-width:1024px) {

    .login-wrapper,
    .card-content {
        width: 98%;

    }
}


.menu-link {
    display: inline;
    margin-left: 5px;
    /* Adjust space between the two spans */
}

.underline {
    text-decoration: underline;
}
</style>