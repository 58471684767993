<template>
    <div class="modal fade" :class="{ 'show': assignCity }" id="addWarehouse" tabindex="-1" aria-modal="true"
        role="dialog" style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Warehouses.Assign') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">



                        <!-- City -->
                        <!-- <div class="col-12 col-md-12">
                            <label for="city" class="form-label">{{ $t('General.Select.City') }}</label>
                            <select :class="{ 'border-danger': errors.city_id }" id="defaultSelect" v-model="city_id"
                                class="form-select" :disabled="citiesData.length < 1">
                                <option value="" disabled>{{ $t('General.Select.City') }}</option>
                                <option disabled v-if="citiesData.length < 1">{{ $t('loading') }} ...</option>
                                <option v-else v-for="city in citiesData" :key="city.id" :value="city.id">{{
                                    city.ar_name }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.city_id }}</span>
                        </div> -->
                        <div class="col-12">
                            <label for="citySelect" class="form-label">
                                <img src="@/assets/dashboard/svg/figma/city.svg" alt="country">
                                {{ $t("General.Select.City") }}</label>
                            <div v-if="citiesData.length < 1">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <MultiSelect :options="citiesData" v-model="city_id" v-else
                                :placeholder="$t('General.Select.City')" />

                            <span class="text-danger">{{ errors.city_id }}</span>
                        </div>
                        <!-- Order Preference -->
                        <div class="col-12 d-flex align-items-center justify-content-between">
                            <label for="show_only_cities" class="form-label">
                                {{ $t('Sections.Orders.OrdersWarehouseCities') }}

                            </label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="show_only_cities"
                                    v-model="show_only_cities">
                            </div>
                        </div>



                        <!-- Submit and Cancel Buttons -->
                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Submit') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5"
                                data-bs-dismiss="modal" @click="closeModel" aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { computed, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

import { toast } from 'vue3-toastify';
import { assignWarehousesCities, getWarehousesCities } from '@/services/warehouse';
import { getCities } from '@/services/setting';
import { useI18n } from 'vue-i18n';
import MultiSelect from '@/components/forms/MultiSelect.vue';
import { getname } from '@/utils/translate';


const emit = defineEmits(['close-add']);

const props = defineProps({
    assignCity: {
        type: Boolean,
        default: true,
    },
    row: {
        type: Object,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true
    },

});

const loading = ref(false);
const eRow = ref(props.row);

const { t } = useI18n();

const citiesData = ref([]);

const validationSchema = computed(() =>
    yup.object({
        city_id: yup.array().required(t('Validation.IsRequired')),
    }));


// Form fields and validation schema using VeeValidate and yup
const { handleSubmit, errors } = useForm({
    validationSchema: validationSchema.value,

});

// Use `useField` to bind individual form fields
const { value: city_id } = useField('city_id');
city_id.value = [];
const { value: show_only_cities } = useField('show_only_cities');
show_only_cities.value = eRow.value.show_only_cities?true:false;



const onSubmit = handleSubmit(async () => {
    try {
        loading.value = true;
        const formData = new FormData();
        formData.append('warehouse_id', eRow.value.id)
        city_id.value.forEach(id => {
            formData.append('cities[]', id); // Append each id individually
        });

        formData.append('show_only_cities', show_only_cities.value ? 1 : 0);


        await assignWarehousesCities(formData);
        emit('close-edit', false);
        props.onEdit();

        toast.success(t('Messages.Success.Edited'), {
            autoClose: 3000, // Adjust time if needed
        });
    } catch (e) {
        loading.value = false;
        console.error(t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-edit', false);
}
const fetchCities = async () => {
    try {
        const responseCities = await getCities(props.row.country_id);
        for (const row of responseCities.data.data) {
            citiesData.value.push({
                ...row,
                name: getname(row)

            });

        }

    } catch (error) {
        console.error(t('Messages.Error.FetchData'), error);
    }
};
const fetchassignCities = async () => {
    try {
        const responseCities = await getWarehousesCities(props.row.id);
        for (const row of responseCities.data.data) {
            console.log(row.id);

            city_id.value.push(row.id);
        }

        fetchCities();



    } catch (error) {
        console.error(t('Messages.Error.FetchData'), error);
    }
};

onMounted(fetchassignCities);

// const modal = ref(null);
// // Function to handle click outside the modal
// const handleClickOutside = (event) => {
//     if (modal.value && !modal.value.contains(event.target)) {
//         closeModel();
//     }
// };

// // Set up event listener when component is mounted
// onMounted(() => {
//     setTimeout(() => {
//         window.addEventListener('click', handleClickOutside);

//     }, 500);
// });

// // Clean up the event listener when component is unmounted
// onBeforeUnmount(() => {
//     window.removeEventListener('click', handleClickOutside);
// });

</script>
<style scoped></style>
