<template>
    <div class="modal fade" :class="{ 'show': showEdit }" id="addEmployee" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Contacts.Details') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <!-- admin_note -->
                        <div class="col-12 ">
                            <label class="form-label" for="admin_note">{{ $t('General.Note') }}</label>
                                <textarea name="admin_note" class="form-control"
                                placeholder="Enter note" rows="6"
                                 id="admin_note" v-model="admin_note"></textarea>
                            <span class="text-danger">{{ errors.admin_note }}</span>
                        </div>

                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5" @click="closeModel"
                                aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import { changeRequestNote } from '@/services/request';

const emit = defineEmits(['close-add']);


const { t } = useI18n();


const props = defineProps({
    row: {
        type: Object,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true
    },

});


const showEdit = ref(true);
const loading = ref(false);
const eRow = ref(props.row);

const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        admin_note: yup.string().required(t('Validation.IsRequired')),
       
    })
});

const { value: admin_note } = useField('admin_note');
admin_note.value = eRow.value.admin_note??'';


const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        values.dqq_request_id =  eRow.value.id ;
        await changeRequestNote(values, eRow.value.id);
        emit('close-edit', false);
        toast.success(t('Messages.Success.Edited'), { autoClose: 3000 });

        props.onEdit();

    } catch (e) {
        loading.value = false;
        console.error(t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-edit', false);
}




const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});

</script>
<style scoped>
.form-control{
    min-height: 8rem !important;
}</style>

