<template>
  <div class="container my-5">
    <!-- Wizard Title -->
    <h1 class="text-center color-green  sub-page-title ico-padding">{{ steps[currentStep - 1].title }}</h1>

    <!-- Step Progress Bar -->
    <div class="d-flex justify-content-center ico-padding">
      <div v-for="(step, index) in steps" :key="index" class="card shadow-none"
        :class="{ 'color-green fw-bold ': currentStep > index, 'text-muted': currentStep <= index }">

        <div class="card-body d-flex p-0 px-1 position-relative ">
          <div class="d-flex align-items-center mb-2">
            <div class="avatar me-4">
              <span class="avatar-initial text-primary rounded-circle bg-label">
                <i v-if="index === 0" class="fa-solid fa-shield" :class="{ 'text-white': currentStep <= index }"></i>
                <i v-else-if="index === 1" class="fa-solid fa-lock" :class="{ 'text-white': currentStep <= index }"></i>
                <i v-else-if="index === 2" class="fa-solid fa-file-lines"
                  :class="{ 'text-white': currentStep <= index }"></i>
              </span>
            </div>

            <div>
              <p class="step  mb-0">{{ $t('General.Step') }} {{ index + 1 }}</p>
              <p class="step-content mb-2">{{ step.label }}</p>
            </div>
          </div>
          <!-- <p class="align-content-center">-------------</p> -->
          <div v-if="index < steps.length - 1" class="dashed-line"></div>

        </div>

      </div>

    </div>
    <form @submit.prevent="onSubmit">
      <!-- Page 1: Add Condition -->
      <div v-if="currentStep === 1" class=" ico-padding">
        <div class="card shadow-none d-flex justify-content-center">
          <div class="card-body">
            <h5 class="text-center card-head-text ">{{ $t('Sections.Conditions.WriteName') }}</h5>
            <div class="m-auto w-50">
              <label class="form-label pb-2"> <img src="@/assets/dashboard/img/shield-up.svg" alt="">
                {{ $t('Sections.Conditions.Name') }}
              </label> <span class="required text-danger fw-bold ">*</span>
              <input v-model="title" type="text" class="form-control form-custom-control "
                :placeholder="$t('Sections.Conditions.WriteName')" />
              <span class="text-danger">{{ errors.title }}</span>
            </div>

            <!-- zid_status  -->
            <div class="m-auto w-50 mt-3 ">
              <label class="form-label" for="zid_status">
                <img src="@/assets/dashboard/img/dqqicon.png" alt="zid" width="24px">
                {{ $t('General.Select.ZidStatus') }}</label> <span class="required text-danger fw-bold ">*</span>
              <select id="zid_status" v-model="zid_status" class="form-select form-custom-select-control select-w">
                <option value="" disabled>{{ $t('General.Select.ZidStatus') }}</option>
                <option v-for="ZidStatus in ZidStatusData" :key="ZidStatus.id" :value="ZidStatus.id">{{
                  ZidStatus.name }}</option>
              </select>
              <span class="text-danger">{{ errors.zid_status }}</span>
            </div>

          </div>
        </div>

        <div class="col-12 mt-5 text-center">
          <button type="button" class="btn cancel-btn bg-green text-primary me-3" aria-label="Close"
            @click="goToCondition">
            {{ $t('General.Cancel') }}
          </button>
          <button @click="nextStep" type="button" class="btn add-btn shadow-none login-button btn-primary"
            :disabled="checkisVaild">

            <span>{{ $t('General.Next') }}</span>
          </button>
        </div>
      </div>


      <!-- Page 2: Condition Restrictions -->
      <div v-else-if="currentStep === 2" class=" ico-padding">
        <div class="card shadow-none d-flex justify-content-center">
          <div class="card-body">
            <h5 class="text-center card-head-text">{{ $t('Sections.Conditions.Restrictions') }}</h5>
            <!-- Rows of fields -->
            <div class="row g-3  ico-padding">

              <div class="col-12 col-md-6 ">
                <label for="country" class="form-label">
                  <img src="@/assets/dashboard/svg/figma/country.svg" alt="country">
                  {{ $t('General.Select.Country') }}
                </label>
                <CountryMultiSelect :countriesData="contriesData" v-model="country_id"
                  :placeholder="$t('General.Select.Country')" />
                <span class="text-danger">{{ errors.country }}</span>
              </div>
              <!-- City Selection -->
              <div class="col-12 col-md-6">
                <label for="citySelect" class="form-label">
                  <img src="@/assets/dashboard/svg/figma/city.svg" alt="country">
                  {{ $t("General.Select.City") }}</label>
                <MultiSelectCities :options="citiesData" v-model="city_id" :placeholder="$t('General.Select.City')" />
                <span class="text-danger">{{ errors.city }}</span>
              </div>

              <!-- Warehouse Selection -->
              <div class="col-12 col-md-6">
                <label for="warehouseSelect" class="form-label">
                  <img src="@/assets/dashboard/svg/figma/warehouse.svg" alt="warehouse">
                  {{ $t("General.Select.Warehouse") }}</label>
                <MultiSelect :options="warehousesData" v-model="warehouse_id"
                  :placeholder="$t('General.Select.Warehouse')" />
                <span class="text-danger">{{ errors.warehouse_id }}</span>
              </div>

              <!-- deleveryCompany Selection -->
              <div class="col-12 col-md-6">
                <label for="selectdeleveryCompany" class="form-label">
                  <img src="@/assets/dashboard/svg/figma/delevry.svg" alt="delevry">
                  {{ $t("General.Select.deleveryCompany") }}</label>
                <MultiSelect :options="filteredDeliveryCompanies" v-model="delivery_company_id"
                  :placeholder="$t('General.Select.deleveryCompany')" />
                <span class="text-danger">{{ errors.delivery_company_id }}</span>
              </div>
              <!-- deleveryCompany Selection -->
              <div class="col-12 col-md-6">
                <label class="form-label">
                  <img src="@/assets/dashboard/svg/figma/delevry.svg" alt="delevry">
                  {{ $t("General.Products") }}</label>
                <MultiSelect :options="ProductData" v-model="product_id" :placeholder="$t('General.Products')" />
                <span class="text-danger">{{ errors.product_id }}</span>
              </div>
              <div class="col-12 col-md-6"> </div>

              <!-- Is Paid  -->
              <div class="col-12 col-md-6">
                <label class="form-label" for="selectIsPaid">
                  <img src="@/assets/dashboard/svg/figma/paid.svg" alt="paid">
                  {{ $t('General.IsPaid') }}</label>
                <select id="selectIsPaid" v-model="is_paid" class="form-select form-custom-select-control select-w">
                  <option :value="true">{{ $t('General.Paid') }}</option>
                  <option :value="false">{{ $t('General.NotPaid') }}</option>


                </select>
                <span class="text-danger">{{ errors.is_paid }}</span>
              </div>
              <!-- payment_method_id  -->
              <div class="col-12 col-md-6">
                <label class="form-label" for="sekectPaymntMethod">
                  <img src="@/assets/dashboard/svg/figma/payment.svg" alt="payment">
                  {{ $t('General.Select.PaymentMethod') }}</label>
                <select id="sekectPaymntMethod" v-model="payment_method_id"
                  class="form-select form-custom-select-control select-w">
                  <option value="" disabled>{{ $t('General.Select.PaymentMethod') }}</option>
                  <option v-for="paymentMethod in filteredPaymentMethodData" :key="paymentMethod.id"
                    :value="paymentMethod.code">{{
                      paymentMethod.name }}</option>
                </select>
                <span class="text-danger">{{ errors.payment_method_id }}</span>
              </div>


            </div>

            <!-- Order Values -->
            <div class="flex ico-padding">
              <p class="order-text">{{ $t('Sections.Orders.Value') }}</p>

              <div class="row g-3  ico-padding">
                <div class="col-12 col-md-6">
                  <label class="form-label color-green" for="more_than">{{ $t('General.MoreThan') }}</label>
                  <div class="input-group  bg-green ">
                    <input type="number" id="more_than" v-model="more_than" class="form-control border-none"
                      :placeholder="$t('General.Enter') + ' ' + $t('General.MoreThan')">
                    <span class="input-group-text border-none color-green ">{{ $t('General.SAR') }}</span>
                  </div>
                  <span class="text-danger">{{ errors.more_than }}</span>
                </div>

                <div class="col-12 col-md-6">
                  <label class="form-label color-green" for="less_than">{{ $t('General.LessThan') }}</label>
                  <div class="input-group  bg-green ">
                    <input type="number" id="less_than" v-model="less_than" class="form-control border-none"
                      :placeholder="$t('General.Enter') + ' ' + $t('General.LessThan')">
                    <span class="input-group-text border-none color-green ">{{ $t('General.SAR') }}</span>
                  </div>
                  <span class="text-danger">{{ errors.less_than }}</span>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="col-12 mt-5 text-center">
          <button type="button" class="btn cancel-btn bg-green text-primary me-3" @click="prevStep" aria-label="Close">
            {{ $t('General.Back') }}
          </button>
          <button @click="nextStep" type="button" class="btn add-btn shadow-none login-button btn-primary "
            :disabled="checkisVaild">

            <span>{{ $t('General.Next') }}</span>
          </button>
        </div>
      </div>

      <!-- Page 3: Condition Events -->
      <div v-else-if="currentStep === 3" class=" ico-padding">

        <div>
          <div v-for="(condition, index) in events" :key="index" class="card border-primary shadow-xl  mb-4">
            <div class="card-body">
              <button type="button" class="btn text-danger delete-form-repeater mx-5 p-0 rounded-pill"
                @click="removeCondition(index)">
                <i class="bx bx-x"></i>
              </button>
              <h5 class="text-center card-head-text">
                {{ $t("Sections.Conditions.Events") }} ({{ index + 1 }})
              </h5>

              <div class="mb-3">
                <label class="form-label">{{ $t("Sections.Conditions.Type") }}</label>
                <span class="required text-danger fw-bold">*</span>
                <select v-model="events[index].condition_type" class="form-select form-custom-select-control select-w">
                  <option value="1">{{ $t("General.HideInApp") }}</option>
                  <option value="2">{{ $t("General.ChangeStatus") }}</option>
                  <option value="3">{{ $t("Entities.SendNotification") }}</option>
                  <option value="4">{{ $t("General.PrintWayBil") }}</option>
                </select>
                <span class="text-danger">{{ errors[index]?.condition_type }}</span>
              </div>

              <!-- Conditional rendering based on condition_type -->
              <div class="mb-3" v-if="events[index].condition_type == '1'">
                <div class="row">
                  <div class="col-12 mb-3">

                    <label class="form-label">{{ $t("General.Select.Employees") }}</label>
                    <span class="required text-danger fw-bold">*</span>
                    <MultiSelect :options="EmplyeesData" :diss="events[index].all_employee_ids"
                      v-model="events[index].employee_ids" />
                    <span class="text-danger">{{ errors[index]?.employee_ids }}</span>
                  </div>
                  <div class="col-12 d-flex justify-content-end">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" v-model="events[index].all_employee_ids"
                        id="allEmployeeToggle">
                      <label class="form-check-label" for="allEmployeeToggle">{{ $t("General.AllEmployees") }}</label>
                    </div>
                  </div>

                </div>

              </div>
              <div class="mb-3" v-if="events[index].condition_type == '2'">
                <label class="form-label" for="zid_status">{{ $t("General.Select.ZidStatus") }}</label>
                <span class="required text-danger fw-bold">*</span>
                <select id="zid_status" v-model="events[index].zid_status"
                  class="form-select form-custom-select-control select-w">
                  <option value="" disabled>{{ $t("General.Select.Status") }}</option>
                  <option v-for="ZidStatus in ZidStatusData" :key="ZidStatus.id" :value="ZidStatus.id">
                    {{ ZidStatus.name }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[index]?.zid_status }}</span>
              </div>
              <div class="mb-3" v-if="events[index].condition_type == '3'">
                <div class="mb-3">
                  <label class="form-label" for="notification_type">{{ $t("General.notificationType") }}</label>
                  <span class="required text-danger fw-bold">*</span>
                  <select id="notification_type" v-model="events[index].notification_type"
                    class="form-select form-custom-select-control select-w">
                    <option value="1">{{ $t("General.Email") }}</option>
                    <option value="2">{{ $t("General.Notifications") }}</option>
                  </select>
                  <span class="text-danger">
                    {{ errors[index]?.notification_type }}
                  </span>
                </div>
                <div class="mb-3" v-if="events[index].notification_type == '1'">
                  <label class="form-label color-green" for="email">
                    {{ $t("General.Email") }}
                  </label>
                  <span class="required text-danger fw-bold">*</span>
                  <input type="text" id="email" v-model="events[index].email" class="form-control form-custom-control"
                    placeholder="example@gmail.com" />
                  <span class="text-danger">{{ errors[index]?.email }}</span>
                </div>
                <div class="mb-3" v-if="events[index].notification_type == '2'">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label class="form-label">{{ $t("General.Select.Employees") }}</label>
                      <span class="required text-danger fw-bold">*</span>
                      <MultiSelect :options="EmplyeesData" v-model="events[index].notify_employee_ids"
                        :placeholder="$t('General.Select.Employees')" />
                      <span class="text-danger">
                        {{ errors[index]?.notify_employee_ids }}
                      </span>
                    </div>

                    <div class="col-12 d-flex justify-content-end">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" v-model="events[index].all_notify_employee_ids"
                          id="allEmployeeToggle">
                        <label class="form-check-label" for="allEmployeeToggle">{{ $t("General.AllEmployees") }}</label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="mb-3" v-if="events[index].condition_type == '4'">
                <label class="form-label">{{ $t("General.PrintWaybill") }}</label>
                <span class="required text-danger fw-bold">*</span>
                <MultiSelect :options="PrintData" v-model="events[index].print_ids" />
                <span class="text-danger">{{ errors[index]?.print_ids }}</span>
              </div>

            </div>
          </div>
          <button type="button" class="btn btn-primary" @click="addCondition">
            {{ $t("General.AddEvent") }}
            <i class="bx bx-plus me-1"></i>
          </button>
        </div>
        <div class="col-12 mt-5 text-center">
          <button type="button" class="btn cancel-btn bg-green text-primary me-3" @click="prevStep" aria-label="Close">
            {{ $t('General.Back') }}
          </button>
          <button type="submit" class="btn add-btn shadow-none login-button btn-primary" :disabled="loading">

            <span>{{ $t('Sections.Conditions.Create') }}</span>
          </button>
        </div>
      </div>


    </form>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { getAllDeleveryCompany, getAllPaymentMetod, getAllZidStatus, getCities, getcountries } from "@/services/setting";
import { useField, useForm, useResetForm } from 'vee-validate';
import * as yup from 'yup';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { toast } from 'vue3-toastify';
import { SelectWarehouses } from "@/services/warehouse";
import MultiSelect from "../forms/MultiSelect.vue";
import MultiSelectCities from "../forms/MultiSelectCities.vue";
import CountryMultiSelect from "../forms/CountryMultiSelect.vue";
import { getAllEmployees } from "@/services/employee";
import { storeConditions } from "@/services/condition";
import { useRouter } from "vue-router";

const currentStep = ref(1);
const dataContries = ref([]);
const citiesData = ref([]);
const warehousesData = ref([]);
const deliveryData = ref([]);
const PaymentMethodData = ref([]);
const PrintData = ref([]);
const ZidStatusData = ref([]);
const EmplyeesData = ref([]);
const ProductData = ref([]);

const router = useRouter();

const authStore = useAuthStore();


const goToCondition = () => {
  router.push({ name: 'Conditions' }); // Navigate to the 'order' route
};

const loading = ref(false);

const steps = computed(() => [
  { title: t('Sections.Conditions.CreateNew'), label: t("Sections.Conditions.AddCondition") },
  { title: t('Sections.Conditions.CreateNew'), label: t("Sections.Conditions.Restrictions") },
  { title: t('Sections.Conditions.CreateNew'), label: t("Sections.Conditions.Events") },
]);

// Form fields and validation schema using VeeValidate and yup
const validationSchema = computed(() =>
  yup.object({
    title: yup.string().required(t('Validation.IsRequired')),
    country_id: yup.array().nullable(),
    city_id: yup.array().nullable(),
    is_paid: yup.boolean().nullable(),

    zid_status: yup
      .string()
      .required(t('Validation.IsRequired'))
    ,
    more_than: yup
      .number()
      .min(0, t('Validation.MustBePositive')).nullable()
    ,
    less_than: yup
      .number()
      .moreThan(yup.ref('more_than'), t('Validation.LessThanMoreThan')).nullable()
    ,




  }),
);

const conditionSchema = computed(() => yup.object().shape({
  condition_type: yup
    .number()
    .required(t('Validation.IsRequired')),

  employee_ids: yup
    .array()
    .nullable()
    .when('condition_type', (conditionType, schema) =>
      conditionType === 1
        ? schema.required(t('Validation.IsRequired'))
        : schema.nullable()
    ),

  notification_type: yup
    .number()
    .nullable()
    .when('condition_type', (conditionType, schema) =>
      conditionType === 3
        ? schema.required(t('Validation.IsRequired'))
        : schema.nullable()
    ),

  email: yup
    .string()
    .email(t('Validation.InvalidEmail'))
    .nullable()
    .when('notification_type', (notificationType, schema) =>
      notificationType === 1
        ? schema.required(t('Validation.IsRequired'))
        : schema.nullable()
    ),

  notify_employee_ids: yup
    .array()
    .of(yup.string().required(t('Validation.IsRequired')))
    .nullable()
    .when('notification_type', (notificationType, schema) =>
      notificationType === 2
        ? schema.required(t('Validation.IsRequired'))
        : schema.nullable()
    ),

  zid_status: yup
    .number()
    .nullable()
    .when('condition_type', (conditionType, schema) =>
      conditionType === 2
        ? schema.required(t('Validation.IsRequired'))
        : schema.nullable()
    ),
}));

const conditionFrom = yup.array().of(conditionSchema.value);
const { handleSubmit, errors } = useForm({
  validationSchema: validationSchema.value,
  conditionSchema: conditionFrom


});
const nextStep = () => {
  if (dataContries.value.length < 1) {
    fetchcontries();
  }

  if (warehousesData.value.length < 1) {
    fetchWarehouse()
  }
  if (deliveryData.value.length < 1) {
    fetchDeleveryCompanies()
  }
  if (PaymentMethodData.value.length < 1) {
    fetchPaymentMethod()
  }
  if (PrintData.value.length < 1) {
    fetchPrint()
  }

  if (EmplyeesData.value.length < 1) {
    fetchEmployees()
  }
  if (ProductData.value.length < 1) {
    fetchProduct()
  }

  if (currentStep.value < steps.value.length) currentStep.value++;
};
const prevStep = () => {
  if (currentStep.value > 1) currentStep.value--;
};
const showSuccess = computed(() => {
  return t('Messages.Success.Created');
});

// Computed property to filter and limit delivery companies
const filteredDeliveryCompanies = computed(() => {

  const mergedDeliveryData = Object.values(
    deliveryData.value.reduce((acc, method) => {
      const key = method.name.toLowerCase();
      if (!acc[key]) {
        acc[key] = { ...method, id: [method.id] };
      } else {
        acc[key].id.push(method.id);
      }
      return acc;
    }, {})
  )

  return mergedDeliveryData;

});
const filteredPaymentMethodData = computed(() => {

  const mergedPaymentData = Object.values(
    PaymentMethodData.value.reduce((acc, method) => {
      const key = method.name.toLowerCase();
      if (!acc[key]) {
        acc[key] = { ...method, id: [method.id] };
      } else {
        acc[key].id.push(method.id);
      }
      return acc;
    }, {})
  )

  return mergedPaymentData;

});


// const showError = computed(() => {
//   return t('Messages.Error.SubmitError');
// });

const onSubmit = handleSubmit(async (values) => {
  loading.value = true;


  try {
    values.events = filteredEvents.value;

    await storeConditions(values);
    toast.success(showSuccess.value);
    // Reset form or navigate to another page
    currentStep.value = 1;
    // Clear form
    useResetForm();
    router.push('Conditions')
  } catch (error) {
    // toast.error(showError.value);
    console.error(error);
  } finally {
    loading.value = false;
  }
});



const { value: title } = useField('title');
const { value: country_id } = useField('country_id');
const { value: city_id } = useField('city_id');
const { value: warehouse_id } = useField('warehouse_id');
const { value: delivery_company_id } = useField('delivery_company_id');
const { value: is_paid } = useField('is_paid');
const { value: payment_method_id } = useField('payment_method_id');
const { value: product_id } = useField('product_id');
const { value: zid_status } = useField('zid_status');
const { value: more_than } = useField('more_than');
const { value: less_than } = useField('less_than');



const lastItem = computed(() => country_id.value?.at(-1));



const msgfetchData = computed(() => {
  return t('Messages.Error.FetchData');

});
const fetchcontries = async () => {

  try {
    const responseContry = await getcountries();
    dataContries.value = responseContry.data.data;
  } catch (error) {
    toast.error(msgfetchData.value);
    console.error(error);
  }
};
const contriesData = computed(() => {
  return [...dataContries.value].sort((a, b) => {
    if (a.code === 'SA') return -1;
    if (b.code === 'SA') return 1;
    return a.code.localeCompare(b.code);
  });
});
const msgfetchCities = computed(() => {
  return t('Messages.Error.FetchCities');

});

const fetchCities = async () => {
  if (!lastItem.value) return;

  try {
    const country = contriesData.value.find((c) => c.id == lastItem.value);
    if (!country || citiesData.value.some((item) => item.label === country.name)) return;

    const responseCities = await getCities(lastItem.value);
    citiesData.value = [
      ...citiesData.value,
      { label: country.name, options: responseCities.data.data },
    ];
  } catch (error) {
    toast.error(msgfetchCities.value);
    console.error(error);
  }
};

// Watch the 'country_id' field for changes
watch(country_id, (newVal) => {
  if (newVal && newVal.length > 0) {
    fetchCities();
  } else {
    citiesData.value = []; // Clear if no country selected
  }
});


watch(more_than, () => {
  if (more_than.value && more_than.value >= 0) {
    console.log('more_than must be positive');

  } else {
    more_than.value = 0;

  }
});
watch(less_than, () => {
  if (less_than.value && less_than.value >= 0) {
    console.log('less_than must be positive');

  } else {
    less_than.value = 0;

  }
});

const { locale } = useI18n();
watch(locale, () => {

  fetchZidStatus();
});

onMounted(() => {
  fetchZidStatus();
});




const fetchWarehouse = async () => {
  try {
    const warehousesResponse = await SelectWarehouses();
    warehousesData.value = warehousesResponse.data.data;

  } catch (error) {
    console.error(msgfetchData.value, error);
  }
};
const fetchDeleveryCompanies = async () => {
  try {
    const deliveryCompanyResponse = await getAllDeleveryCompany();
    deliveryData.value = deliveryCompanyResponse.data.data;

  } catch (error) {
    console.error(msgfetchData.value, error);
  }
};
const fetchPaymentMethod = async () => {
  try {
    const PaymentMethodResponse = await getAllPaymentMetod();
    PaymentMethodData.value = PaymentMethodResponse.data.data;


  } catch (error) {
    console.error(msgfetchData.value, error);
  }
};
const fetchPrint = async () => {
  try {
    if (authStore.user?.client?.id) {

      const Response = await getPrintNoteByClient(authStore.user?.client?.id);
      PrintData.value = Response.data.data;
    } else {
      PrintData.value = [];

    }



  } catch (error) {
    console.error(msgfetchData.value, error);
  }
};
const fetchZidStatus = async () => {
  try {
    const ZidStatusResponse = await getAllZidStatus();
    ZidStatusData.value = ZidStatusResponse.data.data;

  } catch (error) {
    console.error(msgfetchData.value, error);
  }
};
const fetchEmployees = async () => {
  try {
    const EmployeesResponse = await getAllEmployees();

    EmplyeesData.value = EmployeesResponse.data.data.map(employee => {
      return {
        id: employee.id,
        name: employee.user.name
      };
    });

  } catch (error) {
    console.error(msgfetchData.value, error);
  }
};
const fetchProduct = async () => {
  try {
    const productResponse = await getAllProducts();
    ProductData.value = productResponse.data.data;
  } catch (error) {
    console.error(msgfetchData.value, error);
  }
};



const checkisVaild = computed(() => {
  if (currentStep.value === 1 && title.value != null && title.value.trim() !== "") {
    return false;

  }
  if (currentStep.value === 2 && zid_status.value != null) {
    return false;

  }

  return true; // Disable by default
});

import { reactive } from "vue";
import { getAllProducts } from "@/services/product";
import { getPrintNoteByClient } from "@/services/printNode";
import { useAuthStore } from "@/stores/auth";

const events = reactive([
  {
    condition_type: null,
    employee_ids: [],
    all_notify_employee_ids: null,
    all_employee_ids: null,
    zid_status: null,
    notification_type: null,
    email: "",
    notify_employee_ids: [],
    print_ids: [],
  },
]);


const filteredEvents = computed(() => {
  return events
    .map(event => {
      // Create a new object by filtering key-value pairs
      return Object.fromEntries(
        Object.entries(event).filter(([key, value]) => {
          // Check for null, empty strings, or empty arrays
          return (
            key &&
            value !== null &&
            value !== "" &&
            (!Array.isArray(value) || value.length !== 0)
          );
        })
      );
    })
    .filter(event => {
      // Remove any objects that are entirely empty
      return Object.keys(event).length > 0;
    });
});




const error = reactive([]);

const addCondition = () => {
  events.push({
    condition_type: null,
    employee_ids: [],
    zid_status: null,
    notification_type: null,
    email: "",
    notify_employee_ids: [],
  });
  error.push({});
};

const removeCondition = (index) => {
  events.splice(index, 1);
  error.splice(index, 1);
};

</script>


<style>
.icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;

}

.card-head-text {
  font-family: Public Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
  color: #22303E;

}

.btn-green {
  color: #fff;
  background-color: #24A259;
  border-color: #24A259;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(113, 221, 55, 0.4);
}

.btn-green:hover {
  color: #24A259 !important;
  background-color: #24a2585b !important;
  border-color: #24a2585b !important;
}

.sub-page-title {
  margin-bottom: 25px;
}

.ico-padding {
  padding: 1.5rem;
}



.form-container {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.header-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.btn-next {
  background-color: #28a745;
  color: #fff;
  border: none;
}

.btn-next:hover {
  background-color: #218838;
}

.btn-cancel {
  color: #6c757d;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
}



.select2-container--default .select2-selection--single {
  background: #EDF8F2 !important;
  color: #24A259;
}

.select2-container .select2-selection--multiple {
  background: #EDF8F2 !important;
  min-height: 70px !important;
}

.order-text {
  font-family: Public Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  color: #22303E !important;

}

.step {
  font-family: Public Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #22303E;
  text-transform: capitalize;
}

.step-content {
  font-family: Public Sans;
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.03em;
}

.bg-label {
  background-color: rgba(34, 48, 62, 0.03);
}

.dashed-line {
  width: 100px;
  height: 1px;
  margin: auto;
  margin-left: 10px;
  border-top: 2px dashed rgba(34, 48, 62, 0.7);
}

.cancel-btn {
  width: 200px;
}

.cancel-btn:hover {
  background-color: #24a258a9 !important;
  color: #FFFFFF !important;
}

.add-btn {
  width: 250px;
}

.delete-form-repeater {
  position: absolute;
  right: 0;
}
</style>