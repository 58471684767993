<template>
    <div class="content-wrapper" v-if="authStore.isAdmin">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.Contact') }}</h3>
            <DataTable v-model:rows="requestData" :headers="headers" :fields="fields" :total="total" :loading="loading"
                @page-changed="onPageChanged">
                <template v-slot:head-contant="{ }">
                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <!-- <i class="fa-solid fa-users color-green"></i> -->
                            <svg width="36" height="37" class="color-green" viewBox="0 0 36 37" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M23.25 11.4501C23.25 14.3496 20.8995 16.7001 18 16.7001C15.1005 16.7001 12.75 14.3496 12.75 11.4501C12.75 8.55058 15.1005 6.20007 18 6.20007C20.8995 6.20007 23.25 8.55058 23.25 11.4501Z"
                                    fill="#24A259" />
                                <path
                                    d="M27 24.95C27 27.8495 22.9706 30.2 18 30.2C13.0294 30.2 9 27.8495 9 24.95C9 22.0505 13.0294 19.7 18 19.7C22.9706 19.7 27 22.0505 27 24.95Z"
                                    fill="#24A259" />
                                <path
                                    d="M10.6831 7.69992C10.9493 7.69992 11.2091 7.72604 11.4601 7.77577C10.8487 8.86161 10.5 10.115 10.5 11.4499C10.5 12.7523 10.832 13.9771 11.4159 15.0445C11.1787 15.0887 10.9337 15.1118 10.6831 15.1118C8.56145 15.1118 6.84154 13.4526 6.84154 11.4059C6.84154 9.35913 8.56145 7.69992 10.6831 7.69992Z"
                                    fill="#24A259" />
                                <path
                                    d="M8.17101 28.6789C7.31913 27.6605 6.75 26.4109 6.75 24.9499C6.75 23.5336 7.28486 22.3159 8.09367 21.315C5.23665 21.5366 3 23.0992 3 24.994C3 26.9065 5.27595 28.4807 8.17101 28.6789Z"
                                    fill="#24A259" />
                                <path
                                    d="M25.5001 11.45C25.5001 12.7524 25.1682 13.9772 24.5842 15.0446C24.8214 15.0888 25.0664 15.1119 25.317 15.1119C27.4387 15.1119 29.1586 13.4527 29.1586 11.406C29.1586 9.35922 27.4387 7.70001 25.317 7.70001C25.0509 7.70001 24.791 7.72613 24.54 7.77586C25.1514 8.8617 25.5001 10.1151 25.5001 11.45Z"
                                    fill="#24A259" />
                                <path
                                    d="M27.8291 28.679C30.7242 28.4808 33.0001 26.9066 33.0001 24.9941C33.0001 23.0993 30.7635 21.5367 27.9064 21.3151C28.7153 22.316 29.2501 23.5337 29.2501 24.95C29.2501 26.411 28.681 27.6606 27.8291 28.679Z"
                                    fill="#24A259" />
                            </svg>

                            <h3 class="table-title">{{ $t('Sections.Contacts.List') }}</h3>
                        </div>

                    </div>
                    <div class="card-header flex-column flex-md-row pb-0">

                        <div class="dt-action-buttons text-start pt-6 pt-md-0">
                            <div class="dt-buttons btn-group flex-wrap">


                                <div class="dropdown order-dropdown">

                                    <button class="btn dropdown-toggle create-new btn-primary" tabindex="0"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                                        aria-controls="DataTables_Table_0" type="button" :disabled="selectedCount < 1">
                                        <span class="d-none d-sm-inline-block  me-sm-3">
                                            {{ $t('Sections.Contacts.Checked') }}
                                        </span>
                                        <i class='bx bxs-bullseye'></i>
                                    </button>
                                    <ul class="dropdown-menu" style="width: 100%;"
                                        aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <a class="dropdown-item" href="#" @click.prevent="toggleStatusSelected(2)">
                                                {{ $t("General.Select.Reviewing") }}
                                            </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="#" @click.prevent="toggleStatusSelected(3)">
                                                {{ $t("General.Select.Pending") }}
                                            </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="#" @click.prevent="toggleStatusSelected(4)">
                                                {{ $t("General.Select.Completed") }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="dt-buttons btn-group flex-wrap">

                                <button class="btn btn  create-new delete-btn" tabindex="0"
                                    :disabled="selectedCount < 1" @click="triggerDeleteModal()"
                                    aria-controls="DataTables_Table_0" type="button">

                                    <i class='bx bx-trash'></i>

                                </button>
                            </div>
                        </div>
                    </div>

                </template>
                <template v-slot:table-header>
                    <th class="text-center">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="selectAll"
                                @change="toggleSelectAll">
                        </div>
                    </th>
                    <th class="text-center">#</th>
                    <th class="text-center">{{ $t('General.Name') }}</th>
                    <th class="text-center">{{ $t('General.Contact') }}</th>
                    <th class="text-center">{{ $t('General.OrdersSizeDaily') }}</th>
                    <th class="text-center">{{ $t('General.EmployeesCount') }}</th>
                    <th class="text-center">{{ $t('General.Status') }}</th>
                    <th class="text-center">{{ $t('General.Note') }}</th>
                    <th class="text-center">{{ $t('General.CreatedAt') }}</th>
                    <th class="text-center">{{ $t('General.UpdatedAt') }}</th>
                    <th class="text-center"></th>

                </template>

                <template v-slot:action-buttons="{ rowColum }">
                    <td class="text-center align-middle">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="selectedRows[rowColum.id]">

                        </div>
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.ids }}

                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.name }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.contact }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.orders_size_daily }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.employees_count }}
                    </td>
                    <td class="text-center align-middle">
                        <span class="badge " :class="{
                            ' bg-primary': rowColum.status == 'received',
                            ' bg-secondary': rowColum.request_status === 0,
                            ' bg-warning': rowColum.request_status === 2,
                            ' bg-info': rowColum.request_status === 3,
                            ' bg-success': rowColum.request_status === 4
                        }">{{
                            rowColum.status_name?.name }}</span>
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.note }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.created_at }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.updated_at }}
                    </td>


                    <td class="">

                        <div class="d-flex me-2 justify-content-center">
                            <button type="button" class="btn edit-btn mx-1" @click="onEditRow(rowColum)">
                                <i class='bx bxs-edit-alt'></i>
                            </button>

                            <button class="btn mx-1 btn-sm delete-btn" @click="triggerDeleteModal(rowColum)">
                                <svg width="13" height="15" viewBox="0 0 13 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.5 3.74921C0.5 3.48622 0.717806 3.27302 0.986484 3.27302H4.17858C4.18292 2.7122 4.2437 1.94336 4.80025 1.41112C5.23824 0.992261 5.83872 0.733337 6.49999 0.733337C7.16127 0.733337 7.76174 0.992261 8.19974 1.41112C8.75629 1.94336 8.81707 2.7122 8.82141 3.27302H12.0135C12.2822 3.27302 12.5 3.48622 12.5 3.74921C12.5 4.0122 12.2822 4.2254 12.0135 4.2254H0.986484C0.717806 4.2254 0.5 4.0122 0.5 3.74921Z"
                                        fill="#EE1D52" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M6.2304 14.0667H6.7696C8.62474 14.0667 9.55231 14.0667 10.1554 13.4761C10.7585 12.8854 10.8202 11.9166 10.9436 9.97901L11.1214 7.18707C11.1884 6.13574 11.2219 5.61008 10.9193 5.27697C10.6167 4.94386 10.1058 4.94386 9.08397 4.94386H3.91603C2.89418 4.94386 2.38325 4.94386 2.0807 5.27697C1.77815 5.61008 1.81163 6.13574 1.87858 7.18707L2.05639 9.979C2.1798 11.9166 2.2415 12.8854 2.84459 13.4761C3.44769 14.0667 4.37526 14.0667 6.2304 14.0667ZM5.33085 7.52566C5.30337 7.23642 5.05835 7.0254 4.78358 7.05432C4.50881 7.08325 4.30834 7.34116 4.33581 7.6304L4.66915 11.1392C4.69662 11.4284 4.94165 11.6394 5.21642 11.6105C5.49119 11.5816 5.69166 11.3237 5.66419 11.0344L5.33085 7.52566ZM8.21642 7.05432C8.49119 7.08325 8.69166 7.34116 8.66419 7.6304L8.33085 11.1392C8.30338 11.4284 8.05835 11.6394 7.78358 11.6105C7.50881 11.5816 7.30834 11.3237 7.33581 11.0344L7.66915 7.52566C7.69663 7.23642 7.94165 7.0254 8.21642 7.05432Z"
                                        fill="#EE1D52" />
                                </svg>

                            </button>
                        </div>

                    </td>

                </template>


            </DataTable>
            <ContactNote v-if="showEdit" :row="editRow" @close-edit="onCloseEdit" :onEdit="fetchData" />

            <DeleteModal ref="deleteModal" :row="editRow" :onDelete="handleDelete"
                :title="$t('Sections.Contacts.Delete')" />

        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, h } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';
import i18n from '@/i18n.js';
import { formatDateToYMDHMS } from '@/utils/dateFormatter';
import { useAuthStore } from '@/stores/auth';
import { changeRequestStatus, deleteRequest, getRequest } from '@/services/request';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import ContactNote from './componants/ContactNote.vue';
import DeleteModal from '@/components/pages/DeleteModal.vue';

const authStore = useAuthStore();
// State
// Create a reference to the DeleteModal component
const requestData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const searchQuery = ref('');

const editRow = ref({});



// Table headers and fields
const headers = [];
const fields = [];

const { t } = useI18n();
const editedMessage = computed(() => t('Messages.Success.Edited'));
const errorMessage = computed(() => t('Messages.Error.FetchData'));





// Fetch users with pagination
const fetchData = async () => {

    try {
        loading.value = true;
        requestData.value = [];
        const response = await getRequest(currentPage.value, searchQuery.value);

        let count = 1;
        for (const row of response.data.data.data) {
            requestData.value.push({
                ...row,
                ids: (((currentPage.value ?? 1) - 1) * 10) + count,
                request_status: row.status == 'received' ? 1 : 0,

                created_at: formatDateToYMDHMS(row.created_at),
                updated_at: formatDateToYMDHMS(row.updated_at),

            });
            count = count + 1;

        }
        total.value = response.data.data.total;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(errorMessage.value, error);
    }
};





const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};

// Watch for changes on searchQuery
watch(searchQuery, () => {
    currentPage.value = 1;


    fetchData();
});



// Fetch users on mount
onMounted(fetchData);

const selectAll = ref(false);
const selectedRows = ref([]);


const toggleSelectAll = () => {
    if (selectAll.value) {

        selectedRows.value = requestData.value.reduce((acc, req) => {
            acc[req.id] = true;
            return acc;
        }, {});
    } else {
        selectedRows.value = requestData.value.reduce((acc, req) => {
            acc[req.id] = false;
            return acc;
        }, {});
    }
};

const selectedCount = computed(() => {
    return Object.values(selectedRows.value).filter(selected => selected).length;
});
watch(selectedRows, () => {
    selectAll.value = Object.values(selectedRows.value).every(row => row) && selectedCount.value == (10 > total.value ? total.value : 10);
}, { deep: true });


// const toggleStatus = async (id, isChecked) => {

//     try {
//         const newStatus = isChecked ? 4 : 2;
//         const formData = {
//             dqq_request_ids: id,
//             status: newStatus
//         };
//         await changeRequestStatus(formData)
//         // toast.success(editedMessage.value, {
//         //     autoClose: 2000,
//         // });
//         toast.success(editedMessage.value, {
//             timeout: 3000, // Auto-close in 3 seconds
//             position: "top-center",
//             icon: () => h("i", { class: "bx bx-check", style: "font-size:36px; color: green;" }),
//             style: {
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 position: "fixed",
//             },
//         });


//     } catch (error) {
//         console.error(errorMessage.value, error);
//     }
// };
const toggleStatusSelected = async (status) => {

    try {
        const formData = new FormData();

        Object.keys(selectedRows.value).forEach(id => {
            if (selectedRows.value[id]) { // Check if the value is true
                formData.append('dqq_request_ids[]', id); // Append each true id
            }// Append each id individually
        });
        formData.append('status', status);
        await changeRequestStatus(formData)
        // toast.success(editedMessage.value, {
        //     autoClose: 2000,
        // });
        selectedRows.value = [];
        toast.success(editedMessage.value, {
            timeout: 3000, // Auto-close in 3 seconds
            position: "top-center",
            icon: () => h("i", { class: "bx bx-check", style: "font-size:36px; color: green;" }),
            style: {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                position: "fixed",
            },
        });
        fetchData()


    } catch (error) {
        console.error(errorMessage.value, error);
    }
};


//edit
const showEdit = ref(false);
function onEditRow(row) {
    showEdit.value = !showEdit.value;

    editRow.value = row;

}
function onCloseEdit() {
    showEdit.value = !showEdit.value;

}


//delete
const deleteModal = ref(null);
const handleDelete = async () => {
    try {
        loading.value = true;
        const formData = new FormData();

        Object.keys(selectedRows.value).forEach(id => {
            if (selectedRows.value[id]) { // Check if the value is true
                formData.append('dqq_request_ids[]', id); // Append each true id
            }// Append each id individually
        });
        await deleteRequest(formData);
        fetchData();
        selectedRows.value = [];
        toast.success(i18n.global.t('Messages.Success.Deleted'), {
            autoClose: 3000, // Adjust time if needed
        });

        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error('Error fetching users:', error);
    }
};
const triggerDeleteModal = (row) => {
    editRow.value = row;

    deleteModal.value.openModal();
};

</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

@media screen and (max-width:1024px) {
    .table-custom-head {
        display: flex;
        flex-direction: column;
    }

    .input-group-search {
        width: 100% !important;
        margin-top: 20px;
    }
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>
