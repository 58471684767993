<template>
    <div class="offcanvas offcanvas-end w-25" :class="{ show: showEdit }" ref="modal" tabindex="-1" id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel" v-if="showEdit">
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel" class="text-dark">{{ row.name }} Details</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" @click="closeModel"
                aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
            <div class="d-flex justify-content-center align-items-center">
                <img class="mx-auto" :src="eRow.logo" width="200px" alt="logo image">
            </div>

            <div v-if="client">
                <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('Sections.Clients.Name') }}</span>
                    <p>{{ client[0]?.name }}</p>
                </div>

                <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('General.Email') }}</span>
                    <p>{{ client[0]?.email }}</p>
                </div>

                <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('General.Phone') }}</span>
                    <p>{{ client[0]?.mobile }}</p>
                </div>

                <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('General.StoreName') }}</span>
                    <p>{{ client[0]?.store_name }}</p>
                </div>

                <div class="mt-5 d-flex justify-content-between align-items-center">
                    <span class="text-uppercase text-primary">{{ $t('General.Status') }}</span>
                    <p class="text-decoration-underline" style="color: #1168FF;">
                        <!-- <img src="@/assets/dashboard/img/shield-user.svg" alt="shield-user"> -->
                        {{ client[0]?.status_name }}
                    </p>
                </div>
     
                <hr />

                <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('General.ReferenceNumber') }}</span>
                    <p>{{ client[0]?.reference_number }}</p>
                </div>

            </div>
        </div>
    </div>
    <div class="offcanvas-backdrop fade show" v-if="showEdit"></div>
</template>
<script setup>
import { defineProps, onBeforeUnmount, onMounted, ref } from 'vue';


const emit = defineEmits(['close-edit']);
const showEdit = ref(true);
const modal = ref(null);


const props = defineProps({
    client: Object, // Selected client data
    row: {
        type: Object,
        required: true,
    },
});
const eRow = ref(props.row);

function closeModel() {
    emit('close-edit', false);
}
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});


</script>