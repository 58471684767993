<template>
    <div class="calendar p-5 shadow" :class="{ 'show-calendar': showCalendar }">
        <div class="calendar-content border rounded p-5">
            <div class="d-flex flex-col">
                <div class="border-r-primary pt-5 mt-1 row gap-1">
                    <button class="btn col-lg-6 mx-auto  fs-6 mt-1" type="button" @click="setAllDays"
                        :class="{ 'bg-primary text-white': selectedButton === 'allDays', 'bg-green text-primary': selectedButton !== 'allDays' }">
                        {{ $t("General.AllDays") }}
                    </button>
                    <button class="btn col-lg-6 mx-auto fs-6 mt-1" type="button" @click="setToday"
                        :class="{ 'bg-primary text-white': selectedButton === 'today', 'bg-green text-primary': selectedButton !== 'today' }">
                        {{ $t("General.Today") }}
                    </button>
                    <button class="btn col-lg-6 mx-auto  fs-6 mt-1" type="button" @click="setYesterday"
                        :class="{ 'bg-primary text-white': selectedButton === 'yesterday', 'bg-green text-primary': selectedButton !== 'yesterday' }">
                        {{ $t("General.Yesterday") }}
                    </button>
                    <button class="btn col-lg-6  mx-auto  fs-6 mt-1" type="button" @click="setThisWeek"
                        :class="{ 'bg-primary text-white': selectedButton === 'thisWeek', 'bg-green text-primary': selectedButton !== 'thisWeek' }">
                        {{ $t("General.ThisWeek") }}
                    </button>
                    <button class="btn col-lg-6 mx-auto fs-6 mt-1" type="button" @click="setThisMonth"
                        :class="{ 'bg-primary text-white': selectedButton === 'thisMonth', 'bg-green text-primary': selectedButton !== 'thisMonth' }">
                        {{ $t("General.ThisMonth") }}
                    </button>
                    <button class="btn col-lg-6 mx-auto fs-6 mt-1" type="button" @click="setThisYear"
                        :class="{ 'bg-primary text-white': selectedButton === 'thisYear', 'bg-green text-primary': selectedButton !== 'thisYear' }">
                        {{ $t("General.ThisYear") }}
                    </button>
                </div>
                <div class="d-flex flex-col">
                    <!-- First Calendar -->
                    <div class="calendar-container ms-5">
                        <Datepicker v-if="isDatepickerLoaded" v-model="selectedDate1" :inline="true" :input-props="{ style: 'display: none' }"
                            :week-start="0" :highlight-today="true" :enable-time-picker="false"
                            :custom-week-days="weeks" class=" calendar-dates ms-5 no-border"
                            :highlight="highlightedDates" @date-update="onDateChange1" />
                    </div>

                    <!-- Second Calendar -->
                    <div class="calendar-container ">
                        <Datepicker v-if="isDatepickerLoaded" v-model="selectedDate2" :inline="true" :input-props="{ style: 'display: none' }"
                            :week-start="0" :highlight-today="true" :enable-time-picker="false"
                            :custom-week-days="weeks" class="custom-datepicker no-border" :highlight="highlightedDates"
                            @date-update="onDateChange2" />
                    </div>
                </div>

                <div class="border-l-primary flex-col mt-2">
                    <span class="text-dark fw-bold">{{ $t('General.FilterBy') }} :</span>
                    <button class="btn  fs-6 mt-1" type="button" :class="{
                        'bg-primary text-white': selectedDateType === 'created_date',
                        'bg-green text-primary': selectedDateType !== 'created_date'
                    }" @click="changeDateType('created_date')">

                        {{ $t("General.CreatedDate") }}

                    </button>
                    <button class="btn fs-6 mt-1" type="button" :class="{
                        'bg-primary text-white': selectedDateType === 'ready_date',
                        'bg-green text-primary': selectedDateType !== 'ready_date'
                    }" @click="changeDateType('ready_date')">
                        {{ $t("General.ReadyDate") }}
                    </button>
                    <button class="btn fs-6 mt-1" type="button" :class="{
                        'bg-primary text-white': selectedDateType === 'shipping_date',
                        'bg-green text-primary': selectedDateType !== 'shipping_date'
                    }" @click="changeDateType('shipping_date')">
                        {{ $t("General.ShippingDate") }}
                    </button>
                    <button class="btn fs-6 mt-1" type="button" :class="{
                        'bg-primary text-white': selectedDateType === 'delivery_date',
                        'bg-green text-primary': selectedDateType !== 'delivery_date'
                    }" @click="changeDateType('delivery_date')">
                        {{ $t("General.DeliveryDate") }}
                    </button>
                    <button class="btn fs-6 mt-1" type="button" :class="{
                        'bg-primary text-white': selectedDateType === 'updated_date',
                        'bg-green text-primary': selectedDateType !== 'updated_date'
                    }" @click="changeDateType('updated_date')">
                        {{ $t("General.UpdatedDate") }}
                    </button>

                </div>
            </div>
        </div>

        <div class="p-5 d-flex gap-3">
            <button class="btn " :class="{
                'bg-primary text-white': selectedSortedBt === 'desc',
                'bg-green text-primary': selectedSortedBt !== 'desc'
            }" @click="changeSortedBy('desc')"> {{ $t("General.SortedByDescending") }} </button>
            <button class="btn " :class="{
                'bg-primary text-white': selectedSortedBt === 'asc',
                'bg-green text-primary': selectedSortedBt !== 'asc'
            }" @click="changeSortedBy('asc')">{{ $t("General.SortedByAscending") }} </button>
        </div>

        <div class="d-flex flex-col align-items-center justify-content-between">
            <div>
                <span class="text-uppercase text-primary">{{ $t("General.StartWith") }} :</span>
                {{ formatDateToDMY(selectedDate1) }}
            </div>
            <div>
                <span class="text-uppercase text-primary">{{ $t("General.EndWith") }} :</span>
                {{ formatDateToDMY(selectedDate2) }}

            </div>
            <div class="d-flex gap-3 calender-btns">
                <button class="btn text-primary bg-green" @click="SyncCulender">{{ $t('General.Cancel') }}</button>
                <button class="btn text-white bg-primary" @click="SyncCulender">{{ $t('General.Apply') }}</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, defineAsyncComponent, onMounted, ref } from "vue";
// import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { formatDateToDMY } from "@/utils/dateFormatter";

const Datepicker = defineAsyncComponent(() => import("@vuepic/vue-datepicker"));



const emit = defineEmits(["update:startDate", "update:endDate"]);
const weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    startDate: {
        type: Date,
        required: true,
    },
    endDate: {
        type: Date,
        required: true,
    },
    date_type: {
        type: String,
        required: true,
    },
    sort_value: {
        type: String,
        required: true,
    },
});

const showCalendar = ref(props.show);
const selectedDate1 = ref(props.startDate); // Store selected date for first calendar
const selectedDate2 = ref(props.endDate); // Store selected date for second calendar

const selectedButton = ref('allDays');
const selectedDateType = ref('created_date');
const selectedSortedBt = ref('desc');

const formattedStartDate = new Date(1970, 0, 1); // January 1, 1970
const earliestDate = formattedStartDate.toISOString().split('T')[0];

const date_type = ref(props.date_type);
const sortBy = ref(props.sort_value);

const highlightedDates = computed(() => {
    if (!selectedDate1.value || !selectedDate2.value) return [];

    const start = new Date(selectedDate1.value);
    const end = new Date(selectedDate2.value);
    let dates = [];

    while (start <= end) {
        dates.push(new Date(start));
        start.setDate(start.getDate() + 1);
    }

    return dates;
});

// Function to handle date changes
const onDateChange1 = (newDate) => {

    selectedDate1.value = newDate;
    emit("update:startDate", newDate);

};
const onDateChange2 = (newDate) => {

    selectedDate2.value = newDate;
    emit("update:endDate", newDate);

};
const today = new Date();




// Set selected dates to cover all days (no range)
const setAllDays = () => {
    selectedButton.value = 'allDays';
    selectedDate1.value = earliestDate;
    selectedDate2.value = today;
    onDateChange1(earliestDate);

    onDateChange2(today);

};

// Set selected dates to today
const setToday = () => {
    selectedButton.value = 'today';
    selectedDate1.value = today;
    selectedDate2.value = today;
    onDateChange1(today);
    onDateChange2(today);
};

// Set selected dates to yesterday
const setYesterday = () => {
    selectedButton.value = 'yesterday';
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    selectedDate1.value = yesterday;
    selectedDate2.value = yesterday;
    onDateChange1(yesterday);
    onDateChange2(yesterday);
};

// Set selected dates to this week (starting Sunday to Saturday)
const setThisWeek = () => {
    selectedButton.value = 'thisWeek';
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek); // Sunday

    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - dayOfWeek)); // Saturday

    selectedDate1.value = startOfWeek;
    selectedDate2.value = endOfWeek;
    onDateChange1(startOfWeek);
    onDateChange2(endOfWeek);
};

// Set selected dates to this month (1st of the month to current date)
const setThisMonth = () => {
    selectedButton.value = 'thisMonth';
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    selectedDate1.value = startOfMonth;
    selectedDate2.value = today;
    onDateChange1(startOfMonth);
    onDateChange2(today);
};

// Set selected dates to this year (1st January to current date)
const setThisYear = () => {
    selectedButton.value = 'thisYear';
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    selectedDate1.value = startOfYear;
    selectedDate2.value = today;
    onDateChange1(startOfYear);
    onDateChange2(today);
};

const changeDateType = (newDateType) => {
    selectedDateType.value = newDateType;
    date_type.value = newDateType;
    emit("update:date_type", newDateType);

};
const changeSortedBy = (newSortedBy) => {
    selectedSortedBt.value = newSortedBy;
    sortBy.value = newSortedBy;
    emit("update:sort_value", newSortedBy);

};



const SyncCulender = () => {
    showCalendar.value = !showCalendar.value;
};

const isShowCalendar = () => {
    return showCalendar.value
}


const isDatepickerLoaded = ref(false);

onMounted(() => {
    setTimeout(() => {
        isDatepickerLoaded.value = true;
    }, 1000);
});


defineExpose({
    SyncCulender,
    isShowCalendar

});

</script>

<style>
.dp__action_select {
    display: none;
}

.dp__menu {
    border: none !important;
}
</style>
<style scoped>
[dir="ltr"] .calendar {
    position: absolute;
    top: 50%;
    right: -90vw;
    display: none;
    background-color: #fff;
    transform: translateY(10%);
    transition: right 3s ease;
    z-index: 1000;
}

[dir="rtl"] .calendar {
    position: absolute;
    top: 50%;
    right: -48vw;
    width: min-content;
    display: none;
    background-color: #fff;
    transform: translateY(10%);
    transition: right 3s ease;
    z-index: 1000;
}

.calendar button {
    width: 120px;
    display: block;
    text-transform: capitalize;
}


[dir="ltr"] .show-calendar {
    right: 0;
    display: block;
}

[dir="rtl"] .show-calendar {
    right: auto !important;
    left: 0px !important;
    display: block;
}



.calendar-navigation {
    display: flex;
    justify-content: center;
    align-items: center;

}

.calendar-navigation span {
    height: 38px;
    width: 38px;
    margin: 0 1px;
    cursor: pointer;
    text-align: center;
    line-height: 38px;
    border-radius: 12px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aeabab;
    font-size: 1.9rem;
    background: #f2f2f2;
}

.calendar-current-date {
    color: #000336;
    padding: 10px;
    margin-top: 20px;
}

.calendar-body ul {
    list-style: none;
    flex-wrap: wrap;
    display: flex;
    text-align: center;
}

.calendar-body .calendar-dates {
    margin-bottom: 20px;
}

.calendar-body li {
    width: calc(100% / 7);
    font-size: 1.07rem;
    color: #414141;
}

.calendar-body .calendar-weekdays li {
    cursor: default;
    font-weight: 500;
}

.calendar-body .calendar-dates li {
    margin-top: 10px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.calendar-dates li.inactive {
    color: #aaa;
}

.calendar-dates li.active {
    color: #fff;
}

[dir="ltr"].calendar-dates li::before {
    position: absolute;
    content: "";
    z-index: -1;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    transform: translate(-50%, -50%);
}



.calendar-dates li.active::before {
    background: #24A259;
}

.calendar-container {
    width: 300px;
}

.calendar-dates li:not(.active):hover::before {
    background: #e4e1e1;
}

.calendar {
    position: absolute;
    top: 50%;
    right: -90vw;
    display: none;
    background-color: #fff;
    transform: translateY(10%);
    transition: right 3s ease;
    z-index: 1000;
}

.calendar button {
    text-transform: capitalize;
}

.show-calendar {
    right: 0;
    display: block;
}

.calendar-container {
    width: 300px;
}

.custom-datepicker {
    --dp-highlight-color: #24A259;
    --dp-day-hover-color: #e4e1e1;
    --dp-selected-day-color: #fff;
    --dp-selected-day-bg: #24A259;
}

@media screen and (max-width:1024px) {
    .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .calendar-dates.ms-5,
    .calendar-container.ms-5 {
        margin-left: 0px !important;
    }

    .calender-btns,
    .calendar-container {
        margin-top: 20px !important;
    }

    .calendar {
        max-width: 90vw !important;
        top: 0 !important;
        transform: translateY(5%) !important;
    }

    .border-l-primary button {
        width: 100%;
    }

    [dir="rtl"] .calendar {
        right: 0px !important;
        top: 5px !important;
        width: 90vw !important;
    }

    [dir=rtl] .ms-5 {
        margin-right: 0 !important;
    }
}

.dp__range_between {
    background-color: rgba(36, 162, 89, 0.08) !important;
    color: white !important;
}
</style>
