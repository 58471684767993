import { defineStore } from 'pinia';
import { login, logOut, getprofile, register, registerById } from '@/services/auth';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isLoggedIn: localStorage.getItem("access_token") !== null,
    isPrevToken: localStorage.getItem("prevToken") !== null,
    user: {
      id: null,
      name: '',
      email: '',
      userName: '',
      token: '',
      logo: '',
      roles: [],
      client: [],
    },
  }),
  getters: {
    userPermissions() {
      // Flatten permissions from all roles into a single array for easy lookup
      return this.user.roles.flatMap(role => role.permissions.map(permission => permission.name));
    },
    isAdmin() {
      return this.user.roles[0]?.name == 'Admin'

    },
    isClient() {
      return this.user.roles[0]?.name == 'Client'

    },

  },
  actions: {
    async handleLogin(credentials) {
      try {
        const response = await login(credentials);
        localStorage.setItem('access_token', response.data.data.token);
        this.isLoggedIn = true;
        const { id, name, email, user_name: userName, token, logo, roles, client } = response.data.data;

        // Assign user data to the store state
        this.user = { id, name, email, userName, token, logo, roles, client };
        localStorage.setItem('user', JSON.stringify(this.user));

        return response;
      } catch (error) {
        throw new Error();
      }
    },
    async profile() {
      try {
        if (this.user.id == null) {

          const response = await getprofile();
          const { id, name, email, user_name: userName, logo, roles, client } = response.data.data;

          this.user = { id, name, email, userName, logo, roles, client };

          localStorage.setItem('user', JSON.stringify(this.user));
        }




        return;
      } catch (error) {

        throw new Error();
      }
    },
    async registerId(data) {
      try {
        if (this.user.id != null) {
          if (!this.isPrevToken) {
            const prevToken = localStorage.getItem("access_token")
            localStorage.setItem('prevToken', prevToken);
            this.isPrevToken = true;


          }
          const response = await registerById(data);
          localStorage.setItem('access_token', response.data.data.token);
          const { id, name, email, user_name: userName, logo, roles, client } = response.data.data;


          this.user = { id, name, email, userName, logo, roles, client };

          localStorage.setItem('user', JSON.stringify(this.user));
        }




        return;
      } catch (error) {

        throw new Error();
      }
    },
    async backUser() {
      if (this.isPrevToken) {
        localStorage.setItem('access_token', localStorage.getItem("prevToken"));
        localStorage.removeItem('prevToken');
        this.isPrevToken = false;
        const response = await getprofile();
        const { id, name, email, user_name: userName, logo, roles, client } = response.data.data;

        this.user = { id, name, email, userName, logo, roles, client };
        localStorage.setItem('user', JSON.stringify(this.user));

      }

    },
    async redirectToken(redirect_token) {
      localStorage.setItem('access_token', redirect_token);

      const response = await getprofile();

      this.isLoggedIn = true;
      const { id, name, email, user_name: userName, logo, roles, client } = response.data.data;

      this.user = { id, name, email, userName, logo, roles, client };

      localStorage.setItem('user', JSON.stringify(this.user));

    },

    async handleRegister(credentials) {
      try {
        return await register(credentials);

      } catch (error) {
        throw new Error();
      }
    },

    async handleLogout() {
      try {
        await logOut();
        this.isLoggedIn = false;
        this.user = {
          id: null,
          name: '',
          email: '',
          userName: '',
          token: '',
          logo: '',
          roles: [],
        };
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        localStorage.removeItem('prevToken');
        this.isPrevToken = false;

      } catch (e) {
        console.log('Error during logout');
      }
    },

    hasPermission(permissionName = null) {

      if (permissionName) {
        if (Array.isArray(permissionName)) {
          return permissionName.some(permission => this.userPermissions.includes(permission));
        } else {

          return this.userPermissions.includes(permissionName);
        }
      }

      return true;
    },

  },
});




