<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.Users') }}</h3>
            <DataTable v-model:rows="CustomerData" :headers="headers" :fields="fields" :total="total" :loading="loading"
                @page-changed="onPageChanged">
                <template v-slot:head-contant="{}">

                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="13.3333" cy="9.00002" r="5.33333" fill="#24A259" />
                                <ellipse cx="13.3333" cy="23.6666" rx="9.33333" ry="5.33333" fill="#24A259" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M24.4761 3.15234C24.7602 2.67876 25.3745 2.5252 25.8481 2.80935L25.3336 3.66684C25.8481 2.80935 25.8476 2.80907 25.8481 2.80935L25.8499 2.81046L25.852 2.81173L25.8569 2.81473L25.8697 2.82262C25.8795 2.82872 25.8918 2.83651 25.9065 2.846C25.9359 2.86497 25.9747 2.8908 26.0216 2.92365C26.1154 2.98927 26.242 3.08328 26.3904 3.20695C26.6865 3.45373 27.0738 3.8224 27.4595 4.32379C28.2367 5.33411 29.0002 6.87663 29.0002 9.00017C29.0002 11.1237 28.2367 12.6662 27.4595 13.6765C27.0738 14.1779 26.6865 14.5466 26.3904 14.7934C26.242 14.9171 26.1154 15.0111 26.0216 15.0767C25.9747 15.1095 25.9359 15.1354 25.9065 15.1543C25.8918 15.1638 25.8795 15.1716 25.8697 15.1777L25.8569 15.1856L25.852 15.1886L25.8499 15.1899C25.8495 15.1902 25.8481 15.191 25.3336 14.3335L25.8481 15.191C25.3745 15.4751 24.7602 15.3216 24.4761 14.848C24.1931 14.3764 24.3442 13.7653 24.8131 13.4796L24.8205 13.4749C24.8302 13.4686 24.8487 13.4564 24.8747 13.4382C24.9268 13.4018 25.0084 13.3416 25.11 13.257C25.3139 13.0871 25.5933 12.8224 25.8743 12.4571C26.4304 11.7341 27.0002 10.61 27.0002 9.00017C27.0002 7.39038 26.4304 6.26623 25.8743 5.54322C25.5933 5.17795 25.3139 4.91328 25.11 4.74339C25.0084 4.65873 24.9268 4.59857 24.8747 4.56211C24.8487 4.54391 24.8302 4.53172 24.8205 4.52544L24.8131 4.52073C24.3442 4.23502 24.1931 3.62393 24.4761 3.15234Z"
                                    fill="#24A259" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M21.7725 5.88629C22.0195 5.39231 22.6201 5.19209 23.1141 5.43908L22.6669 6.3335C23.1141 5.43908 23.1136 5.43881 23.1141 5.43908L23.1163 5.44016L23.1186 5.44132L23.1235 5.44385L23.135 5.44979L23.1638 5.46526C23.1855 5.47721 23.2122 5.49247 23.2432 5.51127C23.305 5.54881 23.3844 5.60079 23.4752 5.66892C23.6565 5.80485 23.887 6.00789 24.1144 6.29214C24.5794 6.87334 25.0002 7.75725 25.0002 9.00017C25.0002 10.2431 24.5794 11.127 24.1144 11.7082C23.887 11.9925 23.6565 12.1955 23.4752 12.3314C23.3844 12.3996 23.305 12.4515 23.2432 12.4891C23.2122 12.5079 23.1855 12.5231 23.1638 12.5351L23.135 12.5506L23.1235 12.5565L23.1186 12.559L23.1163 12.5602C23.1157 12.5604 23.1141 12.5613 22.6753 11.6836L23.1141 12.5613C22.6201 12.8083 22.0195 12.608 21.7725 12.1141C21.5285 11.6262 21.7208 11.0343 22.2014 10.7818C22.2023 10.7812 22.2036 10.7805 22.2052 10.7795C22.2163 10.7728 22.2411 10.757 22.2752 10.7314C22.344 10.6799 22.4468 10.5912 22.5527 10.4588C22.7544 10.2067 23.0002 9.75725 23.0002 9.00017C23.0002 8.24309 22.7544 7.79367 22.5527 7.54153C22.4468 7.40912 22.344 7.32049 22.2752 7.26892C22.2411 7.2433 22.2163 7.22757 22.2052 7.22084C22.2036 7.21989 22.2023 7.21912 22.2014 7.21854C21.7208 6.966 21.5285 6.37412 21.7725 5.88629Z"
                                    fill="#24A259" />
                            </svg>


                            <h3 class="table-title">{{ $t('Sections.Customers.List') }}</h3>

                        </div>

                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31" v-model.lazy="searchQuery">
                        </div>
                    </div>

                </template>
                <template v-slot:table-header>
                    <th class="text-center">#</th>
                    <th class="text-center" v-if="authStore.hasPermission('view-clients')">
                        {{ $t('Entities.Clients') }}
                    </th>
                    <th class="text-center">{{ $t('General.Name') }}</th>
                    <th class="text-center">{{ $t('General.Email') }}</th>
                    <th class="text-center">{{ $t('General.Phone') }}</th>
                    <th class="text-center">{{ $t('General.Verified') }}</th>
                    <th class="text-center">{{ $t('Sections.Customers.IsActive') }}</th>
                </template>

                <template v-slot:action-buttons="{ rowColum }">
                    <td class="text-center align-middle" v-if="authStore.hasPermission('view-clients')">
                        <!-- {{ rowColum.clients.length ? rowColum.clients : '--' }} -->
                        <span class="badge badge-light-success bg-green text-primary fw-bolder fs-8 px-2 py-1 ms-2"
                            v-for="client in rowColum.clients" :key="client.id">
                            {{ client.name }}
                        </span>
                    </td>
                    <td class="text-center align-middle">{{ rowColum.name }}</td>
                    <td class="text-center align-middle">{{ rowColum.email }}</td>
                    <td class="text-center align-middle">{{ rowColum.mobile }}</td>
                    <td class="text-center align-middle">
                        <span v-if="rowColum.verified == 1">
                            <i class="bx bxs-badge-check bx-sm me-sm-2 color-green "></i>
                            <span class="d-none d-sm-inline-block color-green">
                                {{ $t('General.Verified') }}
                            </span>
                        </span>
                        <span v-else>
                            <i class="bx bx-badge-check bx-sm me-sm-2"></i>
                            <span class="d-none d-sm-inline-block ">
                                {{ $t('General.NotVerified') }}
                            </span>
                        </span>



                    </td>
                    <td class="text-center align-middle">

                        <button v-if="rowColum.is_active" class="btn blue-btn  " tabindex="0"
                            aria-controls="DataTables_Table_0" type="button">
                            {{ $t('Sections.Customers.Active') }}

                        </button>
                        <button v-else class="btn delete-btn " tabindex="0" aria-controls="DataTables_Table_0"
                            type="button">
                            {{ $t('Sections.Customers.Inactive') }}

                        </button>

                    </td>


                </template>

            </DataTable>




        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';

import i18n from '@/i18n.js';
import { getCustomers } from '@/services/customer';
import { useAuthStore } from '@/stores/auth';


// State
// Create a reference to the DeleteModal component
const CustomerData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const searchQuery = ref('');


const authStore = useAuthStore();


// Table headers and fields
const headers = [];
const fields = ['ids'];

// {
//                 "customer_id": 14056411,
//                 "id": 14056411,
//                 "reference_id": "14218810",
//                 "country_id": null,
//                 "city_id": null,
//                 "name": "\u0628\u064a\u0627\u0646 \u0627\u062d\u0645\u062f",
//                 "email": "bayan90ah@gmail.com",
//                 "mobile": "966554769960",
//                 "nickname": "\u0628\u064a\u0627\u0646 \u0627\u062d\u0645\u062f",
//                 "order_counts": 0,
//                 "verified": 1,
//                 "is_active": 1,
//                 "created_at": null,
//                 "updated_at": null,
//                 "clients": [
//                     {
//                         "id": 1,
//                         "reference_number": "660194",
//                         "user_id": 2,
//                         "serial_number": "0001",
//                         "name": "Roxa",
//                         "email": "aass999@gmail.com",
//                         "mobile": "+966540888224",
//                         "code": "ROX",
//                         "store_name": "Roxa",
//                         "store_id": 158842,
//                         "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyNTE3IiwianRpIjoiODhlYWM3ZTg4NTZmNDNkZmVlMzQ5MWMzNjRjMzFhZjNlNTQzZjI0NjIxZmNhNTIwM2EzODQ4MmE3OGIzNGUzNTYwMWRmYjlmOWI0NzFlYWQiLCJpYXQiOjE3MzA1MzExNTIuMDEzNzY1LCJuYmYiOjE3MzA1MzExNTIuMDEzNzY4LCJleHAiOjE3NjIwNjcxNTEuOTg4NTkxLCJzdWIiOiI2NjAxOTQiLCJzY29wZXMiOlsidGhpcmRfYWNjb3VudF9yZWFkIiwidGhpcmRfdmF0X3JlYWQiLCJ0aGlyZF9jYXRlZ29yaWVzX3JlYWQiLCJ0aGlyZF9jdXN0b21lcnNfcmVhZCIsInRoaXJkX29yZGVyX3JlYWQiLCJ0aGlyZF9vcmRlcl93cml0ZSIsInRoaXJkX2NvdXBvbnNfd3JpdGUiLCJ0aGlyZF9kZWxpdmVyeV9vcHRpb25zX3JlYWQiLCJ0aGlyZF9hYmFuZG9uZWRfY2FydHNfcmVhZCIsInRoaXJkX3BheW1lbnRfcmVhZCIsInRoaXJkX3dlYmhvb2tfcmVhZCIsInRoaXJkX3dlYmhvb2tfd3JpdGUiLCJ0aGlyZF9wcm9kdWN0X3JlYWQiLCJ0aGlyZF9wcm9kdWN0X3dyaXRlIiwidGhpcmRfY291bnRyaWVzX3JlYWQiLCJ0aGlyZF9jYXRhbG9nX3dyaXRlIiwidGhpcmRfc3Vic2NyaXB0aW9uX3JlYWQiLCJ0aGlyZF9pbnZlbnRvcnlfcmVhZCIsInRoaXJkX2pzX3dyaXRlIiwidGhpcmRfYnVuZGxlX29mZmVyc19yZWFkIiwidGhpcmRfY3JlYXRlX29yZGVyIiwidGhpcmRfcHJvZHVjdF9zdG9ja19yZWFkIiwidGhpcmRfcHJvZHVjdF9zdG9ja193cml0ZSIsInRoaXJkX2ludmVudG9yeV93cml0ZSJdfQ.fGEtQDqQQtIXaTtwHJAqqGLDB08cxl7b2r2g58DYgHWRPkOmvo7ptb6ah_LnN6vYskJAqKZvIt57UjaTj1-8WcSJEePLOBgOm-wK8UoWIgnal8TsNdPj1Abq-Ox9s_atM7IsljRZTEA7xGvw6wFdE1z6kgTs8OehcxPPb7z0v2FGcUgH918pLBrs-hCKl-14GcYARiC2cLJ7vUTcnO_u5JanMwY0fVqnxK5Jajd7BhMLfCOJySP5TMY_2l3ANbZ6-dECSkaW3RfRZV7lWOe2PA7fLmySGGaD__SlJq5JJMZpCB2hKZJ6zuNBd67oprZWbG46HC-6c6MkHDIcrTamaeOCwsAlD5saZvBEpmiEuQYM5F2LidxRus-t2OHMeEbb4Y1zcxYTuR2tIdeqAf50C_tDNr9jr578M12DrHe9TsRXTg0vUaCXXhnbMt9VCY7cg6gVKvB0iQqoTbL62XMy3TIzsY9OdytgHYQZ1ZvmZQmYw4Cuydiae_fgapMM89OWHKszrgEX_8F-NR4UgTi041AOc2EA6kY4Rdq78RfZ-f3MEiGNOza9vBzRy8hmiUPnjOnaCCLolrVhyP4Nis_WFtKjJ0KFAKqaj_dWHfcbD6_CctR2VI9rzzBkXQDg1T5lrOrIhaOc9Rwd9RwpWMNl33TuROYApFdZ8-by8sCRAhE",
//                         "access_token": "eyJpdiI6Ik9zck9abGd2WEI2S3VKZEFCOUlkWnc9PSIsInZhbHVlIjoicEo5SVRFMnRSRzk2OXBiZGtucTcxaVBIRkthT2JhRDJFbTIxVjh6WUhCU3Q2L1pUMVMzSEhaZy9BSkhqR0dkYjl4dEpJdDBzT3NXcFpXY2ZkR1NuK0lTOG0zNTFoSlh6Z2VpTUd0VElRQkI4cTN1RExzTDNkL3hkdkFnOUVWbDN6b0J4ajczSHgzWDFkalhnaExaK3ZNOXBmZ0FDTXhRNUpqcGdoTmdkbEdkL0JpbTJkTUJPTUg5dmVjNDBRQUZTUjRHZXlvZkVGVkllbXNTdW8ybU9BOEdia1JKK2N0RlREWExOQnBKdXVDND0iLCJtYWMiOiJiMjQxNzc1MzY4NzcwYWFjMDVlMzlkZWRkYzlmOTA2N2NlMWJiMTExNjMyMGY2N2QxOGI4MjFmZTQ4NGZiMGZkIiwidGFnIjoiIn0=",
//                         "refresh_token": "def50200fd8ef69a0068715cf807fd7ed592709260067df45cb154e749384795728b64d653680877cd51aedb43804b2d221b95a120e65baf83d288e5f438bb1135aaa47bd3f1f7a5112cc527476aa2ccac41cab0dd2b105bee469aff324d1cd462a489d06b9ae6c7c6f999bc87a1525e3876b40efc29d5aa7a478f49822b9a4d0dcc635c19976a7ab81bb993548ad8b628aa1d2958453f1c83e509ab602dc31eb094171774fd0390371c77bb982cad1bdd66260e1cbb0bb00758eaa840e9af774440956355a26b7cd7c68b700091a4cc78d968b302d69777a19416eef59d13779f7793d990f9356b735d71db625ead8a6a0088cc9ab25db7500c09bf05ebae0b73f15483e0da383c778ec04bd0783b753f41b654c9435588b1223b8fa0b483a03822ee666373e3953ca011291adf37ed6816bbc760b731a54414e262b82bf46c53b6a97165e6a9789a2cf3c1e9b2d7629d44390a446fcaa497a68f309ec4f4c0fa79e165c17a2a94ed116b5baa3a0f26af4d86b3087554926f7021e38f6139eee4e096839b7716aa06eb55639b8e35fa01da693aa191240411fd6fa90d3a2f20a5d0792ac6357a9d5a401178726f1d94b37763e18b08ac73363d0412f62d949fd5a1c28f2a31fdd0c4cdd5d5b8c5f89ea2028b96b61e821468e8b365f1711b573b95091636632b84a0b78766a65d79555f4b090a1133e69961e4ad125e347266ba18f290a0d39a38b0e4ff34950967a46c5d0c27de71e2d1236241fb573fd4da80a093ba03978de782f4b8cb217ee632b05d2ea363478473342e99b8533bd19512fe85816b232a7f76ba8c4a54c73c972a8d8a597b2758fab8ee5cf00cfcde417b99067aac2bbd9c6402884f0c5728ade9987cab4298407bf5d3147e70fe53f1224445f10f72c74f87369a8ecf4b358a0894221935b3f5b4fbfa0aa74ff548558cc4e2896834507f7dfbdaab54d5b4102e564c44363aa702b02cb4149f7a62e99f9b74d68f1112718bbbd7c35830abdfc71308a5f32ea051d20b5493ae93ab02b2592bf8c33e73935856d5d0dc51ede4a1ffd0cc18a314ea3698264b3de2681f430bc165caef22b8097d279ba2a674631fc2333c918dbfdefd8122b449921313aeb1ce48baa62a480cabfe013330d881fd144789062a79e6ec4c405bc6b75d14000877b978a3ca07bbc3ad900ecc3e4bacbe6d9071e81df5da51a019c355fc5fc6ee9f7280a4a6715c42adaab945ef1f4440df368e863e1de55081a75f189dba115f725e746c4c0038dc91d342ab",
//                         "expires_in": 31536000,
//                         "last_update_token": "2024-11-06 13:08:36",
//                         "error_connect": 1,
//                         "recive_order_status": 0,
//                         "change_status_automatic": 1,
//                         "change_paid_order_automatic": 1,
//                         "scan_column": 0,
//                         "is_sync_order": 1,
//                         "update_quantity_in_zid": 0,
//                         "update_orders_in_zid": 0,
//                         "status": 1,
//                         "created_at": "2024-08-01T07:31:17.000000Z",
//                         "updated_at": "2024-11-06T10:12:42.000000Z",
//                         "status_name": "Approved",
//                         "pivot": {
//                             "customer_id": 14056411,
//                             "client_id": 1
//                         }
//                     }
//                 ]
//             },


// Fetch users with pagination
const fetchData = async () => {

    try {
        loading.value = true;
        CustomerData.value = [];
        const response = await getCustomers(currentPage.value, searchQuery.value);
        let count = 1 ; 

        for (const row of response.data.data.data) {

            CustomerData.value.push({
                ...row,
                ids:   (((currentPage.value??1) -1 ) *10) + count++ , 

                client_name: row.client?.name || "NAN",
                // created_at: formatDateToYMDHMS(row.created_at),
                // updated_at: formatDateToYMDHMS(row.updated_at),
            });

        }
        // ClientsData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};
const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};

watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});

computed(() => {
    if (!Array.isArray(CustomerData.value)) return []; // Ensure it's an array
    if (searchQuery.value) {
        return CustomerData.value.filter(row =>
            fields.some(field =>
                String(row[field]).toLowerCase().includes(searchQuery.value.toLowerCase())
            )
        );
    }
    return CustomerData.value;
});

// Fetch users on mount
onMounted(fetchData);



</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>