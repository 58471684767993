import { ref } from "vue";
import axiosClient from "./axiosClient"

export function getCustomers(page = 1, searchQuery = '') {
  const url = ref('');
  if (searchQuery) {
    url.value = `v1/dashboard/customers?page=${page}&per_page=10&search=${searchQuery}`;
  } else {
    url.value = `v1/dashboard/customers?page=${page}&per_page=10`;

  }
  return axiosClient({
    url: url.value,
  })
}
export function searchCustomer(data = null) {
  return axiosClient({
    url: `v1/dashboard/search-customer`,
    method: 'POST',
    data
  })
}
export function storeCustomer(data ) {
  return axiosClient({
    url: `v1/dashboard/store-customer`,
    method: 'POST',
    data
  })
}
export function getCustomerLocations(data = null) {
  return axiosClient({
    url: `v1/dashboard/get-customer-addresses`,
    method: 'POST',
    data
  })
}
export function StoreCustomerLocations(data) {
  return axiosClient({
    url: `v1/dashboard/store-customer-address`,
    method: 'POST',
    data
  })
}