<template>
    <div class="modal-backdrop fade" :class="{ 'show': showEdit }"
        style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 1040;">
    </div>
    <div class="modal fade" :class="{ 'show': showEdit }" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog  modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2">{{ $t('Sections.PrintNode.Computers') }}</h4>
                    </div>
                    <div class="table-responsive text-nowrap">
                        <table class="table table-hover">
                            <thead class="bg-green">
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-center">{{ $t('General.ComputerID') }}</th>
                                    <th class="text-center">{{ $t('General.Name') }}</th>
                                    <th class="text-center">{{ $t('General.HostName') }}</th>
                                    <th class="text-center">{{ $t('General.State') }}</th>
                                    <th class="text-center">{{ $t('General.Version') }}</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr v-if="loading">
                                    <td colspan="7" class="text-center">{{ $t('General.Loading') }}</td>
                                </tr>
                                <tr v-else-if="computers.length === 0">
                                    <td colspan="7" class="text-center">{{ $t('General.NoDataAvailable') }}</td>
                                </tr>
                                <tr v-else v-for="detail in computers" :key="detail.id">
                                    <td class="text-center">{{ detail.id }}</td>
                                    <td class="text-center">{{ detail.computer_id }}</td>
                                    <td class="text-center">{{ detail.name }}</td>
                                    <td class="text-center">{{ detail.hostname }}</td>
                                    <td class="text-center">{{ detail.state }}</td>
                                    <td class="text-center">{{ detail.version }}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button"  v-if="authStore.hasPermission('sync-computers')" @click="syncComputerFunction" class="btn btn-primary text-nowrap">
                            <i class='bx bx-refresh'></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import i18n from '@/i18n.js';
import { showChildPrintNode, syncComputers } from '@/services/printNode';
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();

const emit = defineEmits(['close-edit']);
const props = defineProps({
    showEdit: {
        type: Boolean,
        default: true,
    },
    row: {
        type: Object,
        required: true,
    },
});

const loading = ref(false);
const computers = ref([]);

function closeModel() {
    emit('close-edit', false);
}

const fetchData = async () => {
    try {
        loading.value = true;
        const response = await showChildPrintNode(props.row.id);
        console.log(response.data);
        console.log(response.data.data);

        if (response.data && response.data.data) {
            computers.value = response.data.data;
        } else {
            computers.value = [];
        }
    } catch (error) {
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    } finally {
        loading.value = false;
    }
};

onMounted(fetchData);

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});

const syncComputerFunction = async () => {
    try {
        loading.value = true;
        const response = await syncComputers(props.row.id);
        if (response.success == true) {
            fetchData();
        }
    } catch (error) {
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    } finally {
        loading.value = false;
    }
};

</script>

<style scoped>
label {
    text-transform: uppercase;
}
</style>
