import { ref } from "vue";
import axiosClient from "./axiosClient"

export function getRequest(page = 1, searchQuery = '') {
  const url = ref('');
  if (searchQuery) {
    url.value = `v1/dashboard/get_requests?page=${page}&per_page=10&search=${searchQuery}`;
  } else {
    url.value = `v1/dashboard/get_requests?page=${page}&per_page=10`;

  }
  return axiosClient({
    url: url.value,
  })
}
export function changeRequestStatus(data) {
  return axiosClient({
    url: `v1/dashboard/update_request_status`,
    method: "POST",
    data
  })
}
export function changeRequestNote(data) {
  return axiosClient({
    url: `v1/dashboard/update_admin_note`,
    method: "POST",
    data
  })
}
export function deleteRequest(data) {
  return axiosClient({
    url: `v1/dashboard/delete_dqq_request`,
    method: 'post',
    data

  })
}
