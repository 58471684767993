<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
                <!-- User Sidebar -->
                <div class="col-xl-4 col-lg-5 order-1 order-md-0">
                    <!-- User Card -->
                    <div class="card mb-6" >
                        <div class="card-body pt-12">
                            <div class="user-avatar-section">
                                <div class="d-flex align-items-center flex-column">
                                    <img class="img-fluid rounded mb-4" src="@/assets/dashboard/img/avatar-la.png"
                                        height="120" width="120" alt="User avatar" />
                                    <div class="user-info text-center">
                                        <h5>{{ selectedEmployee.user?.name }}</h5>
                                        <span class="badge bg-label-secondary">{{ $t('Entities.Employees') }}</span>
                                    </div>
                                </div>
                            </div>

                            <h5 class="pb-4 border-bottom mb-4">{{ $t("General.Details") }}</h5>
                            <div class="info-container">
                                <ul class="list-unstyled mb-6">
                                    <li class="mb-2">
                                        <span class="h6 mx-3">{{ $t("General.Username") }}:</span>
                                        <span>@{{ selectedEmployee.user?.user_name }}</span>
                                    </li>
                                    <li class="mb-2">
                                        <span class="h6 mx-3">{{ $t("General.Email") }}:</span>
                                        <span>{{ selectedEmployee.user?.email }}</span>
                                    </li>
                                    <li class="mb-2" v-if="statusLabel ">
                                        <span class="h6 mx-3">{{ $t("General.Status") }}:</span>
                                        <span>{{ statusLabel }}</span>
                                    </li>
                                    <li class="mb-2" v-if="selectedEmployee?.client">
                                        <span class="h6 mx-3">{{ $t("General.Contact") }}:</span>
                                        <span>{{ selectedEmployee.client?.mobile }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /User Card -->
                </div>
                <!--/ User Sidebar -->

                <!-- User Content -->
                <div class="col-xl-8 col-lg-7 order-0 order-md-1">
                    <!-- User Pills -->
                    <!-- Project table -->
                    <div class="card mb-6">


                        <!-- Notifications -->
                        <div class="card-header">
                            <h5 class="mb-0">{{ $t("General.Notifications") }}</h5>
                            <span class="card-subtitle">{{ $t("General.NotificationsAds") }}</span>
                        </div>

                        <div class="card-body">
                            <form class="row g-6 p-3" @submit.prevent="onSubmit">
                                <!-- Select Client ID -->
                                <div class="col-12 mb-3">
                                    <label for="client-id" class="form-label">{{ $t("General.Select.SelectClient")
                                        }}</label> <span class="required text-danger fw-bold ">*</span>
                                    <select id="client-id" class="form-select" v-model="user_id"
                                        :class="{ 'is-invalid': errors.user_id }">
                                        <option  value="" >{{ $t("General.Select.All") }}</option>
                                        <option v-for="user in employeesData" :key="user?.user_id" :value="user?.user_id">
                                            {{ user?.user?.name }}
                                        </option>
                                    </select>
                                    <div v-if="errors.user_id" class="invalid-feedback">{{ errors.user_id }}</div>
                                </div>

                                <!-- Title Input -->
                                <div class="col-12 mb-3">
                                    <label for="title" class="form-label">{{ $t("General.Title") }}</label><span
                                        class="required text-danger fw-bold ">*</span>
                                    <input type="text" id="title" class="form-control" v-model="title"
                                        :class="{ 'is-invalid': errors.title }"
                                        :placeholder="$t('General.Enter') + ' ' + $t('General.Title')"  />
                                    <div v-if="errors.title" class="invalid-feedback">{{ errors.title }}</div>
                                </div>

                                <!-- Body Editor -->
                                <div class="col-12 mb-3">
                                    <label for="message-editor" class="form-label">{{ $t("General.Message")
                                        }}</label><span class="required text-danger fw-bold ">*</span>
                                    <textarea id="message-editor" class="form-control form-textarea" v-model="body"
                                        :class="{ 'is-invalid': errors.body }" rows="5"
                                        :placeholder="$t('General.Enter') + ' ' + $t('General.Message')" ></textarea>
                                    <div v-if="errors.body" class="invalid-feedback">{{ errors.body }}</div>
                                </div>

                                <!-- Buttons -->
                                <div class="col-12 text-center d-flex justify-content-center gap-2 el p-3">
                                    <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                        :disabled="loading">
                                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        {{ $t('Entities.SendNotification') }}
                                    </button>
                                    <button type="reset" class="btn cancel-btn bg-green text-primary px-5"
                                        :disabled="loading"> {{ $t('General.Discard') }}</button>
                                </div>
                            </form>
                        </div>

                        <!-- /Project table -->
                    </div>
                    <!--/ User Content -->
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getAllEmployees } from "@/services/employee";
import { computed, onMounted, ref } from "vue";
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { useField, useForm } from "vee-validate";
import * as yup from 'yup';

import { toast } from 'vue3-toastify';
import { SendNotification } from "@/services/setting";

const loading = ref(false);

// Reactive states
const employeesData = ref([]);

const validationSchema = computed(() => yup.object({
    user_id: yup.string().nullable(),
    title: yup.string().required(t('Validation.IsRequired')),
    body: yup.string().required(t('Validation.IsRequired')),
}));
const { handleSubmit, errors } = useForm({
    validationSchema: validationSchema.value,
});
const { value: user_id } = useField('user_id');
user_id.value = '' ;
const { value: title } = useField('title');
const { value: body } = useField('body');

const successMessage = computed(() => t('Messages.Success.Sended'));
// const errorMessage = computed(() => t('Messages.Error.SubmitError'));

const onSubmit = handleSubmit(async (value) => {
    try {
        loading.value = true;
        // Filter out null and empty data
        const filteredValue = Object.fromEntries(
            Object.entries(value).filter(([, v]) => v != null && v !== '')
        );
        await SendNotification(filteredValue);
        toast.success(successMessage.value, { autoClose: 3000 });
    } catch (error) {
        // toast.error(errorMessage.value, { autoClose: 3000 });
        console.error('Error submitting notification:', error);
    } finally {
        loading.value = false;
    }
});

const selectedEmployee = computed(() => {
    const user = employeesData.value.find(employee => employee.user_id === user_id.value);
    if (!user) {
        return {
            user: {
                user_id: '',
                user_role: '',
                user_name: 'All',
                email: 'All'
            }
            

        };
    }

    return user;
});

const statusLabel = computed(() => {
    switch (selectedEmployee.value.status) {
        case 0:
            return t('General.Pending');
        case 1:
            return t('General.Approved');
        case 2:
            return t('General.Blocked');
        default:
            return t('General.Select.All');
    }
});

const fetchEmployees = async () => {
    selectedEmployee
    try {
        const EmployeesResponse = await getAllEmployees();

        employeesData.value = EmployeesResponse.data.data;


    } catch (error) {
        console.error(t('Messages.Error.FetchData'), error);
    }
};
onMounted(fetchEmployees)



</script>

<style scoped>
.form-textarea {
    min-height: 100px !important;
}
</style>