import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useReloadStore = defineStore('reload', () => {
    const reloadKey = ref(0);
    const triggerReload = () => {
        reloadKey.value += 1;
    };
    return { reloadKey, triggerReload };
});
