<template>
  <div class="content-wrapper" v-if="authStore.hasPermission('create-condition')">
    <div class="container-xxl flex-grow-1 container-p-y">
      <div class="pageheader">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item text-secondary"  style="cursor: pointer;">
              
                <div class="text-truncate" @click="goToCondition">{{ $t("Entities.Conditions") }}</div>
            </li>
            <li class="breadcrumb-item active text-dark fw-medium" aria-current="page"> {{  $t('Sections.Conditions.CreateNew') }}</li>
          </ol>
        </nav>
      </div>
      <div class="card p-3">
        <CondictionWizard />

      </div>
    </div>
  </div>
</template>
<script setup>
import CondictionWizard from '@/components/wizard/CondictionWizard.vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
const router = useRouter();
const authStore = useAuthStore();


const goToCondition = () => {
    router.push({ name: 'Conditions' }); // Navigate to the 'order' route
};

</script>

<style scoped></style>