<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['update:modelValue']);

const imageFiles = ref([...props.modelValue]);
const dragOver = ref(false);

// Handle file selection
const handleFileSelect = (event) => {
  const files = event.target.files;
  processFiles(files);
};

// Handle drag and drop
const handleDrop = (event) => {
  event.preventDefault();
  dragOver.value = false;

  const files = event.dataTransfer.files;
  processFiles(files);
};

// Process selected or dropped files
const processFiles = (files) => {
  
  for (const file of files) {
    if (file.type === "image/png" || file.type === "image/jpeg") {
      
      const imageUrl = URL.createObjectURL(file);
      imageFiles.value.push({ file, url: imageUrl });
    }
  }
  emit('update:modelValue', imageFiles.value);
};

// Remove image from the list
const removeImage = (index) => {
  imageFiles.value.splice(index, 1);
  emit('update:modelValue', imageFiles.value);
};

// Watch for changes in props.modelValue to update imageFiles
watch(() => props.modelValue, (newValue) => {
  imageFiles.value = [...newValue];
});
</script>

<template>
  <div class="card mb-6">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5 class="mb-0 card-title">Product Images</h5>
      <!-- <a href="javascript:void(0);" class="fw-medium">Add media from URL</a> -->
    </div>
    <div class="card-body">
      <div class="dropzone needsclick p-0 dz-clickable" id="dropzone-basic" @dragover.prevent="dragOver = true"
        @dragleave="dragOver = false" @drop="handleDrop" :class="{ 'drag-over': dragOver }">
        <input type="file" multiple hidden @change="handleFileSelect" id="fileInput"  accept="image/png, image/jpeg">
        <label for="fileInput" class="dz-message needsclick">
          <p class="h4 needsclick pt-4 mb-2">Drag and drop your images here</p>
          <p class="h6 text-muted d-block fw-normal mb-2">or</p>
          <span class="note needsclick btn btn-sm btn-label-primary">Browse images</span>
        </label>
      </div>

      <!-- Image Preview -->
      <div v-if="imageFiles.length" class="image-preview mt-3 d-flex flex-wrap">
        <div v-for="(image, index) in imageFiles" :key="index" class="preview-container me-2 mb-2">
          <img :src="image.url" alt="Uploaded Image" class="preview-img">

          <button type="button" @click="removeImage(index)" class="btn btn-sm  btn-x"> <i class='bx bx-x'></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dropzone {
  width: 100%;
  height: 200px;
  border: 2px dashed #aaa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}

.dropzone.drag-over {
  background-color: #f0f0f0;
  border-color: #333;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
}

.preview-container {
  position: relative;
  display: inline-block;
}

.preview-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.btn-x{
  position: absolute;
  top: 0;
  color: red;
  border: 3px solid red  ;
  right: 3px;
  z-index: 1000 ;
  width: 100%;
  height: 100%;


}
</style>
