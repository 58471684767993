import axios from 'axios';
import { toast } from 'vue3-toastify';
import i18n from '../i18n.js';

// Create an Axios instance
const axiosClient = axios.create({
  // baseURL: 'https://sub.dqq.app/api/',
  baseURL: process.env.VUE_APP_BASE_URL || 'https://sub.dqq.app/api/',
});

// Common headers
axiosClient.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axiosClient.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axiosClient.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE';
axiosClient.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization, LANG, Content-Type';
axiosClient.defaults.headers.common['Accept'] = 'application/json';
axiosClient.defaults.headers.common['Content-Type'] = 'application/json';

// Add a request interceptor to dynamically set the Authorization header
axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  // Set the 'lang' header dynamically
  const lang = localStorage.getItem('lang') || 'en';
  config.headers.lang = lang;

  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response interceptor for error handling
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { data, status, config } = error.response;
      const generalErrorMessage = i18n.global.t('General.Error');

      if (status === 422) {
        toast.error(i18n.global.t(data.message) || data.error || generalErrorMessage);
      } else if (status === 401) {
        if (config.url === 'auth/login') {
          toast.error(i18n.global.t('Login.Error'));
        } else {
          localStorage.removeItem('access_token');
          window.location.href = '/login';
        }
      } else {
        toast.error(data.message || data.error || generalErrorMessage);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;