<template>
    <div class="offcanvas offcanvas-end w-25" :class="{ show: showServices }" ref="modal" tabindex="-1"
        id="offcanvasRight" aria-labelledby="offcanvasRightLabel" v-if="showServices">
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel" class="text-dark">{{ row.name }} Details</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" @click="closeModel"
                aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
            <div class="d-flex justify-content-center align-items-center">
                <img class="mx-auto" :src="eRow.logo" width="200px" alt="logo image">
            </div>

            <div v-if="service">
                <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('General.ServiceName') }}</span>
                    <!-- <p>{{ service[0]?.name }}</p> -->
                    <input v-model="editableService.name" class="form-control" />

                </div>

                <!-- <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('General.ClientID') }}</span>
                    <p>{{ service[0]?.client_id }}</p>
                </div>

                <div class="mt-5">
                    <span class="text-uppercase text-primary" >{{ $t('General.ServiceID') }}</span>
                    <p>{{ service[0]?.service_id }}</p>
                </div> -->

                <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('General.IntegrationKey') }}</span>
                    <!-- <p class="text-break">{{ service[0]?.integration_key }}</p> -->
                    <input v-model="editableService.integration_key" class="form-control" />
                </div>

                <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('General.IntegrationURL') }}</span>
                    <p class="text-break">
                        <input v-model="editableService.integration_url" class="form-control" />
                    </p>
                </div>

                <div class="mt-5 form-check form-switch">
                    <input class="form-check-input form-check-input-lg " type="checkbox" id="asDefaultSwitch"
                        v-model="editableService.as_default">
                    <label class="form-check-label  text-uppercase text-primary" for="asDefaultSwitch">{{
                        $t('General.Default') }}</label>
                </div>

                <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('General.CreatedAt') }}</span>
                    <p>{{ formatDate(service[0]?.created_at) }}</p>
                </div>

                <div class="mt-5">
                    <span class="text-uppercase text-primary">{{ $t('General.UpdatedAt') }}</span>
                    <p>{{ formatDate(service[0]?.updated_at) }}</p>
                </div>

                <div @click="updateService" class="mt-5 d-flex justify-content-center gap-3 align-items-center">
                    <button type="button" class="btn blue-btn w-75 h-25">
                        <i class='bx bxs-edit-alt'></i> {{ $t('General.Edit') }}
                    </button>

                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-backdrop fade show" v-if="showServices"></div>
</template>
<script setup>
import { editDeliveryIntegration } from '@/services/connectServices';
import { defineProps, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { toast } from 'vue3-toastify';


const emit = defineEmits(['close-edit']);
const showServices = ref(true);
const modal = ref(null);
import i18n from '@/i18n.js';

// const loading = ref(false);

const props = defineProps({
    service: Object, // Selected client data
    row: {
        type: Object,
        required: true,
    },
});
const eRow = ref(props.row);

function closeModel() {
    emit('close-edit', false);
}
const formatDate = (date) => {
    return new Date(date).toLocaleString();
};
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};
const editableService = reactive({
    name: props.service[0].name,
    integration_key: props.service[0].integration_key,
    integration_url: props.service[0].integration_url,
    as_default: props.service[0].as_default? true : false,
})

// API Call to Update Service
const updateService = async () => {

    try {
        editableService.as_default ? 1 : 0 ;
        await editDeliveryIntegration(props.service[0].id, editableService);
        toast.success(i18n.global.t('Messages.Success.Edited'), {
            autoClose: 3000
        });

    } catch (error) {
        console.error('Update failed:', error);
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});


</script>
<style scoped>
.offcanvas-body::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.offcanvas-body::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
.offcanvas-body::-webkit-scrollbar-thumb {
    background: #24A259;

    border-radius: 10px;
}

/* Handle on hover */
.offcanvas-body::-webkit-scrollbar-thumb:hover {
    background: #d7d7d7;

}
</style>