import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';

import LoginPage from '@/pages/login/LoginPage.vue';
import RegisterPage from '@/pages/login/RegisterPage.vue';
import UsersIndex from '@/pages/user/users/UsersIndex.vue';
import EmpIndex from '@/pages/user/employees/EmpIndex.vue';
import CustomersIndex from '@/pages/user/customers/CustomersIndex.vue';
import WarehouseIndex from '@/pages/store/warehouse/WarehouseIndex.vue';
import InventoryIndex from '@/pages/store/inventory/InventoryIndex.vue';
import ProductIndex from '@/pages/store/product/ProductIndex.vue';
import PlansIndex from '@/pages/subscription/plan/PlansIndex.vue';
import SubscriptionsIndex from '@/pages/subscription/subscription/SubscriptionsIndex.vue';
import ClientIndex from '@/pages/user/clients/ClientIndex.vue';
import ConditionIndex from '@/pages/setting/condition/ConditionIndex.vue';
import ConditionAdd from '@/pages/setting/condition/ConditionAdd.vue';
import CityIndex from '@/pages/setting/city/CityIndex.vue';
import DeleveryCompanyIndex from '@/pages/setting/deliveryCompany/DeleveryCompanyIndex.vue';
import PaymentMethodIndex from '@/pages/setting/paymentMethod/PaymentMethodIndex.vue';
import WebhookIndex from '@/pages/setting/webhook/WebhookIndex.vue';
import ZidDataIndex from '@/pages/setting/zid/ZidDataIndex.vue';
import FulfilmentIndex from '@/pages/shipping/fulfilment/FulfilmentIndex.vue';
import CountryIndex from '@/pages/setting/country/CountryIndex.vue';
import DqqSetting from '@/pages/setting/dqqSetting/DqqSetting.vue';
import ReOrderIndex from '@/pages/shipping/reorder/ReOrderIndex.vue';
import PackageIndex from '@/pages/shipping/package/PackageIndex.vue';
import ReturnOrderIndex from '@/pages/shipping/returnOrder/ReturnOrderIndex.vue';
import RoleIndex from '@/pages/setting/role/RoleIndex.vue';
import DashboardView from '@/pages/DashboardView.vue';
import OrderIndex from '@/pages/order/OrderIndex.vue';
import ConnectServices from '@/pages/services/ConnectServices.vue';
import OrderView from '@/pages/order/OrderView.vue';
import SendNotification from '@/pages/setting/Notification/SendNotification.vue';
import RoleAdd from '@/pages/setting/role/RoleAdd.vue';
import ProfileView from '@/pages/ProfileView.vue';
import RoleEdit from '@/pages/setting/role/RoleEdit.vue';
import PrintNodeIndex from '@/pages/setting/printNode/PrintNodeIndex.vue';
import ShipmentIndex from '@/pages/shipment/ShipmentIndex.vue';
import ShipmentAdd from '@/pages/shipment/ShipmentAdd.vue';
import ProductAdd from '@/pages/store/product/ProductAdd.vue';
import GProductAdd from '@/pages/store/product/GProductAdd.vue';
import ConditionIndex2 from '@/pages/setting/condition/ConditionIndex2.vue';
import ContactusIndex from '@/pages/contactUs/ContactusIndex.vue';
import OrderAdd from '@/pages/order/OrderAdd.vue';



const users = [
  {
    path: '/users',
    component: UsersIndex,
    name: 'Users',
    meta: { title: 'user', 
      requiredRoles: ['view-users'] 
    },
  },
  {
    path: '/employees',
    component: EmpIndex,
    name: 'Employees',
    meta: { title: 'employees',
       requiredRoles: ['view-employees']
       },

  },
  {
    path: '/clients',
    component: ClientIndex,
    name: 'Clients',
    meta: { title: 'clients', requiredRoles: ['view-clients'] },

  },
  {
    path: '/customers',
    component: CustomersIndex,
    name: 'Customers',
    meta: { title: 'customers', requiredRoles: ['view-customers'] },

  },


];
const stores = [
  {
    path: '/warehouse',
    component: WarehouseIndex,
    name: 'Warehouse',
    meta: { title: 'Warehouse', requiredRoles: ['view-warehouse'] },

  },
  {
    path: '/inventory',
    component: InventoryIndex,
    name: 'Inventory',
    meta: { title: 'Inventory', requiredRoles: ['view-inventory'] },

  },
  {
    path: '/inventory/:id',
    component: InventoryIndex,
    name: 'ProductInventory',
    meta: { title: 'Inventory', requiredRoles: ['view-inventory'] },

  },
  {
    path: '/products',
    component: ProductIndex,
    name: 'Products',
    meta: { title: 'Products', requiredRoles: ['view-products'] },

  },
  {
    path: '/products/add',
    component: ProductAdd,
    name: 'Product-Add',
    meta: { title: 'Products', requiredRoles: ['create-products'] },

  },
  {
    path: '/products/add/Group',
    component: GProductAdd,
    name: 'GProduct-Add',
    meta: { title: 'Products', requiredRoles: ['create-products'] },

  },



];
const subscription = [
  {
    path: '/plans',
    component: PlansIndex,
    name: 'Plans',
    meta: { title: 'Plans', requiredRoles: ['view-plans'] },

  },
  {
    path: '/subscriptions',
    component: SubscriptionsIndex,
    name: 'Subscriptions',
    meta: { title: 'subscriptions', requiredRoles: ['view-subscriptions'] },

  },




];
const settings = [
  {
    path: '/conditions',
    component: ConditionIndex,
    name: 'Conditions',
    meta: { title: 'Conditions',
       requiredRoles: ['view-condition'] 
      },

  },
  {
    path: '/conditions2',
    component: ConditionIndex2,
    name: 'Conditions2',
    meta: { title: 'Conditions',
       requiredRoles: ['view-condition'] 
      },

  },
  {
    path: '/add/conditions',
    component: ConditionAdd,
    name: 'ConditionsAdd',
    meta: { title: 'Conditions Add' ,  requiredRoles:['create-condition'] },

  },
  {
    path: '/print-node',
    component: PrintNodeIndex,
    name: 'PrintNode',
    meta: { title: 'print-node' ,  requiredRoles:['view-printnode'] },

  },
  {
    path: '/countries',
    component: CountryIndex,
    name: 'Countries',
    meta: { title: 'Countries' ,  requiredRoles:['view-countries'] },

  },
  {
    path: '/cities',
    component: CityIndex,
    name: 'Cities',
    meta: { title: 'Cities' ,  requiredRoles:['view-cities'] },

  },
  {
    path: '/delivery-company',
    component: DeleveryCompanyIndex,
    name: 'DeliveryCompany',
    meta: { title: 'Delivery Company',  requiredRoles:['view-deliveryCompanies'] },

  },
  {
    path: '/payment-method',
    component: PaymentMethodIndex,
    name: 'PaymentMethod',
    meta: { title: 'Payment Method' ,  requiredRoles:['view-paymentMethods'] },

  },
  {
    path: '/webhooks',
    component: WebhookIndex,
    name: 'Webhooks',
    meta: { title: 'Webhooks' ,  requiredRoles:['view-webhooks'] },

  },
  {
    path: '/dqq-setting',
    component: DqqSetting,
    name: 'DqqSetting',
    meta: { title: 'Dqq Setting' ,  requiredRoles:['admin-settings' , 'view-order-settings' , 'view-notify-setting'] },

  },
  {
    path: '/send-notification',
    component: SendNotification,
    name: 'SendNotification',
    meta: { title: 'Send Notification' ,  requiredRoles:['view-notification'] },

  },
  {
    path: '/zid-data-info',
    component: ZidDataIndex,
    name: 'ZidDataInfo',
    meta: { title: 'Zid Data Info' ,  requiredRoles:['view-zid-data'] },

  },
  {
    path: '/Roles',
    component: RoleIndex,
    name: 'Roles',
    meta: { title: 'Roles' ,  requiredRoles:['view-roles'] },

  },
  {
    path: '/Roles/add',
    component: RoleAdd,
    name: 'Roles-add',
    meta: { title: 'Role Add' ,  requiredRoles:['view-roles'] },

  },
  {
    path: '/Roles/edit/:id',
    component: RoleEdit,
    name: 'Roles-edit',
    meta: { title: 'Role Edit' ,  requiredRoles:['view-roles'] },

  },
  
  




];
const shipping = [
  {
    path: '/fulfilments',
    component: FulfilmentIndex,
    name: 'fulfilments',
    meta: { title: 'fulfilments' ,requiredRoles:['view-fulfilment'] }
  },
  {
    path: '/ReorderProducts',
    component: ReOrderIndex,
    name: 'reOrder',
    meta: { title: ' Re order Products' ,  requiredRoles:['view-reorder'] }
  },
  {
    path: '/packages',
    component: PackageIndex,
    
    name: 'packages',
    meta: { title: 'packages' ,  requiredRoles:['view-packages'] }
  },
  {
    path: '/return-order',
    component: ReturnOrderIndex,
    
    name: 'returnOrder',
    meta: { title: 'return-order' ,  requiredRoles:['view-returns'] }
  },




];
const profile = [
  {
    path: '/login',
    component: LoginPage,
    
    name: 'Login',
    meta: { title: 'Login' }
  },
  {
    path: '/register',
    component: RegisterPage,
    
    name: 'Register',
    meta: { title: 'Register' }
  },
  {
    path: '/profile',
    component: ProfileView,
    
    name: 'Profile',
    meta: { title: 'Profile' }
  },



];



const routes = [
  ...profile,

  {

    path: '/',
    component: DashboardView,
    name: 'Dashboard',   
     meta: { title: 'Dashboard'},

  },
  ...users,
  ...stores,

  {
    path: '/orders',
    component: OrderIndex,
    
    name: 'Orders',  
    meta: { title: 'Orders'
      , requiredRoles: ['view-orders'] 
    },

  },
  {
    path: '/orders/add',
    component: OrderAdd,
    
    name: 'Orders-add',  
    meta: { title: 'Orders Add'
      , requiredRoles: ['view-orders'] 
    },

  },
  {
    path: '/orders/:id',
    component: OrderView,
    
    name: 'OrderView',  
    meta: { title: 'Orders'
      , requiredRoles: ['view-orders'] 
    },

  },
  ...subscription,
  ...settings,
  ...shipping,
  {
    path: '/connect-services',
    component: ConnectServices,
    
    name: 'Services',  
    meta: { title: 'Connect Services Tab' , requiredRoles:['view-delivery-services' ,'view-store-services']},

  },
  {
    path: '/shipment',
    component: ShipmentIndex,
    
    name: 'Shipment',  
    meta: { title: 'Shipments' , requiredRoles:['view-shipments'] },

  },
  {
    path: '/shipment/add',
    component: ShipmentAdd,
    
    name: 'Shipments-Add',  
    meta: { title: 'Shipment Add' ,  requiredRoles:['add-new-shipments']},

  },

  {

    path: '/contact-requests',
    component: ContactusIndex,
    name: 'ContactRequest',   
     meta: { title: 'Contact Request'},

  },


  {
    path: '/*',
    component: DashboardView,
    
    name: 'NotFound',  
    meta: { title: 'Not found'},

  },



  // Add other routes here
];



const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const isLoggedIn = authStore.isLoggedIn;

  // Check for restricted access (Login and Register pages)
  if ((to.name === 'Login' || to.name === 'Register') && isLoggedIn) {
    // If user is logged in and tries to access Login or Register, redirect to home
    return next('/');
  }

  // If the user is not logged in and tries to access a protected route (not Login/Register)
  if (!isLoggedIn && (to.name !== 'Login' && to.name !== 'Register')) {
    return next('/login');
  }
    // // Check if the route has specific role requirements
    // if (to.meta.requiredRoles) {
    //   console.log(0);
    //   const hasPermission = to.meta.requiredRoles.some(role => authStore.hasPermission(role));
    //   console.log(hasPermission);
    //   if (!hasPermission) {
    //     console.log(2);
        
    //     // Redirect to an "Unauthorized" page or Home if no permission
    //     return next({ name: 'NotFound' });
    //   }
    // }
  
    // Allow navigation to the target route
    next();
  });



export default router;