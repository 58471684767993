<!-- src/components/DeleteModal.vue -->
<template>
  <div>
    <!-- Modal Structure -->
    <div class="modal fade d-flex align-items-center justify-content-center" :class="{ show: isModalVisible }"
      tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" style="display: block" v-if="isModalVisible"
      >
      <div class="modal-dialog modal-lg modal-simple modal-status">
        <div class="modal-content" ref="modal">
          <!-- <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">Confirm Deletion</h5>
              <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
            </div> -->
          <div class="modal-header d-flex flex-column align-items-center text-center p-2">
            <!-- <i class="fa fa-solid fa-person-circle-minus p-4" style="font-size: 100px; color: #EE1D52; "></i> -->
            <img class="shadow-lg delete-icon" src="@/assets/dashboard/img/delete-icon.gif" alt="delete icon">
            <h5 class="title title-modal-margin pt-6 ">{{ title }}</h5>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center modal-margin">
            <button type="button" class="btn custom-delete-btn" @click="deleteItem">{{ $t('General.Delete') }}</button>
            <button type="button" class="btn delete-btn" @click="closeModal">{{ $t('General.Cancel') }}</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const isModalVisible = ref(false); // Controls modal visibility

const props = defineProps({
  onDelete: {
    type: Function,
    required: true
  }, title: {
    type: String,
    defualt: "Are you sure for delete this Item ?"
  }
});


// Open the modal
const openModal = () => {
  isModalVisible.value = true;
};

// Close the modal
const closeModal = () => {
  isModalVisible.value = false;
};

// Handle deletion (Replace with actual logic)
const deleteItem = () => {
  props.onDelete();
  closeModal();
};

// const modal = ref(null);
// // Function to handle click outside the modal
// const handleClickOutside = (event) => {
//   if (modal.value && !modal.value.contains(event.target) ) {
//     closeModal();
//   }
// };

// // Set up event listener when component is mounted
// onMounted(() => {
//     setTimeout(() => {
//         window.addEventListener('click', handleClickOutside);

//     }, 500);
// });

// // Clean up the event listener when component is unmounted
// onBeforeUnmount(() => {
//   window.removeEventListener('click', handleClickOutside);
// });


// Expose `openModal` method to be used in parent
defineExpose({
  openModal,
});
</script>


<style scoped>
.title {
  font-family: Public Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 40.8px;
  text-align: center;
  color: #EE1D52;
}

.title-modal-margin {
  margin-right: 150px;
  margin-left: 150px;
}

.modal-margin {
  margin-right: 5rem;
  margin-left: 5rem;
}

.custom-delete-btn {
  width: 60%;
  height: 66px;
  margin-right: 1rem;
  background-color: #EE1D52 !important;
  color: #FFFFFF;
  font-family: Public Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 26.01px;
  text-align: center;
  border-radius: 16px;

}

.delete-btn {
  width: 30%;
  height: 66px;

  font-family: Public Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.19px;
  text-align: center;
  border-radius: 16px;

}

.delete-icon {
  width: 100px;
  height: 100px;
  border-radius: 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
</style>