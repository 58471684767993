import axiosClient from "./axiosClient"

export function getWarehouses(page = 1 , searchQuery = '' ) {
  return axiosClient({
                   url   : `v1/dashboard/warehouse?page=${page}&per_page=10&search=${searchQuery}`,
                 })
}
export function SelectWarehouses() {
  return axiosClient({
                   url   : `v1/dashboard/warehouse`,
                 })
}
export function editWarehouse(data , id) {
    return axiosClient({
                     url   : `v1/dashboard/warehouse/update/${id}`,
                     method: 'POST',
                     data
                   })
  }
export function deleteWarehouse( id) {
    return axiosClient({
                     url   : `v1/dashboard/warehouse/delete/${id}`,
                     method: 'delete',
                     
                   })
  }
  export function addWarehouse(data) {
    return axiosClient({
                     url   : `v1/dashboard/warehouse/store`,
                     method: 'post',
                     data
                   })
  }
  export function getWarehousesCities(id) {
    return axiosClient({
                     url   : `v1/dashboard/warehouse/cities/${id}`
                   })
  }
  export function assignWarehousesCities(data) {
    return axiosClient({
                     url   : `v1/dashboard/warehouse/assign-cities`,
                     method: 'POST',
                     data
                   })
  }