<template>
    <div class="modal fade" :class="{ 'show': showAdd }" id="addEmployee" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Employees.AddNew') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <!-- Name -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="name">{{ $t('General.Name') }}</label>
                            <input :class="{ 'border-danger': errors.name }" type="text" id="name" v-model="name"
                                class="form-control" :placeholder="$t('General.Enter') + ' ' + $t('General.Name')">
                            <span class="text-danger">{{ errors.name }}</span>
                        </div>

                        <!-- Username -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="user_name">{{ $t('Auth.Username') }}</label>
                            <input :class="{ 'border-danger': errors.user_name }" type="text" id="user_name"
                                v-model="user_name" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('Auth.Username')">
                            <span class="text-danger">{{ errors.user_name }}</span>
                        </div>

                        <!-- Email -->
                        <div class="col-12">
                            <label class="form-label" for="email">{{ $t('General.Email') }}</label>
                            <input :class="{ 'border-danger': errors.email }" type="email" id="email" v-model="email"
                                class="form-control" :placeholder="$t('General.Enter') + ' ' + $t('General.Email')">
                            <span class="text-danger">{{ errors.email }}</span>
                        </div>
                        <!-- Password -->

                        <div class="col-12 mt-5">
                            <div class="d-flex justify-content-between align-items-center ">
                                <label class="mb-1  fw-medium  text-capitalize" for="password">{{ $t('Auth.Password')
                                    }}<span class="required text-danger fw-bold ">*</span></label>
                                <span @click="togglePasswordVisibility" class=" text-capitalize text-primary "> {{
                                    !showPassword ? $t('General.Show' ): $t('General.Hide') }}

                                    <button type="button" class="border-none text-primary outline-none bg-transparent">
                                        <i :class="showPassword ? 'bx bx-hide' : 'bx bx-show'"></i>
                                    </button>

                                </span>
                            </div>
                            <input :type="showPassword ? 'text' : 'password'" v-model="password"
                                :class="{ 'border-danger': errors.password }" class="form-control" id="password"
                                autocomplete="current-password">
                            <div v-if="errors.password" class="text-danger">{{ errors.password }}</div>
                        </div>

                        <!-- Role Selection -->
                        <div class="col-12">
                            <label class="form-label" for="selectRole">{{ $t('General.Select.Roles') }}</label>
                            <select :class="{ 'border-danger': errors.role_id }" id="selectRole" v-model="role_id"
                                class="form-select form-custom-select-control select-w">
                                <option value="" disabled>{{ $t('General.Select.Roles') }}</option>
                                <option v-for="role in rolesData" :key="role.id" :value="role.id">{{ role.alise_name }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.role_id }}</span>
                        </div>

                        <!-- Client Selection -->
                        <div class="col-12" v-if="authStore.hasPermission('view-clients')">
                            <label class="form-label" for="selectClient">{{ $t('General.Client') }}</label>
                            <select :class="{ 'border-danger': errors.client_id }" id="selectClient" v-model="client_id"
                                class="form-select form-custom-select-control select-w">
                                <option value="" disabled>{{ $t('General.Client') }}</option>
                                <option v-for="client in clientsData" :key="client.id" :value="client.id">{{ client.name
                                    }}</option>
                            </select>
                            <span class="text-danger">{{ errors.client_id }}</span>
                        </div>

                        <!-- Warehouse Selection -->
                        <div class="col-12">
                            <label class="form-label" for="selectWarehouse">{{ $t('General.Select.Warehouse') }}</label>
                            <select :class="{ 'border-danger': errors.warehouse_id }" id="selectWarehouse"
                                v-model="warehouse_id" class="form-select form-custom-select-control select-w">
                                <option value="">{{ $t('General.AllWarehouse') }}</option>
                                <option v-for="warehouse in warehousesData" :key="warehouse.id" :value="warehouse.id">{{
                                    warehouse.name }}</option>
                            </select>
                            <span class="text-danger">{{ errors.warehouse_id }}</span>
                        </div>

                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn btn-primary me-3 login-button" :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>

                            <button type="button" class="btn btn-label-secondary" @click="closeModel"
                                aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import { addEmployee } from '@/services/employee';
import { SelectClients } from '@/services/client';
// import { SelectRoles } from '@/services/role';
import { SelectWarehouses } from '@/services/warehouse';
import { useAuthStore } from '@/stores/auth';
import { useI18n } from 'vue-i18n';
import { SelectRoles } from '@/services/role';

const emit = defineEmits(['close-add']);
const props = defineProps({
    // showAdd: { type: Boolean, default: true },
    onAdd: { type: Function, required: true }
});
const authStore = useAuthStore();

const { t } = useI18n();

const showAdd = ref(true);
const clientsData = ref([]);
const rolesData = ref([]);
const warehousesData = ref([]);
const loading = ref(false);
const showPassword = ref(false);

const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
};


const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        name: yup.string().required(t('Validation.Required.Name')),
        user_name: yup.string().required(t('Validation.Required.Username')),
        role_id: yup.string().required(t('Validation.Required.Roles')),
        email: yup.string().email(t('Validation.Invalid.Email')).required(t('Validation.Required.Email')),
        password: yup
            .string()
            .min(8, t('Validation.Password.MinLength8'))
            .required(t('Validation.Required.Password')),
        client_id: yup
            .string()
            .when([], {
                is: () => authStore.hasPermission('view-clients'),
                then: (schema) => schema.required(t('Validation.IsRequired')),
                otherwise: (schema) => schema.nullable(),
            }),
        warehouse_id: yup.string().nullable()
    })
});

const { value: name } = useField('name');
const { value: user_name } = useField('user_name');
const { value: email } = useField('email');
const { value: role_id } = useField('role_id');
const { value: client_id } = useField('client_id');
const { value: warehouse_id } = useField('warehouse_id');
warehouse_id.value = "";
const { value: password } = useField('password');

const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        if(values.warehouse_id == ""){
            values.warehouse_id = null
        }
        await addEmployee(values);
        emit('close-add', false);
        toast.success(t('Messages.Success.Created'), { autoClose: 3000 });

        props.onAdd();

    } catch (e) {
        loading.value = false;
        console.error(t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-add', false);
}

const fetchData = async () => {
    try {
        loading.value = true;
        const [
            clientsResponse,
            rolesResponse,
            warehousesResponse] = await Promise.all([
                SelectClients(),
                SelectRoles(),
                SelectWarehouses()]
            );
        clientsData.value = clientsResponse.data.data;
        rolesData.value = rolesResponse.data.data;
        warehousesData.value = warehousesResponse.data.data;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(t('fetching.data.Error'), error);
    }
};

onMounted(fetchData);

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});

</script>

<style scoped>
.select-w {
    background-size: 7% !important;
}
</style>