<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.Shipments') }}</h3>
            <DataTable v-model:rows="RolesData" :headers="headers" :fields="fields" :total="total" :loading="loading"
                @page-changed="onPageChanged">
                <template v-slot:head-contant="{}">

                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <i class='bx bxs-ship color-green'></i>
                            <h3 class="table-title">{{ $t('Sections.Shipments.List') }}</h3>

                        </div>
                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31"
                                v-model.lazy="searchQuery">
                        </div>
                    </div>
                    <div class="card-header flex-column flex-md-row pb-0">

                        <div class="dt-action-buttons text-end pt-6 pt-md-0">
                            <div class="dt-buttons btn-group flex-wrap">

                                <router-link :to="{ name: 'Shipments-Add' }">
                                    <button class="btn btn-secondary create-new btn-primary" tabindex="0"
                                        aria-controls="DataTables_Table_0" type="button"><span><i
                                                class="bx bx-plus bx-sm me-sm-2"></i> <span
                                                class="d-none d-sm-inline-block">
                                                {{ $t('Sections.Shipments.AddNew') }}
                                            </span></span>
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>


                </template>
                <template v-slot:action-buttons="{ rowColum }">

                    <td class="text-center align-middle">
                        <a :href="rowColum.awb_file_url"   target="_blank" rel="noopener noreferrer">{{ $t('General.Download') }}</a>

                     
                    </td>
                    <td class=" text-center align-middle">
                        {{ rowColum.created_at }}
                     
                    </td>

                </template>


            </DataTable>


        </div>
    </div>

</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';

import i18n from '@/i18n.js';
import { formatDateToDMY } from '@/utils/dateFormatter';
import { getShipment } from '@/services/shipment';



// State
// Create a reference to the DeleteModal component
const RolesData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const searchQuery = ref('');

// Table headers and fields
const headers = computed(() =>
    [
        '#',
        i18n.global.t('Sections.Clients.Name'),
        i18n.global.t('Entities.Services'),
        i18n.global.t('Sections.Orders.NumberOrder'),
        i18n.global.t('General.Type'),
        i18n.global.t('General.Sawb'),
        i18n.global.t('General.Policy'),
        i18n.global.t('General.CreatedAt'),
    ]);
const fields =
    [
        'ids',
        'client_name',
        'service_name',
        'reference_number',
        'type',
        'sawb',
    ];


// Fetch users with pagination
const fetchData = async () => {

    try {
        loading.value = true;
        RolesData.value = [];

        const response = await getShipment(currentPage.value, searchQuery.value);
        let count = 1;

        for (const row of response.data.data.data) {

            RolesData.value.push({
                ...row,
                ids:   (((currentPage.value??1) -1 ) *10) + count++ , 

                client_name: row.client?.client_name,
                service_name: row.delivery_integration?.name,
                reference_number: row.order?.reference_number,
                created_at: formatDateToDMY(row.created_at),
                updated_at: formatDateToDMY(row.updated_at),
            });

        }
        // RolesData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};

// Fetch users on mount
onMounted(fetchData);
// Watch for changes on searchQuery
watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});
const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};





</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>