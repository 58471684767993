<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="pageheader">
                <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item text-secondary" style="cursor: pointer;">
                            <div class="text-truncate" @click="goToShipments">{{ $t("Entities.Shipments") }}</div>
                        </li>
                        <li class="breadcrumb-item active text-dark fw-medium" aria-current="page">
                            {{ $t('Sections.Shipments.Create') }}
                        </li>
                    </ol>
                </nav>
            </div>
            <div class="row">
                <div class="col-xxl">
                    <div class="card">
                        <div class="card-header pb-6">
                            <h3>
                                <i class="fa-solid fa-truck color-green me-2"></i>
                                {{ $t('Sections.Shipments.Create') }}
                            </h3>
                        </div>
                        <div class="card-body">

                            <form class="row g-6" @submit.prevent="onSubmit">

                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <label class="form-label" for="delivery_integration_id">
                                            {{ $t('Sections.Shipments.delivery_integration_id') }}
                                            <span class="required text-danger fw-bold">*</span>
                                        </label>

                                        <select id="delivery_integration_id" v-model="delivery_integration_id"
                                            :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.delivery_integration_id')"
                                            class="form-select ">
                                            <option value="" disabled>{{
                                                $t('Sections.Shipments.delivery_integration_id') }}
                                            </option>
                                            <option v-for="data in integrationData" :key="data.id" :value="data.id">
                                                {{ data.name }}
                                            </option>
                                        </select>
                                        <span v-if="errors.delivery_integration_id" class="text-danger">{{
                                            errors.delivery_integration_id }}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 ">
                                        <h4 class="text-center">{{ $t('Entities.Orders') }}</h4>
                                        <div class="row mb-4">
                                            <div class="  align-middle">
                                                <div class="input-group input-group-merge border border-primary">
                                                    <span class="input-group-text" id="basic-addon-search31"><i
                                                            class="bx bx-search"></i></span>
                                                    <input id="order_id" type="text" class="form-control border-none"
                                                        v-model="order_id" @input="debouncedOrderSearch"
                                                        :placeholder="$t('General.Search') + ' ' + $t('Sections.Shipments.order_id')"
                                                        :aria-label="$t('General.Search') + ' ' + $t('Sections.Shipments.order_id')"
                                                        aria-describedby="basic-addon-search31">
                                                    <span v-if="errors.order_id" class="text-danger">
                                                        {{ errors.order_id }}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row p-3">

                                            <div class="col-12  mb-3 ">
                                                <label class="form-label" for="OrderNumber">
                                                    {{ $t('Sections.Shipments.OrderNumber') }}
                                                    <span class="required text-danger fw-bold">*</span>
                                                </label>
                                                <input type="text" id="OrderNumber" v-model="OrderNumber"
                                                    class="form-control"
                                                    :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.OrderNumber')" />
                                                <span v-if="errors.OrderNumber" class="text-danger">{{
                                                    errors.OrderNumber }}</span>
                                            </div>

                                            <div class="col-12  mb-3 ">
                                                <label class="form-label" for="OrderContactName">
                                                    {{ $t('Sections.Shipments.OrderContactName') }}
                                                    <span class="required text-danger fw-bold">*</span>
                                                </label>
                                                <input type="text" id="OrderContactName" v-model="OrderContactName"
                                                    class="form-control"
                                                    :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.OrderContactName')" />
                                                <span v-if="errors.OrderContactName" class="text-danger">{{
                                                    errors.OrderContactName
                                                }}</span>
                                            </div>

                                            <div class="col-12  mb-3 ">
                                                <label class="form-label" for="orderContactNumber">
                                                    {{ $t('Sections.Shipments.orderContactNumber') }}
                                                    <span class="required text-danger fw-bold">*</span>
                                                </label>
                                                <input type="text" id="orderContactNumber" v-model="orderContactNumber"
                                                    class="form-control"
                                                    :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.orderContactNumber')" />
                                                <span v-if="errors.orderContactNumber" class="text-danger">{{
                                                    errors.orderContactNumber }}</span>
                                            </div>
                                            <!-- warehouse_id  -->
                                            <div class="col-12 mb-3" v-if="showWarehouse">
                                                <label class="form-label" for="selectWarehouse">
                                                    <img src="@/assets/dashboard/svg/figma/payment.svg" alt="payment">
                                                    {{ $t('General.Select.Warehouse') }}</label>
                                                <select id="selectWarehouse" v-model="warehouse_id"
                                                    class="form-select ">
                                                    <option value="" disabled>{{ $t('General.Select.Warehouse') }}
                                                    </option>
                                                    <option v-for="warehouse in warehousesData" :key="warehouse.id"
                                                        :value="warehouse.id">{{
                                                            warehouse.name }}</option>
                                                </select>
                                                <span class="text-danger">{{ errors.warehouse_id }}</span>
                                            </div>
                                            <div class="col-12  mb-3 ">
                                                <label class="form-label" for="ShippingAddress">
                                                    {{ $t('Sections.Shipments.ShippingAddress') }}
                                                    <span class="required text-danger fw-bold">*</span>
                                                </label>
                                                <input type="text" id="ShippingAddress" v-model="ShippingAddress"
                                                    class="form-control"
                                                    :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.ShippingAddress')" />
                                                <span v-if="errors.ShippingAddress" class="text-danger">{{
                                                    errors.ShippingAddress
                                                }}</span>
                                            </div>




                                            <div class="col-12  mb-3 ">
                                                <label class="form-label" for="description">
                                                    {{ $t('Sections.Shipments.description') }}
                                                    <span class="required text-danger fw-bold">*</span>
                                                </label>
                                                <input type="text" id="description" v-model="description"
                                                    class="form-control"
                                                    :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.description')" />
                                                <span v-if="errors.description" class="text-danger">{{
                                                    errors.description }}</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="col-6 ">
                                        <h4 class="text-center">{{ $t('Entities.Customers') }}</h4>
                                        <div class="row mb-4">
                                            <div class="align-middle">
                                                <div class="input-group input-group-merge border border-primary">
                                                    <span class="input-group-text" id="basic-addon-search31"><i
                                                            class="bx bx-search"></i></span>
                                                    <input type="text" class="form-control border-none" id="customer_id"
                                                        v-model="customer_id" @input="debouncedCustomerSearch"
                                                        :aria-label="$t('General.Search') + ' ' + $t('Sections.Shipments.customer_id')"
                                                        :placeholder="$t('General.Search') + ' ' + $t('Sections.Shipments.customer_id')"
                                                        aria-describedby="basic-addon-search31">
                                                    <span v-if="errors.customer_id" class="text-danger">{{
                                                        errors.customer_id }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row p-3">

                                            <div class="col-12 mb-3">
                                                <label class="form-label" for="customer_email">
                                                    {{ $t('Sections.Shipments.customer_email') }}
                                                    <span class="required text-danger fw-bold">*</span>
                                                </label>

                                                <div class="input-group input-group-merge border border-primary">
                                                    <input type="email" class="form-control border-none"
                                                        id="customer_email" v-model="customer_email"
                                                        :aria-label="$t('General.Enter') + ' ' + $t('Sections.Shipments.customer_email')"
                                                        :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.customer_email')"
                                                        aria-describedby="basic-addon-search31">
                                                    <span v-if="errors.customer_id" class="text-danger">{{
                                                        errors.customer_id }}</span>
                                                </div>
                                            </div>



                                            <div class="col-12 mb-3">
                                                <label class="form-label" for="ContactName">
                                                    {{ $t('Sections.Shipments.ContactName') }}
                                                    <span class="required text-danger fw-bold">*</span>
                                                </label>
                                                <input type="text" id="ContactName" v-model="ContactName"
                                                    class="form-control"
                                                    :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.ContactName')" />
                                                <span v-if="errors.ContactName" class="text-danger">{{
                                                    errors.ContactName }}</span>
                                            </div>

                                            <div class="col-12 mb-3">
                                                <label class="form-label" for="ContactPhoneNumber">
                                                    {{ $t('Sections.Shipments.ContactPhoneNumber') }}
                                                    <span class="required text-danger fw-bold">*</span>
                                                </label>
                                                <input type="text" id="ContactPhoneNumber" v-model="ContactPhoneNumber"
                                                    class="form-control"
                                                    :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.ContactPhoneNumber')" />
                                                <span v-if="errors.ContactPhoneNumber" class="text-danger">{{
                                                    errors.ContactPhoneNumber }}</span>
                                            </div>

                                            <div class="col-12 mb-3">
                                                <label class="form-label" for="ContactPhoneNumber2">
                                                    {{ $t('Sections.Shipments.ContactPhoneNumber2') }}
                                                    <span class="required text-danger fw-bold">*</span>
                                                </label>
                                                <input type="text" id="ContactPhoneNumber2"
                                                    v-model="ContactPhoneNumber2" class="form-control"
                                                    :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.ContactPhoneNumber2')" />
                                                <span v-if="errors.ContactPhoneNumber2" class="text-danger">{{
                                                    errors.ContactPhoneNumber2 }}</span>
                                            </div>

                                            <div class="col-12 mb-3">
                                                <label class="form-label" for="AddressLine1">
                                                    {{ $t('Sections.Shipments.AddressLine1') }}
                                                    <span class="required text-danger fw-bold">*</span>
                                                </label>
                                                <input type="text" id="AddressLine1" v-model="AddressLine1"
                                                    class="form-control"
                                                    :placeholder="$t('General.Enter') + ' ' + $t('Sections.Shipments.AddressLine1')" />
                                                <span v-if="errors.AddressLine1" class="text-danger">{{
                                                    errors.AddressLine1
                                                }}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <hr>
                                <div class="row">
                                    <div class="col-md p-6 d-flex justify-content-around">
                                        <label class="form-label" for="shipment_type">
                                            {{ $t('Sections.Shipments.shipment_type') }}
                                            <span class="required text-danger fw-bold">*</span>
                                        </label>
                                        <div>
                                            <div class="form-check form-check-inline mx-2">
                                                <input class="form-check-input" type="radio" id="shipment_send"
                                                    value="1" v-model="shipment_type" />
                                                <label class="form-check-label" for="shipment_send">
                                                    {{ $t('Sections.Shipments.send') }}
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" id="shipment_received"
                                                    value="2" v-model="shipment_type" />
                                                <label class="form-check-label" for="shipment_received">
                                                    {{ $t('Sections.Shipments.recived') }}
                                                </label>
                                            </div>
                                        </div>
                                        <span v-if="errors.shipment_type" class="text-danger">
                                            {{ errors.shipment_type }}
                                        </span>
                                    </div>

                                </div>
                                <div class="col-12 text-center el-form-item__content">
                                    <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                        :disabled="loading">
                                        <span v-if="loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                        </span>
                                        <span v-else>{{ $t('General.Save') }}</span>
                                    </button>

                                    <button type="button" class="btn cancel-btn bg-green text-primary px-5"
                                        @click="closeModel" aria-label="Close">
                                        {{ $t('General.Cancel') }}
                                    </button>
                                </div>
                            </form>

                            <div v-if="showModal" class="custom-modal-overlay">
                                <div class="custom-modal">
                                    <p class="custom-modal-text">{{ $t('Messages.CompleteDataWarning') }}</p>
                                    <button @click="goToCompleteData" class="text-capitalize btn rounded-md bg-primary text-white">
                                        {{ $t('General.GoToCompleteData') }}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { editShipment, getDeliveryIntegrations, searchByClient, searchByOrder, warehousesByClientId } from '@/services/shipment';
import { debounce } from '@/utils/debounce';

const { t } = useI18n();
const router = useRouter();
const loading = ref(false);
const showWarehouse = ref(false);



// Define form schema
const schema = computed(() =>
    yup.object({
        delivery_integration_id: yup.string().required(t('Validation.IsRequired')),
        // customer_id: yup.string().required(t('Validation.IsRequired')),
        ContactName: yup.string().required(t('Validation.IsRequired')),
        ContactPhoneNumber: yup.string().required(t('Validation.IsRequired')),
        ContactPhoneNumber2: yup.string().notRequired(),
        AddressLine1: yup.string().required(t('Validation.IsRequired')),
        // order_id: yup.string().required(t('Validation.IsRequired')),
        OrderNumber: yup.string().required(t('Validation.IsRequired')),
        OrderContactName: yup.string().required(t('Validation.IsRequired')),
        orderContactNumber: yup.string().required(t('Validation.IsRequired')),
        ShippingAddress: yup.string().required(t('Validation.IsRequired')),
        shipment_type: yup.string().required(t('Validation.IsRequired')),
        description: yup.string().notRequired(),
        customer_email: yup.string().email(t('Validation.Invalid.Email')).notRequired(),

    })
);

// Initialize form
const { handleSubmit, errors } = useForm({ validationSchema: schema });

// Define and destructure form fields
const { value: delivery_integration_id } = useField('delivery_integration_id');
const { value: customer_id } = useField('customer_id');
const { value: ContactName } = useField('ContactName');
const { value: ContactPhoneNumber } = useField('ContactPhoneNumber');
const { value: ContactPhoneNumber2 } = useField('ContactPhoneNumber2');
const { value: AddressLine1 } = useField('AddressLine1');
const { value: order_id } = useField('order_id');
const { value: OrderNumber } = useField('OrderNumber');
const { value: OrderContactName } = useField('OrderContactName');
const { value: orderContactNumber } = useField('orderContactNumber');
const { value: ShippingAddress } = useField('ShippingAddress');
const { value: shipment_type } = useField('shipment_type');
const { value: description } = useField('description');
const { value: customer_email } = useField('customer_email');
const { value: warehouse_id } = useField('warehouse_id');



const goToShipments = () => {
    router.push({ name: 'Shipment' });
};
const goToCompleteData = () => {
    router.push({ name: 'Services' }); // Replace with actual route name
};

const warehousesData = ref([]);
const integrationData = ref([]);

// Form submission
const onSubmit = handleSubmit(async (value) => {
    try {
        loading.value = true;



        await editShipment(value);
        toast.success(t('Messages.Success.Created'), { autoClose: 3000 });
        router.push({ name: 'Shipments' });
    } catch (error) {
        console.error(t('Messages.Error.SubmitError'), error);
    } finally {
        loading.value = false;
    }
});

const searchOrder = async () => {
    if (order_id.value) {
        try {
            const response = await searchByOrder(order_id.value);
            if (response.data.data) {
                showWarehouse.value = true
                customer_id.value = response.data.data.customer_id
                ContactName.value = response.data.data.customer_name
                ContactPhoneNumber.value = response.data.data.customer_phone
                ContactPhoneNumber2.value = response.data.data.customer_phone
                AddressLine1.value = response.data.data.address
                OrderNumber.value = response.data.data.reference_number
                OrderContactName.value = response.data.data.client?.name
                orderContactNumber.value = response.data.data.client?.mobile
                // ShippingAddress.value = response.data.data.ShippingAddress
                shipment_type.value = response.data.data.zid_status
                description.value = response.data.data.customer_notes
                customer_email.value = response.data.data.customer_email
                searchWarehouse(response.data.data.client?.id);


            } else {
                showWarehouse.value = false;
            }
        } catch (error) {
            console.error(t('Messages.Error.SubmitError'), error);
        }
    }
}
const searchCustomer = async () => {
    if (customer_id.value) {

        try {
            const response = await searchByClient(customer_id.value);
            if (response.data.data) {
                ContactName.value = response.data.data.name
                ContactPhoneNumber.value = response.data.data.mobile
                ContactPhoneNumber2.value = response.data.data.mobile
                customer_email.value = response.data.data.email
            }

        } catch (error) {
            toast.error(t('Messages.Error.SearchError'), { autoClose: 3000 });
        }
    }
};

const searchWarehouse = async (value) => {
    try {
        const response = await warehousesByClientId(value);
        warehousesData.value = response.data.data

    } catch (error) {
        console.error(t('Messages.Error.SubmitError'), error);
    }
};


const warehouseAdressFill = (warehouse) => {
    ShippingAddress.value = warehouse.address_1;
};
const showModal = ref(false);
const getIntegrations = async (value) => {
    try {
        const response = await getDeliveryIntegrations(value);
        integrationData.value = response.data.data
        // If no data, force show the modal
        if (!integrationData.value.length) {
            showModal.value = true;
        }

    } catch (error) {
        console.error(t('Messages.Error.SubmitError'), error);
    }
};
watch(order_id, (newVal) => {
    if (!newVal) {
        customer_id.value = '';
        ContactName.value = '';
        ContactPhoneNumber.value = '';
        ContactPhoneNumber2.value = '';
        AddressLine1.value = '';
        OrderNumber.value = '';
        OrderContactName.value = '';
        orderContactNumber.value = '';
        ShippingAddress.value = '';
        shipment_type.value = '';
        description.value = '';
        customer_email.value = '';
        showWarehouse.value = false;

    }
});
watch(customer_id, (newVal) => {
    if (!newVal) {
        ContactName.value = '';
        ContactPhoneNumber.value = '';
        ContactPhoneNumber2.value = '';
        customer_email.value = '';
    }
});
watch(warehouse_id, (newVal) => {
    if (newVal) {
        const selectedWarehouse = warehousesData.value.find(warehouse => warehouse.id === newVal);
        if (selectedWarehouse) {
            warehouseAdressFill(selectedWarehouse);
        }
    }
});

onMounted(() => {
    getIntegrations();
});

const debouncedOrderSearch = debounce(searchOrder, 500);
const debouncedCustomerSearch = debounce(searchCustomer, 500);

</script>

<style scoped>
form {
    margin-bottom: 20px;
}

label {
    margin-right: 10px;
}

button {
    margin-top: 10px;
}

.input-field {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}

/* Buttons */
.primary-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}

.primary-button:hover {
    background-color: var(--primary-hover);
}

/* Custom Modal */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    /* Ensure it appears on top */
}

.custom-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
    max-width: 500px;
    width: 100%;
    text-align: center;
    z-index: 10001;
    /* Ensure it appears on top */
}

.custom-modal-text {
    font-size: 18px;
    color: var(--modal-text);
    margin-bottom: 15px;
}

.custom-primary-button:hover {
    background-color: var(--primary-hover);
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}
</style>
