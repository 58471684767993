import { buildUrl } from "@/utils/buildUrl"
import axiosClient from "./axiosClient"

// Country
export function getcountries() {
  return axiosClient({
    url: `v1/dashboard/countries`,
  })
}
export function getAllCountries(page = 1, searchQuery = '') {
  return axiosClient({
    url: `v1/dashboard/countries?page=${page}&per_page=10&search=${searchQuery}`,
  })
}

export function deleteCountries(id) {
  return axiosClient({
    url: `v1/dashboard/country/delete/${id}`,
    method: 'DELETE',
  })
}
export function editCountries(data, id) {
  return axiosClient({
    url: `v1/dashboard/country/update/${id}`,
    method: 'post',
    data
  })
}

// City


export function getCities(id) {
  return axiosClient({
    url: `v1/dashboard/city-by-country-id?country_id=${id}`,
  })
}
export function getAllCities(page = 1, searchQuery = '') {
  return axiosClient({
    url: `v1/dashboard/city?page=${page}&per_page=10&search=${searchQuery}`,
  })
}
export function getOrdersCities() {
  return axiosClient({
    url: `v1/dashboard/city`,
  })
}
export function deleteCities(id) {
  return axiosClient({
    url: `v1/dashboard/city/delete/${id}`,
    method: 'DELETE',
  })
}

export function editCities(data, id) {
  return axiosClient({
    url: `v1/dashboard/city/update/${id}`,
    method: 'post',
    data
  })
}
// Delevery

export function getDeleveryCompany(page = 1, searchQuery = '') {
  return axiosClient({
    url: `v1/dashboard/delivery-company?page=${page}&per_page=10&search=${searchQuery}`,
  })
}
export function getAllDeleveryCompany() {
  return axiosClient({
    url: `v1/dashboard/delivery-company`,
  })
}


// Payment Metod


export function getPaymentMethod(page = 1, searchQuery = '') {
  return axiosClient({
    url: `v1/dashboard/payment-method?page=${page}&per_page=10&search=${searchQuery}`,
  })
}
export function getAllPaymentMetod() {
  return axiosClient({
    url: `v1/dashboard/payment-method`,
  })
}
export function deletePaymentMetod(id) {
  return axiosClient({
    url: `v1/dashboard/payment-method/${id}`,
    method: 'DELETE',
  })
}
export function editPaymentMetod(data, id) {
  return axiosClient({
    url: `v1/dashboard/payment-method/update/${id}`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}


// Zid

export function getAllZidStatus() {
  return axiosClient({
    url: `v1/dashboard/zid-status`,
  })
}
export function getZidData(page = 1, searchQuery = null, clientId = null) {
  const url = buildUrl("v1/dashboard/zid-data", {
    page,
    per_page: 10,
    all: searchQuery,
    client_id: clientId,
  });

  return axiosClient({ url });
}
export function syncZid(data) {
  return axiosClient({
    url: `v1/dashboard/zid-data/sync`,
    method: "POST",
    data
  })
}


// WebHook

export function getWebhooks(page = 1, searchQuery = '') {
  return axiosClient({
    url: `v1/dashboard/weebhook?page=${page}&per_page=10&search=${searchQuery}`,
  })
}


// Setting


export function getDqqSetting() {
  return axiosClient({
    url: `v1/dashboard/dqq-settings`,
  })
}
export function updateDqqSetting(data) {
  return axiosClient({
    url: `v1/dashboard/dqq/setting/update`,
    method: "PUT",
    data
  })
}
export function updateDqqSettingSync(data) {
  return axiosClient({
    url: `v1/dashboard/dqq-settings/sync/order/status`,
    method: "PUT",
    data
  })
}
export function updateStaticSetting(data) {
  return axiosClient({
    url: `v1/dashboard/store/notification/setting`,
    method: "PUT",
    data
  })
}
export function updateNotificationSetting(data) {
  return axiosClient({
    url: `v1/dashboard/store/notification/setting`,
    method: "post",
    data
  })
}
export function updateOrderSetting(data) {
  return axiosClient({
    url: `v1/dashboard/store/order/setting`,
    method: "PUT",
    data
  })
}
export function SendNotification(data) {
  return axiosClient({
    url: `v1/dashboard/store/send-notification/setting`,
    method: 'post',
    data,
  })
}

export function getStatus() {
  return axiosClient({
    url: `v1/dashboard/dqq-status`,
  })
}