<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <section>
                <nav aria-label="breadcrumb" class="mt-5">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item text-secondary">

                            <div class="text-truncate" style="cursor: pointer;" @click="goToOrder"> {{
                                $t('Entities.Orders') }}</div>

                        </li>
                        <li class="breadcrumb-item active text-dark fw-medium" aria-current="page">{{
                            $t('Entities.ViewOrder') }}</li>
                    </ol>
                </nav>

                <div class="d-flex mx-5 order-details-header align-items-center gap-3 position-relative">
                    <h2 class="title fw-medium fs-4 ms-2">Order {{ orderItemData.reference_number }}</h2>
                    <p class="date">{{ orderItemData.created_at_actually }}</p>
                    <p class=" p-2 d-flex align-items-center rounded text-white "
                        :style="{ backgroundColor: statusDqq?.bg_color || '#ccc' }">{{ statusDqq?.name
                        }}</p>
                    <button class="btn text-white bg-primary position-absolute end-0 bottom-50" @click="goToOrder">
                        {{ $t('Entities.CloseOrder') }}</button>
                </div>

                <div class="row mx-5">
                    <div class="col-lg-9 col-md-8 col-sm-12">
                        <div class="order-details bg-white py-5">
                            <span class="text-uppercase text-primary px-5"> {{ $t('Entities.OrderDetails') }}</span>
                            <hr />
                            <div class="d-flex flex-col align-items-center  justify-content-between px-5">
                                <span class="text-uppercase text-primary px-5">{{ $t('Entities.OrderNumber') }} :
                                    <span class="text-dark fs-6 fw-light">
                                        {{ orderItemData.reference_number }}
                                    </span></span>

                                <div class="d-flex align-items-center">
                                    <span class="text-uppercase status-title text-primary px-5">
                                        {{ $t('Entities.orderStatus') }} : </span>
                                    <!-- <div class="dropdown order-dropdown">
                                        <button class="btn dropdown-toggle bg-primary text-white" type="button"
                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            New
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a class="dropdown-item" href="#">New 1</a></li>
                                            <li><a class="dropdown-item" href="#">New 2</a></li>
                                            <li><a class="dropdown-item" href="#">New 3</a></li>
                                        </ul>
                                    </div> -->
                                    <button style="width: 100%;" class="btn text-white "
                                        :style="{ backgroundColor: orderItemData.status_name?.bg_color || '#ccc' }">
                                        {{ orderItemData.status_name?.name }}
                                    </button>
                                </div>
                            </div>

                            <div class="mt-5 px-5">
                                <span class="text-uppercase text-primary px-5"> {{ $t("General.Client") }} :
                                    <span class="text-dark fs-6 fw-light">{{ orderItemData.client?.name }}</span></span>
                            </div>

                            <div
                                class="mt-5 px-5 d-flex align-items-center flex-col justify-content-between position-relative">
                                <span class="text-uppercase text-primary px-5">{{ $t("General.Date") }} :
                                    <span class="text-dark fs-6 fw-light">{{ orderItemData.created_at_actually
                                    }}</span></span>

                                <div class="d-flex align-items-center position-absolute end-0 px-5">
                                    <div class="d-flex align-items-center ms-5 ">
                                        <span class="text-uppercase  status-title text-primary me-5 pe-2">
                                            {{ $t('General.DQQStatus') }} :
                                        </span>

                                        <button style="width: 100%;" class="btn text-white "
                                            :style="{ backgroundColor: orderItemData.status_dqq_name?.bg_color || '#ccc' }">
                                            {{ orderItemData.status_dqq_name?.name }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="order-details bg-white rounded py-5 mt-5">
                            <span class="text-uppercase text-primary px-5"> {{ $t('Sections.Product.Products') }}
                                :</span>
                            <div class="table-responsive text-nowrap">
                                <table class="table mt-3">
                                    <thead>
                                        <tr>
                                            <th class="text-center ">#</th>
                                            <th class="">{{ $t('Entities.Products') }}</th>
                                            <th class="text-center ">{{ $t('General.Price') }}</th>
                                            <th class="text-center ">{{ $t('General.Quantity') }}</th>
                                            <th class="text-center ">{{ $t('General.Total') }}</th>
                                            <th class="text-center "> </th>

                                        </tr>
                                    </thead>
                                    <tbody class="table-border-bottom-0" v-for="products in productListData"
                                        :key="products.id">


                                        <tr v-for="product in products.products" :key="product.id">

                                            <td v-if="product.split_quantity" style="background-color: #24a259">
                                                <div class="checkbox-wrapper text-center bg-green text-white"
                                                    style="background-color: #24A259;">
                                                    <img src="@/assets/dashboard/svg/figma/ProductDone.svg"
                                                        class="product-done" width="20px" alt="">
                                                    {{ $t('General.Done') }}

                                                </div>

                                            </td>
                                            <!-- first coulom  -->
                                            <!-- <td class="action-cell" v-else>
                                                <div class="checkbox-wrapper  text-center">
                                                    <input type="checkbox" class="form-check-input" />
                                                </div>
                                            </td> -->
                                            <td class="action-cell" v-else-if="product.is_group">
                                                <div class="checkbox-wrapper  text-center">
                                                    <input type="checkbox" class="form-check-input"
                                                        v-model="selectedRows[product.product?.id]" />
                                                </div>
                                            </td>
                                            <td v-else-if="!product.is_group" style="background-color: #f5f5f5">
                                                <div class="checkbox-wrapper text-center bg-green"
                                                    style="background-color: #24A259;">

                                                </div>
                                            </td>
                                            <td v-else style="background-color: #f5f5f5">
                                                <div class="checkbox-wrapper  text-center">
                                                    <input type="checkbox" class="form-check-input"
                                                        v-model="selectedRows[product.product?.id]" />
                                                </div>
                                            </td>

                                            <!-- second colum -->
                                            <td class="text-center" v-if="product.is_group">
                                                <div class=" d-flex align-items-center">
                                                    <div class="product-image">
                                                        <img :src="product.images ?? img" alt="Creams pack"
                                                            class="img-fluid rounded-circle" @error="replaceByDefault">
                                                    </div>
                                                    <div class="product-details">
                                                        <h5 class="product-title mb-1">
                                                            {{ product?.name }}</h5>
                                                        <p class="product-code text-muted mb-0">{{
                                                            product.sku
                                                        }}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center " v-if="!product.is_group"
                                                style="padding-left: 40px; ">
                                                <div class="d-flex align-items-center">
                                                    <div class="product-image me-3">
                                                        <img :src="product.images ?? img" alt="Creams pack"
                                                            class="img-fluid rounded-circle" @error="replaceByDefault">
                                                    </div>
                                                    <div class="product-details">
                                                        <h5 class="product-title mb-1 text-center">
                                                            {{ product.product?.name }}</h5>
                                                        <p class="product-code text-muted mb-0">{{
                                                            product.product?.sku
                                                        }}</p>
                                                    </div>
                                                </div>
                                            </td>

                                            <!-- third -->
                                            <td class="text-center text-dark">{{ product.is_group ? product.price :
                                                product.product.price }}
                                            </td>
                                            <!-- four -->
                                            <td class="text-center text-dark">{{ product.is_group ? product.quantity :
                                                product.product?.quantity }}
                                            </td>
                                            <!-- five -->
                                            <td class="text-center text-dark">{{ product.is_group ? product.totalPrice
                                                :
                                                (product.product?.price * product.product?.quantity)
                                            }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p class="text-uppercase text-primary px-5 mt-5 "> {{ $t('General.Bill') }}
                            </p>
                            <hr />

                            <div class="d-flex text-dark align-items-center justify-content-between px-5">
                                <p> {{ $t('General.Subtotal') }} </p>
                                <span>{{ formatNumber(orderItemData.total_cost) }} SAR</span>
                            </div>

                            <div class="d-flex align-items-center justify-content-between px-5">
                                <p class="text-secondary"> {{ $t('General.Coupon') }} </p>
                                <span class="text-dark">{{ orderItemData.discount_coupon == "" ? "00.00" :
                                    orderItemData.discount_coupon }} SAR</span>
                            </div>

                            <!-- <div class="d-flex align-items-center justify-content-between px-5">
                                <p class="text-secondary">Estimated shipping & Handling</p>
                                <span class="text-dark">5,00 SAR</span>
                            </div> -->

                            <hr />

                            <div class="d-flex text-primary align-items-center justify-content-between px-5">
                                <p>{{ $t('General.TotalSpent') }}</p>
                                <span>{{ formatNumber(total_cost) }} SAR</span>
                            </div>

                        </div>

                        <div class="order-details bg-white rounded py-5 mt-5">
                            <span class="text-uppercase text-dark px-5">{{ $t('Sections.Orders.OrderActivity') }}</span>

                            <div class="d-flex align-items-start justify-content-between mt-5 px-5"
                                v-for="item in orderTimeLinemData" :key="item.id">
                                <div class="d-flex align-items-start justify-content-center">

                                    <div class="active-order active" :style="{ Color: item.color }">
                                        <span class="circle" :style="{ backgroundColor: item.color }"></span>
                                    </div>

                                    <div class="mx-2">
                                        <p class="text-dark">{{ item.order_status }}</p>
                                        <span class="text-secondary" v-html="item.comment"></span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-end justify-content-between mt-5 px-5">
                                    <div style=" justify-items: end;">
                                        <p class="text-secondary">{{ formatDateToYMDHM(item.date) }}</p>
                                        <span class="text-secondary">{{ item.by }}</span>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <div class="order-details bg-white rounded py-5">
                            <p class="text-capitalize px-5 text-dark fw-bold">{{
                                $t('Sections.Customers.CustomerDetails') }}
                            </p>
                            <div class="px-5"> <i class='bx bxs-user text-primary'></i> <span
                                    class="text-capitalize fs-6 fw-lighter text-dark">{{ orderItemData.customer_name
                                    }}</span></div>

                            <div class="px-5 mt-3"><i class='bx bxs-phone text-primary'></i> <span
                                    class="text-capitalize fs-6 fw-lighter text-dark">+{{ orderItemData.customer_phone
                                    }}</span></div>

                            <div class="px-5 mt-3"><i class='bx bxs-envelope text-primary'></i> <span
                                    class="text-capitalize fs-6 fw-lighter text-dark">{{ orderItemData.customer_email
                                    }}</span>

                                <hr style="border: 1px solid #24A259;" />
                            </div>

                            <div class="px-5 mt-3 fs-6 ">
                                <span class="text-capitalize text-primary">{{ $t('General.Country') }} :
                                    <span class="text-dark fw-light">{{ orderItemData.country?.name }}</span></span>
                            </div>

                            <div class="px-5 mt-3 fs-6 ">
                                <span class="text-capitalize text-primary">{{ $t('General.City') }} :
                                    <span class="text-dark fw-light">{{ getname(orderItemData.city) }}</span></span>
                            </div>

                            <div class="px-5 mt-3 fs-6 ">
                                <span class="text-capitalize text-primary">{{ $t('General.Street') }} :
                                    <span class="text-dark fw-light">{{ orderItemData.address }}</span></span>
                            </div>
                        </div>

                        <div class="order-details rounded p-5 bg-white mt-5" v-if="orderItemData.delivery?.image_url">
                            <span class="text-uppercase text-dark px-5">{{ $t('Entities.ShippingMethod') }}</span>
                            <!-- <img src="@/assets/dashboard/img/dhl.png" alt=""> -->
                            <img :src="orderItemData.delivery?.image_url" alt="">
                        </div>

                        <div class="order-details rounded p-5 bg-white mt-5">
                            <span class="text-capitalize text-dark px-5">{{ $t('Sections.Orders.ChangeStatus') }}</span>
                            <div class="dropdown order-dropdown mt-5">
                                <button style="width: 100%;" class="btn dropdown-toggle text-white" type="button"
                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                                    :style="{ backgroundColor: status?.bg_color || '#ccc' }">
                                    {{ status?.name }}
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="width: 100%;">
                                    <li v-for="item in ZidData" :key="item.id">
                                        <a class="dropdown-item" href="#" @click.prevent="selectStatus(item)">{{
                                            item?.name }}</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div class="order-details rounded p-5 bg-white mt-5">
                            <!-- v-if="selectedCount > 0" -->


                            <!-- <span class="text-capitalize text-dark p-5">{{ $t('Sections.Orders.Split') }}</span> -->
                            <div class="d-flex align-items-center justify-content-between mt-3 position-relative">
                                <span class="text-capitalize text-dark">{{ $t('Sections.Orders.Split') }}</span>
                                <i class="bx bx-question-mark text-warning" style="cursor: pointer;"
                                    @mouseover="showTooltip = true" @mouseleave="showTooltip = false"></i>
                                <div v-if="showTooltip" class="tooltip-content">
                                    {{ $t('Sections.Orders.SplitDescription') }}
                                </div>
                            </div>

                            <button class="btn btn-outline-warning  w-100 mt-5 py-2" v-if="warehouseIsVisibile"
                                @click.prevent="SplitOrder()">
                                {{ selectedCount ? $t('Sections.Orders.Split') : $t('Sections.Orders.Return') }}
                                <i class='bx bx-undo'></i>
                            </button>
                            <button class="btn btn-outline-warning  w-100 mt-5 py-2" v-else
                                @click.prevent="onWarehouseRow()">
                                {{ selectedCount ? $t('Sections.Orders.Split') : $t('Sections.Orders.Return') }}
                                <i class='bx bx-undo'></i>
                            </button>


                        </div>


                        <button class="btn bg-green text-primary w-100 mt-5 py-2" @click.prevent="fetchData()"> <i
                                class='bx bx-refresh'></i>{{ $t('Sections.Orders.Refresh') }}</button>
                    </div>

                </div>
            </section>
        </div>
        <SelectWarehouse v-if="showWarehouse" @close-status="onCloseWarehouse" v-model="warehouse_id"
            :onWarehoseSubmit="SplitOrder" />
    </div>
</template>
<script setup>
import { useLoadingStore } from '@/stores/loading';
import { onMounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { changeOrderStatus, returnedSplitOrder, showOrder, showOrderTimeLine } from '@/services/order';
import { useRoute, useRouter } from 'vue-router';
import img from "@/assets/dashboard/img/dqqicon.png"
import { getAllZidStatus } from '@/services/setting';
import { onBeforeMount } from 'vue';
import { toast } from 'vue3-toastify';
import { formatDateToYMDHM } from '@/utils/dateFormatter';
import { getname } from '@/utils/translate';
import SelectWarehouse from './componants/SelectWarehouse.vue';

const { t } = useI18n();

const errorMessage = computed(() => t('Messages.Error.FetchData'));
const successMessage = computed(() => t('Messages.Success.Edited'));

const orderItemData = ref({});
const productListData = ref([]);
const orderTimeLinemData = ref([]);
const ZidData = ref([]);
const status = ref({});
const statusDqq = ref({});
const total_cost = ref(0);
const selectedRows = ref([]);
const warehouse_id = ref(null);
const warehouseIsVisibile = ref(false);
const showTooltip = ref(false);




const selectedCount = computed(() => {
    return Object.values(selectedRows.value).filter(selected => selected).length;
});

const loadingStore = useLoadingStore();
const route = useRoute()
const router = useRouter();


const goToOrder = () => {
    router.push({ name: 'Orders' }); // Navigate to the 'order' route
};


const { locale } = useI18n();
watch(locale, () => {
    fetchZidStatus();
    fetchData();
    fetchTimeLine();
});


const fetchZidStatus = async () => {
    try {
        const response = await getAllZidStatus();
        ZidData.value = response.data.data;
    } catch (error) {
        console.error(errorMessage.value, error);
    }
};

const fetchData = async () => {
    loadingStore.setLoading(true);
    try {

        const response = await showOrder(route.params.id);
        orderItemData.value = response.data.data;

        productListData.value = [];
        for (const group of response.data.data.show_products) {

            // Initialize an array to hold the combined products
            const combinedProducts = [];

            if (group.group_details != null) {
                const totalPrice = group.sub_products.reduce((sum, sub) => {
                    const price = parseFloat(sub.unit_price || 0); // Ensure price is a valid number
                    const quantity = parseFloat(sub.product.quantity || 0); // Ensure price is a valid number
                    const total = price * quantity;
                    return sum + total;
                }, 0);
                // split_quantity

                combinedProducts.push({
                    is_group: !!group.group_details,
                    id: group.group_details?.id,
                    name: group.group_details?.name,
                    barcode: group.group_details?.barcode,
                    price: group.group_details?.price,
                    quantity: group.group_details?.quantity,
                    sku: group.group_details?.sku,
                    images: group.group_details?.images?.length > 0 ? group.group_details?.images[0] : null,
                    totalPrice: totalPrice,
                    split_quantity: group.group_details?.split_quantity,


                });
            }


            // Add all sub-products to the combined products array
            if (group.sub_products && group.sub_products.length > 0) {
                for (const sub of group.sub_products) {

                    if (group.group_details == null) {
                        combinedProducts.push({
                            ...sub,
                            is_group: group.group_details == null,
                            name: sub.product?.name,
                            barcode: sub.product?.barcode,
                            price: sub.unit_price,
                            quantity: sub.quantity,
                            sku: sub.product?.sku,
                            images: sub.product?.images?.length > 0 ? sub.product?.images[0] : null,
                            totalPrice: (sub.product?.price * sub.quantity)
                        });

                    } else {
                        combinedProducts.push({
                            ...sub,
                            is_group: group.group_details == null,
                            images: sub.product?.images?.length > 0 ? sub.product?.images[0] : null,
                        });

                    }

                }
            }

            // Push the final structure into productListData
            productListData.value.push({
                products: combinedProducts,
                is_group: !!group.group_details, // Determine if this is a group
            });
        }
        warehouse_id.value = response.data.data.warehouse_id;
        if (warehouse_id.value) {
            warehouseIsVisibile.value = true;
        }
        status.value = response.data.data.status_name;
        statusDqq.value = response.data.data.status_dqq_name;
        total_cost.value = Number(orderItemData.value.total_cost) + Number(orderItemData.value.discount_coupon)
    } catch (error) {
        console.error(errorMessage.value, error);
    } finally {
        loadingStore.setLoading(false);
    }
};




const fetchTimeLine = async () => {
    try {

        const activites = await showOrderTimeLine(route.params.id);
        orderTimeLinemData.value = activites.data.data;
    } catch (error) {
        console.error(errorMessage.value, error);
    } finally {
        loadingStore.setLoading(false);
    }
}
onBeforeMount(fetchZidStatus)
onMounted(fetchData)
onMounted(fetchTimeLine)

const selectStatus = async (item) => {
    const formData = new FormData();
    formData.append('status', statusDqq.value.id);
    formData.append('zid_status', item.id);
    formData.append('ids[]', orderItemData.value.id);
    try {
        loadingStore.setLoading(true);
        await changeOrderStatus(formData);
        toast.success(successMessage.value, { autoClose: 3000 });
        fetchData();
    } catch (error) {
        console.error(errorMessage.value, error);
    } finally {
        loadingStore.setLoading(false);
    }

    if (status.value.id != item?.name) {
        status.value = item
    }
    closeDropdown();
};
const SplitOrder = async (item) => {
    const formData = new FormData();
    Object.keys(selectedRows.value).forEach(id => {
        if (selectedRows.value[id]) { // Check if the value is true
            formData.append('return_products[]', id); // Append each true id
        }// Append each id individually
    });



    formData.append('order_id', route.params.id);

    if (warehouse_id.value) {
        formData.append('warehouse_id', warehouse_id.value);

    }
    try {
        loadingStore.setLoading(true);
        await returnedSplitOrder(formData);
        toast.success(successMessage.value, { autoClose: 3000 });
        fetchData();
    } catch (error) {
        console.error(errorMessage.value, error);
    } finally {
        loadingStore.setLoading(false);
    }

    if (status.value.id != item?.name) {
        status.value = item
    }
    closeDropdown();
};


//status
const showWarehouse = ref(false);
function onWarehouseRow() {
    showWarehouse.value = !showWarehouse.value;


}
function onCloseWarehouse() {
    showWarehouse.value = !showWarehouse.value;

}






const formatNumber = (value) => {
    return Number(value).toFixed(2); // Format to 2 decimal places
};
const closeDropdown = () => {
    const dropdownButton = document.getElementById("dropdownMenuButton");
    dropdownButton?.click(); // Toggles the dropdown off
};

const replaceByDefault = (e) => {
    e.target.src = img
    // You can now perform actions based on the page change, like refetching data.
};

</script>

<style scoped>
.hovered-row td {
    transform: translateX(10px);
    /* Adjust left shift to fit the button */
    transition: transform 0.3s ease-in-out;
    /* Smooth animation */
}

table tr .text-dark {
    color: rgba(34, 48, 62, 1);
    font-size: 18px;
    font-weight: 500;
}

/* Styling for the action button */
.action-cell {
    transition: all 0.3s ease-in-out;
}

/* Hover effect */
.hovered-row {
    background-color: #F9FEFB;
    /* Light green */
    transition: background-color 0.3s ease-in-out;
}

/* Button container on hover */
.hover-button {
    height: 100%;
    width: 100%;
    transition: background-color 0.3s ease-in-out;
}

/* Action cell */
.action-cell {
    width: 100px;
    text-align: center;
    vertical-align: middle;
}



.product-image img {
    width: 40px;
    /* Adjust size based on preference */
    object-fit: cover;
}

.product-title {
    font-size: 14px;
    font-weight: bold;
    text-align: start;
}

.order-details {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.order-details .fs-6 {
    font-size: 12px !important;
}

.active-order .circle {
    width: 12px;
    display: block;
    height: 12px !important;
    border-radius: 50%;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ddd;
}

.active-order.active .circle {
    width: 12px;
    display: block;
    height: 12px !important;
    border-radius: 50%;
    position: relative;
    box-shadow: 0px 4px 8px rgba(36, 162, 89, 0.5);
    /* background-color: #24A259; */
}


.active-order.active .circle::before {
    content: "";
    width: 1px;
    height: 40px;
    border: none;
    background-color: inherit;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(50%, 50%);
}


.active-order .circle::before {
    content: "";
    width: 1px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 2px dashed #ddd;
    transform: translate(50%, 50%);
}

@media screen and (max-width:1024px) {

    .flex-col,
    .order-details-header {
        display: flex;
        flex-direction: column;
        justify-content: start !important;
        align-items: start !important;
    }

    .position-absolute {
        position: static !important;
        left: 0 !important;
        margin-top: 15px;
        align-items: start;
        padding: 0 !important;
    }
}

.status-title {
    min-width: max-content;
}

.table thead th {
    color: rgba(34, 48, 62, 1);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
}

.table tbody tr td {
    text-align: start;
}

.table tbody tr td img {
    min-width: 38px;
    height: 38px;
}

.product-done {
    min-width: 20px !important;
    max-width: 20px !important;
}

.tooltip-content {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 200px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
}

i+.tooltip-content {
    display: block;
}
</style>