<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.Subscriptions') }}</h3>
            <DataTable v-model:rows="SubscriptionsData" :headers="headers" :fields="fields" :total="total"
                :loading="loading" @page-changed="onPageChanged">
                <template v-slot:head-contant="{}">
                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M21.5152 6.30464L23.9547 8.74415C27.54 12.3295 29.3327 14.1221 29.3327 16.3497C29.3327 18.5774 27.54 20.37 23.9547 23.9554C20.3694 27.5407 18.5767 29.3333 16.3491 29.3333C14.1215 29.3333 12.3288 27.5407 8.74351 23.9553L6.304 21.5158C4.24328 19.4551 3.21293 18.4248 2.82953 17.088C2.44614 15.7511 2.77379 14.3313 3.4291 11.4917L3.807 9.85408C4.35831 7.46506 4.63397 6.27055 5.45194 5.45258C6.26991 4.63461 7.46442 4.35895 9.85344 3.80764L11.491 3.42974C14.3307 2.77444 15.7505 2.44678 17.0873 2.83017C18.4241 3.21357 19.4545 4.24392 21.5152 6.30464ZM14.8616 19.1046C13.9643 18.2073 13.9719 16.9198 14.5085 15.8995C14.2403 15.51 14.2795 14.9726 14.6259 14.6262C14.971 14.2811 15.5057 14.241 15.8949 14.5059C16.3481 14.2651 16.846 14.1378 17.3421 14.1424C17.8944 14.1476 18.3378 14.5996 18.3326 15.1518C18.3274 15.7041 17.8755 16.1476 17.3233 16.1423C17.0874 16.1401 16.7774 16.246 16.5115 16.5118C15.9948 17.0286 16.1307 17.5452 16.2758 17.6904C16.4209 17.8355 16.9376 17.9714 17.4543 17.4547C18.4997 16.4093 20.3046 16.0623 21.4612 17.219C22.3585 18.1162 22.3509 19.4037 21.8143 20.424C22.0825 20.8135 22.0434 21.3509 21.6969 21.6973C21.3517 22.0425 20.8169 22.0826 20.4276 21.8174C19.8185 22.1412 19.1254 22.2625 18.4647 22.1267C17.9237 22.0155 17.5753 21.4869 17.6865 20.9459C17.7977 20.4049 18.3263 20.0565 18.8673 20.1677C19.1035 20.2162 19.4843 20.1387 19.8113 19.8117C20.328 19.295 20.1921 18.7783 20.047 18.6332C19.9019 18.4881 19.3852 18.3522 18.8685 18.8689C17.8231 19.9142 16.0182 20.2612 14.8616 19.1046ZM13.3606 13.7241C14.402 12.6828 14.402 10.9943 13.3606 9.95291C12.3192 8.91152 10.6308 8.91152 9.58939 9.95291C8.548 10.9943 8.548 12.6828 9.58939 13.7241C10.6308 14.7655 12.3192 14.7655 13.3606 13.7241Z"
                                    fill="#24A259" />
                            </svg>

                            <h3 class="table-title">{{ $t('Subscription.List') }}</h3>

                        </div>
                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31" v-model.lazy="searchQuery">
                        </div>
                    </div>
                    <div class="card-header flex-column flex-md-row pb-0"
                        v-if="authStore.hasPermission('create-subscriptions')">

                        <div class="dt-action-buttons text-end pt-6 pt-md-0">
                            <div class="dt-buttons btn-group flex-wrap">

                                <button class="btn btn-secondary create-new btn-primary" tabindex="0"
                                    @click="onAddRow()" aria-controls="DataTables_Table_0" type="button"><span><i
                                            class="bx bx-plus bx-sm me-sm-2"></i> <span
                                            class="d-none d-sm-inline-block">
                                            {{ $t('Subscription.AddNew') }}
                                        </span></span>
                                </button>
                            </div>
                        </div>
                    </div>


                </template>
                <template v-slot:action-buttons="{ rowColum }">
                    <td v-if="rowColum.is_active" class="p-0 col-auto text-center align-middle">
                        <button type="button" 
                        class="btn btn-sm btn-size m-0 succ-btn" style="min-width: max-content;" 
                        @click="onStatusRow(rowColum)">
                        {{ $t('Sections.Customers.Active') }}
                        </button>
                    </td>
                    <td v-else class=" p-0 col-auto text-center align-middle">
                        <button type="button" 
                        class="btn btn-sm btn-size m-0  delete-btn" style="min-width: max-content;"
                         @click="onStatusRow(rowColum)">
                         {{ $t('Sections.Customers.Inactive') }}
                        </button>
                    </td>

                    <td class="p-0 col-auto text-center align-middle">
                        <div class="d-flex mx-2 justify-content-center">
                            <button type="button" class="btn btn-sm mx-1  edit-btn "
                            v-if="authStore.hasPermission('update-subscriptions')"
                             @click="onEditRow(rowColum)">
                                <i class='bx bxs-edit-alt'></i>
                            </button>
                            <button 
                             v-if="authStore.hasPermission('delete-subscriptions')"
                              class="btn mx-1 btn-sm delete-btn"
                              
                              @click="triggerDeleteModal(rowColum)">
                                <svg width="13" height="15" viewBox="0 0 13 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.5 3.74921C0.5 3.48622 0.717806 3.27302 0.986484 3.27302H4.17858C4.18292 2.7122 4.2437 1.94336 4.80025 1.41112C5.23824 0.992261 5.83872 0.733337 6.49999 0.733337C7.16127 0.733337 7.76174 0.992261 8.19974 1.41112C8.75629 1.94336 8.81707 2.7122 8.82141 3.27302H12.0135C12.2822 3.27302 12.5 3.48622 12.5 3.74921C12.5 4.0122 12.2822 4.2254 12.0135 4.2254H0.986484C0.717806 4.2254 0.5 4.0122 0.5 3.74921Z"
                                        fill="#EE1D52" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M6.2304 14.0667H6.7696C8.62474 14.0667 9.55231 14.0667 10.1554 13.4761C10.7585 12.8854 10.8202 11.9166 10.9436 9.97901L11.1214 7.18707C11.1884 6.13574 11.2219 5.61008 10.9193 5.27697C10.6167 4.94386 10.1058 4.94386 9.08397 4.94386H3.91603C2.89418 4.94386 2.38325 4.94386 2.0807 5.27697C1.77815 5.61008 1.81163 6.13574 1.87858 7.18707L2.05639 9.979C2.1798 11.9166 2.2415 12.8854 2.84459 13.4761C3.44769 14.0667 4.37526 14.0667 6.2304 14.0667ZM5.33085 7.52566C5.30337 7.23642 5.05835 7.0254 4.78358 7.05432C4.50881 7.08325 4.30834 7.34116 4.33581 7.6304L4.66915 11.1392C4.69662 11.4284 4.94165 11.6394 5.21642 11.6105C5.49119 11.5816 5.69166 11.3237 5.66419 11.0344L5.33085 7.52566ZM8.21642 7.05432C8.49119 7.08325 8.69166 7.34116 8.66419 7.6304L8.33085 11.1392C8.30338 11.4284 8.05835 11.6394 7.78358 11.6105C7.50881 11.5816 7.30834 11.3237 7.33581 11.0344L7.66915 7.52566C7.69663 7.23642 7.94165 7.0254 8.21642 7.05432Z"
                                        fill="#EE1D52" />
                                </svg>

                            </button>
                        </div>
                    </td>

                </template>

            </DataTable>
            <AddSubscription v-if="showAdd" @close-add="onCloseAdd" :onAdd="fetchData" />
            <EditSubscription v-if="showEdit" :subscriptionData="editRow" @close-edit="onCloseEdit"
                :onEdit="fetchData" />
            <DeleteModal ref="deleteModal" :row="editRow" :onDelete="handleDelete"
                :title="$t('Sections.Subscription.Delete')" />




        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';

import i18n from '@/i18n.js';
import { deleteSubscription, getSubscriptions } from '@/services/subscription';
import AddSubscription from './componants/AddSubscription.vue';

// State
// Create a reference to the DeleteModal component
const SubscriptionsData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const searchQuery = ref('');

//for Action btn

import { useAuthStore } from '@/stores/auth';
import EditSubscription from './componants/EditSubscription.vue';
import DeleteModal from '@/components/pages/DeleteModal.vue';
import { toast } from 'vue3-toastify';


const authStore = useAuthStore();


// Table headers and fields
const headers =  computed(()=> ['#', i18n.global.t('General.Email'), 
i18n.global.t('General.Phone'), i18n.global.t('General.Plan'),
    i18n.global.t('General.StartDate'),
     i18n.global.t('General.EndDate'),
      i18n.global.t('General.Paid'),
       i18n.global.t('General.Status'), '']);
const fields = ['ids', 'email', 'phone', 'plan_name', 'start_date', 'end_date', 'amount_paid'];


// Fetch users with pagination
const fetchData = async () => {

    try {
        loading.value = true;
        SubscriptionsData.value = [];

        const response = await getSubscriptions(currentPage.value, searchQuery.value);

        let count = 1 ;
        for (const row of response.data.data.data) {

            SubscriptionsData.value.push({
                ...row,
                ids:   (((currentPage.value??1) -1 ) *10) + count++ , 

                plan_name: row.plan && row.plan.plan_name ? row.plan.plan_name : "NAN",
                // status: row.status == "inactive" ? 0 : 1,

            });

        }
        // SubscriptionsData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};

// Fetch users on mount
onMounted(fetchData);

// Watch for changes on searchQuery
watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});
const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};


//add
const showAdd = ref(false);
function onAddRow() {
    console.log(1);
    
    showAdd.value = !showAdd.value;
}
function onCloseAdd() {
    showAdd.value = !showAdd.value;

}

const editRow = ref({});

//edit
const showEdit = ref(false);
function onEditRow(row) {
    showEdit.value = !showEdit.value;

    editRow.value = row;

}
function onCloseEdit() {
    showEdit.value = !showEdit.value;

}
function onStatusRow() {
    console.log('status');


}

//delete
const deleteModal = ref(null);
const handleDelete = async () => {
    try {
        loading.value = true;
        await deleteSubscription(editRow.value.id);
        fetchData();
        toast.success(i18n.global.t('Messages.Success.Deleted'), {
            autoClose: 3000, // Adjust time if needed
        });

        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error('Error fetching users:', error);
    }
};
const triggerDeleteModal = (row) => {
    editRow.value = row;

    deleteModal.value.openModal();
};



</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.btn-size {

    min-width: 100% !important;
}
</style>
