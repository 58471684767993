import axiosClient from "./axiosClient"

export function login(data) {
  return axiosClient({
                   url   : `v1/dashboard/login`,
                   method: 'post',
                   data
                 })
}
export function getprofile() {
  return axiosClient({
                   url   : `v1/dashboard/auth-profile`,
                   method: 'get',
                 })
}
export function register(data) {
  return axiosClient({
    url   : `v1/dashboard/register`,
    method: 'post',
                   data
                  })
}


export function logOut()
{
  return axiosClient({
    url   : `v1/dashboard/logout`,
    method: 'post',
  })
}

export function loginAs() {
  return axiosClient({
    url   : `v1/dashboard/login-as`,
    method: 'get',
  })
}
export function loginAsGroup() {
  return axiosClient({
    url   : `v1/dashboard/login-as-group`,
    method: 'get',
  })
}


export function registerById(data) {
  return axiosClient({
                   url   : `v1/dashboard/user-login-as`,
                   method: 'post',
                   data
                 })
}





