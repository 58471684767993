<template>
    <div class="modal fade" :class="{ 'show': showAdd }" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.PrintNode.Add') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <!-- Client ID (Optional) -->
                        <div class="col-12 col-md-12" v-if="authStore.hasPermission('view-clients')">
                            <label for="defaultSelect" class="form-label">{{ $t('General.Client') }}</label> <span
                                class="required invalid-feedback fw-bold ">*</span>
                            <select :class="{ 'is-invalid': errors.client_id }" id="defaultSelect" v-model="client_id"
                                class="form-control form-select form-custom-select-control select-w">
                                <option value="" disabled>{{ $t('General.Select.User') }}</option>
                                <option disabled v-if="userData.length < 1">{{ $t('loading') }} ...</option>
                                <option v-else v-for="client in userData" :key="client.id" :value="client.id">{{
                                    client.name }}
                                </option>
                            </select>
                            <span class="invalid-feedback">{{ errors.client_id }}</span>
                        </div>

                        <!-- First Name -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="first_name">{{ $t('General.FirestName') }}</label>
                            <input :class="{ 'is-invalid': errors.first_name }" type="text" id="first_name"
                                autocomplete="off" v-model="first_name" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('General.FirestName')">
                            <span class="invalid-feedback">{{ errors.first_name }}</span>
                        </div>
                        <!-- First Name -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="last_name">{{ $t('General.LastName') }}</label>
                            <input :class="{ 'is-invalid': errors.last_name }" type="text" id="last_name"
                                autocomplete="off" v-model="last_name" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('General.FirestName')">
                            <span class="invalid-feedback">{{ errors.last_name }}</span>
                        </div>

                        <!-- Email -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="email">{{ $t('General.Email') }}</label>
                            <input :class="{ 'is-invalid': errors.email }" type="email" id="email" v-model="email"
                                autocomplete="off" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('General.Email')">
                            <span class="invalid-feedback">{{ errors.email }}</span>
                        </div>

                        <!-- Password -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="password">{{ $t('Auth.Password') }}</label>
                            <input :class="{ 'is-invalid': errors.password }" type="password" id="password"
                                v-model="password" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('Auth.Password')">
                            <span class="invalid-feedback">{{ errors.password }}</span>
                        </div>

                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-primary me-3" :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    {{ $t('General.Saving') }}
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>
                            <button type="button" class="btn btn-secondary" @click="closeModel">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n';
import { toast } from 'vue3-toastify';
import { createChildPrintNode } from '@/services/printNode';
import { SelectClients } from '@/services/client';


const userData = ref([]);
const emit = defineEmits(['close-add']);

const props = defineProps({
    onAdd: {
        type: Function,
        required: true,
    },
});
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();
const fetchUsers = async () => {
    if (authStore.hasPermission('view-clients')) {
        try {
            const response = await SelectClients();
            userData.value = response.data.data;
        } catch (error) {
            console.error(i18n.global.t('Messages.Error.FetchData'), error);
        }
    }
};

onMounted(() => {
    // fetchUsers();
    setTimeout(() => {
        fetchUsers();
    }, 2000);

});

const showAdd = ref(true);
const loading = ref(false);

const validationSchema = computed(() => yup.object().shape({
    client_id: yup.string().optional(),
    first_name: yup.string().required(i18n.global.t('Messages.Error.Required')),
    last_name: yup.string().required(i18n.global.t('Messages.Error.Required')),
    email: yup.string().email(i18n.global.t('Messages.Error.InvalidEmail')).required(i18n.global.t('Messages.Error.Required')),
    password: yup.string().required(i18n.global.t('Messages.Error.Required')),
}));

const { handleSubmit, errors } = useForm({
    validationSchema: validationSchema.value,
});

const { value: client_id } = useField('client_id');
const { value: first_name } = useField('first_name');
const { value: last_name } = useField('last_name');
const { value: email } = useField('email');
// email.value = "";
const { value: password } = useField('password');



const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;

        const formData = new FormData();
        formData.append('client_id', values.client_id || '');  // client_id is optional
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        formData.append('password', values.password);

        await createChildPrintNode(formData);  // This function should handle the API call to add a user
        emit('close-add', false);
        props.onAdd();
        toast.success(i18n.global.t('Messages.Success.Added'), { autoClose: 3000 });
    } catch (error) {
        console.error(i18n.global.t('Messages.Error.SubmitError'), error);
    } finally {
        loading.value = false;
    }
});

function closeModel() {
    emit('close-add', false);
}

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});

</script>
