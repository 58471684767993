import axiosClient from "./axiosClient"

export function getRoles() {
  return axiosClient({
                   url   : `v1/dashboard/roles`,
                 })
}
export function getRoleId(id) {
  return axiosClient({
                   url   : `v1/dashboard/role/${id}`,
                 })
}
export function getPremissions() {
  return axiosClient({
                   url   : `v1/dashboard/permissions`,
                 })
}
export function getAllRoles(page = 1 , searchQuery = '') {
  return axiosClient({
                   url   : `v1/dashboard/roles?page=${page}&per_page=10&search=${searchQuery}`,
                 })
}
export function SelectRoles() {
  return axiosClient({
                   url   : `v1/dashboard/roles`,
                 })
}


export function storeRole(data) {
  return axiosClient({
    url: `v1/dashboard/role/store`,
    method: 'post',
    data,
  })
}
export function updateRole(id ,data) {
  return axiosClient({
    url: `v1/dashboard/role/${id}/update`,
    method: 'post',
    data,
  })
}
export function deleteRole( id) {
  return axiosClient({
                   url   : `v1/dashboard/role/delete/${id}`,
                   method: 'DELETE',
                 })
}