import { createApp, watchEffect } from 'vue'
import App from './App.vue'
import i18n from './i18n';

import { Vue3Toastify } from 'vue3-toastify';
import { createPinia } from 'pinia';

import router from "./router/routes";

// css
import 'bootstrap';

import '@/assets/dashboard/vendor/css/rtl/core.css';
import '@/assets/dashboard/vendor/css/rtl/theme-default.css';
import '@/assets/dashboard/css/demo.css';
import '@/assets/dashboard/vendor/fonts/boxicons.css';
import '@/assets/dashboard/vendor/fonts/fontawesome.css';
import 'vue3-toastify/dist/index.css';





const app = createApp(App)




// updateGlobalOptions({ rtl: i18n.global.locale === 'ar' });


app.use(Vue3Toastify, {
  autoClose: 3000,
  limit: 1,
});





// Watch for language changes and update body class
watchEffect(() => {
  const currentLocale = i18n.global.locale;
  const direction = currentLocale === 'ar' ? 'rtl' : 'ltr'; // Determine direction based on language

  // Update the <html> element's dir attribute
  document.documentElement.setAttribute('dir', direction);
});

const pinia = createPinia()

app.use(pinia);

app.use(router);
app.use(i18n);
app.mount('#app')
