<template>
  <select v-model="localCountryId" id="countrySelect" class="form-control form-select" ref="countrySelect" >
    <option v-for="country in countriesData" :key="country.id" :value="country.id" :data-flag="country.flag">
      {{ country.name }}
    </option>
  </select>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import 'select2/dist/css/select2.min.css';
import $ from 'jquery';
import 'select2';

const props = defineProps({
  countriesData: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Number,
    default: null
  },

  selectedId: {
    type: [String, Number], // The initial selected country ID
    default: null
  }
});

const emit = defineEmits(['update:modelValue']);
const countrySelect = ref(null);
const localCountryId = ref(props.modelValue || props.selectedId);
function formatCountry(country) {
  if (!country.id) return country.text;
  const flag = $(country.element).data('flag');
  return `<span><img src="${flag}" width="20" class="me-2"/> ${country.text}</span>`;
}

onMounted(() => {
  $(countrySelect.value)
    .select2({
      templateResult: formatCountry,
      templateSelection: formatCountry,
      escapeMarkup: (markup) => markup,
      minimumResultsForSearch: 0,
    })
    .on('select2:select', (e) => {
      emit('update:modelValue', parseInt(e.target.value, 10));
    });
});
onMounted(() => {
  if (props.selectedId) {
    localCountryId.value = props.selectedId;
  }
});

// Sync the selected value with v-model
watch(() => props.modelValue, (newValue) => {
  $(countrySelect.value).val(newValue).trigger('change');
});
// Watch for changes in the selectedId prop and update localCountryId
watch(() => props.selectedId, (newValue) => {
  localCountryId.value = newValue;
});

onBeforeUnmount(() => {
  $(countrySelect.value).select2('destroy');
});
</script>

<style>
.select2-container--open {
  z-index: 9999 !important;
}

.select2-selection {
  height: 40px !important;
}

.select2-selection--single {
  height: 40px !important;

}
</style>
