<template>
    <div class="modal fade" :class="{ 'show': showDelivery }" id="addStore" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-store">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <div class="text-center pb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('General.Connect') }} {{ row.name }}</h4>
                    </div>
                    <form class="row g-6 pt-4" @submit.prevent="onSubmit">
                        <div class="col-12 col-md-12">
                            <label class="form-label" for="modalAddStoreName">{{ $t('General.Name') }} </label> 
                            <span class="required text-danger fw-bold">*</span>
                            <input type="text" id="modalAddStoreName" v-model="name" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('General.Name')"
                                :class="{ 'border-danger': errors.name }">
                            <span class="text-danger">{{ errors.name }}</span>
                        </div>

                        <div class="col-12 col-md-6 d-none">
                            <label class="form-label" for="modalServiceId">{{ $t('General.ServiceID') }}</label> 
                            <span class="required text-danger fw-bold">*</span>
                            <input type="number" id="modalServiceId" disabled v-model="service_id" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('General.ServiceID')"
                                :class="{ 'border-danger': errors.service_id }">
                            <span class="text-danger">{{ errors.service_id }}</span>
                        </div>

                        <div class="col-12">
                            <label class="form-label" for="modalIntegrationKey">{{ $t('General.IntegrationKey') }}</label> 
                            <span class="required text-danger fw-bold">*</span>
                            <input type="text" id="modalIntegrationKey" v-model="integration_key" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('General.IntegrationKey')"
                                :class="{ 'border-danger': errors.integration_key }">
                            <span class="text-danger">{{ errors.integration_key }}</span>
                        </div>

                        <div class="col-12 mb-3">
                            <label class="form-label" for="modalIntegrationUrl">{{ $t('General.IntegrationURL') }}</label> 
                            <span class="required text-danger fw-bold">*</span>
                            <input type="url" id="modalIntegrationUrl" v-model="integration_url" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('General.IntegrationURL')"
                                :class="{ 'border-danger': errors.integration_url }">
                            <span class="text-danger">{{ errors.integration_url }}</span>
                        </div>
                        <hr>
                        <div class="col-12 d-flex align-items-center  justify-content-between">
                            <label class="form-label me-3" for="modalAsDefault">{{ $t('General.Default') }}</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input form-check-input-lg" type="checkbox" id="modalAsDefault" v-model="as_default" style="transform: scale(1.5);">
                            </div>
                        </div>

                        <div class="col-12 text-center d-flex justify-content-center gap-2 el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else class="px-6">{{ $t('General.Save') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5"
                                data-bs-dismiss="modal" @click="closeModel" aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n.js';
import { toast } from 'vue3-toastify';
import { addDeliveryIntegration } from '@/services/connectServices';

const emit = defineEmits(['close-add']);

const props = defineProps({
    showDelivery: {
        type: Boolean,
        default: true,
    },
    row: {
        type: Object,
    },
    onAdd: {
        type: Function,
        required: true
    },
});

const loading = ref(false);
const eRow = ref(props.row)

// Form validation using VeeValidate & Yup
const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        name: yup.string()
            .required(i18n.global.t('Validation.Required.Name'))
            .min(3, i18n.global.t('Validation.Length.Min3'))
            .max(50, i18n.global.t('Validation.Length.Max50')),

        service_id: yup.number()
            .required(i18n.global.t('Validation.Required.ServiceID'))
            .positive(i18n.global.t('Validation.Invalid.ServiceID')),

        integration_key: yup.string()
            .required(i18n.global.t('Validation.Required.IntegrationKey')),

        integration_url: yup.string()
            .url(i18n.global.t('Validation.Invalid.URL'))
            .required(i18n.global.t('Validation.Required.IntegrationURL')),

        as_default : yup.boolean().required(i18n.global.t('Validation.Required.Default')),

    })
});

// Bind input fields using useField
const { value: name } = useField('name');
const { value: service_id } = useField('service_id');
service_id.value = eRow.value.id ;
const { value: integration_key } = useField('integration_key');
const { value: integration_url } = useField('integration_url');
const { value: as_default } = useField('as_default');
as_default.value = false;

const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        values.as_default = values.as_default ? 1 : 0;
        await addDeliveryIntegration(values);
        console.log(values);
        
        emit('close-add', false);
        props.onAdd();

        toast.success(i18n.global.t('Messages.Success.Created'), {
            autoClose: 3000
        });

    } catch (e) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-add', false);
}

const modal = ref(null);

// Handle click outside modal to close it
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);
    }, 500);
});

onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});
</script>


<style scoped>
.add-btn {
    width: 278px;
    /*height: 64px;*/
}

.cancel-btn {
    height: 64px;
    /*height: 64px;*/
}
</style>