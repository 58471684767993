<template>
  <div class="card p-3">
    <div class="card-datatable">
      <div id="DataTables_Table_0_wrapper "
        class="datatables-basic  table dataTable no-footer dtr-column table-responsive-sm">


        <slot name="head-contant">

        </slot>
        <hr class="bg-danger border-2 border-top" />
        <table class="datatables-basic table dataTable no-footer dtr-column  table-hover  table-responsive" :class="{ 'table-striped': tripper }">
          <thead>
            <tr>
              <th class="text-center" v-for="header in headers.filter(item => item !== false && item !=null)" :key="header">{{ header }}</th>
              <slot name="table-header">

              </slot>
            </tr>
          </thead>
          <tbody>
            <tr  :style="{height : heightRow +'px'}" v-if="!loading && rows.length < 1" class="odd">
              <td valign="top" :colspan="rowLength" class="dataTables_empty text-center align-middle">{{ $t('General.NoData') }}</td>
            </tr>
            <tr  :style="{height : heightRow +'px'}" v-if="loading" class="odd">
              <td valign="top" :colspan="rowLength" class="dataTables_empty text-center align-middle">Loading...
              </td>
            </tr>
            <tr  :style="{height : heightRow +'px'}" v-else v-for="(row , index ) in rows" :key="row.id">
              <td class="text-center align-middle" v-for="field in fields" :key="field">{{ row[field] }}</td>
              <slot name="action-buttons" :rowColum="row" :index="index">

              </slot>
            </tr>
          </tbody>
        </table>
        <div class="row pt-5">
          <div class="col-sm-12 col-md-6">
            <div role="status" aria-live="polite" class="table-pages">
              {{ end > total ? total : end }}   {{ $t('General.Showing') }}    {{ $t('General.Of') }}  {{ total }}  {{ $t('General.entries') }}  
            </div>
          </div>
          <div class="col-sm-12 col-md-6" >
            <ul class="pagination justify-content-end">
              <li :class="['paginate_button page-item ', { disabled: currentPageItem === 1  }, { disabled: loading  }]" @click="prevPage(1)">
                <a role="button" class="page-link"><i class="bx bx-chevrons-left bx-18px"></i></a>
              </li>
              <li :class="['paginate_button page-item', { disabled: currentPageItem === 1 }, { disabled: loading  }]"
                @click="prevPage(currentPageItem - 1)">
                <a role="button" class="page-link"><i class="bx bx-chevron-left bx-18px"></i></a>
              </li>
              <li :class="['paginate_button page-item', { disabled: currentPageItem - 2 === currentPageItem }, { disabled: loading  }]"
                @click="prevPage(currentPageItem - 2)"
                v-if="currentPageItem < currentPageItem + 2 && currentPageItem > 2">
                <a role="button" class="page-link">
                  {{ currentPageItem - 2 }}
                </a>
              </li>
              <li :class="['paginate_button page-item', { disabled: currentPageItem - 1 === currentPageItem }, { disabled: loading  }]"
                @click="prevPage(currentPageItem - 1)"
                v-if="currentPageItem < currentPageItem + 1 && currentPageItem > 1">
                <a role="button" class="page-link">
                  {{ currentPageItem - 1 }}
                </a>
              </li>
              <li :class="['paginate_button page-item bg-green', { disabled: currentPageItem === currentPageItem }, { disabled: loading  }]"
                @click="prevPage" v-if="currentPageItem <= totalPages">
                <a role="button" class="page-link">
                  {{ currentPageItem }}
                </a>
              </li>
              <li :class="['paginate_button page-item', { disabled: currentPageItem === currentPageItem + 1 }, { disabled: loading  }]"
                @click="nextPage(currentPageItem + 1)" v-if="currentPageItem + 1 <= totalPages">
                <a role="button" class="page-link">
                  {{ currentPageItem + 1 }}
                </a>
              </li>
              <li :class="['paginate_button page-item', { disabled: currentPageItem === currentPageItem + 2 }, { disabled: loading  }]"
                @click="nextPage(currentPageItem + 2)" v-if="currentPageItem + 2 <= totalPages">
                <a role="button" class="page-link">
                  {{ currentPageItem + 2 }}
                </a>
              </li>
              <li :class="['paginate_button page-item', { disabled: currentPageItem === totalPages }, { disabled: loading  }]"
                @click="nextPage(currentPageItem + 1)">
                <a role="button" class="page-link"><i class="bx bx-chevron-right bx-18px"></i></a>
              </li>
              <li :class="['paginate_button page-item', { disabled: currentPageItem === totalPages }, { disabled: loading  }]"
                @click="nextPage(totalPages)">
                <a role="button" class="page-link"><i class="bx bx-chevrons-right bx-18px"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const emit = defineEmits(['page-changed', 'row-id']);

const props = defineProps({
  rows: {
    type: Array,
    default: () => [],
    required: true
  },
  headers: {
    type: Array,
    default: () => ['Name', 'Email', 'Date', 'Salary', 'Status'],
    required: true
  },
  fields: {
    type: Array,
    default: () => ['name', 'email', 'date', 'salary', 'status'],
    required: true
  },
  heightRow: {
    type: Number,
    required: false
  },
  total: {
    type: Number,
    default: 0,
    required: true
  },
  loading: {
    type: Boolean,
    default: true,
    required: true
  },
  tripper: {
    type: Boolean,
    default: true,
    required: false
  },
  itemsPerPage: {
    type: Number,
    default: 10,
    required: false
  }
});

const currentPageItem = ref(1);
// const itemsPerPage = ref(7);
//  itemsPerPage.value = props.itemsPerPage;

const rowLength = ref( props.headers.length) ;
if(rowLength.value < 1 ){
  rowLength.value = 9 ;
}



const totalPages = computed(() => Math.ceil(props.total / props.itemsPerPage));

const start = computed(() => {
  if (props.total === 0) return 0;
  return (currentPageItem.value - 1) * props.itemsPerPage;
});
const end = computed(() => {
  if (props.total === 0) return 0;
  return start.value + props.itemsPerPage;
});


function nextPage(page = null) {
  if (page != null && currentPageItem.value < totalPages.value) {
    currentPageItem.value = page;
    emit('page-changed', page); // Emit the page number to parent
  }

  else if (currentPageItem.value < totalPages.value) {
    currentPageItem.value += 1;
    emit('page-changed', currentPageItem.value); // Emit the page number to parent
  }
}


function prevPage(page = null) {
  if (page != null && currentPageItem.value > 1) {
    currentPageItem.value = page;
    emit('page-changed', page); // Emit the page number to parent
  }

  else if (currentPageItem.value > 1) {

    currentPageItem.value = currentPageItem.value - 1;
    emit('page-changed', currentPageItem.value); // Emit the page number to parent
  }
}
</script>

<style scoped>

/* Add styles for your table */

.card {
  padding: 3rem;
  border-radius: 5px;
}

table {
  margin-top: 48px;
}

table>thead>tr {
  border-top: #faebd700 !important;
}



.table th,
.table td {
  border-bottom: 1px solid #ddd;
  /* Only add horizontal borders */
  border-right: none !important;
  /* Remove vertical borders */
}

.table-title {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.search-label {
  font-size: 0.9rem;
  margin: 0;
}

.table-info {
  font-size: 0.8rem;
}

.pagination .page-item {
  cursor: pointer;


}


.page-link {
  border-radius: 9px !important;
  background-color: #22303E0F;
}

.pagination .disabled {
  pointer-events: none;
  opacity: 0.6;
}

.bg-green>.page-link {
  color: white !important;
  background-color: #24A259;
  opacity: 1 !important;
}

.page-item:hover .page-link:hover {
  background-color: rgba(36, 162, 89, 0.08);
}

.bg-green {
  opacity: 1 !important;

}

.color-green {

  color: #3aab6a;
}

td {
  max-width: 148px;
}

.table-pages {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #22303E66;

}

/* Style the table header border */


</style>
