export function formatDateToYMDHMS(dateString) {
  const date = new Date(dateString);

  // Manually format the date as yyyy-MM-dd HH:mm:ss
  return date.getFullYear() + '-' +
    String(date.getMonth() + 1).padStart(2, '0') + '-' +
    String(date.getDate()).padStart(2, '0') + ' ' +
    String(date.getHours()).padStart(2, '0') + ':' +
    String(date.getMinutes()).padStart(2, '0') + ':' +
    String(date.getSeconds()).padStart(2, '0');
}

// Function to format the date into "DD MMMM YYYY"
export function formatDateToDMY(dateString) {
  const date = new Date(dateString); // Ensure dateString is a valid date
  const local =  localStorage.getItem('lang') == 'ar'   ?   'ar' :'en-US';
  return new Intl.DateTimeFormat(local, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }).format(date);
}

export function formatDateToYMD(dateString) {
  const date = new Date(dateString);

  // Format the date as yyyy-MM-dd
  return date.getFullYear() + '-' +
    String(date.getMonth() + 1).padStart(2, '0') + '-' +
    String(date.getDate()).padStart(2, '0');
}

export function formatDateToYMDHM(dateStr){
  const date = new Date(dateStr.replace(" ", "T")); // Convert string to Date object
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();
  
  let hours = date.getHours();
  let minutes = date.getMinutes();
  // const ampm = hours >= 12 ? "PM" : "AM";
  
  hours = hours % 12 || 12; // Convert 24-hour to 12-hour format
  minutes = String(minutes).padStart(2, "0");

  return `${day} - ${month} - ${year}, ${hours}:${minutes}`;
  // return `${day} - ${month} - ${year}, ${hours}:${minutes} ${ampm}`;
}