<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">

            <h2 class="title text-primary" v-if="authStore.hasPermission('view-delivery-services')">{{
                $t('General.ConnectServices') }}</h2>
            <section class="delivery-Company" v-if="authStore.hasPermission('view-delivery-services')">
                <div class="connect-services-cards">
                    <div class="row mt-">
                        <div class="col-lg-3 col-md-6 col-sm-12" v-for="company in services.online_stores"
                            :key="company.id">
                            <div class="service-card mt-5">
                                <p v-if="company.note" class="notes  btn  text-white fs-6">
                                    {{ company.note }}
                                </p>
                                <img :src="company.logo" style=" width: 120px; height: 100px; object-fit: contain;"
                                    alt="logo image">
                                <div class="description">{{ company.name }} </div>

                                <div class="card-btns">

                                    <button :disabled="true"
                                        class="rounded-pill d-flex mx-auto gap-2 align-items-center 
                                        justify-content-center bg-green text-primary gap-2 align-items-center px-3 d-flex border-none outline-none py-2"
                                        type="button"
                                        v-if="company.stores && company.stores.length < 1 && company.can_add">
                                        {{ $t('General.Add') }}<i class='bx bx-plus' style="font-size: 12px;"></i>
                                    </button>

                                    <button @click="onEditRow(company)"
                                        v-if="company.stores && company.stores.length > 0"
                                        class="rounded-pill d-flex mx-auto gap-2 align-items-center
                                         justify-content-center bg-green text-primary gap-2 align-items-center px-3 d-flex border-none outline-none py-2" type="button">
                                        {{ $t('General.Store') }}<i class='bx bx-store' style="font-size: 12px;"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <h2 class="title text-primary mt-4" v-if="authStore.hasPermission('view-store-services')">{{
                $t('General.DeliveryCompany') }}</h2>
            <section class="delivery-Company" v-if="authStore.hasPermission('view-store-services')">
                <div class="connect-services-cards">
                    <div class="row mt-">
                        <div class="col-lg-3 col-md-6 col-sm-12" v-for="company in services.delivery_companies"
                            :key="company.id">
                            <div class="service-card mt-5">
                                <p v-if="company.note" class="notes  btn  text-white fs-6">
                                    {{ company.note }}
                                </p>
                                <img :src="company.logo" style=" height: 100px;width: 120px; object-fit: contain;"
                                    alt="logo image">
                                <div class="description">{{ company.name }}</div>

                                <div class="card-btns">
                                    <button @click="onAddDeliveryRow(company)"
                                        v-if="company.integrates && company.integrates.length < 1 && company.can_add"
                                        aria-controls="offcanvasRight" class="rounded-pill d-flex mx-auto gap-2 align-items-center
                                         justify-content-center bg-green text-primary gap-2 
                                         align-items-center px-3 d-flex border-none outline-none py-2" type="button">
                                        {{ $t('General.Add') }}<i class='bx bx-plus' style="font-size: 12px;"></i>
                                    </button>

                                    <button @click="onEditServicesRow(company)"
                                        v-if="company.integrates && company.integrates.length > 0"
                                        class="rounded-pill d-flex mx-auto gap-2 align-items-center justify-content-center bg-green text-primary gap-2 align-items-center px-3 d-flex border-none outline-none py-2"
                                        type="button">
                                        {{ $t('General.Store') }}<i class='bx bx-store' style="font-size: 12px;"></i>
                                    </button>
                                    <button @click="onAddSamsaSteps()"
                                        v-if="company.name === 'Smsa' || company.name === 'سمسا'" class="rounded-pill d-flex mx-auto gap-2 align-items-center justify-content-center
                                         bg-light text-primary gap-2 align-items-center
                                          px-3 d-flex border-none outline-none py-2" type="button">
                                        <i class='bx bx-info-circle' style="font-size: 12px;"></i>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
    <StepToIntegrates v-if="showSamsaSteps" @close-add="onCloseSamsaSteps" />
    <AddDelivery v-if="showDelivery" :row="editRow" @close-add="onCloseAddDelivery" :onAdd="addDeliveryEvent" />
    <ConnectStore v-if="showEdit" :row="editRow" :client="editRow.stores" @close-edit="onCloseEdit" />
    <ConnectIntegrates v-if="showServices" :row="editRow" :service="editRow.integrates"
        @close-edit="onCloseEditServices" />

    <div class="offcanvas offcanvas-end w-25" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel text-dark">Client Details</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="d-flex justify-content-center align-items-center">
                <img class="mx-auto" src="@/assets/dashboard/img/dqqlogo.png" alt="logo image">
            </div>

            <div class="mt-5">
                <span class="text-uppercase text-primary">client name</span>
                <p>Dqq app</p>
            </div>

            <div class="mt-5">
                <span class="text-uppercase text-primary">email</span>
                <p>urovpzjas3@zam-partner.email</p>
            </div>

            <div class="mt-5">
                <span class="text-uppercase text-primary">Mobile Number</span>
                <p>+966540888224</p>
            </div>

            <div class="mt-5">
                <span class="text-uppercase text-primary">store Name</span>
                <p>Dqq app</p>
            </div>

            <div class="mt-5 d-flex justify-content-between align-items-center">
                <span class="text-uppercase text-primary">{{ $t('General.Status') }}</span>
                <p class="text-decoration-underline" style="color: #1168FF;">
                    <img src="@/assets/dashboard/img/shield-user.svg" alt="shield-user">
                    Change Status
                </p>
            </div>

            <div class="mt-5 pb-5 d-flex justify-content-center align-items-center">
                <button class="btn blue-btn w-50">Approved</button>
            </div>
            <hr />

            <div class="mt-5">
                <span class="text-uppercase text-primary">CREATED Date</span>
                <p>2024-10-09 13:09:02</p>
            </div>

            <div class="mt-5 d-flex justify-content-center gap-3 align-items-center">
                <button type="button" class="btn blue-btn w-75 h-25 ">
                    <i class='bx bxs-edit-alt'></i>Edit
                </button>
                <button class="btn bg-red-light w-75 h-25">
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.5 3.74921C0.5 3.48622 0.717806 3.27302 0.986484 3.27302H4.17858C4.18292 2.7122 4.2437 1.94336 4.80025 1.41112C5.23824 0.992261 5.83872 0.733337 6.49999 0.733337C7.16127 0.733337 7.76174 0.992261 8.19974 1.41112C8.75629 1.94336 8.81707 2.7122 8.82141 3.27302H12.0135C12.2822 3.27302 12.5 3.48622 12.5 3.74921C12.5 4.0122 12.2822 4.2254 12.0135 4.2254H0.986484C0.717806 4.2254 0.5 4.0122 0.5 3.74921Z"
                            fill="#EE1D52" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M6.2304 14.0667H6.7696C8.62474 14.0667 9.55231 14.0667 10.1554 13.4761C10.7585 12.8854 10.8202 11.9166 10.9436 9.97901L11.1214 7.18707C11.1884 6.13574 11.2219 5.61008 10.9193 5.27697C10.6167 4.94386 10.1058 4.94386 9.08397 4.94386H3.91603C2.89418 4.94386 2.38325 4.94386 2.0807 5.27697C1.77815 5.61008 1.81163 6.13574 1.87858 7.18707L2.05639 9.979C2.1798 11.9166 2.2415 12.8854 2.84459 13.4761C3.44769 14.0667 4.37526 14.0667 6.2304 14.0667ZM5.33085 7.52566C5.30337 7.23642 5.05835 7.0254 4.78358 7.05432C4.50881 7.08325 4.30834 7.34116 4.33581 7.6304L4.66915 11.1392C4.69662 11.4284 4.94165 11.6394 5.21642 11.6105C5.49119 11.5816 5.69166 11.3237 5.66419 11.0344L5.33085 7.52566ZM8.21642 7.05432C8.49119 7.08325 8.69166 7.34116 8.66419 7.6304L8.33085 11.1392C8.30338 11.4284 8.05835 11.6394 7.78358 11.6105C7.50881 11.5816 7.30834 11.3237 7.33581 11.0344L7.66915 7.52566C7.69663 7.23642 7.94165 7.0254 8.21642 7.05432Z"
                            fill="#EE1D52" />
                    </svg>
                    Delete
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getServices } from '@/services/connectServices';
import { useLoadingStore } from '@/stores/loading';
import { onMounted, reactive, ref } from 'vue';
import ConnectStore from './componants/ConnectStore.vue';
import ConnectIntegrates from './componants/ConnectIntegrates.vue';
import AddDelivery from './componants/AddDelivery.vue';
import StepToIntegrates from './componants/StepToIntegrates.vue';
import { useAuthStore } from '@/stores/auth';



const authStore = useAuthStore();


const services = reactive({
    online_stores: [],
    delivery_companies: []
});

// code email mobile name reference_number serial_number status_name store_id store_name user_id 
// client_id created_at id integration_key integration_url name service_id updated_at



const loadingStore = useLoadingStore();
const FetchData = async () => {
    loadingStore.setLoading(true)

    try {
        const response = await getServices();

        services.online_stores = response.data.data.online_stores
        services.delivery_companies = response.data.data.delivery_companies

    } catch (error) {
        loadingStore.setLoading(false)

    } finally {
        loadingStore.setLoading(false)
    }
}

onMounted(() => FetchData())


const editRow = ref({});
//edit
const showEdit = ref(false);


function onEditRow(row) {
    showEdit.value = !showEdit.value;

    editRow.value = row;

}
function onCloseEdit() {
    showEdit.value = !showEdit.value;

}

//edit
const showServices = ref(false);
const showDelivery = ref(false);
const showSamsaSteps = ref(false);



function onEditServicesRow(row) {
    showServices.value = !showServices.value;

    editRow.value = row;

}
function onCloseEditServices() {
    showServices.value = !showServices.value;

}
function onAddDeliveryRow(row) {
    showDelivery.value = !showDelivery.value;

    editRow.value = row;

}
function onCloseAddDelivery() {
    showDelivery.value = !showDelivery.value;

}


function onAddSamsaSteps() {
    showSamsaSteps.value = !showSamsaSteps.value;


}
function onCloseSamsaSteps() {
    showSamsaSteps.value = !showSamsaSteps.value;

}

function addDeliveryEvent() {
    FetchData();
    onAddSamsaSteps();

}


</script>

<style>
.connect-services-cards .service-card {
    background-color: #fff;
    min-height: 240px;
    border-radius: 16px;
    padding: 24px 40px;
    position: relative;
    text-align: center;
    box-shadow: 0px 3px 8px 0px #22303E1A;
}

.connect-services-cards .service-card .card-btns {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

}

.notes {
    position: absolute;
    top: -15px;
    right: -10px;
    background-color: tomato !important;
}

.notes:hover {
    right: -20px;
}
</style>