import { ref } from "vue";
import axiosClient from "./axiosClient"

export function getPlans(page = 1 , searchQuery = '') {
  const url =  ref('');
  if(searchQuery){
   url.value =  `v1/dashboard/subscripion/plans?page=${page}&per_page=10&search=${searchQuery}` ;
  }else{
   url.value =  `v1/dashboard/subscripion/plans?page=${page}&per_page=10` ;

  }
  return axiosClient({
                   url   : url.value,
                 })
}
export function SelectPlans() {
  
  return axiosClient({
                   url   : `v1/dashboard/subscripion/plans`,
                 })
}
export function addPlan(data) {
  return axiosClient({
                   url   : `v1/dashboard/subscripion/plan/store`,
                   method: 'post',
                   data
                 })
}
export function editPlan(id , data) {
  return axiosClient({
                   url   : `v1/dashboard/subscripion/plan/update/${id}`,
                   method: 'post',
                   data
                 })
}
export function deletePlan(id ) {
  return axiosClient({
                   url   : `v1/dashboard/subscripion/plan/delete/${id}`,
                   method: 'post'
                 })
}
