<template>
    <div class="modal fade" :class="{ 'show': showEdit }" id="editUser" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-edit-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Subscription.Edit') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <!-- Client Selection -->
                        <div class="col-12"  v-if="authStore.hasPermission('view-clients')">
                            <label class="form-label" for="selectClient">{{ $t('General.Select.Client') }}</label>
                            <select :class="{ 'border-danger': errors.client_id }" id="selectClient" v-model="client_id"
                                class="form-select form-custom-select-control select-w ">
                                <option value="" disabled>Select a client</option>
                                <option disabled v-if="clientsData.length < 1">loading ...</option>
                                <option v-for="client in clientsData" :key="client.id" :value="client.id">{{
                                    client.name }}</option>
                            </select>
                            <span class="text-danger">{{ errors.client_id }}</span>
                        </div>

                        <!-- Plans Selection -->
                        <div class="col-12">
                            <label class="form-label" for="selectPlans">{{ $t('General.Select.Plans') }}</label>
                            <select :class="{ 'border-danger': errors.plan_id }" id="selectPlans" v-model="plan_id"
                                class="form-select form-custom-select-control select-w ">
                                <option value="" disabled>Select a plan</option>
                                <option disabled v-if="plansData.length < 1">loading ...</option>
                                <option v-for="plan in plansData" :key="plan.id" :value="plan.id">{{
                                    plan.plan_name }}</option>
                            </select>
                            <span class="text-danger">{{ errors.plan_id }}</span>
                        </div>

                        <!-- Start Date -->
                        <div class="col-12">
                            <label class="form-label" for="startDate">{{ $t('General.StartDate') }}</label>
                            <input :class="{ 'border-danger': errors.start_date }" type="date" id="startDate"
                                v-model="start_date" class="form-control form-custom-control">
                            <span class="text-danger">{{ errors.start_date }}</span>
                        </div>

                        <!-- End Date -->
                        <div class="col-12">
                            <label class="form-label" for="endDate">{{ $t('General.EndDate') }}</label>
                            <input :class="{ 'border-danger': errors.end_date }" type="date" id="endDate"
                                v-model="end_date" class="form-control form-custom-control">
                            <span class="text-danger">{{ errors.end_date }}</span>
                        </div>

                        <!-- Amount Paid -->
                        <div class="col-12">
                            <label class="form-label" for="amountPaid">{{ $t('General.AmountPaid') }}</label>
                            <div class="input-group  bg-green">
                                <input type="number" id="amountPaid" v-model="amount_paid"
                                    class="form-control border-none " placeholder="Enter amount">
                                <span class="input-group-text border-none ">SAR</span>
                            </div>
                            <span class="text-danger">{{ errors.amount_paid }}</span>
                        </div>
                        <!-- number_orders -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="numberOrder">{{ $t('Sections.Orders.NumberOrder') }}</label>
                            <div class="input-group  bg-green">
                                <input type="number" id="numberOrder" v-model="number_orders"
                                    class="form-control border-none " placeholder="Enter Number">
                               
                            </div>
                            <span class="text-danger">{{ errors.number_orders }}</span>
                        </div>
                        <!-- Employees  NumberOf -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="numberEmployees">{{ $t('Sections.Employees.NumberOf')
                                }}</label>
                            <div class="input-group  bg-green">
                                <input type="number" id="numberEmployees" v-model="number_employers"
                                    class="form-control border-none " placeholder="Enter Number">
                               
                            </div>
                            <span class="text-danger">{{ errors.number_employers }}</span>
                        </div>
                        <!-- condition_limit -->
                        <div class="col-12">
                            <label class="form-label" for="condition_limit">{{ $t('Sections.Conditions.Limit')
                                }}</label>
                            <div class="input-group  bg-green">
                                <input type="number" id="condition_limit" v-model="condition_limit"
                                    class="form-control border-none " placeholder="Enter Number">
                               
                            </div>
                            <span class="text-danger">{{ errors.condition_limit }}</span>
                        </div>

                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn me-3 login-button btn btn-primary" :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>

                            <button type="button" class="btn btn-label-secondary" @click="closeModel"
                                aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n.js';
import { toast } from 'vue3-toastify';
import {  EditSubscription } from '@/services/subscription';
import { SelectClients } from '@/services/client';
import { SelectPlans } from '@/services/plan';

const emit = defineEmits(['close-edit']);
const props = defineProps({
    showEdit: { type: Boolean, default: true },
    subscriptionData: {
        type: Object,
        required: true,
    },
    onEdit: { type: Function, required: true }
});

import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();

const clientsData = ref([]);
const plansData = ref([]);
const loading = ref(false);
// Pre-fill the form with existing data
const { handleSubmit, errors } = useForm({
    initialValues: {
        client_id: props.subscriptionData.client_id,
        plan_id: props.subscriptionData.plan_id,
        start_date: props.subscriptionData.start_date.split(" ")[0],
        end_date: props.subscriptionData.end_date.split(" ")[0],
        amount_paid: props.subscriptionData.amount_paid,
        number_orders: props.subscriptionData.number_orders,
        number_employers: props.subscriptionData.number_employers,
        condition_limit: props.subscriptionData.condition_limit
    },
    validationSchema: yup.object({
        client_id: yup.string().required(i18n.global.t('Validation.Required.Client')),
        plan_id: yup.string().required(i18n.global.t('Validation.Required.Plan')),
        start_date: yup.date().required(i18n.global.t('Validation.Required.StartDate')),
        end_date: yup.date().min(yup.ref('start_date'), i18n.global.t('EndDate.AfterStartDate')).required(i18n.global.t('Validation.Required.EndDate')),
        amount_paid: yup.number().required(i18n.global.t('Validation.Required.Amount')).positive(i18n.global.t('Positive.Amount')),
        number_orders: yup.number().nullable().positive(i18n.global.t('Positive.Amount')),
        number_employers: yup.number().nullable().positive(i18n.global.t('Positive.Amount')),
        condition_limit: yup.number().nullable().positive(i18n.global.t('Positive.Amount')),
    })
});

// Use `useField` to bind individual form fields
const { value: client_id } = useField('client_id');
const { value: plan_id } = useField('plan_id');
const { value: start_date } = useField('start_date');
const { value: end_date } = useField('end_date');
const { value: amount_paid } = useField('amount_paid');
const { value: number_orders } = useField('number_orders');
const { value: number_employers } = useField('number_employers');
const { value: condition_limit } = useField('condition_limit');

const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        await EditSubscription(values , props.subscriptionData.id);
        emit('close-edit', false);
        toast.success(i18n.global.t('Messages.Success.Updated'), { autoClose: 3000 });

        props.onEdit();

    } catch (e) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-edit', false);
}

// Fetch clients and plans
const fetchData = async () => {
    try {
        loading.value = true;
        const [clientsResponse, plansResponse] = await Promise.all([SelectClients(), SelectPlans()]);
        clientsData.value = clientsResponse.data.data;
        plansData.value = plansResponse.data.data;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('fetching.data.Error'), error);
    }
};

onMounted(fetchData);

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});

</script>

<style scoped>
.bg-green {
    background-color: #EDF8F2;

}

.input-group-text {
    /* font-family: MadaniArabic; */
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #24A259;
}
</style>