import { ref } from "vue";
import axiosClient from "./axiosClient"

export function getSubscriptions(page = 1 , searchQuery = '') {
  const url =  ref('');
  if(searchQuery){
   url.value =  `v1/dashboard/subscripion?page=${page}&per_page=10&search=${searchQuery}` ;
  }else{
   url.value =  `v1/dashboard/subscripion?page=${page}&per_page=10` ;

  }
  return axiosClient({
                   url   : url.value,
                 })
}
  export function addSubscription(data) {
    return axiosClient({
                     url   : `v1/dashboard/subscripion/store`,
                     method: 'post',
                     data
                   })
  }
  export function EditSubscription(data ,id) {
    return axiosClient({
                     url   : `v1/dashboard/subscripion/update/${id}`,
                     method: 'post',
                     data
                   })
  }
  export function deleteSubscription(id) {
    return axiosClient({
                     url   : `v1/dashboard/subscripion/plan/delete/${id}`,
                     method: 'DELETE',
                   })
  }