<template>
    <div class="modal fade" :class="{ 'show': showEdit }" id="editProduct" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-edit-product">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Product.Edit') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <!-- Name -->
                        <div class="col-12 ">
                            <label class="form-label" for="name">{{ $t('Sections.Product.Name') }}</label>
                            <input :class="{ 'border-danger': errors.name }" type="text" id="name" v-model="name"
                                class="form-control" :placeholder="$t('General.Enter')+' '+$t('Sections.Product.Name') ">
                            <span class="text-danger">{{ errors.name }}</span>
                        </div>

                        <!-- Barcode -->
                        <div class="col-12 ">
                            <label class="form-label" for="barcode">{{ $t('Sections.Product.Barcode') }}</label>
                            <input :class="{ 'border-danger': errors.barcode }" type="text" id="barcode"
                                v-model="barcode" class="form-control" :placeholder="$t('General.Enter')+' '+$t('Sections.Product.Barcode') ">
                            <span class="text-danger">{{ errors.barcode }}</span>
                        </div>

                        <!-- SKU -->
                        <div class="col-12 ">
                            <label class="form-label" for="sku">{{ $t('Sections.Product.SKU') }}</label>
                            <input :class="{ 'border-danger': errors.sku }" type="text" id="sku" v-model="sku"
                                class="form-control" :placeholder="$t('General.Enter')+' '+$t('Sections.Product.SKU') ">
                            <span class="text-danger">{{ errors.sku }}</span>
                        </div>

                        <!-- Price -->

                        <div class="col-12 col-md-6">
                            <label class="form-label" for="price">{{ $t('General.Paid') }}</label>
                            <div class="input-group  bg-green ">
                                <input :class="{ 'border-danger': errors.price }" type="number" id="price"
                                    v-model="price" class="form-control border-none"
                                     :placeholder="$t('General.Enter')+' '+$t('General.Paid') ">
                                <span class="input-group-text border-none ">SAR</span>
                            </div>
                            <span class="text-danger">{{ errors.price }}</span>
                        </div>
                        <!-- Quantity -->
                        <div class="col-12  col-md-6  d-flex ">
                            <label class="form-label me-3">{{ $t('General.Quantity') }}</label>
                           
                            <div class=" duration-container mt-auto">
                                <!-- Decrement Button -->

                                <div class="btn-round" id="decrement" @click="decrementQuantity">
                                    &#8722; <!-- Minus Sign -->
                                </div>

                                <!-- Duration Value -->
                                <input type="number" v-model="quantity" class="form-control duration-value " min="0">

                                <!-- Increment Button -->
                                <div class="btn-round btn-green" id="increment" @click="incrementQuantity">
                                    &#43; <!-- Plus Sign -->
                                </div>
                            </div>

                        </div>

                        <!-- Has Serial Number -->
                        <div class="col-12 d-flex align-items-center">
                            <input :class="{ 'border-danger': errors.HasSerialNumber }" type="checkbox"
                                id="hasSerialNumber" v-model="has_serial_number"
                                class="form-check-input me-2 rounded-circle custom-checkbox">
                            <label class="form-label mb-0" for="hasSerialNumber">{{ $t('General.HasSerialNumber')
                                }}</label>
                        </div>


                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5" @click="closeModel"
                                aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n.js';
import { toast } from 'vue3-toastify';
import { updateProduct } from '@/services/product';

const emit = defineEmits(['close-edit']);

const showEdit = ref(true);
const loading = ref(false);

const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        name: yup.string().required(i18n.global.t('Validation.Required.Name')),
        barcode: yup.string().required(i18n.global.t('Validation.Required.Barcode')),
        sku: yup.string().required(i18n.global.t('Validation.Required.SKU')),
        price: yup.number().required(i18n.global.t('Validation.Required.Price')),
    })
});

const props = defineProps({
    row: {
        type: Object,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true
    },

});
const eRow = ref(props.row);
const quantity = ref(eRow.value.quantity);

const { value: name } = useField('name');
name.value = eRow.value.name;
const { value: barcode } = useField('barcode');
barcode.value = eRow.value.barcode;
const { value: sku } = useField('sku');
sku.value = eRow.value.sku;
const { value: price } = useField('price');
price.value = eRow.value.price;
const check = eRow.value.has_serial_number ? true : false;
const has_serial_number = ref(check);


const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        const productData = {
            ...values,
            has_serial_number: has_serial_number.value,
            quantity: quantity.value,
        };
        await updateProduct(productData, eRow.value.id);
        props.onEdit();
        emit('close-edit', false);
        toast.success(i18n.global.t('Messages.Success.Edited'), { autoClose: 3000 });
    } catch (e) {
        console.error(i18n.global.t('Messages.Error.SubmitError'), e);
    } finally {
        loading.value = false;
    }
});

function closeModel() {
    emit('close-edit', false);
}

const incrementQuantity = () => {
    quantity.value++;
};

const decrementQuantity = () => {
    if (quantity.value > 0) quantity.value--;
};

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
  if (modal.value && !modal.value.contains(event.target) ) {
    closeModel();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});

</script>
<style scoped>
.custom-checkbox {
    width: 1.25em;
    height: 1.25em;
    border-radius: 50%;
    /* Make it circular */
    border: 2px solid #ccc;
    appearance: none;
    /* Remove default styling */
    cursor: pointer;
}

.custom-checkbox:checked {
    background-color: #0d6efd;
    /* Bootstrap primary color */
    border-color: #0d6efd;
}

.custom-checkbox:checked::before {
    content: '';
    display: block;
    width: 0.5em;
    height: 0.5em;
    background-color: #fff;
    border-radius: 50%;
    margin: auto;
    position: relative;
    top: 2px;
}

.bg-green {
    background-color: #EDF8F2;

}

.input-group-text {
    /* font-family: MadaniArabic; */
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #24A259;
}

.duration-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.btn-round {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 50%;
    color: #444;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.btn-round:hover {
    background-color: #24A259;
    color: #FFFFFF;
}

.btn-green {
    background-color: #24A259;
    color: #FFFFFF;
}

.duration-value {
    border: 1px solid #24A259;
    color: #198754;
    margin: 0px 16px 0px 16px;
    /* Bootstrap success color */
    font-size: 1.25rem;
    font-weight: bold;
    padding: 12px;
    text-align: center;
    border-radius: 8px;
    width: 60px;
    font-family: SF Pro Display;
    font-size: 17.99px;
    font-weight: 500;
    line-height: 17.99px;
    text-align: center;
}

.custom-checkbox:checked::before {
    width: 10px !important;
    height: 10px !important;
    background-color: #fff !important;
}

.custom-checkbox:checked {
    background-color: #24A259 !important;
    border: 2px solid #24A259 !important;
}
</style>