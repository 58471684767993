<template>
    <div class="modal fade" :class="{ 'show': showEdit }" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Orders.ChangeStatus') }}</h4>
                    </div>
                    <div class="row g-6 mb-3">
                        <div class="col-6 mb-3 pt-4">
                            <h3> {{ $t('General.Select.SelectZidStatus') }} : </h3>
                        </div>
                        <div class="col-6 mb-3 pt-8">
                            <CustomDropdownButton :data="zid" @status-changed="updateStatusZid" />
                        </div>
                        <div class="col-6 mb-3 ">
                            <h3> {{ $t('General.Select.Warehouse') }} : </h3>
                        </div>
                        <div class="col-6 mb-3" >
                            <select id="category" v-model="warehouse_id" class="form-select">
                                <option v-for="warehouse in warehousesData" :key="warehouse.id" :value="warehouse.id">
                                    {{ warehouse.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-3">
                            <h3> {{ $t('General.Select.SelectDqqStatus') }} :</h3>

                        </div>
                        <div class="col-6 mb-3 pt-4">
                            <CustomDropdownButton :data="dqq" @status-changed="updateStatus" />
                        </div>

                        <div class="col-12 text-center d-flex justify-content-center gap-2 el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                @click="onSubmit" :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">{{ $t('General.Loading') }}...</span>
                                </span>
                                <span v-else class="px-6">{{ $t('Sections.Orders.Edit') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5"
                                data-bs-dismiss="modal" @click="closeModel" aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import i18n from '@/i18n';
// import { toast } from 'vue3-toastify';
import CustomDropdownButton from '@/components/forms/CustomDropdownButton.vue';
import { SelectWarehouses } from '@/services/warehouse';

const emit = defineEmits(['close-edit']);

const props = defineProps({
    zid: {
        type: Function,
        required: true,
    },
    dqq: {
        type: Function,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true,
    },
});

const showEdit = ref(true);
const loading = ref(false);

const zid_status_modal = ref('');
const dqq_Status_modal = ref('');


const warehouse_id = ref(null);
const warehousesData = ref([]);

const fetchWarehouse = async () => {
    try {
        const warehousesResponse = await SelectWarehouses();
        warehousesData.value = warehousesResponse.data.data;
    } catch (error) {
        console.error(error);
    }
};
onMounted(() => {
    fetchWarehouse();
});



const onSubmit = () => {
    try {
        loading.value = true;
        const emitData = { zid_status_modal, dqq_Status_modal };
        if (zid_status_modal.value === 'ready') {
            emitData.warehouse_id = warehouse_id.value;
        }
        
        emit("status-changed", emitData);
        closeModel();
        props.onEdit();
    } catch (error) {
        console.error(i18n.global.t('Messages.Error.SubmitError'), error);
    } finally {
        loading.value = false;
    }
};

const updateStatus = (newStatusId) => {
    // console.log("Selected status ID:", newStatusId);
    dqq_Status_modal.value = newStatusId;
};
const updateStatusZid = (newStatusId) => {
    // console.log("Selected status ID:", newStatusId);
    zid_status_modal.value = newStatusId;
};


const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});


function closeModel() {
    emit('close-edit', false);
}
</script>
