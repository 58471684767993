<template>
    <select 
      v-model="localCountryIds"
      id="countryMultiSelect"
      class="form-control form-select"
      ref="countryMultiSelect"
      multiple
    >
      <option
        v-for="country in countriesData"
        :key="country.id"
        :value="country.id"
        :data-flag="country.flag"
      >
        {{ country.name }}
      </option>
    </select>
  </template>
  
  <script setup>
  import { ref, watch, onMounted, onBeforeUnmount } from "vue";
  import "select2/dist/css/select2.min.css";
  import $ from "jquery";
  import "select2";
  
  // Props
  const props = defineProps({
    countriesData: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array, // Expect an array for multi-select
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "Select countries...",
    },
  });
  
  // Emit
  const emit = defineEmits(["update:modelValue"]);
  
  // Refs and State
  const countryMultiSelect = ref(null);
  // const localCountryIds = ref([...props.modelValue]); // Sync local state with modelValue
  
  // Format country display with flags
  const formatCountry = (country) => {
    if (!country.id) return country.text; // For placeholder
    const flag = $(country.element).data("flag");
    return `<span><img src="${flag}" width="20" class="me-2" /> ${country.text}</span>`;
  };
  
  // Initialize Select2 Multi-Select
  const initSelect2 = () => {
    $(countryMultiSelect.value)
      .select2({
        placeholder: props.placeholder,
        // data: props.countriesData.map((country) => ({
        //   id: country.id,
        //   text: country.name,
        // })),
        templateResult: formatCountry,
        templateSelection: formatCountry,
        escapeMarkup: (markup) => markup,
        width: "100%",
        minimumResultsForSearch: 0,
      })
      .val(props.modelValue) // Set initial values
      .trigger("change");
      // .on("change", () => {
      //   // Emit selected values when selection changes
      //   // const arr = props.modelValue ;
      //   // const selectedIds = $(countryMultiSelect.value).val().map((id) => parseInt(id, 10)).filter((id) => !arr.includes(id));
      //   // console.log([...arr , ...selectedIds]);
        
      //   //   localCountryIds.value = [...arr , ...selectedIds];
        
      //   // emit("update:modelValue",[...arr , ...selectedIds]);
      //   const selected = $(countryMultiSelect.value).val() || [];
      //   emit("update:modelValue", selected);
      // });
      $(countryMultiSelect.value).on("change", () => {
      const selected = $(countryMultiSelect.value).val() || [];
      emit("update:modelValue", selected);
    });
  };
  
  // Watchers
  // Watch for changes in modelValue and sync with Select2
  // watch(
  //   () => props.modelValue,
  //   (newValues) => {
     
  //     localCountryIds.value = [...newValues];
     
     

  //   }
  // );
  
  // Re-initialize Select2 when countriesData updates
  watch(
    () => props.countriesData,
    () => {
      $(countryMultiSelect.value).select2("destroy");
      initSelect2();
    }
  );
  
  // Lifecycle Hooks
  onMounted(() => {
    initSelect2();
  });
  
  onBeforeUnmount(() => {
    $(countryMultiSelect.value).select2("destroy");
  });
  </script>
  
  <style scoped>
  /* Ensure dropdown has proper z-index */
  .select2-container--open {
    z-index: 9999 !important;
  }
  

  
  .select2-selection--multiple .select2-selection__rendered {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .select2-selection--multiple .select2-selection__choice {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .select2-selection__choice__remove {
    color: #999 !important;
  }
  
  </style>
  