<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">

            <!--order-list-->
            <div class="order-list bg-white shadow-lg rounded p-4 mt-5 shadow">
                <div class="d-flex justify-content-between">
                    <h2 class="title fw-semibold fs-4"><i class='bx bxs-package fs-2 text-primary'></i> {{
                        $t('Sections.Orders.OrdersList') }}</h2>
                    <div class="dt-action-buttons text-end pt-6 pt-md-0">
                        <div class="dt-buttons btn-group flex-wrap">

                            <router-link :to="{ name: 'Orders-add' }" class="btn btn-secondary create-new btn-primary"
                                tabindex="0" aria-controls="DataTables_Table_0" type="button"><span>
                                    <span class="d-none d-sm-inline-block  me-sm-3">
                                        {{ $t('Sections.Orders.AddNew') }}
                                    </span><i class="bx bx-plus bx-sm "></i>
                                </span>
                            </router-link>
                        </div>
                    </div>

                </div>



                <div class="order-list-tabs">
                    <ul class="d-flex align-items-end justify-content-start m-0 p-0 text-capitalize text-semibold"
                        style="gap: 35px;">
                        <li class="order-list-tabs-item py-4  " :class="{ 'active ': tab == 'all' }"
                            @click.prevent="changeTab('all')">
                            {{ $t("Entities.All") }}
                            <span class="bg-green px-2 py-1 rounded-pill fs-6 text-primary">{{ tabAll ?? "00"
                                }}</span>
                        </li>
                        <li class="order-list-tabs-item py-4 " :class="{ 'active ': tab == 'new' }"
                            @click.prevent="changeTab('new')">
                            {{ $t("Entities.New") }}
                            <span class="bg-green px-2 py-1 rounded-pill fs-6 text-primary">{{ tabNew ?? "00" }}</span>
                        </li>
                        <li class="order-list-tabs-item py-4 " :class="{ 'active ': tab == 'ready' }"
                            @click.prevent="changeTab('ready')">
                            {{ $t("Entities.Ready") }}
                            <span class="bg-green px-2 py-1 rounded-pill fs-6 text-primary">{{ tabReady ?? "00"
                                }}</span>
                        </li>
                        <li class="order-list-tabs-item py-4 " :class="{ 'active ': tab == 'delivery' }"
                            @click.prevent="changeTab('delivery')">
                            {{ $t("Entities.Shipping") }}
                            <span class="bg-green px-2 py-1 rounded-pill fs-6 text-primary">{{ tabDelivery ?? "00"
                                }}</span>
                        </li>
                        <li class="order-list-tabs-item py-4 " :class="{ 'active ': tab == 'completed' }"
                            @click.prevent="changeTab('completed')">
                            {{ $t("Entities.Completed") }}

                            <span class="bg-green px-2 py-1 rounded-pill fs-6 text-primary">{{ tabCompleted ?? "00"
                                }}</span>
                        </li>
                        <li class="order-list-tabs-item py-4 " :class="{ 'active ': tab == 'cancelled' }"
                            @click.prevent="changeTab('cancelled')">
                            {{ $t("Entities.Cancelled") }}
                            <span class="bg-green px-2 py-1 rounded-pill fs-6 text-primary">{{ tabCancelled ?? "00"
                                }}</span>
                        </li>
                        <li class="order-list-tabs-item py-4 "
                            :class="{ 'active ': tab == 'return_in_progress_orders' }"
                            @click.prevent="changeTab('return_in_progress_orders')">
                            {{ $t("Entities.return_in_progress_orders") }}
                            <span class="bg-green px-2 py-1 rounded-pill fs-6 text-primary">{{ tabProgress ?? "00"
                                }}</span>
                        </li>
                        <li class="order-list-tabs-item py-4 " :class="{ 'active ': tab == 'reversed' }"
                            @click.prevent="changeTab('reverseed')">
                            {{ $t("Entities.Returned") }}

                            <span class="bg-green px-2 py-1 rounded-pill fs-6 text-primary">{{ tabReverseed ?? "00"
                                }}</span>
                        </li>

                    </ul>
                </div>

                <!--Filters-->
                <div class="input-group m-3">
                    <span class="input-group-text bg-primary text-white" @click.prevent="toggleFilters">
                        <img class="mx-3" :src="filtersIcon" alt="Filters Icon" /> <span class="hidden-filter"> {{
                            $t("Entities.Filters") }}</span> <i class='mx-3 bx'
                            :class="!filterToggle ? 'bx-chevron-down' : 'bx-chevron-up'"></i></span>
                    <input type="text" v-model.lazy="searchQuery" class="form-control bg-green shadow-none outline-none"
                        aria-label="Amount (to the nearest dollar)">
                    <button @click.prevent="OrderSearchFunction()" :disabled="loading"
                        class="input-group-text search-btn me-5  bg-primary text-white">
                        <img class="mx-3" :src="searchIcon" alt="search Icon" />

                        <span v-if="loading">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="visually-hidden">Loading...</span>
                        </span>

                        <span v-else class="hidden-filter"> {{ $t("General.Search") }} </span></button>
                </div>

                <transition-group name="fade" tag="div" class="badges d-flex flex-wrap gap-2 ms-3 mt-3">
                    <button class="badge-btn" v-if="translatedFilters?.length > 1" @click="appiedFiltersEmpty()">
                        <span>{{ $t('General.Reset') }}</span>
                        <img :src="closeIcon" alt="close Icon" />
                    </button>
                    <div v-for="(badge, index) in translatedFilters" :key="index" class="badge-btn">
                        <span>{{ badge }}</span>
                        <button>
                            <img :src="closeIcon" alt="close Icon" @click.prevent="removeFilter(badge)" />
                        </button>
                    </div>
                </transition-group>
                <transition-group name="fade">
                    <div class="row  text-center filter-top" v-if="filterToggle">


                        <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12  filter-col">
                            <div class="dropdown order-dropdown">
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ zid_status_name ?? $t('General.Select.ZidStatus') }}
                                </button>
                                <ul class="dropdown-menu" style="width: 100%;" aria-labelledby="dropdownMenuButton1">
                                    <li v-if="zid_status_name">
                                        <a class="dropdown-item" href="#" @click.prevent="SelectZidStatus(null, null)">
                                            {{ $t("General.Select.ZidStatus") }}
                                        </a>
                                    </li>
                                    <li v-for="status in ZidData" :key="status.id">
                                        <a class="dropdown-item" href="#"
                                            @click.prevent="SelectZidStatus(status.id, status.name)">
                                            {{ status.name }}
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12 filter-col">
                            <div class="dropdown order-dropdown">
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ SelectProductName ?? $t('General.AllProducts') }}
                                </button>
                                <ul class="dropdown-menu" style="width: 100%;" aria-labelledby="dropdownMenuButton1">
                                    <!-- Search input -->
                                    <li>
                                        <input type="text" v-model="searchProduct" placeholder="Search products..."
                                            class="form-control search" />
                                    </li>
                                    <li v-if="!SelectProductName">
                                        <a class="dropdown-item" href="#" @click.prevent="SelectProduct(null, null)">
                                            {{ $t("General.AllProducts") }}
                                        </a>
                                    </li>
                                    <!-- Product list -->
                                    <li v-for="(product, index) in filteredProducts" :key="index">
                                        <div class="row">
                                            <div class="col-8">
                                                <a class="dropdown-item" href="#"
                                                    @click.prevent="SelectProduct(product.reference_number, product.name)">
                                                    {{ product.name }}
                                                </a>
                                            </div>
                                            <div class="col-4">
                                                <span class="bg-green  rounded-pill fs-6 text-primary">
                                                    {{ product.total_orders }}
                                                </span>
                                            </div>
                                        </div>


                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div class="col-lg-3 col-sm-6 col-xs-12 col-md-6  filter-col">
                            <input type="number" class="form-control " min="0" v-model="quantityValue"
                                :placeholder="$t('General.QuantityProducts')" @keydown="handleEnterQuantity">
                        </div>
                        <div class="col-lg-3 col-sm-6 col-xs-12 col-md-6  filter-col">
                            <input type="number" class="form-control " min="0" v-model="numProduct"
                                :placeholder="$t('General.NumberProducts')" @keydown="handleEnterNum">
                        </div>
                    </div>
                    <div class="row text-center" style="padding:0px 30px ;" v-if="filterToggle">

                        <div class="col-lg-3 col-sm-6 col-xs-12 col-md-6   filter-col ">
                            <div class="dropdown order-dropdown">
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ $t("Entities.PaymentStatus") }}
                                </button>
                                <ul class="dropdown-menu" style="width: 100%;" aria-labelledby="dropdownMenuButton1">
                                    <li v-for="(payment, index) in paymentStatus" :key="index">
                                        <a class="dropdown-item" href="#"
                                            @click.prevent="changePaymentStatus(payment.value, payment.name)">
                                            {{ payment.name }}
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-xs-12 col-md-6  filter-col">
                            <div class="dropdown order-dropdown">
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ $t('General.AllPaymentMethods') }}
                                </button>
                                <ul class="dropdown-menu" style="width: 100%;" aria-labelledby="dropdownMenuButton1">
                                    <!-- Search input -->
                                    <li>
                                        <input type="text" v-model="searchPaymentMethod"
                                            :placeholder="$t('General.AllPaymentMethods')"
                                            class="form-control search" />
                                    </li>

                                    <!-- Payment method list -->
                                    <li v-for="(method, index) in filteredPaymentMethods" :key="index">
                                        <a class="dropdown-item" href="#"
                                            @click.prevent="SelectPaymentMethod(method.code, method.name)">
                                            {{ method.name }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-12 col-md-6  filter-col">
                            <div class="dropdown order-dropdown">
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ order_print_waybill_name ?? $t('General.PrintWaybill') }}
                                </button>
                                <ul class="dropdown-menu" style="width: 100%;" aria-labelledby="dropdownMenuButton1">
                                    <li v-for="(waybill, index) in printWaybill" :key="index">
                                        <a class="dropdown-item" href="#"
                                            @click.prevent="changeWaybill(waybill.value, waybill.name)">
                                            {{ waybill.name }}
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-12 col-md-6  filter-col">
                            <div class="dropdown order-dropdown">
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ $t('General.AllDeliveryCompanies') }}
                                </button>
                                <ul class="dropdown-menu" style="width: 100%;" aria-labelledby="dropdownMenuButton1">
                                    <!-- Search input -->
                                    <li>
                                        <input type="text" v-model="searchDeliveryCompany"
                                            :placeholder="$t('General.AllDeliveryCompanies')"
                                            class="form-control search" />
                                    </li>
                                    <!-- Delivery company list -->
                                    <li v-for="(company, index) in filteredDeliveryCompanies" :key="index">
                                        <a class="dropdown-item" href="#"
                                            @click.prevent="SelectDeliveryCompany(company.id, company.name)">
                                            {{ company.name }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div class="row text-center filter-top" v-if="filterToggle">
                        <div class="col-lg-3 col-sm-6 col-md-6  filter-col">
                            <div class="dropdown order-dropdown">
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ $t('General.AllCities') }}
                                </button>
                                <ul class="dropdown-menu" style="width: 100%;" aria-labelledby="dropdownMenuButton1">
                                    <!-- Search input -->
                                    <li>
                                        <input type="text" v-model="searchCity" :placeholder="$t('General.AllCities')"
                                            class="form-control search" />
                                    </li>
                                    <!-- Product list -->
                                    <li v-for="(city, index) in filteredCities" :key="index">
                                        <a class="dropdown-item" href="#"
                                            @click.prevent="SelectCity(city.id, city.ar_name)">
                                            {{ getname(city) }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </transition-group>

                <!--Show Result-->
                <div class="show-result d-flex align-items-center justify-content-between gap-5 mt-5 pt-5">
                    <div class=" filters d-flex align-items-center justify-content-center gap-4">
                        <h4 class="mt-2">{{ $t('Sections.Orders.NumberOf') }}</h4>
                        <div class="dropdown order-dropdown">
                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                {{ perPage }} {{ $t('Entities.Orders') }}
                            </button>
                            <div class="dropdown order-dropdown">
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a class="dropdown-item" @click.prevent="updatePerPage(200)" href="#">200 {{
                                        $t('Entities.Orders') }}</a>
                                    </li>
                                    <li><a class="dropdown-item" @click.prevent="updatePerPage(100)" href="#">100 {{
                                        $t('Entities.Orders') }}</a>
                                    </li>
                                    <li><a class="dropdown-item" @click.prevent="updatePerPage(50)" href="#">50 {{
                                        $t('Entities.Orders') }}</a>
                                    </li>
                                    <li><a class="dropdown-item" @click.prevent="updatePerPage(20)" href="#">20 {{
                                        $t('Entities.Orders') }}</a>
                                    </li>
                                    <li><a class="dropdown-item" @click.prevent="updatePerPage(10)" href="#">10 {{
                                        $t('Entities.Orders') }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class=" filters d-flex align-items-center justify-content-center gap-4">


                        <!-- <button class="btn bg-green  text-primary"><i class='bx bx-sort-alt-2 '></i> Sort by created
                            date <i class='bx bxs-chevron-down'></i></button> -->
                        <div>

                            <div style="position: relative;" ref="modal">
                                <!-- Trigger Button -->
                                <button class="btn bg-primary text-white fs-6" type="button" :disabled="isLoading"
                                    @click.prevent="toggleCalendar">
                                    <span v-if="isLoading">🔄 Loading...</span>
                                    <span v-else>
                                        <i class="bx bx-calendar-alt"></i>
                                        {{ formatDateToDMY(startDate) }} - {{ formatDateToDMY(endDate) }}
                                        <i class="bx bxs-chevron-down"></i>
                                    </span>

                                </button>
                                <DatePicker :show="showCalendar" ref="pickDate" v-model:startDate="startDate"
                                    v-model:endDate="endDate" v-model:sort_value="sort_value"
                                    v-model:date_type="date_type" />



                            </div>


                        </div>


                    </div>
                </div>
            </div>


            <!--Result Table-->
            <div
                class="result-table bg-white shadow-lg d-flex justify-content-between align-items-center rounded p-4 mt-6 shadow">
                <h4 class="text-primary fs-5">{{ $t('Entities.Result') }}</h4>
                <div class=" tabs-buttons d-flex gap-3">
                    <button @click.prevent="onImportRow" class="btn succ-btn ">
                        {{ $t('Sections.Product.Import') }}
                    </button>
                    <button @click.prevent="syncChangeStatus" :disabled="selectedCount < 1" class="btn blue-btn">
                        {{ $t('Sections.Orders.ChangeStatus') }}
                    </button>
                    <button class="btn text-primary bg-green" :disabled="selectedCount < 1 || loadingPrint"
                        @click="exportPrint">
                        <div v-if="loadingPrint" class="loading-spinner">
                            <i class="bx bx-loader-alt bx-spin"></i> {{ $t('General.Loading') }}
                        </div>
                        <div>

                            <i class='bx bx-printer mx-1'></i>
                            {{ $t('General.Print') }}
                        </div>

                    </button>


                    <div class="dropdown">
                        <a class="btn export-btn text-white dropdown-toggle" href="#" role="button"
                            id="dropdownExportMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            <div v-if="loadingExcel" class="loading-spinner">
                                <i class="bx bx-loader-alt bx-spin"></i> {{ $t('General.Loading') }}
                            </div>
                            <template v-else>
                                <i class='bx bx-export mx-1'></i> {{ $t('General.Export') }}
                            </template>
                        </a>

                        <ul class="dropdown-menu" aria-labelledby="dropdownExportMenuLink">
                            <li><a class="dropdown-item" href="#" @click="exportExcelAll" :disabled="loadingExcel">
                                    {{ $t('General.ExportAllOrders') }}
                                </a></li>
                            <li><button class="dropdown-item" :disabled="selectedCount < 1 || loadingExcel"
                                    @click="exportExcel" href="#">{{
                                        $t('General.ExportSelectedOrders') }}</button></li>
                            <li><button class="dropdown-item" href="#" :disabled="selectedCount < 1 || loadingPdf"
                                    @click="exportPDF">
                                    <div v-if="loadingPdf" class="loading-spinner">
                                        <i class="bx bx-loader-alt bx-spin"></i> {{ $t('General.Loading') }}
                                    </div>
                                    <div>
                                        {{
                                            $t('General.ExportPDF')
                                        }}

                                    </div>
                                </button></li>
                        </ul>

                        <!-- Loading Spinner (this can be any loading spinner you like) -->

                    </div>
                </div>
            </div>


            <DataTable v-model:rows="OrdersData.data" :headers="headers" :fields="fields" :total="total"
                :loading="loading" :tripper="false" @page-changed="onPageChanged" :itemsPerPage="perPage">

                <template v-slot:table-header>
                    <th class="text-center">
                        #
                    </th>
                    <th class="text-center">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="selectAll"
                                @change="toggleSelectAll">
                        </div>
                    </th>
                    <th class="text-center" :class="{ 'd-flex': date_type == 'reference_number' }"
                        @click="SortByTable('reference_number')">
                        <span>
                            {{ $t('Sections.Orders.NumberOrder') }}
                        </span>
                        <div v-if="date_type == 'reference_number'">
                            <i v-if="sort_value == 'asc'" class='bx bxs-up-arrow-alt'></i>
                            <i v-if="sort_value == 'desc'" class='bx bxs-down-arrow-alt'></i>
                        </div>


                    </th>
                    <th :class="{ 'd-flex': date_type == 'client_id' }" class="text-center"
                        v-if="authStore.hasPermission('view-clients')">
                        <span>{{ $t('Entities.Clients') }}
                        </span>
                        <div v-if="date_type == 'client_id'">
                            <i v-if="sort_value == 'asc'" class='bx bxs-up-arrow-alt'></i>
                            <i v-if="sort_value == 'desc'" class='bx bxs-down-arrow-alt'></i>
                        </div>
                    </th>
                    <th :class="{ 'd-flex': date_type == 'delivery.name' }" class="text-center">
                        <span>{{ $t('Entities.Shipping') }}
                        </span>
                        <div v-if="date_type == 'delivery.name'">
                            <i v-if="sort_value == 'asc'" class='bx bxs-up-arrow-alt'></i>
                            <i v-if="sort_value == 'desc'" class='bx bxs-down-arrow-alt'></i>
                        </div>
                    </th>
                    <th :class="{ 'd-flex': sort_colum == 'created_at_actually' }" class="text-center"
                        @click="SortByTable('created_at_actually')">
                        <span>{{ $t('Sections.Orders.Date') }}
                        </span>
                        <div v-if="sort_colum == 'created_at_actually'">
                            <i v-if="sort_value == 'asc'" class='bx bxs-up-arrow-alt'></i>
                            <i v-if="sort_value == 'desc'" class='bx bxs-down-arrow-alt'></i>
                        </div>
                    </th>
                    <th :class="{ 'd-flex': date_type == 'total_cost_with_currency' }" class="text-center"
                        @click="SortByTable('total_cost_with_currency')">
                        <span>{{ $t('General.Total') }}
                        </span>
                        <div v-if="date_type == 'total_cost_with_currency'">
                            <i v-if="sort_value == 'asc'" class='bx bxs-up-arrow-alt'></i>
                            <i v-if="sort_value == 'desc'" class='bx bxs-down-arrow-alt'></i>
                        </div>
                    </th>
                    <th :class="{ 'd-flex': date_type == 'status_dqq_name.name' }" class="text-center">
                        <span>{{ $t('General.DqqStatus') }}
                        </span>
                        <div v-if="date_type == 'status_dqq_name.name'">
                            <i v-if="sort_value == 'asc'" class='bx bxs-up-arrow-alt'></i>
                            <i v-if="sort_value == 'desc'" class='bx bxs-down-arrow-alt'></i>
                        </div>
                    </th>
                    <th :class="{ 'd-flex': date_type == 'status_name.name' }" class="text-center">
                        <span>{{ $t('General.ZidStatus') }}
                        </span>
                        <div v-if="date_type == 'status_name.name'">
                            <i v-if="sort_value == 'asc'" class='bx bxs-up-arrow-alt'></i>
                            <i v-if="sort_value == 'desc'" class='bx bxs-down-arrow-alt'></i>
                        </div>
                    </th>
                    <th class="text-center"> </th>


                </template>
                <template v-slot:action-buttons="{ rowColum, index }">

                    <td class="text-center align-middle">
                        {{ ((currentPage ?? 1) - 1) * (perPage ?? 10) + (index + 1) }}
                    </td>
                    <td class="text-center align-middle">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="selectedRows[rowColum.id]">
                            <!-- <i v-if="rowColum.is_gift_order" class='bx bxs-gift'></i> -->
                            <i v-if="rowColum.is_gift_order" class='bx bxs-gift' data-toggle="tooltip"
                                data-placement="top" :title="rowColum.gift_message"></i>

                        </div>
                    </td>
                    <td class="text-center align-middle">
                        <span v-if="rowColum.related_order_id">
                            {{ rowColum.reference_number }}
                        </span>
                        <span v-else>
                            {{ rowColum.reference_number }}
                        </span>
                        <div v-if="rowColum.related_order_id && rowColum.reference_number?.slice(-2) < 100"
                            class="text-center align-middle part-number">
                            <i class='bx bxs bxs-parking '></i>

                            <span style=" font-weight: bolder;"> {{
                                rowColum.reference_number?.slice(-2) }}</span>
                        </div>
                    </td>
                    <td class="text-center align-middle" v-if="authStore.hasPermission('view-clients')">
                        {{ rowColum.client?.name }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.delivery?.name }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.created_at_actually }}
                    </td>
                    <td class="text-center align-middle color-green">
                        {{ rowColum.total_cost_with_currency }}
                    </td>
                    <td class="text-center align-middle">
                        <!-- <CustomDropdownButton :data="DqqStatusData" :defaultId="rowColum.status_dqq_name.id"
                            @status-changed="updateStatus" /> -->
                        <button style="width: 100%;" class="btn text-white "
                            :style="{ backgroundColor: rowColum.status_dqq_name?.bg_color || '#ccc' }">
                            {{ rowColum.status_dqq_name?.name }}
                        </button>
                    </td>
                    <td class="text-center align-middle ">
                        <!-- <CustomDropdownButton :data="ZidData" :defaultId="rowColum.status"
                            @status-changed="updateStatus" /> -->
                        <button style="width: 100%;" class="btn text-white "
                            :style="{ backgroundColor: rowColum.status_name?.bg_color || '#ccc' }">
                            {{ rowColum.status_name?.name }}
                        </button>
                    </td>
                    <td class="text-center align-middle d-flex h-full " style="border: none !important;">

                        <router-link class="d-inline-block h-full py-2"
                            :to="{ name: 'OrderView', params: { id: rowColum.id } }">
                            <button class="btn text-primary bg-green rounded-pill" style="min-width: max-content">
                                {{ $t('General.ViewOrder') }}
                            </button>

                        </router-link>



                        <!-- <router-link :to="{ name: 'OrderView', params: { id: rowColum.id } }">
                            <button class="btn btn-xs  delete-btn rounded-pill" style="min-width: max-content">
                                <i class='bx bx-undo'></i>
                            </button>
                        </router-link> -->

                    </td>
                </template>

            </DataTable>
            <ChangeStatus v-if="showEdit" @close-edit="syncChangeStatus" @status-changed="updateStatus"
                :onEdit="OrderSearchFunction" :zid="ZidData" :dqq="DqqStatusData" />
            <ImportOrder v-if="showImport" @close-edit="onCloseImport" :onEdit="fetchData" />

        </div>
    </div>
</template>



<script setup>

import filtersIcon from '@/assets/dashboard/img/icons/dashboards/filters.svg';
import searchIcon from '@/assets/dashboard/img/icons/dashboards/search.svg';
import closeIcon from '@/assets/dashboard/img/icons/dashboards/close.svg';

import { toast } from 'vue3-toastify';

import { computed, h, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { changeOrderStatus, exportOrderExcel, exportOrderPDF, exportOrderPrint, getOrders, statisticsOrders, statisticsProductsOrders } from '@/services/order';
import DataTable from '@/components/pages/DataTable.vue';
// import { SelectClients } from '@/services/client';
import { getAllDeleveryCompany, getAllPaymentMetod, getAllZidStatus, getOrdersCities, getStatus } from '@/services/setting';
import DatePicker from '@/components/forms/DatePicker.vue';
import { formatDateToDMY, formatDateToYMD } from '@/utils/dateFormatter';
import { useAuthStore } from '@/stores/auth';
// import { getAllProducts } from '@/services/product';
import { useLoadingStore } from '@/stores/loading';
import ChangeStatus from './componants/ChangeStatus.vue';
import { getname } from '@/utils/translate';
import ImportOrder from './componants/ImportOrder.vue';


// Lazy-load DatePicker

const OrdersData = ref([]);
const currentPage = ref(1);
// const perPage = ref();
const perPage = ref(localStorage.getItem('OrderperPage') ? parseInt(localStorage.getItem('OrderperPage')) : 10);
const searchProduct = ref(null);
const searchCity = ref(null);
const searchDeliveryCompany = ref(null);
const searchPaymentMethod = ref(null);
const total = ref(null);
const loading = ref(true);
const selectAll = ref(false);
const selectedRows = ref([]);

const loadingStore = useLoadingStore();
// const clientData = ref([]);
const DqqStatusData = ref([]);
const ZidData = ref([]);
const deliveryData = ref([]);
const PaymentMethodData = ref([]);
const ProductData = ref([]);
const CityData = ref([]);

const authStore = useAuthStore();
const exportMsg = computed(() => t('Messages.ExportFile'));

const toggleSelectAll = () => {
    if (selectAll.value) {

        selectedRows.value = OrdersData.value.data.reduce((acc, order) => {
            acc[order.id] = true;
            return acc;
        }, {});
    } else {
        selectedRows.value = OrdersData.value.data.reduce((acc, order) => {
            acc[order.id] = false;
            return acc;
        }, {});
    }
};
const selectedCount = computed(() => {
    return Object.values(selectedRows.value).filter(selected => selected).length;
});
watch(selectedRows, () => {
    selectAll.value = Object.values(selectedRows.value).every(row => row) && selectedCount.value == perPage.value;
}, { deep: true });


const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};


const updatePerPage = (value) => {

    if (value != perPage.value) {
        perPage.value = value;
        localStorage.setItem('OrderperPage', value);
        fetchData();
    }

}



// const client_name = ref(null);
// const SelectClient = (id, name) => {
//     client_name.value = name;
//     client_id.value = [id];
//     OrderSearchFunction();
//     fetchProduct();


// }

const SelectZidStatus = (id, name) => {
    zid_status_name.value = name;
    zid_status.value = id;
    if (name) {
        updateFilter(t('General.ZidStatus'), name);
        // addBadges('Zid Status', name)

    } else {
        removeFilter(t('General.ZidStatus'))
    }


}
const SelectProduct = (id, name) => {

    product_id.value = id;
    if (name) {
        SelectProductName.value = name.substring(0, 20) + "...";
        updateFilter(t('General.Product'), name);

    } else {
        removeFilter(t('General.Product'))
    }


}

const changeWaybill = (id, name) => {
    order_print_waybill_name.value = name;
    order_print_waybill.value = id;
    if (name) {
        updateFilter(t('General.PrintWaybill'), name);

    } else {
        removeFilter(t('General.PrintWaybill'))
    }


}
const changePaymentStatus = (id, name) => {
    order_payment_status_name.value = name;
    order_payment_status.value = id;

    if (name) {
        updateFilter(t('General.PaymentStatus'), name);

    } else {
        removeFilter(t('General.PaymentStatus'))
    }

}

// Computed property to filter and limit products
const filteredProducts = computed(() => {
    if (searchProduct.value) {

        return ProductData.value
            .filter(product =>
                product.name.toLowerCase().includes(searchProduct.value.toLowerCase())
            )
            .slice(0, 10); // Limit to the first 10 items
    } else {

        return ProductData.value
            .slice(0, 10); // Limit to the first 10 items
    }
});

// Computed property to filter and limit products
const filteredCities = computed(() => {
    if (searchCity.value) {

        return CityData.value
            .filter(city =>
                city.ar_name.toLowerCase().includes(searchCity.value.toLowerCase())
            )
            .slice(0, 10); // Limit to the first 10 items
    } else {

        return CityData.value
            .slice(0, 10); // Limit to the first 10 items
    }
});

// Computed property to filter and limit delivery companies
const filteredDeliveryCompanies = computed(() => {
    if (searchDeliveryCompany.value) {
        // return deliveryData.value
        //     .filter(company =>
        //         company.name.toLowerCase().includes(searchDeliveryCompany.value.toLowerCase())
        //     )
        //     .slice(0, 10); // Limit to the first 10 items
        const mergedDeliveryData = Object.values(
            deliveryData.value.reduce((acc, method) => {
                const key = method.name.toLowerCase();
                if (!acc[key]) {
                    acc[key] = { ...method, id: [method.id] };
                } else {
                    acc[key].id.push(method.id);
                }
                return acc;
            }, {})
        )
            .filter(method =>
                method.name.toLowerCase().includes(searchDeliveryCompany.value.toLowerCase())
            );

        return mergedDeliveryData;
    } else {
        // return deliveryData.value.slice(0, 10); // Limit to the first 10 items
        const mergedDeliveryData = Object.values(
            deliveryData.value.reduce((acc, method) => {
                const key = method.name.toLowerCase();
                if (!acc[key]) {
                    acc[key] = { ...method, id: [method.id] };
                } else {
                    acc[key].id.push(method.id);
                }
                return acc;
            }, {})
        )

        return mergedDeliveryData;
    }
});

// Computed property to filter and limit payment methods
const filteredPaymentMethods = computed(() => {
    if (searchPaymentMethod.value) {
        const mergedPaymentMethods = Object.values(
            PaymentMethodData.value.reduce((acc, method) => {
                const key = method.name.toLowerCase();
                if (!acc[key]) {
                    acc[key] = { ...method, code: [method.code] };
                } else {
                    acc[key].code.push(method.code);
                }
                return acc;
            }, {})
        )
            .filter(method =>
                method.name.toLowerCase().includes(searchPaymentMethod.value.toLowerCase())
            );

        return mergedPaymentMethods;
    } else {
        // return PaymentMethodData.value.slice(0, 10); // Limit to the first 10 items
        const mergedPaymentMethods = Object.values(
            PaymentMethodData.value.reduce((acc, method) => {
                const key = method.name.toLowerCase();
                if (!acc[key]) {
                    acc[key] = { ...method, code: [method.code] };
                } else {
                    acc[key].code.push(method.code);
                }
                return acc;
            }, {})
        );

        return mergedPaymentMethods;
    }
});

const tabs = {
    all: [null],
    new: [0],
    ready: [1],
    delivery: [3],
    cancelled: [4],
    completed: [5],
    reverseed: [7],
    return_in_progress_orders: [6],
};
const changeTab = (name) => {

    if (!loading.value) {

        if (tabs[name]) {
            const [code] = tabs[name];
            if (code == dqq_Status.value) {
                console.log("same tab");

            } else {
                dqq_Status.value = code;
                tab.value = name
                fetchData();

            }


        } else {
            alert(name)
            console.warn('Invalid tab name');
        }
    }

};

const SortByTable = (value) => {
    if (sort_colum.value == value) {
        if (sort_value.value == 'desc') {
            sort_value.value = 'asc'
        } else {
            sort_value.value = 'desc'

        }
    } else {
        sort_value.value = 'desc'

    }
    sort_colum.value = value;

    fetchData();

}


const zid_status_name = ref(null);
const SelectProductName = ref(null);

const order_print_waybill_name = ref(null);
const order_payment_status_name = ref(null);

const formattedStartDate = new Date(1970, 0, 1); // January 1, 1970
const earliestDate = formattedStartDate.toISOString().split('T')[0];
// Reactive state for start and end dates
const tab = ref('all');
const startDate = ref(earliestDate); // Call the function to get its return value
const endDate = ref(getTodayDate()); // Call the function to get its return value
const client_id = ref(null);

const date_type = ref('created_date');
const sort_value = ref('desc');//desc and asc 
const sort_colum = ref('created_at_actually')
const searchQuery = ref('');
const product_id = ref(null);
const city_id = ref([]);
const delivery_company_id = ref([]);
const payment_method_id = ref([]);
const dqq_Status = ref(null);
const zid_status = ref(null);
const quantityValue = ref(null);
const numProduct = ref(null);
const order_print_waybill = ref(null);
const order_payment_status = ref(null);

const count = ref(0);



const { locale } = useI18n();
// Declare a reactive reference for the current language
watch(locale, () => {
    appliedFilters.splice(0, appliedFilters.length);

    fetchData();
    fetchZidStatus();
    fetchDqqStatus();

});


const appiedFiltersEmpty = () => {
    // console.log(appliedFilters);

    appliedFilters.splice(0, appliedFilters.length);
    zid_status.value = null;
    zid_status_name.value = null;
    SelectProductName.value = null;
    product_id.value = null;
    order_print_waybill.value = null;
    order_print_waybill_name.value = null;
    order_payment_status.value = null;
    order_payment_status_name.value = null;
    quantityValue.value = null;
    numProduct.value = null;
    delivery_company_id.value = [];
    payment_method_id.value = [];
    city_id.value = [];
    // OrderSearchFunction();
    // console.log(appliedFilters);


}




const headers = [];
const fields = [];


const tabAll = ref(null);
const tabNew = ref(null);
const tabReady = ref(null);
const tabDelivery = ref(null);
const tabCompleted = ref(null);
const tabCancelled = ref(null);
const tabReverseed = ref(null);
const tabProgress = ref(null);

watch([startDate, endDate], () => {
    startDate.value = formatDateToYMD(startDate.value);
    endDate.value = formatDateToYMD(endDate.value);
});

const errorMessage = computed(() => t('Messages.Error.FetchData'));

const fetchData = async () => {
    try {
        loading.value = true;
        selectedRows.value = [];
        selectAll.value = false;


        const response = await getOrders(
            currentPage.value,
            perPage.value,
            searchQuery.value,
            client_id.value ?? null,
            dqq_Status.value ?? null,
            zid_status.value ?? null,
            product_id.value ?? null,
            city_id.value ?? null,
            quantityValue.value ?? null,
            numProduct.value ?? null,
            order_print_waybill.value ?? null,
            order_payment_status.value ?? null,
            delivery_company_id.value ?? [],
            payment_method_id.value ?? [],
            startDate.value,
            endDate.value,
            date_type.value,
            sort_value.value,
            sort_colum.value
        );
        //         const tabs = {
        //     all: [null],
        //     new: [0],
        //     ready: [1],
        //     delivery: [3],
        //     cancelled: [4],
        //     completed: [5],
        //     reverseed: [7],
        //     return_in_progress_orders: [6],
        // };

        OrdersData.value = response.data.data.orders;

        total.value = response.data.data.orders.total;

        const statusMap = {
            0: tabNew,
            1: tabReady,
            3: tabDelivery,
            4: tabCompleted,
            5: tabCancelled,
            6: tabProgress,
            7: tabReverseed,
        };

        if (dqq_Status.value && dqq_Status.value !== undefined && statusMap[dqq_Status.value]) {
            statusMap[dqq_Status.value].value = total.value;
        }
        count.value = 1
        currentPage.value = response.data.data.orders.current_page;
        loading.value = false;
        // ProductData.value = response.data.data.products;

        // Lazy load additional data
        // if (clientData.value?.length < 1) fetchClient();
        if (PaymentMethodData.value?.length < 1) fetchPaymentMethod();
        if (ZidData.value?.length < 1) fetchZidStatus();
        if (DqqStatusData.value?.length < 1) fetchDqqStatus();
        if (deliveryData.value?.length < 1) fetchDeleveryCompanies();
        if (CityData.value?.length < 1) fetchCity();
    } catch (error) {
        loading.value = false;
        console.error(errorMessage.value, error);
    } finally {
        loading.value = false;
    }
};

const fetchStatistic = async () => {


    try {
        const response = await statisticsOrders(
            searchQuery.value,
            client_id.value ?? null,
            // dqq_Status.value ?? null,
            zid_status.value ?? null,
            product_id.value ?? null,
            city_id.value ?? null,
            quantityValue.value ?? null,
            numProduct.value ?? null,
            order_print_waybill.value ?? null,
            order_payment_status.value ?? null,
            delivery_company_id.value ?? null,
            payment_method_id.value ?? null,
            startDate.value,
            endDate.value,
            date_type.value,
            sort_value.value,

        );
        tabAll.value = response.data.data.all_orders

        tabNew.value = response.data.data.new_orders
        tabReady.value = response.data.data.ready_orders
        tabDelivery.value = response.data.data.shipping_orders
        tabCompleted.value = response.data.data.delivered_orders
        tabCancelled.value = response.data.data.deleted_orders
        tabReverseed.value = response.data.data.returned_orders
        tabProgress.value = response.data.data.return_in_progress_orders
    } catch (error) {
        console.error(errorMessage.value, error);
    }
}
// const fetchClient = () => {
//     setTimeout(async () => {
//         if (authStore.hasPermission('view-clients')) {

//             try {
//                 const response = await SelectClients();
//                 clientData.value = response.data.data;
//             } catch (error) {
//                 console.error(errorMessage.value, error);
//             }
//         }
//     }, 3000)

// };
const fetchZidStatus = async () => {
    try {
        const response = await getAllZidStatus();
        ZidData.value = response.data.data;
    } catch (error) {
        console.error(errorMessage.value, error);
    }
};
const fetchDqqStatus = async () => {
    try {
        const response = await getStatus();
        DqqStatusData.value = response.data.data;
    } catch (error) {
        console.error(errorMessage.value, error);
    }
};
const fetchDeleveryCompanies = async () => {
    try {
        const deliveryCompanyResponse = await getAllDeleveryCompany();
        deliveryData.value = deliveryCompanyResponse.data.data;

    } catch (error) {
        console.error(errorMessage.value, error);
    }
};
const fetchPaymentMethod = async () => {
    try {
        const PaymentMethodResponse = await getAllPaymentMetod();
        PaymentMethodData.value = PaymentMethodResponse.data.data;


    } catch (error) {
        console.error(errorMessage.value, error);
    }
};
const fetchProduct = async () => {
    try {
        const productResponse = await statisticsProductsOrders(
            searchQuery.value,
            client_id.value ?? null,
            dqq_Status.value ?? null,
            zid_status.value ?? null,
            product_id.value ?? null,
            city_id.value ?? null,
            quantityValue.value ?? null,
            numProduct.value ?? null,
            order_print_waybill.value ?? null,
            order_payment_status.value ?? null,
            delivery_company_id.value ?? [],
            payment_method_id.value ?? [],
            startDate.value,
            endDate.value,
            date_type.value,
            sort_value.value,
            sort_colum.value
        );
        ProductData.value = productResponse.data.data;
    } catch (error) {
        console.error(errorMessage.value, error);
    }
};
const fetchCity = async () => {
    try {
        const citiesResponses = await getOrdersCities();
        CityData.value = citiesResponses.data.data;
    } catch (error) {
        console.error(errorMessage.value, error);
    }
};
const loadingExcel = ref(false); // Track the loading state
const loadingPrint = ref(false); // Track the loading state
const loadingPdf = ref(false); // Track the loading state
const exportExcelAll = async () => {
    try {
        loadingExcel.value = true; // Start loading
        toast.info(exportMsg.value); // Notify user action

        const formData = new FormData();


        if (searchQuery.value) formData.append('all', searchQuery.value);
        if (client_id.value) formData.append('client_id', client_id.value);
        if (dqq_Status.value) formData.append('order_status', dqq_Status.value);
        if (zid_status.value) formData.append('order_zid_status', zid_status.value);
        if (product_id.value) formData.append('product_id', product_id.value);
        if (city_id.value) formData.append('order_cities_id', city_id.value);
        if (quantityValue.value) formData.append('quantity_product_in_order', quantityValue.value);
        if (numProduct.value) formData.append('number_products_in_order', numProduct.value);
        if (order_print_waybill.value) formData.append('order_print_waybill', order_print_waybill.value);
        if (order_payment_status.value) formData.append('order_payment_status', order_payment_status.value);
        if (delivery_company_id.value.length) formData.append('delivery_company_id', delivery_company_id.value);
        if (payment_method_id.value.length) formData.append('payment_method_id', payment_method_id.value);
        if (startDate.value) formData.append('from', startDate.value);
        if (endDate.value) formData.append('to', endDate.value);
        if (date_type.value) formData.append('date_type', date_type.value);
        if (sort_value.value) formData.append('sort_value', sort_value.value);
        if (sort_colum.value) formData.append('sort_colum', sort_colum.value);

        const response = await exportOrderExcel(formData);

        if (response.data.data.link) {
            const link = document.createElement('a');
            link.href = response.data.data.link; // The URL from the response
            link.download = response.data.data.name; // Optional: Set file name


            document.body.appendChild(link); // Append to body
            toast.success(h("div", [
                "File is ready! ",
                h("button", {
                    onClick: () => window.open(link, "_blank"),
                    style: "background: #4CAF50; color: white; padding: 5px 10px; border: none; cursor: pointer; margin-left: 10px; cursor: pointer;"
                }, "Download")
            ]), {
                autoClose: false, // Keeps the toast open until manually dismissed
                closeOnClick: false, // Prevents closing when clicking the toast itself
                draggable: false, // Disables accidental drag-to-close
                closeButton: true // Ensures a close button is visible
            });


            link.click(); // Trigger the download
            document.body.removeChild(link); // Clean up

            toast.success(response.data.data.message);
        } else {
            toast.success(response.data.data.message);
        }



    } catch (error) {
        console.error(errorMessage.value, error);
    } finally {
        loadingExcel.value = false; // Stop loading
    }

}
const exportExcel = async () => {
    try {
        loadingExcel.value = true; // Start loading
        toast.info(exportMsg.value); // Notify user action

        const formData = new FormData();
        Object.keys(selectedRows.value).forEach(id => {
            if (selectedRows.value[id]) { // Check if the value is true
                formData.append('ids[]', id); // Append each true id
            }// Append each id individually
        });


        const response = await exportOrderExcel(formData);

        if (response.data.data.link) {
            const link = document.createElement('a');
            link.href = response.data.data.link; // The URL from the response
            link.download = response.data.data.name; // Optional: Set file name


            document.body.appendChild(link); // Append to body
            toast.success(h("div", [
                "File is ready! ",
                h("button", {
                    onClick: () => window.open(link, "_blank"),
                    style: "background: #4CAF50; color: white; padding: 5px 10px; border: none; cursor: pointer; margin-left: 10px; cursor: pointer;"
                }, "Download")
            ]), {
                autoClose: false, // Keeps the toast open until manually dismissed
                closeOnClick: false, // Prevents closing when clicking the toast itself
                draggable: false, // Disables accidental drag-to-close
                closeButton: true // Ensures a close button is visible
            });


            link.click(); // Trigger the download
            document.body.removeChild(link); // Clean up

            toast.success(response.data.data.message);
        } else {
            toast.success(response.data.data.message);
        }



    } catch (error) {
        console.error(errorMessage.value, error);
    } finally {
        loadingExcel.value = false; // Stop loading
    }

}
const exportPDF = async () => {
    try {

        // loadingStore.setLoading(true)
        loadingPdf.value = true;
        toast.info(exportMsg.value); // Notify user action

        const formData = new FormData();
        Object.keys(selectedRows.value).forEach(id => {
            if (selectedRows.value[id]) { // Check if the value is true
                formData.append('ids[]', id); // Append each true id
            }// Append each id individually
        });

        if (searchQuery.value) formData.append('all', searchQuery.value);
        if (client_id.value) formData.append('client_id', client_id.value);
        if (dqq_Status.value) formData.append('order_status', dqq_Status.value);
        if (zid_status.value) formData.append('order_zid_status', zid_status.value);
        if (product_id.value) formData.append('product_id', product_id.value);
        if (city_id.value) formData.append('order_cities_id', city_id.value);
        if (quantityValue.value) formData.append('quantity_product_in_order', quantityValue.value);
        if (numProduct.value) formData.append('number_products_in_order', numProduct.value);
        if (order_print_waybill.value) formData.append('order_print_waybill', order_print_waybill.value);
        if (order_payment_status.value) formData.append('order_payment_status', order_payment_status.value);
        if (delivery_company_id.value.length) formData.append('delivery_company_id', delivery_company_id.value);
        if (payment_method_id.value.length) formData.append('payment_method_id', payment_method_id.value);
        if (startDate.value) formData.append('from', startDate.value);
        if (endDate.value) formData.append('to', endDate.value);
        if (date_type.value) formData.append('date_type', date_type.value);
        if (sort_value.value) formData.append('sort_value', sort_value.value);
        if (sort_colum.value) formData.append('sort_colum', sort_colum.value);

        const response = await exportOrderPDF(formData);

        if (response.data.success && response.data && response.data.data.download_url) {
            const link = document.createElement('a');
            console.log(response.data);
            console.log(response.data.data);

            link.href = response.data.data.download_url; // The correct URL from the response
            link.download = response.data.data.name ?? ''; // Optional: Set file name (fallback)
            link.target = '_blank'; // Open in a new tab
            document.body.appendChild(link); // Append to body
            toast.success(h("div", [
                "File is ready! ",
                h("button", {
                    onClick: () => window.open(link, "_blank"),
                    style: "background: #4CAF50; color: white; padding: 5px 10px; border: none; cursor: pointer; margin-left: 10px; cursor: pointer;"
                }, "Download")
            ]), {
                autoClose: false, // Keeps the toast open until manually dismissed
                closeOnClick: false, // Prevents closing when clicking the toast itself
                draggable: false, // Disables accidental drag-to-close
                closeButton: true // Ensures a close button is visible
            });
            link.click(); // Trigger the download
            document.body.removeChild(link); // Clean up

        } else {
            console.error("Failed to get the download link.");
        }
    } catch (error) {
        console.error("Error in exporting PDF:", error);
    } finally {
        // loadingStore.setLoading(false)
        loadingPdf.value = false;

    }
};

const exportPrint = async () => {

    try {
        loadingPrint.value = true; // Start loading
        toast.info(exportMsg.value); // Notify user action
        const formData = new FormData();
        Object.keys(selectedRows.value).forEach(id => {
            if (selectedRows.value[id]) { // Check if the value is true
                formData.append('ids[]', id); // Append each true id
            }// Append each id individually
        });
        const response = await exportOrderPrint(formData);

        if (response.data.data.link) {
            const link = document.createElement('a');
            link.href = response.data.data; // The URL from the response
            link.download = response.data.data; // Optional: Set file name
            link.target = '_blank'; // Open in a new tab


            document.body.appendChild(link); // Append to body
            link.click(); // Trigger the download
            toast.success(h("div", [
                "File is ready! ",
                h("button", {
                    onClick: () => window.open(link, "_blank"),
                    style: "background: #4CAF50; color: white; padding: 5px 10px; border: none; cursor: pointer; margin-left: 10px;"
                }, "Download")
            ]));


            document.body.removeChild(link); // Clean up

            toast.success(response.data.message);
        } else {
            toast.success(response.data.message);
        }



    } catch (error) {
        console.error(errorMessage.value, error);
    } finally {
        loadingPrint.value = false; // Stop loading
    }

}


// Fetch users on mount
onMounted(fetchData);
onMounted(fetchStatistic);








watch(quantityValue, () => {
    if (quantityValue.value && quantityValue.value >= 0) {
        updateFilter(t('General.QuantityProducts'), quantityValue.value);


    } else {
        quantityValue.value = null;
        removeFilter(t('General.QuantityProducts'))

    }
});
watch(numProduct, () => {
    if (numProduct.value && numProduct.value >= 0) {
        // console.log('numProduct must be positive');
        updateFilter(t("General.NumberProducts"), numProduct.value);
    } else {
        numProduct.value = null;
        removeFilter(t("General.NumberProducts"))


    }
});

const OrderSearchFunction = () => {
    fetchData();
    fetchStatistic();
    currentPage.value = 1;
    ProductData.value = [];
    if (appliedFilters.length > 0)
        fetchProduct();

}

const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};

const filterToggle = ref(localStorage.getItem('filterToggle') ? JSON.parse(localStorage.getItem('filterToggle')) : false);
const toggleFilters = () => {


    localStorage.setItem('filterToggle', !filterToggle.value);
    filterToggle.value = !filterToggle.value;
}


const appliedFilters = reactive([]);


const translatedFilters = computed(() => {
    return appliedFilters.map(filter => {
        if (filter.startsWith('Zid Status') || filter.startsWith('ZidStatus')) {
            return `${t('General.ZidStatus')} : ${filter.split(' : ')[1]}`;
        }
        if (filter.startsWith('Product')) {
            return `${t('General.Product')} : ${filter.split(' : ')[1]}`;
        }
        if (filter.startsWith('Print Waybill')) {
            return `${t('General.PrintWaybill')} : ${filter.split(' : ')[1]}`;
        }
        if (filter.startsWith('Payment Status')) {
            return `${t('General.PaymentStatus')} : ${filter.split(' : ')[1]}`;
        }
        if (filter.startsWith('Quantity Products')) {
            return `${t('General.QuantityProducts')} : ${filter.split(' : ')[1]}`;
        }
        if (filter.startsWith('Number Products')) {
            return `${t('General.NumberProducts')} : ${filter.split(' : ')[1]}`;
        }
        if (filter.startsWith('Delivery Company')) {
            return `${t('General.DeliveryCompany')} : ${filter.split(' : ')[1]}`;
        }
        if (filter.startsWith('Payment Method')) {
            return `${t('General.PaymentMethod')} : ${filter.split(' : ')[1]}`;
        }
        return filter;
    });
});



const removeFilter = (prefix) => {
    if (appliedFilters.some(filter => filter.startsWith(prefix))) {
        if (prefix.startsWith(t('General.ZidStatus'))) {
            zid_status_name.value = null;
            zid_status.value = null;
        }
        if (prefix.startsWith(t('General.Product'))) {
            SelectProductName.value = null;
            product_id.value = null;
        }
        if (prefix.startsWith(t('General.Cities'))) {
            // SelectCityName.value = null;
            // city_id.value = null;
            removeCities(prefix)

        }
        if (prefix.startsWith(t('General.PrintWaybill'))) {
            order_print_waybill_name.value = null;
            order_print_waybill.value = null;
        }
        if (prefix.startsWith(t('General.PaymentStatus'))) {
            order_payment_status_name.value = null;
            order_payment_status.value = null;
        }
        if (prefix.startsWith(t('General.QuantityProducts'))) {
            quantityValue.value = null;
        }
        if (prefix.startsWith(t('General.NumberProducts'))) {
            numProduct.value = null;
        }
        if (prefix.startsWith(t('General.DeliveryCompany'))) {

            removeFromDelivery(prefix)
        }
        if (prefix.startsWith(t('General.PaymentMethod'))) {
            removeFromPaymentMethod(prefix);
        }
        // Find the index of the existing filter
        const index = appliedFilters.findIndex(filter => filter.startsWith(prefix));
        if (index !== -1) {
            // Remove the existing filter
            appliedFilters.splice(index, 1);
        }
    }
};
const removeFromDelivery = (prefix) => {

    const extractedName = prefix.replace(t('General.DeliveryCompany') + " : ", "");


    const deliveryCompany = filteredDeliveryCompanies.value.find(company => company.name === extractedName);
    if (deliveryCompany) {
        if (delivery_company_id.value.includes(deliveryCompany.id)) {
            const index = delivery_company_id.value.indexOf(deliveryCompany.id);
            if (index !== -1) {
                delivery_company_id.value.splice(index, 1);
            }
        }
    }
}
const removeCities = (prefix) => {

    const extractedName = prefix.replace(t('General.Cities') + " : ", "");


    const cities = filteredCities.value.find(city => city.ar_name === extractedName);
    if (cities) {
        if (city_id.value.includes(cities.id)) {
            const index = city_id.value.indexOf(cities.id);
            if (index !== -1) {
                city_id.value.splice(index, 1);
            }
        }
    }
}




const removeFromPaymentMethod = (prefix) => {

    // const extractedName = prefix.replace("Payment Method : ", "");
    const extractedName = prefix.replace(t('General.PaymentMethod') + " : ", "");
    const paymentMethodobject = filteredPaymentMethods.value.find(company => company.name === extractedName);
    if (paymentMethodobject) {
        if (payment_method_id.value.includes(paymentMethodobject.code)) {
            const index = payment_method_id.value.indexOf(paymentMethodobject.code);
            if (index !== -1) {
                payment_method_id.value.splice(index, 1);
            }
        }
    }

}


const paymentStatus = computed(() => [
    { name: t('General.Unpaid'), value: 0 },
    { name: t('General.Paid'), value: 1 },

]);
const printWaybill = computed(() => [
    { name: t('General.NotPrint'), value: 0 },
    { name: t('General.Printed'), value: 1 },

]);



const updateFilter = (prefix, newValue) => {
    // Check if any filter starts with the specified prefix
    if (appliedFilters.some(filter => filter.startsWith(prefix))) {
        // Find the index of the existing filter
        const index = appliedFilters.findIndex(filter => filter.startsWith(prefix));
        if (index !== -1) {
            // Remove the existing filter
            appliedFilters.splice(index, 1);
        }
    }

    // Add the new filter
    appliedFilters.push(`${prefix} : ${newValue}`);

};
const handleEnterQuantity = (event) => {
    if (event.key === 'Enter') {
        if (quantityValue.value) {
            updateFilter(t('General.QuantityProducts'), quantityValue.value);

        }

    }

};
const handleEnterNum = (event) => {

    if (event.key === 'Enter') {
        if (numProduct.value) {
            updateFilter(t("General.NumberProducts"), numProduct.value);
        }

    }

};

// Add a Badge to the Applied Filters
const addBadges = (name, value, id = null) => {

    let badge = name + " : " + value

    if (!appliedFilters.includes(badge)) {
        appliedFilters.push(badge);
        if (name === "Delivery Company" || name == t('General.DeliveryCompany')) {
            if (!delivery_company_id.value.includes(id)) delivery_company_id.value.push(id);
        }
        if (name === "Payment Method" || name == t('General.PaymentMethod')) {
            if (!payment_method_id.value.includes(id)) payment_method_id.value.push(id);

        }
        if (name === "Cities" || name == t('General.Cities')) {
            if (!city_id.value.includes(id)) city_id.value.push(id);

        }

    }

};

const updateStatus = async ({ zid_status_modal, dqq_Status_modal, warehouse_id = null }) => {
    const formData = new FormData();
    formData.append('status', dqq_Status_modal.value);

    if (warehouse_id) {
        formData.append('warehouse_id', warehouse_id);
    }
    formData.append('zid_status', zid_status_modal.value);
    Object.keys(selectedRows.value).forEach(id => {
        if (selectedRows.value[id]) {
            formData.append('ids[]', id); // Append each id individually
        }
    });
    try {
        loadingStore.setLoading(true)
        await changeOrderStatus(formData);
        OrderSearchFunction();
        toast.success(t('Messages.Success.Edited'), { autoClose: 3000 });


    } catch (error) {
        loadingStore.setLoading(false)
    } finally {
        loadingStore.setLoading(false)
    }


};



//date

const showCalendar = ref(false);

//delete
const pickDate = ref(null);
const modal = ref(null);

const toggleCalendar = async () => {

    await nextTick();
    pickDate.value.SyncCulender();
};

// Function to handle click outside the modal
const handleClickOutside = () => {
    if (modal.value && !modal.value.contains(event.target) && pickDate.value?.isShowCalendar()) {
        pickDate.value.SyncCulender();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});



//edit
const showEdit = ref(false);

function syncChangeStatus() {
    showEdit.value = !showEdit.value;

}
//Import
const showImport = ref(false);
function onImportRow() {
    showImport.value = !showImport.value;
}
function onCloseImport() {
    showImport.value = !showImport.value;

}

const SelectDeliveryCompany = (id, name) => {
    addBadges(t('General.DeliveryCompany'), name, id);

};

const SelectPaymentMethod = (id, name) => {
    addBadges(t('General.PaymentMethod'), name, id);

};
const SelectCity = (id, name) => {

    addBadges(t('General.Cities'), name, id);


}


</script>


<style scoped>
.title {
    font-size: 24px;
    font-family: "Public Sans";
    line-height: 22px;
}

.breadcrumb .title {
    color: #22303E;
    font-size: 24px;
}

.order-list .title {
    color: #22303E;
}

/* .shadow-lg {
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.01) !important; 
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important;
} */

.sub-title {
    font-family: "Public Sans";
    font-weight: 600;
    line-height: 24px;
    font-size: 15px;
    text-transform: capitalize;

}

.order-numbers {
    margin-top: 40px;
}

.order-number {
    font-weight: 700;
    color: #000336;
}

.icon {
    border-radius: 6px;
    width: 42px;
    height: 42px;
}

.orders-shipped .icon {
    background-color: rgba(36, 162, 89, 0.1);
}

.processed-orders .icon {
    background-color: rgba(47, 205, 114, 0.1);
}


.remaining-orders .sub-title {
    color: #FF810B;
}

.remaining-orders .icon {
    background-color: rgba(255, 69, 11, 0.1);
}

.late-orders .sub-title {
    color: #ED284F;
}

.late-orders .icon {
    background-color: rgba(237, 40, 79, 0.1);
}

.remaining-orders,
.orders-shipped,
.processed-orders {
    border-right: 1px solid #e4e6e8;
}

.btn {
    position: relative;
    z-index: 1;
}

.btn:hover {
    background-color: rgba(128, 128, 128, 0.5);
}

.search-order h3 {
    color: #22303E;
    font-size: 24px;
    font-family: "Public Sans";
}

.badge-btn {
    display: inline;
    width: max-content;
}

.badge-btn button {
    background-color: transparent;
    outline: none;
    border: none;
}

.badge-btn,
.search-order button {
    outline: none;
    border: none;
    color: #24A259;
    padding: 10px 16px;
    border-radius: 6px;
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: rgba(36, 162, 89, 0.1);
}


.order-dropdown button {
    background-color: #fff !important;
    font-size: 16px;
    color: #24A259;
    border: 1px solid #24A259 !important;
}

.show-result h4 {
    font-size: 24px;
    color: #22303E;
}

.order-list-tabs {
    max-width: 100vw;
    overflow-x: scroll;
}

.order-list-tabs ul {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    list-style: none;
}

.order-list-tabs ul li {
    cursor: pointer;
    color: #22303E;
    font-family: 18px;
}

.order-list-tabs::-webkit-scrollbar {
    height: 0px;
    background-color: #ddd;
}

/* .order-list-tabs::-webkit-scrollbar-track {
    background: #24A259;
    border-radius: 6px;
} */

.search-btn {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

}

.border-green {
    border: 1px solid #24A259;
}

.blue-btn {
    color: #1168FF !important;
    background-color: rgba(17, 104, 255, 0.1) !important;
}

.export-btn:focus,
.export-btn {
    background-color: #EE1D52;
}

/* Style the table header border */

/* Header Styles */
th {
    text-transform: uppercase;
    position: relative;
    border: none !important;
}



.btn .dropdown-toggle {
    white-space: normal;
    word-wrap: break-word;
}

/* Header Separator (using pseudo-element) */
th:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 14px;
    background-color: #22303E1F;
    /* Transparent gray color */
}

@media screen and (max-width:1024px) {

    .hidden-filter {
        display: none;
    }

    .row.text-center .dropdown,
    .filter-col {
        margin-top: 10px;
    }

    .row.text-center {
        text-align: start !important;
        padding: 0 !important;
    }

    .remaining-orders,
    .orders-shipped,
    .late-orders,
    .processed-orders {
        border-right: none;
        border-bottom: 1px solid #e4e6e8;
    }

    .show-result,
    .show-result .filters,
    .result-table,
    .tabs-buttons,
    .badges {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

/* .order-list-tabs {
    display: flex;
    border-bottom: 1px solid #e4e6e8;
} */

.order-list-tabs-item {
    min-height: 100%;
    height: 60px;
    min-width: max-content;
}


.order-list-tabs-item.active {
    /*  background-color: lavenderblush !important; */
    /* background-color: #24A259 !important; */

    /*  padding: 5px;
    border-radius: 15px; */
    border-bottom: 2px solid #19703d;

}

.order-list-tabs-item:hover {
    /* background-color: #24A259 !important; */
    /* background-color: lavenderblush !important; */

    /* color: white !important; */

    /* padding: 5px;
    border-radius: 15px; */
    border-bottom: 2px solid #19703d;

}

/* Animation for badges */
.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s ease;
}

.fade-enter-from {
    opacity: 0;
    transform: translateY(-10px);
}

.fade-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

/* Style for badges */
.badges .badge {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #e0f7fa;
    color: #00796b;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
}

.badges .btn-close {
    background: none;
    border: none;
    font-size: 12px;
    color: #00796b;
    cursor: pointer;
}

.badges .btn-close:hover {
    color: #004d40;
}

.col-lg-3 .dropdown button {
    min-width: 100% !important;
    display: flex;
    justify-content: space-between !important;
}

.col-lg-3 .form-control {
    height: 40px;
    background-color: #fff !important;
    border: 1px solid #24A259 !important;
}

.col-lg-3 .form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #24A259;
    font-size: 16px;

    opacity: 1;
    /* Firefox */
}



button {
    min-width: max-content;
}



.border-r-primary {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    border-right: 1px solid #24A259;
}

.border-l-primary {
    display: flex;
    padding-left: 20px;
    flex-direction: column;
    min-width: max-content !important;
    border-left: 1px solid #24A259;
}

.input-group {
    box-shadow: none !important;
}

.form-control.search {
    border: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid #ddd !important;
}

.form-control.search::placeholder {
    color: #cccccc !important;
}

.dropdown-menu {
    max-width: 250px !important;
    max-height: 300px;
    overflow-y: auto;
    word-wrap: break-word !important;
}

.dropdown-menu .dropdown-item,
.dropdown-menu li a {
    min-height: max-content;
    max-width: 250px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
}

::-webkit-scrollbar {
    width: 3px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #24a259 !important;
    width: 3px !important;
    border-radius: 6px;
}


.dropdown-menu::-webkit-scrollbar-track {
    border-radius: 6px;
    width: 3px;
    background-color: #ddd !important;
}

@media (min-width: 997px) {
    .filter-top {
        margin: 20px !important;
    }

}

html[dir="rtl"] .part-number {
    display: flex;
    justify-content: center;
    flex-flow: row-reverse;
}

html[dir="ltr"] .part-number {
    display: flex;
    justify-content: center;
}
</style>
