<template>
  <div class="content-wrapper">
    <div class="container-xxl flex-grow-1 container-p-y">
      <div class="pageheader">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item text-secondary" style="cursor: pointer;">

              <div class="text-truncate" @click="goToOrder">{{ $t("Entities.Orders") }}</div>
            </li>
            <li class="breadcrumb-item text-dark fw-medium" aria-current="page">
              {{ $t('Sections.Orders.Create') }}</li>
          </ol>
        </nav>
      </div>
      <div class="row">
        <div class="col-xxl">
          <div class="card mb-6">
            <div class="card-header pb-6">

              <h3>
                <i class="fa-solid fa-pen-ruler color-green me-2"></i>
                {{ $t('Sections.Orders.Create') }}
              </h3>
            </div>
            <div class="card-body  d-flex justify-content-center align-items-center ">

              <div class="d-flex justify-content-center align-items-center gap-4 mb-4" v-for="(step, index) in steps"
                :key="index">

                <div class="d-flex flex-column align-items-center">

                  <div class="rounded-circle" :class="{ active: currentStep === index }">
                    <i :class="step.icon" style="font-size: 40px;"></i>
                  </div>
                  <span>{{ step.label }}</span>
                </div>
                <div v-if="index < steps.length - 1" class="dashed-line"></div>
              </div>
            </div>
          </div>

          <form @submit.prevent="onSubmit">

            <component :is="steps[currentStep].component" v-model:products="orderData.products"
              v-model:warehouse_id="orderData.warehouse_id" v-model:quantity="orderData.quantity"
              v-model:client="client" v-model:customer_notes="customer_notes" v-model:location="location"
              v-model:currency_id="orderData.currency_id" v-model:delivery_integration_id="orderData.delivery_integration_id"
              v-model:payment_method_id="orderData.payment_method_id" v-model:payment_image="orderData.payment_image" />

            <div class="mt-4 d-flex justify-content-center gap-2">
              <button type="button" @click.prevent="prevStep" :disabled="currentStep === 0"
                class="btn btn-secondary">Back</button>
              <button type="button" @click.prevent="nextStep" :disabled="isNextDisabled"
                class="btn add-btn shadow-none login-button btn-primary me-3">
                <span v-if="loading">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="visually-hidden">Loading...</span>
                </span>
                <span v-else> {{ isLastStep ? $t('General.Submit') : $t('General.Next') }}</span>

              </button>
            </div>

          </form>
        </div>
      </div>


    </div>
  </div>



</template>

<script setup>
import { submitOrder } from '@/services/order';
import { ref, computed, defineAsyncComponent } from 'vue';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';


const orderData = ref({
  warehouse_id: null,
  products: null,
  quantity: 1,
  place: null,
  customer_notes: "",
  currency_id: null,
  delivery_integration_id: null,
  payment_method_id: null,
  payment_image: null,
});
const client = ref(null);
const location = ref(null);
const loading = ref(false)

const steps = ref([
  { label: 'Add Product', icon: 'bx bx-cart-add', component: defineAsyncComponent(() => import('./componants/wizard/StepOne.vue')) },
  { label: 'Customer Info', icon: 'bx bx-map', component: defineAsyncComponent(() => import('./componants/wizard/StepTwo.vue')) },
  { label: 'Shipment', icon: 'fa-solid fa-truck', component: defineAsyncComponent(() => import('./componants/wizard/StepThree.vue')) },
  { label: 'Summary', icon: 'bx bx-receipt', component: defineAsyncComponent(() => import('./componants/wizard/StepFour.vue')) },
  // { label: 'Summary', icon: 'bx bx-receipt', component: defineAsyncComponent(() => import('./Step4.vue')) },
]);

const currentStep = ref(0);
const isLastStep = computed(() => currentStep.value === steps.value.length - 1);
const isNextDisabled = computed(() => {
  if (loading.value) {
    return true;
  }
  if (currentStep.value === 0) {
    return !orderData.value.warehouse_id ||
      !orderData.value.products ||
      orderData.value.products.length === 0 ||
      !orderData.value.quantity ||
      orderData.value.quantity <= 0;
  } else if (currentStep.value === 1) {
    return !client.value ||
      !location.value ||
      !orderData.value.currency_id ||
      orderData.value.currency_id === null;
  } else if (currentStep.value === 2) {
    return !orderData.value.delivery_integration_id ||
      !orderData.value.payment_method_id ||
      (orderData.value.payment_method_id?.code === 'bank_transfer_dqq' && !orderData.value.payment_image);
  }
  return false;
});

const router = useRouter();

const nextStep = async () => {
  if (currentStep.value === 0) {
    if (!orderData.value.warehouse_id) {
      toast.error('Please select a warehouse');
      return;
    }
    if (!orderData.value.products || orderData.value.products.length === 0) {
      toast.error('Please add at least one product');
      return;
    }
    if (!orderData.value.quantity || orderData.value.quantity <= 0) {
      toast.error('Please enter a valid quantity');
      return;
    }
  } else if (currentStep.value === 1) {
    if (!client.value) {
      toast.error('Please provide client information');
      return;
    }
    if (!location.value) {
      toast.error('Please provide a location');
      return;
    }
    if (!orderData.value.currency_id) {
      toast.error('Please provide a currency');
      return;
    }
  } else if (currentStep.value === 2) {
    if (!orderData.value.delivery_integration_id) {
      toast.error('Please select a delivery integration');
      return;
    }
    if (!orderData.value.payment_method_id) {
      toast.error('Please select a payment method');
      return;
    }
    if (orderData.value.payment_method_id?.code === 'bank_transfer_dqq' && !orderData.value.payment_image) {
      toast.error('Please upload a payment image for bank transfer');
      return;
    }
  }
  if (!isLastStep.value) {
    currentStep.value++;

  } else {

    try {
      loading.value = true;

      const formData = new FormData();
      formData.append('city_id', location.value.city_id);
      formData.append('country_id', location.value.country_id);
      formData.append('currency_id', orderData.value.currency_id);
      formData.append('custome_address_id', location.value.id);
      formData.append('customer_notes', orderData.value.customer_notes);
      formData.append('customer_id', client.value.id);
      formData.append('warehouse_id', orderData.value.warehouse_id);
      formData.append('delivery_integration_id', orderData.value.delivery_integration_id);
      formData.append('payment_method_id', orderData.value.payment_method_id?.id);
      if (orderData.value.payment_image) {
        formData.append('payment_image', orderData.value.payment_image);
      }

      orderData.value.products.forEach((product, index) => {
        formData.append(`products[${index}][product_id]`, product.product_id);
        formData.append(`products[${index}][quantity]`, product.quantity);
      });

      const response = await submitOrder(formData);
      toast.success(response.data.message);
      router.push({ name: 'Orders' });
    } catch (error) {
      console.error('Error submitting order:', error);
    } finally {
      loading.value = false;
    }
  }
};

const prevStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
};
</script>

<style scoped>
.rounded-circle {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  border-radius: 50%;
}

.active {
  background-color: green;
  color: white;
}

.dashed-line {
  width: 80px;
  height: 2px;
  background: repeating-linear-gradient(90deg, black, black 10px, transparent 10px, transparent 20px);
}
</style>
