<template>
    <div class="modal fade" :class="{ 'show': showEdit }" id="editProduct" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-dialog-scrollable modal-lg modal-simple modal-edit-product">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-6 sub-page-title">{{ $t('Sections.Product.Select') }}</h4>
                        <div class="input-group input-group-merge input-group-search"
                            style="width: 100%; border: 1px solid #24a259;">
                            <span class="input-group-text " id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31" v-model="searchQuery">
                        </div>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-hover">
                                <thead class="bg-green">
                                    <tr>
                                        <th class="text-center action-cells ">
                                            <div class="checkbox-wrapper  text-center">
                                                <input type="checkbox" class="form-check-input" />
                                            </div>
                                        </th>
                                        <th class="text-center ">#</th>
                                        <th class="text-center ">{{ $t('General.Name') }}</th>
                                        <th class="text-center ">{{ $t('General.Price') }}</th>
                                        <th class="text-center ">{{ $t('General.Quantity') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr v-for="product in filteredRow" :key="product.id">
                                        <td class="action-cell text-center">
                                            <div class="checkbox-wrapper">
                                                <input type="checkbox" class="form-check-input" :value="product"
                                                    v-model="selectedProducts" />
                                            </div>
                                        </td>
                                        <td class="text-center ">{{ product.id }}</td>
                                        <td class="text-center ">{{ product.name }}</td>
                                        <td class="text-center ">{{ product.price }}</td>
                                        <td class="text-center ">{{ product.quantity }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div class=" sticky-submit text-center el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5" @click="closeModel"
                                aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, computed } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n.js';

const emit = defineEmits(['close-edit', 'update-selected']);

const showEdit = ref(true);
const loading = ref(false);

const { handleSubmit } = useForm({
    validationSchema: yup.object({})
});

const props = defineProps({
    row: {
        type: Array,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true
    }
});

const selectedProducts = ref([]); // Track selected products

const onSubmit = handleSubmit(async () => {
    try {
        console.log("Selected Products:", selectedProducts.value);
        emit('update-selected', selectedProducts.value); // Emit selected rows
        closeModel();
    } catch (e) {
        console.error(i18n.global.t('Messages.Error.SubmitError'), e);
    } finally {
        loading.value = false;
    }
});

function closeModel() {
    emit('close-edit', false);
}

const searchQuery = ref('');

const filteredRow = computed(() => {
    if (!searchQuery.value) return props.row.length ? props.row[0] : props.row;
    return props.row.length ? props.row[0].filter(product =>
        product?.name?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        product?.barcode?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        product?.sku?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        product?.price?.toString().includes(searchQuery.value.toLowerCase()) ||
        product?.reference_number?.toLowerCase().includes(searchQuery.value.toLowerCase())
    ) :
        props.row.filter(product =>
            product?.name?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            product?.barcode?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            product?.sku?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            product?.price?.toString().includes(searchQuery.value.toLowerCase()) ||
            product?.reference_number?.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
});

const modal = ref(null);
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);
    }, 500);
});

onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});
</script>


<style scoped>
.input-group-search {
    width: 100% !important;
}

.box-search {
    border: none !important;
}

.modal-body {
    max-height: max-content !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

/* Track */
.modal-body::-webkit-scrollbar-track {
    border-radius: 10px !important;
}

.modal-body::-webkit-scrollbar {
    width: 3px !important;
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: #24a259 !important;
}

.modal-body::-webkit-scrollbar-thumb:hover {
    background-color: #1b7440;

}

.sticky-submit {
    position: sticky;
    bottom: 0;
    background: transparent;
    padding: 10px 0;
}
</style>
