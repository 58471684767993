import axiosClient from "./axiosClient";
import { buildUrlWithArrays } from "@/utils/buildUrl";

export function getOrders(page = 1,
  per_page,
  searchQuery = null,
  clientId = [],
  order_status = null,
  order_zid_status = null,
  product_id = [],
  city_id = [],
  quantity_product_in_order = null,
  number_product_in_order = null,
  order_print_waybill = null,
  order_payment_status = null,
  delivery_company_id = [],
  payment_method_id = [],
  from = null,
  to = null,
  date_type = null,
  sort_value = 'desc', //desc and asc 
  sort_colum = 'created_at_actually', //desc and asc 

) {
  const url = buildUrlWithArrays("v1/dashboard/order", {
    page,
    per_page,
    client_id: clientId,
    order_status,
    all: searchQuery,
    product_id,
    order_cities_id: city_id,
    order_zid_status,
    quantity_product_in_order,
    number_products_in_order: number_product_in_order,
    order_print_waybill,
    order_payment_status,
    delivery_company_id,
    payment_method_id,
    from,
    to,
    date_type,
    sort_value,
    sort_column: sort_colum,
  });
  return axiosClient({ url });

}
export function statisticsOrders(
  searchQuery = null,
  clientId = [],
  // order_status = null,
  order_zid_status = null,
  product_id = [],
  city_id = [],
  quantity_product_in_order = null,
  number_product_in_order = null,
  order_print_waybill = null,
  order_payment_status = null,
  delivery_company_id = [],
  payment_method_id = [],
  from = null,
  to = null,
  date_type = null,
  sort_value = 'desc', //desc and asc 

) {
  const url = buildUrlWithArrays("v1/dashboard/statisticsOrders", {
    client_id: clientId,
    // order_status,
    all: searchQuery,
    product_id,
    order_cities_id: city_id,
    order_zid_status,
    quantity_product_in_order,
    number_products_in_order: number_product_in_order,
    order_print_waybill,
    order_payment_status,
    delivery_company_id,
    payment_method_id,
    from,
    to,
    date_type,
    sort_value,
  });
  return axiosClient({ url });

}
export function statisticsProductsOrders(
  searchQuery = null,
  clientId = [],
  order_status = null,
  order_zid_status = null,
  product_id = [],
  city_id = [],
  quantity_product_in_order = null,
  number_product_in_order = null,
  order_print_waybill = null,
  order_payment_status = null,
  delivery_company_id = [],
  payment_method_id = [],
  from = null,
  to = null,
  date_type = null,
  sort_value = 'desc', //desc and asc 
  sort_colum = 'created_at_actually', //desc and asc 

) {
  const url = buildUrlWithArrays("v1/dashboard/statisticsProductsOrders", {
    client_id: clientId,
    order_status,
    all: searchQuery,
    product_id,
    order_cities_id: city_id,
    order_zid_status,
    quantity_product_in_order,
    number_products_in_order: number_product_in_order,
    order_print_waybill,
    order_payment_status,
    delivery_company_id,
    payment_method_id,
    from,
    to,
    date_type,
    sort_value,
    sort_column: sort_colum,
  });
  return axiosClient({ url });

}
export function showOrder(id) {
  return axiosClient({
    url: `v1/dashboard/order/${id}`,
  })
}
export function showOrderTimeLine(id) {
  return axiosClient({
    url: `v1/dashboard/order/getTimeline/${id}`,
  })
}
export function changeOrderStatus(data) {
  return axiosClient({
    url: `v1/dashboard/order/change-status`,
    method: "POST",
    data
  })
}
export function exportOrderExcel(data) {
  return axiosClient({
    url: `v1/dashboard/order/export/excel/product`,
    method: "POST",
    data
  })
}
export function exportOrderPDF(data) {
  return axiosClient({
    url: `v1/dashboard/order/export/pdf/product`,
    method: "POST",
    data
  })
}

export function exportOrderPrint(data) {
  return axiosClient({
    url: `v1/dashboard/order/bulk/print`,
    method: "POST",
    data
  })
}
export function returnedSplitOrder(data) {
  return axiosClient({
    url: `v1/dashboard/returned-split-order`,
    method: "POST",
    data
  })
}
export function importOrder() {
  return axiosClient({
    url: `/v1/dashboard/order/download_sample`,
  })
}
export function uploadOrderFile(data) {
  return axiosClient({
    url: `v1/dashboard/order/import`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

}

export function getCurrencies(data) {
  return axiosClient({
    url: `v1/dashboard/get_currencies`,
    method: "get",
    data
  });
}

export function submitOrder(data) {
  return axiosClient({
    url: `v1/dashboard/store-order`,
    method: "POST",
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data
  });
}
export function getDqqPaymentMethod() {
  return axiosClient({
    url: `v1/dashboard/get_dqq_payment_method`,
    method: "GET",
  });
}