<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="pageheader">
                <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item text-secondary" style="cursor: pointer;">

                            <div class="text-truncate" @click="goToRoles">{{ $t("Entities.Roles") }}</div>
                        </li>
                        <li class="breadcrumb-item active text-dark fw-medium" aria-current="page">
                            {{ $t('Sections.Roles.Create') }}</li>
                    </ol>
                </nav>
            </div>
            <div class="row">
                <div class="col-xxl">
                    <!-- Unified Card for Tabs and Content -->
                    <div class="card">
                        <div class="card-header pb-6">

                            <h3>
                                <i class="fa-solid fa-pen-ruler color-green me-2"></i>
                                {{ $t('Sections.Roles.Create') }}
                            </h3>
                        </div>
                        <div class="card-body ">
                            <div class="table-responsive text-nowrap p-4 table-hover">
                                <form @submit.prevent="onSubmit">
                                    <div class="row mb-6">
                                        <div class="col-4">
                                            <label class=" col-form-label " for="role_name">
                                                {{ $t('Sections.Roles.Name') }}
                                            </label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" class="form-control" id="role_name" v-model="role_name"
                                                :placeholder="$t('General.Enter') + ' ' + $t('Sections.Roles.Name')"
                                                :class="{ 'is-invalid': errors.role_name }" />
                                            <div v-if="errors.role_name" class="invalid-feedback">{{ errors.role_name }}
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div>
                                        <!-- Tabs Navigation -->
                                        <ul class="nav nav-pills mb-4 nav-fill" id="permissionsTabs" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active" id="dashboard-tab" data-bs-toggle="tab"
                                                    data-bs-target="#dashboard" type="button" role="tab"
                                                    aria-controls="dashboard" aria-selected="true">
                                                    {{ $t('Sections.Roles.DashboardPermissions') }}</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="api-tab" data-bs-toggle="tab"
                                                    data-bs-target="#api" type="button" role="tab" aria-controls="api"
                                                    aria-selected="false"> {{ $t('Sections.Roles.APIPermissions')
                                                    }}</button>
                                            </li>
                                        </ul>

                                        <!-- Tabs Content -->
                                        <div class="tab-content" id="permissionsTabContent">
                                            <!-- Dashboard Permissions Tab -->
                                            <div class="tab-pane fade show active" id="dashboard" role="tabpanel"
                                                aria-labelledby="dashboard-tab">
                                                <div class="d-flex">
                                                    <h4 class="">{{ $t('Sections.Roles.DashboardPermissions') }}</h4>
                                                    <div class=" d-flex align-items-center mx-4">
                                                        <div>
                                                            <input class="form-check-input" type="checkbox"
                                                                :checked="isToggleSelect('dashboard')"
                                                                @change="toggleSelectAll('dashboard', $event.target.checked)" />
                                                        </div>
                                                        <label class="col-form-label px-2 mb-2" for="role_name">
                                                            {{ $t("General.Select.All") }}
                                                        </label>
                                                    </div>
                                                </div>
                                                <table class="table table-flush-spacing table-hover mb-0">
                                                    <tbody>
                                                        <tr v-for="(permissions, category) in allPermissions"
                                                            :key="category" class="border-none text-capitalize">
                                                            <th scope="row" class="fw-semibold text-dark">{{ category }}
                                                            </th>
                                                            <td v-for="(permission, name) in permissions" :key="name">
                                                                <div class="form-check my-0">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        :id="name"
                                                                        v-model="selectedPermissions[permission]" />
                                                                    <label class="form-check-label" :for="name">{{ name
                                                                        }}</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <!-- API Permissions Tab -->
                                            <div class="tab-pane fade" id="api" role="tabpanel"
                                                aria-labelledby="api-tab">
                                                <div class="d-flex">
                                                    <h4>{{ $t('Sections.Roles.APIPermissions') }}</h4>
                                                    <div class=" d-flex align-items-center mx-4">
                                                        <div>
                                                            <input class="form-check-input" type="checkbox"
                                                                :checked="isToggleSelect('api')"
                                                                @change="toggleSelectAll('api', $event.target.checked)" />
                                                        </div>
                                                        <label class="col-form-label px-2 mb-2" for="role_name">
                                                            {{ $t("General.Select.All") }}
                                                        </label>
                                                    </div>
                                                </div>

                                                <table class="table table-flush-spacing  table-hover mb-0">
                                                    <tbody>
                                                        <tr v-for="(permissions, category) in permissions_api"
                                                            :key="category" class="border-none text-capitalize">
                                                            <th scope="row" class="fw-semibold text-dark">{{ category }}
                                                            </th>
                                                            <td v-for="(permission, name) in permissions" :key="name">
                                                                <div class="form-check my-0">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        :id="name" :value="permission"
                                                                        v-model="selectedPermissions[permission]" />
                                                                    <label class="form-check-label" :for="name">{{ name
                                                                        }}</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- Action Buttons -->
                                    <div class="col-12 text-center d-flex justify-content-center gap-2 el p-3">
                                        <button type="submit"
                                            class="btn add-btn shadow-none login-button btn-primary me-3"
                                            :disabled="loading">
                                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            {{ $t('Sections.Roles.Create') }}
                                        </button>
                                        <button type="reset" class="btn cancel-btn bg-green text-primary px-5"
                                            :disabled="loading"> {{ $t("General.Discard") }}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script setup>
import { getPremissions, storeRole } from '@/services/role';
import { useField, useForm } from 'vee-validate';
import { ref, reactive, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import { toast } from 'vue3-toastify';

const router = useRouter();


const goToRoles = () => {
    router.push({ name: 'Roles' }); // Navigate to the 'order' route
};

const loading = ref(false);

// Reactive state for permissions fetched from API
const allPermissions = reactive({});
const permissions_api = reactive({});

// Reactive state for storing selected permissions
const selectedPermissions = reactive({});


// State to store created role with selected permissions

// Fetch permissions from the API
const fetchPermissions = async () => {
    try {
        // Replace this with your actual API call
        const response = await getPremissions();  // assuming getPermissions() is your API call

        Object.keys(response.data.data.permissions).forEach((category) => {
            allPermissions[category] = response.data.data.permissions[category];
            // selectedPermissions[category] = [];
            Object.values(allPermissions).forEach((category) => {
                Object.values(category).forEach((item) => {
                    selectedPermissions[item] = false;
                })
            });

        });
        Object.keys(response.data.data.permissions_api).forEach((category) => {
            permissions_api[category] = response.data.data.permissions_api[category];
            // selectedPermissions[category] = [];
            Object.values(permissions_api).forEach((category) => {
                Object.values(category).forEach((item) => {
                    selectedPermissions[item] = false;
                })
            });
        });


    } catch (error) {
        console.error("Error fetching permissions:", error);
    }
};

const isToggleSelect = (name) => {
    const selectedCategoryPermissions = Object.keys(selectedPermissions).filter(key => selectedPermissions[key]);
    if (name == 'dashboard') {
        return Object.keys(allPermissions).every((category) => {
            const allCategoryPermissions = Object.values(allPermissions[category]);

            // Check if every permission in allPermissions[category] is in selectedPermissions[category]
            return allCategoryPermissions.every((perm) => selectedCategoryPermissions.includes(perm));
        });

    }
    if (name == 'api') {
        return Object.keys(permissions_api).every((category) => {
            const allCategoryPermissions = Object.values(permissions_api[category]);
            return allCategoryPermissions.every((perm) => selectedCategoryPermissions.includes(perm));
        });

    }

}

const { locale } = useI18n();
// Declare a reactive reference for the current language
watch(locale, () => {
    Object.keys(allPermissions).forEach(key => delete allPermissions[key]);
    Object.keys(permissions_api).forEach(key => delete permissions_api[key]);
    fetchPermissions();

});

const toggleSelectAll = (name, checked) => {
    isToggleSelect(name)
    if (checked) {
        if (name === 'dashboard') {
            Object.values(allPermissions).forEach((category) => {
                Object.values(category).forEach((item) => {
                    selectedPermissions[item] = true;
                })
            });

        }
        if (name === 'api') {
            Object.values(permissions_api).forEach((category) => {
                Object.values(category).forEach((item) => {
                    selectedPermissions[item] = true;
                })
            });

        }
    } else {
        if (name === 'dashboard') {
            // Clear all permissions under dashboard

            Object.values(allPermissions).forEach((category) => {
                Object.values(category).forEach((item) => {
                    selectedPermissions[item] = false;
                })
            });
        }
        if (name === 'api') {
            // Clear all permissions under dashboard
            Object.values(permissions_api).forEach((category) => {
                Object.values(category).forEach((item) => {
                    selectedPermissions[item] = false;
                })
            });
        }
    }
};

const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        role_name: yup
            .string()
            .required(t('Validation.Required'))
            .min(3, t('Validation.Min3'))
            .max(50, t('Validation.Max5')),
    }),

});
const { value: role_name } = useField('role_name');
// const { value: selectedPermissions } = useField('selectedPermissions');




const onSubmit = handleSubmit(async (values) => {
    const formData = new FormData();
    const permissions = Object.keys(selectedPermissions).filter(key => selectedPermissions[key]);

    // Prepare permissions object based on selected permissions

    formData.append('role_name', values.role_name);
    permissions.forEach(permission => {
        formData.append('permissions[]', permission); // Append each permission individually
    });
    try {
        loading.value = true;
        await storeRole(formData);
        toast.success(t('Messages.Success.Created'), { autoClose: 3000 });

        await router.push({ name: 'Roles' });


    } catch (error) {
        toast.error(t('Messages.Error.SubmitError'), { autoClose: 3000 });
        console.error('Error submitting :', error);
    } finally {
        loading.value = false;
    }
});


// Fetch permissions on component mount
onMounted(() => {
    fetchPermissions();
});</script>

<style scoped>
form {
    margin-bottom: 20px;
}

div {
    margin-bottom: 10px;
}

label {
    margin-right: 10px;
}

button {
    margin-top: 10px;
}

/* .card {
    height: 72vh;
    overflow-y: scroll;
} */

/* width */
::-webkit-scrollbar {
    width: 10px;
}

.card::-webkit-scrollbar-thumb {
    background-color: #24a259 !important;
    width: 3px !important;
    border-radius: 6px;
}


.card::-webkit-scrollbar-track {
    border-radius: 6px;
    width: 3px;
    background-color: #ddd !important;
}
</style>