<template>
    <div class="modal fade d-flex align-items-center justify-content-center" :class="{ 'show': showStatusModal }"
        tabindex="-1" aria-modal="true" role="dialog" style="display: block; padding-left: 0px;" >
        <div class="modal-dialog modal-lg modal-simple modal-status ">
            <div class="modal-content" ref="modal">


                <div class="modal-header d-flex flex-column align-items-center text-center ">
                    <i class="bx bx-check-shield" style="font-size: 85px; color: #1168FF;"></i>
                    <h5 class="title pt-6">{{ $t('Sections.Clients.StatusChange') }}</h5>
                    <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                </div>

                <form class="row " @submit.prevent="onSubmit">
                    <div class="modal-body text-center">
                        <input type="text" name="status" id="status" v-model="status" hidden>


                        <!-- Status Selection Buttons -->
                        <div class=" mb-6  ">
                            <button type="button" class="btn rounded succ-btn "
                                :class="{ 'select-border-succ': status == 0 }" @click="selectStatus('0')">
                                {{ $t('General.StatusOptions.Pending') }}
                            </button>
                            <button type="button" class="btn rounded  blue-btn"
                                :class="{ 'select-border-edit': status == 1 }" @click="selectStatus('1')">
                                {{ $t('General.StatusOptions.Approved') }}
                            </button>
                            <button type="button" class="btn rounded bg-red-light"
                                :class="{ 'select-border-danger': status == 2 }" @click="selectStatus('2')">
                                {{ $t('General.StatusOptions.Blocked') }}
                            </button>
                        </div>
                        <span class="text-danger">{{ errors.status }}</span>

                        <!-- Submit Button -->
                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn btn-primary  done-btn" :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Done') }}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useField, useForm } from 'vee-validate';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { toast } from 'vue3-toastify';
import * as yup from 'yup';
import i18n from '@/i18n.js';
import { statusClient } from '@/services/client';

const emit = defineEmits(['close-status']);
const props = defineProps({
    onStatusChange: { type: Function, required: true },
    row: {
        type: Object,
        required: true,
    },
});
const eRow = ref(props.row);
const showStatusModal = ref(true);
const loading = ref(false);

function selectStatus(selectedStatus) {
    status.value = selectedStatus;
}

const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        status: yup.string().required(i18n.global.t('Validation.Required.Status')),

    })
});
const { value: status } = useField('status');
status.value = eRow.value.status;

const onSubmit = handleSubmit(async (values) => {
    if (!status.value) {
        toast.error("Please select a status.", { autoClose: 3000 });
        return;
    }
    loading.value = true;
    try {
        // Placeholder for the actual status change logic
        await statusClient(values, eRow.value.id)
        props.onStatusChange();
        closeModal();
        toast.success(i18n.global.t('Messages.Success.Edited'), { autoClose: 3000 });

    } catch (error) {
        console.error(i18n.global.t('Messages.Error.SubmitError'), error);
    } finally {
        loading.value = false;
    }
});
function closeModal() {
    emit('close-status');
}

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
  if (modal.value && !modal.value.contains(event.target) ) {
    closeModal();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});

</script>


<style scoped>
.modal-content {
    border-radius: 8px;
}


.title {
    font-family: Public Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 40.8px;
    text-align: center;
    color: #1168FF;
}


.select-border-succ {
    border: 2px solid #28a745 !important;
    /* Default border to avoid collapse */

}

.select-border-edit {
    border: 2px solid #1168FF !important
        /*  border for "Approved" */
}

.select-border-danger {
    border: 2px solid #EE1D52 !important;
    /* Red border for "Blocked" */
}

.btn {
    width: 30%;
    height: 60px;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 33px;

}

.done-btn {
    width: 60%;
    padding: 13px 24px;
    border-radius: 16px;
    background-color: #24A259;
    font-family: Public Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 26.01px;
    text-align: center;

}

form {
    margin-right: 5rem;
    margin-left: 5rem;
}
</style>
