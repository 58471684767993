import axiosClient from "./axiosClient"

export function getInventories(page = 1, searchQuery = '', id = null) {
  let url = `v1/dashboard/inventory?page=${page}&per_page=10&search=${searchQuery}`;
  if (id) {
    url = url + `&id=${id}`
  }
  return axiosClient({
    url: url,
  })
}
