<template>
    <button ref="popoverButton" type="button" class="btn btn-primary text-nowrap">
        <i class='bx bx-refresh'></i>
    </button>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { Popover } from "bootstrap";

// Define the emit event
const emit = defineEmits(["onSync"]);

const popoverButton = ref(null);
let popoverInstance = null;

// Dynamic content with event listeners
const createPopoverContent = () => {
    const container = document.createElement("div");

    try {
        // Message content
        const message = document.createElement("p");
        message.textContent = "Are you sure if you want to send and skip?";
        container.appendChild(message);

        // Button group container
        const buttonGroup = document.createElement("div");
        buttonGroup.className = "d-flex justify-content-between";

        // Skip button
        const skipButton = document.createElement("button");
        skipButton.type = "button";
        skipButton.className = "btn btn-sm btn-label-secondary mx-2";
        skipButton.textContent = "Skip";
        skipButton.addEventListener("click", () => {
            if (popoverInstance) {
                popoverInstance.hide();
            } else {
                console.error("Popover instance is not initialized.");
            }
        });

        // Sync button
        const sureButton = document.createElement("button");
        sureButton.type = "button";
        sureButton.className = "btn btn-sm btn-primary login-button add-btn";
        sureButton.textContent = "Sync";
        sureButton.addEventListener("click", () => {
            // Emit the "onSync" event
            emit("onSync");
            if (popoverInstance) {
                popoverInstance.hide();
            }
        });

        // Append buttons to the group and container
        buttonGroup.appendChild(skipButton);
        buttonGroup.appendChild(sureButton);
        container.appendChild(buttonGroup);

        return container;
    } catch (error) {
        console.error("Error creating popover content:", error);
        return null;
    }
};

onMounted(() => {
    if (popoverButton.value) {
        popoverInstance = new Popover(popoverButton.value, {
            html: true,
            content: createPopoverContent,
            placement: "bottom",
        });
    } else {
        console.error("popoverButton is not defined.");
    }
});

onBeforeUnmount(() => {
    if (popoverInstance) {
        popoverInstance.dispose();
    }
});
</script>

<style>
/* Add any custom styles here if needed */
</style>
