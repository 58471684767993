export const buildUrl = (base, params) => {
  const query = Object.entries(params)
    .filter(([, value]) => value !== null && value !== undefined && value !== "")
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `${base}?${query}`;
};
export const buildUrlWithArrays = (base, params) => {
  const query = Object.entries(params)
    .filter(([, value]) => value !== null && value !== undefined && value !== "")
    .flatMap(([key, value]) => {
      return Array.isArray(value)
        ? value.map((item) =>
          Array.isArray(item) ?
            item.map((arr) => `${key.replace(/,/g, "")}[]=${encodeURIComponent(arr)}&`).join("")
            : `${key}[]=${encodeURIComponent(item)}`
        )

        : `${key}=${encodeURIComponent(value)}`



    }
    )
    .join("&");

  return `${base}?${query}`;
};
