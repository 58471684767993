<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head"> 
                <span v-if="route.params.id" class="btn" @click="router.back()">
                   <i class='bx bx-left-arrow-alt bx-lg'></i>
                </span>
                 {{ $t('Entities.Inventory') }}
                </h3>
            <DataTable v-model:rows="InvetoriesData" :headers="headers" :fields="fields" :total="total"
                :loading="loading" @page-changed="onPageChanged">
                <template v-slot:head-contant="{}">

                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.9994 8H11.9993C9.48519 8 8.22811 8 7.44706 8.78105C6.66602 9.5621 6.66602 10.8192 6.66602 13.3333L6.66609 21.6667H25.3326L25.3327 13.3333C25.3327 10.8192 25.3327 9.5621 24.5516 8.78105C23.7706 8 22.5135 8 19.9993 8H16.9994V14.6301L17.9068 13.5714C18.2662 13.1521 18.8975 13.1035 19.3168 13.463C19.7362 13.8224 19.7847 14.4537 19.4253 14.873L16.7586 17.9841C16.5686 18.2058 16.2913 18.3333 15.9994 18.3333C15.7074 18.3333 15.4301 18.2058 15.2401 17.9841L12.5734 14.873C12.214 14.4537 12.2626 13.8224 12.6819 13.463C13.1012 13.1035 13.7325 13.1521 14.092 13.5714L14.9994 14.6301V8Z"
                                    fill="#24A259" />
                                <path
                                    d="M6.70682 23.6667H25.2919C25.2235 24.7208 25.0407 25.3965 24.5516 25.8856C23.7706 26.6667 22.5135 26.6667 19.9993 26.6667H11.9993C9.48519 26.6667 8.22811 26.6667 7.44706 25.8856C6.95799 25.3965 6.77516 24.7208 6.70682 23.6667Z"
                                    fill="#24A259" />
                                <path
                                    d="M7.8512 4H24.1475C27.0112 4 29.3327 6.39997 29.3327 9.36048C29.3327 11.0796 28.5499 12.6097 27.3326 13.5907L27.3327 13.214C27.3328 12.0599 27.3329 11.0016 27.2172 10.1408C27.0897 9.19285 26.7899 8.19092 25.9658 7.36685C25.1418 6.54279 24.1398 6.24293 23.1919 6.11548C22.331 5.99975 21.2728 5.99987 20.1186 6.00001H11.88C10.7258 5.99987 9.66759 5.99975 8.80676 6.11548C7.85881 6.24293 6.85688 6.54279 6.03282 7.36685C5.20876 8.19092 4.9089 9.19285 4.78145 10.1408C4.66571 11.0016 4.66584 12.0598 4.66597 13.214L4.66599 13.5906C3.4488 12.6097 2.66602 11.0796 2.66602 9.36048C2.66602 6.39997 4.9875 4 7.8512 4Z"
                                    fill="#24A259" />
                            </svg>

                            <h3 class="table-title">{{ $t('Sections.Inventory.List') }}</h3>

                        </div>
                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31"
                                v-model.lazy="searchQuery">
                        </div>
                    </div>
                </template>
                <template v-slot:action-buttons="{ rowColum }">
                    <td class="text-center align-middle" v-if="authStore.hasPermission('view-clients')">
                        {{ rowColum.client_name }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.quantity }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.created_at }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.updated_at }}
                    </td>
                    <td>
                        <button type="button" class="btn btn-bg btn-primary" @click="onEditRow(rowColum)"
                            :disabled="rowColum.transactions && !rowColum.transactions.length > 0">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M4.83001 1.33334H11.17C11.9426 1.33334 12.3289 1.33334 12.6404 1.44175C13.2312 1.64731 13.695 2.1248 13.8947 2.73298C14 3.05371 14 3.45138 14 4.24672V13.5828C14 14.155 13.3433 14.4585 12.9279 14.0784C12.6839 13.8551 12.3161 13.8551 12.0721 14.0784L11.75 14.3731C11.3223 14.7645 10.6777 14.7645 10.25 14.3731C9.82228 13.9817 9.17772 13.9817 8.75 14.3731C8.32228 14.7645 7.67772 14.7645 7.25 14.3731C6.82228 13.9817 6.17772 13.9817 5.75 14.3731C5.32228 14.7645 4.67772 14.7645 4.25 14.3731L3.92794 14.0784C3.68388 13.8551 3.31612 13.8551 3.07206 14.0784C2.65666 14.4585 2 14.155 2 13.5828V4.24672C2 3.45138 2 3.05371 2.1053 2.73298C2.30499 2.1248 2.76881 1.64731 3.35959 1.44175C3.67114 1.33334 4.05743 1.33334 4.83001 1.33334ZM10.0396 5.66636C10.2236 5.46037 10.2057 5.14429 9.99967 4.96038C9.79369 4.77646 9.47761 4.79436 9.2937 5.00034L7.28571 7.24928L6.7063 6.60034C6.52239 6.39436 6.20631 6.37646 6.00033 6.56038C5.79434 6.74429 5.77645 7.06037 5.96036 7.26636L6.91275 8.33302C7.00761 8.43927 7.14328 8.50001 7.28571 8.50001C7.42815 8.50001 7.56382 8.43927 7.65868 8.33302L10.0396 5.66636ZM5 9.83335C4.72386 9.83335 4.5 10.0572 4.5 10.3333C4.5 10.6095 4.72386 10.8333 5 10.8333H11C11.2761 10.8333 11.5 10.6095 11.5 10.3333C11.5 10.0572 11.2761 9.83335 11 9.83335H5Z"
                                    fill="white" />
                            </svg>
                            <span class="px-1">Transactions </span>
                        </button>
                    </td>
                </template>

            </DataTable>

            <TransactionsInventory v-if="showEdit" :row="editRow" @close-edit="onCloseEdit"/>





        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';

import i18n from '@/i18n.js';
import { formatDateToYMDHMS } from '@/utils/dateFormatter';

import { getInventories } from '@/services/inventory';
import TransactionsInventory from './componants/TransactionsInventory.vue';
import { useAuthStore } from '@/stores/auth';
import { useRoute, useRouter } from 'vue-router';

// State
// Create a reference to the DeleteModal component
const InvetoriesData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const searchQuery = ref('');

//for Action btn
const editRow = ref({});


const authStore = useAuthStore();

// Table headers and fields
const headers = computed(() => ['#', i18n.global.t('Sections.Warehouses.Name'),
    i18n.global.t('Sections.Product.Name'),
    authStore.hasPermission('view-clients') ? i18n.global.t('General.Client') : null,
    i18n.global.t('General.Quantity'),
    i18n.global.t('General.CreatedAt'),
    i18n.global.t('General.UpdatedAt'),
    '']);
const fields = ['ids', 'warehouse_name', 'product_name'];
// {
//     "id": 79,
//     "warehouse_id": 20,
//     "product_id": 74,
//     "quantity": 54,
//     "created_at": "2024-10-02T10:00:05.000000Z",
//     "updated_at": "2024-10-02T10:00:05.000000Z",
//     "warehouse": {
//         "id": 20,
//         "reference_id": "9a50116e-9e26-48d6-98c1-1ec266eb0917",
//         "client_id": 7,
//         "country_id": 184,
//         "city_id": 76,
//         "name": "مخزن دقق 3",
//         "phone": null,
//         "address_1": "3030، 6925، الكعكية، مكة 24352، السعودية",
//         "address_2": null,
//         "created_at": "2024-10-02T10:00:04.000000Z",
//         "updated_at": "2024-10-02T10:00:04.000000Z"
//     },
//     "product": {
//         "id": 74,
//         "reference_number": "c72fe3ef-1270-44b7-aa8a-eb7983d13b8c",
//         "parent_id": null,
//         "type_id": 0,
//         "name": "المنتج الأول",
//         "barcode": "",
//         "sku": "Z.392973.1692289038246086",
//         "client_id": 7,
//         "price": "0",
//         "sale_price": null,
//         "quantity": 43,
//         "has_serial_number": 1,
//         "created_at": "2024-10-02T10:00:05.000000Z",
//         "updated_at": "2024-11-06T11:54:12.000000Z",
//         "type_name": {
//             "name": "Normal",
//             "id": 0
//         },
//         "client_normal_data": {
//             "id": 7,
//             "reference_number": "421927",
//             "user_id": 24,
//             "serial_number": "0002",
//             "client_name": "dqqapp",
//             "email": "urovpzjas3@zam-partner.email",
//             "mobile": "+966540888224",
//             "status": 1,
//             "status_name": "Approved"
//         }
//     },
// "transactions": {
//     "id": 1,
//     "inventory_id": 79,
//     "user_id": 3,
//     "type": "reorder",
//     "quantity": 100,
//     "created_at": "2024-09-12T09:21:52.000000Z",
//     "updated_at": "2024-09-12T09:21:52.000000Z"
// }
// }


const route = useRoute();
const router = useRouter();


// Fetch users with pagination
const fetchData = async () => {

    try {
        loading.value = true;
        InvetoriesData.value = [];


        const response = await getInventories(currentPage.value, searchQuery.value , route.params.id );
        let count = 1;
        for (const row of response.data.data.data) {

            InvetoriesData.value.push({
                ...row,
                ids:   (((currentPage.value??1) -1 ) *10) + count++ , 

                created_at: formatDateToYMDHMS(row.created_at),
                updated_at: formatDateToYMDHMS(row.updated_at),
                // client_name: row.client && row.client.name ? row.client.name : "NAN",
                client_name: row.product && row.product.client_normal_data && row.product.client_normal_data.client_name ? row.product.client_normal_data.client_name : "NAN",
                warehouse_name: row.warehouse && row.warehouse.name ? row.warehouse.name : "NAN",
                product_name: row.product && row.product.name ? row.product.name : "NAN",

            });

        }
        // InvetoriesData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};

// Fetch users on mount
onMounted(fetchData);

// Watch for changes on searchQuery
watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});
const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};



//View
const showEdit = ref(false);
function onEditRow(row) {

    if (row.transactions && row.transactions.length > 0) {
        console.log(123);

        showEdit.value = !showEdit.value;

        editRow.value = row;
    }

}
function onCloseEdit() {
    showEdit.value = !showEdit.value;

}




</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>