<template>
    <div class="modal fade" :class="{ 'show': showImport }" id="importProduct" tabindex="-1" aria-modal="true"
        role="dialog" style="display: block; padding-left: 0px;" @dragover.prevent="showDragOverlay"
        @dragleave.prevent="hideDragOverlay" @drop.prevent="handleFileDrop">
        <!-- Drag Overlay -->
        <div v-if="dragging" class="drag-overlay">
            <p>{{ $t('General.DropFileHere') }}</p>
        </div>
        <!-- Modal Content -->
        <div class="modal-dialog modal-lg modal-simple">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" @click="closeModal" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-4">
                        <h4 class="mb-2">{{ $t('Sections.Product.Import') }}</h4>
                    </div>

                    <!-- Download Button -->


                    <!-- Form with Select and Dropzone -->
                    <form @submit.prevent="onSubmit">
                        <!-- Display errors above the form -->
                        <div v-if="formErrors.length" class="alert alert-danger">
                            <ul>
                                <li v-for="(error, index) in formErrors" :key="index">{{ error }}</li>
                            </ul>
                        </div>
                        <!-- Select Dropdown -->
                        <div class="mb-3">
                            <label class="form-label" for="category">{{ $t('General.Select.Warehouse') }}</label>
                            <select id="category" v-model="warehouse_id" class="form-select">
                                <option v-for="warehouse in warehousesData" :key="warehouse.id" :value="warehouse.id">
                                    {{ warehouse.name }}
                                </option>
                            </select>

                        </div>

                        <!-- Dropzone -->
                        <div class="row">
                            <div class="mb-3 col-9">
                                <label class="form-label">{{ $t('General.UploadFile') }}</label>
                                <div class="dropzone" @click="triggerFileInput">
                                    <p>{{ $t('General.DragDropFile') }}</p>
                                    <input type="file" ref="fileInput" class="d-none" @change="handleFileChange"
                                        accept=".csv, .xlsx" />
                                </div>
                                <p v-if="uploadedFile">{{ uploadedFile.name }}</p>
                            </div>
                            <div class="mb-3 col-3 d-flex align-items-end">
                                <button type="button" class="btn btn-primary mb-4" @click="downloadTemplate">
                                    {{ $t('Sections.Product.DownloadTemplate') }}
                                </button>
                            </div>
                        </div>


                        <!-- Submit Button -->
                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5" @click="closeModal"
                                aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { h, onBeforeUnmount, onMounted, ref } from 'vue';
import i18n from '@/i18n.js';
import { SelectWarehouses } from '@/services/warehouse';
import {  uploadFile } from '@/services/product';
import { toast } from 'vue3-toastify';


const props = defineProps({
    onEdit: {
        type: Function,
        required: true
    },

});
const emit = defineEmits(['close-edit']);

const showImport = ref(true);
const loading = ref(false);
const dragging = ref(false);

const uploadedFile = ref(null);
const warehouse_id = ref(null);
const warehousesData = ref([]);
const formErrors = ref([]); // Reactive variable to store form errors

const downloadTemplate = async () => {
    try {
        const link = document.createElement('a');

        link.href = "https://sub.dqq.app/dashboard/product/download_sample"; // The URL from the response
        link.download = 'OrderTemplateExample'; // Optional: Set file name
        document.body.appendChild(link); // Append to body
        toast.success(h("div", [
            "File is ready! ",
            h("button", {
                onClick: () => window.open(link, "_blank"),
                style: "background: #4CAF50; color: white; padding: 5px 10px; border: none; cursor: pointer; margin-left: 10px; cursor: pointer;"
            }, "Download")
        ]), {
            autoClose: false, // Keeps the toast open until manually dismissed
            closeOnClick: false, // Prevents closing when clicking the toast itself
            draggable: false, // Disables accidental drag-to-close
            closeButton: true // Ensures a close button is visible
        });
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up

    } catch (error) {
        console.error(error);
    }
};

const triggerFileInput = () => {
    const fileInput = document.querySelector('input[type="file"]');
    fileInput.click();
};

const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'text/csv' || file.name.endsWith('.xlsx'))) {
        uploadedFile.value = file;
    } else {
        toast.error(i18n.global.t('Validation.InvalidFileType'));
        event.target.value = null; // Reset the input
    }
};

const handleFileDrop = (event) => {
    const file = event.dataTransfer.files[0];
    if (file && (file.type === 'text/csv' || file.name.endsWith('.xlsx'))) {
        uploadedFile.value = file;
        dragging.value = false; // Hide overlay after dropping the file
    } else {
        toast.error(i18n.global.t('Validation.InvalidFileType'));
    }
};

const showDragOverlay = () => {
    dragging.value = true;
};

const hideDragOverlay = () => {
    dragging.value = false;
};

const fetchWarehouse = async () => {
    try {
        const warehousesResponse = await SelectWarehouses();
        warehousesData.value = warehousesResponse.data.data;
    } catch (error) {
        console.error(error);
    }
};
onMounted(() => {
    fetchWarehouse();
});

const onSubmit = async () => {
    formErrors.value = []; // Clear previous errors
    if (!warehouse_id.value || !uploadedFile.value) {
        formErrors.value.push(i18n.global.t('Validation.Required.AllFields'));
        return;
    }
    try {
        loading.value = true;
        const formData = new FormData();
        formData.append('file', uploadedFile.value);
        formData.append('warehouse_id', warehouse_id.value);
        await uploadFile(formData);
        emit('close-edit', false);
        props.onEdit();
        toast.success(i18n.global.t('Messages.Success.Imported'));
        closeModal();
    } catch (error) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.data) {
            formErrors.value = error.response.data.data.map(
                (err) => `Row ${err.row}: ${err.errors.join(', ')}`
            );
        } else {
            formErrors.value.push(i18n.global.t('Messages.Error.SubmitError'));
        }
    } finally {
        loading.value = false;
    }
};

const closeModal = () => {
    // showImport.value = false;
    emit('close-edit', false);
};

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModal();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.dropzone {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.2s;
}

.dropzone:hover {
    border-color: #24A259;
}

.drag-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    /* Ensure it appears above the modal */
    pointer-events: none;
    font-size: 1.5rem;
    text-align: center;
}
</style>