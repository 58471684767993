<template>
    <div class="position-relative">
        <select ref="multiSelect" class="select2 form-select form-custom-select-control select-w"></select>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import 'select2/dist/css/select2.min.css';
import $ from 'jquery';
import 'select2';
// Props
const props = defineProps({
    options: {
        type: Array,
        required: true,
    },
    modelValue: {
        type: Array,
        default: () => [],
    },
    placeholder: {
        type: String,
        default: "Choose items...",
        required: false
    },
});

// Emits
const emit = defineEmits(["update:modelValue"]);

// References
const multiSelect = ref(null);

// Initialize Select2
const initSelect2 = () => {
    $(multiSelect.value)
        .select2({
            data: props.options.map((opt) => ({ id: opt.id, text: opt.name })),
            placeholder: props.placeholder ?? "",
            width: "100%",
            allowClear: true,
            minimumResultsForSearch: 0, // Ensures search is always available
        })
        .val(props.modelValue)
        .trigger("change");

    // Emit changes to the parent when Select2 changes
    $(multiSelect.value).on("change", () => {
        const selected = $(multiSelect.value).val() || [];
        emit("update:modelValue", selected);
    });
};

watch(
    () => props.options,
    () => {
        // Reinitialize when options change
        $(multiSelect.value).select2("destroy");
        initSelect2();
    }
);

// Lifecycle Hooks
onMounted(() => initSelect2());

onBeforeUnmount(() => {
    $(multiSelect.value).off().select2("destroy");
});
</script>
<style>
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 5px !important;
    /* Adjust spacing between selected items */
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #fff !important;
    border-radius: 4px !important;
    padding: 5px !important;
    border: 1px solid #24A259 !important;
}

.select2-container {
    min-height: 70px !important;
}

.select2-selection {
    min-height: 70px !important;
}
/* Ensure the overall container has min-height */
.select2-container--default .select2-selection--multiple {
    background: #EDF8F2 !important;
    min-height: 70px !important;
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    padding: 5px !important;
    border: 1px solid #24A259 !important;
}

/* Apply min-height directly to the ul holding selected items */
.select2-selection__rendered {
    min-height: 70px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    gap: 5px !important;
}

/* Ensure individual selected items (tags) are styled properly */
.select2-selection__choice {
    background: white !important;
    border-radius: 4px !important;
    padding: 5px 10px !important;
    border: 1px solid #24A259 !important;
    display: flex !important;
    align-items: center !important;
}

/* Fix overlapping issue */
.select2-container {
    min-height: 70px !important;
}
</style>
