<template>
    <div class="modal-backdrop fade" :class="{ 'show': showEdit }"
        style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 1040;">
    </div>
    <div class="modal fade" :class="{ 'show': showEdit }" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close " data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2">{{ $t('Sections.Inventory.Transaction') }}</h4>
                    </div>
                    <div class="table-responsive text-nowrap">
                        <table class="table table-hover">
                            <thead class="bg-green">
                                <tr>
                                    <th class="text-center ">#</th>
                                    <th class="text-center ">{{ $t('Auth.Username') }}</th>
                                    <th class="text-center ">{{ $t('General.UserRole') }}</th>
                                    <th class="text-center ">{{ $t('General.Type') }}</th>
                                    <th class="text-center ">{{ $t('General.Quantity') }}</th>
                                    <th class="text-center ">{{ $t('General.Date') }}</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr v-for="transaction in row.transactions" :key="transaction.id">
                                    <td class="text-center ">{{ transaction.id }}</td>
                                    <td class="text-center ">{{ transaction.user?.name }}</td>
                                    <td class="text-center ">
                                        <span
                                            class="badge badge-light-success bg-green text-primary fw-bolder fs-8 px-2 py-1 ms-2"
                                            v-for="role in transaction.user?.roles" :key="role.id">
                                            {{ role.name }}
                                        </span>

                                    </td>
                                    <td class="text-center ">{{ transaction.type }}</td>
                                    <td class="text-center ">{{ transaction.quantity }}</td>
                                    <td class="text-center ">{{ formatDateToYMDHMS(transaction.created_at) }}</td>


                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { formatDateToYMDHMS } from '@/utils/dateFormatter';
import { onBeforeUnmount, onMounted, ref } from 'vue';


const emit = defineEmits(['close-add']);

defineProps({
    showEdit: {
        type: Boolean,
        default: true,
    },
    row: {
        type: Object,
        required: true,
    },

});


function closeModel() {
    emit('close-edit', false);
}

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
  if (modal.value && !modal.value.contains(event.target) ) {
    closeModel();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});


</script>
<style scoped>
label {
    text-transform: uppercase;
}
</style>
