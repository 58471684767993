<template>
    <div class="modal fade" :class="{ 'show': showEdit }" id="addWarehouse" tabindex="-1" aria-modal="true"
        role="dialog" style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Warehouses.Edit') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">

                        <!-- User -->
                        <div class="col-12" v-if="authStore.hasPermission('view-clients')">
                            <label for="defaultSelect" class="form-label">{{ $t('General.Users') }}</label>
                            <select :class="{ 'border-danger': errors.role }" id="defaultSelect" v-model="client_id"
                                class="form-select">
                                <option value="" disabled>{{ $t('General.Select.User') }}</option>
                                <option disabled v-if="userData.length < 1">{{ $t('loading') }} ...</option>
                                <option v-else v-for="role in userData" :key="role.id" :value="role.id">{{ role.name }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.role }}</span>
                        </div>

                        <div class="col-12 col-md-6">

                            <label for="country" class="form-label">{{ $t('General.Select.Country') }}</label>
                            <CountrySelect :countriesData="contriesData" v-model="country_id"
                                :selectedId="row.country_id" :class="{ 'border-danger': errors.country_id }" />
                            <span class="text-danger">{{ errors.country_id }}</span>
                        </div>

                        <!-- City -->
                        <div class="col-12 col-md-6">
                            <label for="city" class="form-label">{{ $t('General.Select.City') }}</label>
                            <select :class="{ 'border-danger': errors.city_id }" id="defaultSelect" v-model="city_id"
                                class="form-select" :disabled="citiesData.length < 1">
                                <option value="" disabled>{{ $t('General.Select.City') }}</option>
                                <option disabled v-if="citiesData.length < 1">{{ $t('loading') }} ...</option>
                                <option v-else v-for="city in citiesData" :key="city.id" :value="city.id">{{
                                    getname(city) }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.city_id }}</span>
                        </div>

                        <!-- Reference ID -->
                        <div class="col-12 col-md-6">
                            <label for="reference_id" class="form-label">{{ $t('General.RefernceId') }}</label>
                            <input :class="{ 'border-danger': errors.city }" type="text" id="reference_id"
                                v-model="reference_id" class="form-control" placeholder="Reference ID">
                            <span class="text-danger">{{ errors.reference_id }}</span>
                        </div>

                        <!-- Warehouse Name -->
                        <div class="col-12 col-md-6">
                            <label for="name" class="form-label">{{ $t('Sections.Warehouse.name') }}</label>
                            <input :class="{ 'border-danger': errors.name }" type="text" id="name" v-model="name"
                                class="form-control" placeholder="Warehouse Name">
                            <span class="text-danger">{{ errors.name }}</span>
                        </div>

                        <!-- Phone -->
                        <div class="col-12">
                            <label for="phone" class="form-label">{{ $t('General.Phone') }}</label>
                            <div class="d-flex align-items-center   phone form-control">
                                <img src="https://media.zid.store/static/sa.svg" alt="Saudi Flag"
                                    style="width: 20px; height: 15px;" />
                                <span class="ms-2">+966</span>
                                <input :class="{ 'border-danger': errors.phone }" type="text" id="phone" v-model="phone"
                                    class="form-control custom-form-control border-0" placeholder="phone Number"
                                    style="flex: 1;" />
                            </div>
                            <span class="text-danger">{{ errors.phone }}</span>
                        </div>
                        <!-- Address 1 -->
                        <div class="col-12">
                            <label for="address_1" class="form-label">{{ $t('General.Address_1') }}</label>
                            <input :class="{ 'border-danger': errors.address_1 }" type="text" id="address_1"
                                v-model="address_1" class="form-control" placeholder="Address 1">
                            <span class="text-danger">{{ errors.address_1 }}</span>
                        </div>

                        <!-- Address 2 -->
                        <div class="col-12">
                            <label for="address_2" class="form-label">{{ $t('General.Address_2') }} <span
                                    class="text-muted">(Optional)</span> </label>
                            <input :class="{ 'border-danger': errors.address_2 }" type="text" id="address_2"
                                v-model="address_2" class="form-control" placeholder="Address 2">
                            <span class="text-danger">{{ errors.address_2 }}</span>
                        </div>

                        <!-- Submit and Cancel Buttons -->
                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Submit') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5"
                                data-bs-dismiss="modal" @click="closeModel" aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

import { toast } from 'vue3-toastify';
import { editWarehouse } from '@/services/warehouse';
import { getCities, getcountries } from '@/services/setting';
import { SelectClients } from '@/services/client';
import CountrySelect from '@/components/forms/CountrySelect.vue';
import { useAuthStore } from '@/stores/auth';
import { useI18n } from 'vue-i18n';
import { getname } from '@/utils/translate';

const authStore = useAuthStore();

const emit = defineEmits(['close-add']);

const props = defineProps({
    showEdit: {
        type: Boolean,
        default: true,
    },
    row: {
        type: Object,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true
    },

});

const loading = ref(false);
const eRow = ref(props.row);

const { t } = useI18n();



const userData = ref([]);
const contriesData = ref([]);
const citiesData = ref([]);

const validationSchema = computed(() =>
    yup.object({
        client_id: yup
            .string()
            .when([], {
                is: () => authStore.hasPermission('view-clients'),
                then: (schema) => schema.required(t('Validation.IsRequired')),
                otherwise: (schema) => schema.nullable(),
            }),
        country_id: yup
            .string()
            .required(t('Validation.Required.Country')),
        city_id: yup
            .string()
            .required(t('Validation.Required.City')),
        reference_id: yup
            .string()
            .required(t('Validation.Required.reference_id')),
        name: yup
            .string()
            .required(t('Validation.Required.name'))
            .min(3, t('Validation.Length.Min3'))
            .max(50, t('Validation.Length.Max50')),
        address_1: yup
            .string()
            .required(t('Validation.Required.address_1')),
        phone: yup
            .string()
            .required(t('Validation.Required.phone')),
    }));


// Form fields and validation schema using VeeValidate and yup
const { handleSubmit, errors } = useForm({
    validationSchema: validationSchema.value,

});

// Use `useField` to bind individual form fields
const { value: client_id } = useField('client_id');
client_id.value = eRow.value.client_id;

const { value: country_id } = useField('country_id');
country_id.value = eRow.value.country_id;

const { value: city_id } = useField('city_id');
city_id.value = eRow.value.city_id;

const { value: reference_id } = useField('reference_id');
reference_id.value = eRow.value.reference_id;

const { value: name } = useField('name');
name.value = eRow.value.name;

const { value: phone } = useField('phone');
phone.value = eRow.value.phone;

const { value: address_1 } = useField('address_1');
address_1.value = eRow.value.address_1;

const { value: address_2 } = useField('address_2');
address_2.value = eRow.value.address_2;

const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        await editWarehouse(values, eRow.value.id);
        emit('close-edit', false);
        props.onEdit();

        toast.success(t('Messages.Success.Edited'), {
            autoClose: 3000, // Adjust time if needed
        });
    } catch (e) {
        loading.value = false;
        console.error(t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-edit', false);
}

const fetchUsers = async () => {
    if (authStore.hasPermission('view-clients')) {
        try {
            const response = await SelectClients();
            userData.value = response.data.data;
        } catch (error) {
            console.error(t('Messages.Error.FetchData'), error);
        }
    }
};
const fetchcontries = async () => {
    try {
        const responseContry = await getcountries();
        contriesData.value = responseContry.data.data;

    } catch (error) {
        console.error(t('Messages.Error.FetchData'), error);
    }
};
const fetchCities = async () => {
    try {
        const responseCities = await getCities(country_id.value);
        citiesData.value = responseCities.data.data;
    } catch (error) {
        console.error(t('Messages.Error.FetchData'), error);
    }
};
// Watch the 'country_id' field for changes
watch(country_id, () => {
    citiesData.value = [];
    if (country_id != null) {
        console.log(country_id.value);
        fetchCities();
    }
});


onMounted(fetchcontries);
onMounted(() => {
    if (userData.value.length < 1) {
        setTimeout(() => {
            fetchUsers();
        }, 2000);

    }
});
onMounted(fetchCities);

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});

</script>
<style scoped></style>
