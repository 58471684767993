import { ref } from "vue";
import axiosClient from "./axiosClient"

export function getClients(page = 1 , searchQuery = '') {
  const url =  ref('');
  if(searchQuery){
   url.value =  `v1/dashboard/clients?page=${page}&per_page=10&search=${searchQuery}` ;
  }else{
   url.value =  `v1/dashboard/clients?page=${page}&per_page=10` ;

  }
  return axiosClient({
                   url   : url.value,
                 })
}
export function SelectClients() {
  return axiosClient({
                   url   : `v1/dashboard/clients`,
                 })
}

export function deleteClient( id) {
  return axiosClient({
                   url   : `v1/dashboard/client/delete/${id}`,
                   method: 'DELETE',
                 })
}
export function statusClient(data , id) {
  return axiosClient({
                   url   : `v1/dashboard/client/status/update/${id}`,
                   method: 'put',
                   data
                 })
}
export function editClient(data , id) {
  return axiosClient({
                   url   : `v1/dashboard/client/update/${id}`,
                   method: 'put',
                   data
                 })
}