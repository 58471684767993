import axiosClient from "./axiosClient";
import { buildUrl } from "@/utils/buildUrl";

export function getFulfilments(page = 1, searchQuery = null, clientId = null, status = null, is_confirm_notes = null) {
  const url = buildUrl("v1/dashboard/fulfilment", {
    page,
    per_page: 10,
    all: searchQuery,
    client_id: clientId,
    status,
    is_confirm_notes,
  });

  return axiosClient({ url });
}
export function deleteFulfilment(id) {
  return axiosClient({
    url: `v1/dashboard/fulfilment/${id}`,
    method: 'DELETE',
  })
}



export function getPackages(page = 1, searchQuery = null, clientId = null) {
  const url = buildUrl("v1/dashboard/package", {
    page,
    per_page: 10,
    all: searchQuery,
    client_id: clientId,
  });

  return axiosClient({ url });
}
export function editPackage(data, id) {
  return axiosClient({
    url: `v1/dashboard/package/update/${id}`,
    method: 'post',
    data
  })
}
export function showPackage(id) {
  return axiosClient({
    url: `v1/dashboard/package/${id}`,
  })
}

export function getReturnOrder(page = 1, searchQuery = null, clientId = null, status = null) {
  const url = buildUrl("v1/dashboard/returned-order", {
    page,
    per_page: 10,
    all: searchQuery,
    client_id: clientId,
    status,
  });

  return axiosClient({ url });
}
export function deleteReturnOrders(id) {
  return axiosClient({
    url: `v1/dashboard/fulfilment/${id}`,
    method: 'DELETE',
  })
}
export function showReturnOrdersDetails(id) {
  return axiosClient({
    url: `v1/dashboard/return-order/${id}`,
    method: 'get',
  })
}


export function getReOrder(page = 1, searchQuery = null, clientId = null) {
  const url = buildUrl("v1/dashboard/reorder", {
    page,
    per_page: 10,
    all: searchQuery,
    client_id: clientId,
  });

  return axiosClient({ url });
}