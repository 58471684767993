<template>
    <div class="modal fade" :class="{ 'show': showEdit }" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.PaymentMethod.Edit') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <!-- Reference ID -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="reference_id">{{ $t('General.ReferenceId') }}</label>
                            <input :class="{ 'border-danger': errors.reference_id }" type="text" id="reference_id"
                                v-model="reference_id" class="form-control" placeholder="Enter reference ID">
                            <span class="text-danger">{{ errors.reference_id }}</span>
                        </div>

                        <!-- Name -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="name">{{ $t('General.Name') }}</label>
                            <input :class="{ 'border-danger': errors.name }" type="text" id="name" v-model="name"
                                class="form-control" placeholder="Enter name">
                            <span class="text-danger">{{ errors.name }}</span>
                        </div>

                        <!-- Code -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="code">{{ $t('General.Code') }}</label>
                            <input :class="{ 'border-danger': errors.code }" type="text" id="code" v-model="code"
                                class="form-control" placeholder="Enter code">
                            <span class="text-danger">{{ errors.code }}</span>
                        </div>

                        <!-- Type -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="type">{{ $t('General.Type') }}</label>
                            <input :class="{ 'border-danger': errors.type }" type="text" id="type" v-model="type"
                                class="form-control" placeholder="Enter type">
                            <span class="text-danger">{{ errors.type }}</span>
                        </div>

                        <!-- Image Upload -->
                        <div class="col-12">
                            <label class="form-label" for="image">{{ $t('General.Image') }}</label>
                            <input :class="{ 'border-danger': errors.image }" type="file" id="image" ref="imageInput"
                                class="form-control" @change="handleImageUpload">
                            <span class="text-danger">{{ errors.image }}</span>
                        </div>

                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-primary me-3" :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    {{ $t('General.Saving') }}
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>
                            <button type="button" class="btn btn-secondary" @click="closeModel">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n';
import { toast } from 'vue3-toastify';
import { editPaymentMetod } from '@/services/setting';

const emit = defineEmits(['close-edit']);

const props = defineProps({
    row: {
        type: Object,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true,
    },
});

const showEdit = ref(true);
const loading = ref(false);
const image = ref(null);
const eRow = ref(props.row);

const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        reference_id: yup.string().required(i18n.global.t('Validation.Required.ReferenceID')),
        name: yup.string().required(i18n.global.t('Validation.Required.Name')),
        code: yup.string().required(i18n.global.t('Validation.Required.Code')),
        type: yup.string().required(i18n.global.t('Validation.Required.Type')),
        image: yup.mixed().nullable().notRequired(),
    }),
});

const { value: reference_id } = useField('reference_id');
reference_id.value = eRow.value.reference_id;

const { value: name } = useField('name');
name.value = eRow.value.name;

const { value: code } = useField('code');
code.value = eRow.value.code;

const { value: type } = useField('type');
type.value = eRow.value.type;

const handleImageUpload = (event) => {

    const file = event.target.files[0];
    image.value = file || null;

};

const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;

        const formData = new FormData();
        formData.append('reference_id', values.reference_id);
        formData.append('name', values.name);
        formData.append('code', values.code);
        formData.append('type', values.type);
        if (image.value) {
            formData.append('image', image.value);
        }

        await editPaymentMetod(formData, eRow.value.id);
        emit('close-edit', false);
        toast.success(i18n.global.t('Messages.Success.Edited'), { autoClose: 3000 });
        props.onEdit();
    } catch (error) {
        console.error(i18n.global.t('Messages.Error.SubmitError'), error);
    } finally {
        loading.value = false;
    }
});

function closeModel() {
    emit('close-edit', false);
}

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
  if (modal.value && !modal.value.contains(event.target) ) {
    closeModel();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});

</script>
