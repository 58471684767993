// src/stores/loading.js
import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('loading', {
  state: () => ({
    isLoading: true, // Initial state
  }),
  actions: {
    setLoading(status) {
      this.isLoading = status;
    },
  },
});
