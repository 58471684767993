<template>
    <div class="modal fade" :class="{ 'show': showEdit }" id="editUser" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-xl modal-simple modal-edit-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Plans.Edit') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <!-- Title -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="title">{{ $t('Sections.Plans.Name') }} <span
                                    class="text-muted">({{ $t('General.Name') }})</span></label>
                            <input :class="{ 'border-danger': errors.plan_name }" type="text" id="title"
                                v-model="plan_name" class="form-control" placeholder="Enter plan_name">
                            <span class="text-danger">{{ errors.plan_name }}</span>
                        </div>

                        <!-- Price -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="price">{{ $t('Price') }}</label>
                            <div class="input-group  bg-green ">
                                <input :class="{ 'border-danger': errors.price }" type="number" id="price"
                                    v-model="price" class="form-control border-none" placeholder="Enter amount">
                                <span class="input-group-text border-none ">SAR</span>
                            </div>
                            <span class="text-danger">{{ errors.price }}</span>
                        </div>

                        <!-- Description -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="description">{{ $t('Description') }}</label>
                            <textarea :class="{ 'border-danger': errors.description }" id="description"
                                v-model="description" class="form-control form-textarea"
                                placeholder="Enter description"></textarea>
                            <span class="text-danger">{{ errors.description }}</span>
                        </div>

                        <!-- Duration -->
                        <div class="col-12 col-md-6 d-flex  justify-content-around ">
                            <div class="DurationPerMonth">

                                <label class="form-label" for="duration">{{ $t('General.DurationPerMonth') }}</label>
                                <!-- <div class="input-group">
                                    <button type="button" class="btn btn-outline-secondary"
                                        @click="decrement('duration')">-</button>
                                    <input :class="{ 'border-danger': errors.end_date }" type="number" id="duration" v-model="duration" class="form-control" min="0">
                                    <button type="button" class="btn btn-outline-secondary"
                                        @click="increment('duration')">+</button>
                                </div> -->
                                <div class=" duration-container mt-auto">
                                    <!-- Decrement Button -->

                                    <div class="btn-round" id="decrement" @click="decrement('duration')">
                                        &#8722; <!-- Minus Sign -->
                                    </div>

                                    <!-- Duration Value -->
                                    <div class="input-group  bg-green ">

                                        <input type="number" v-model="duration" class="form-control border-none "
                                            min="0">
                                        <span class="input-group-text border-none ">Day</span>

                                    </div>
                                    <!-- Increment Button -->
                                    <div class="btn-round" id="increment" @click="increment('duration')">
                                        &#43; <!-- Plus Sign -->
                                    </div>
                                </div>
                                <span class="text-danger">{{ errors.duration }}</span>
                            </div>
                            <div>
                                <label class="form-label" for="freeDays">{{ $t('General.FreeDays') }}</label>
                                <!-- <div class="input-group">
                                    <button type="button" class="btn btn-outline-secondary"
                                        @click="decrement('free_days')">-</button>
                                    <input :class="{ 'border-danger': errors.end_date }" type="number" id="freeDays" v-model="free_days" class="form-control" min="0">
                                    <button type="button" class="btn btn-outline-secondary"
                                        @click="increment('free_days')">+</button>
                                </div> -->
                                <div class=" duration-container mt-auto">
                                    <!-- Decrement Button -->

                                    <div class="btn-round" id="decrement" @click="decrement('free_days')">
                                        &#8722; <!-- Minus Sign -->
                                    </div>

                                    <!-- Duration Value -->
                                    <div class="input-group  bg-green ">

                                        <input type="number" v-model="free_days" class="form-control border-none "
                                            min="0">
                                        <span class="input-group-text border-none ">Day</span>

                                    </div>
                                    <!-- Increment Button -->
                                    <div class="btn-round" id="increment" @click="increment('free_days')">
                                        &#43; <!-- Plus Sign -->
                                    </div>
                                </div>
                                <span class="text-danger">{{ errors.free_days }}</span>
                            </div>
                        </div>

                        <!-- Free Days -->



                        <!-- Number of Orders -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="numberOfOrders">{{ $t('Sections.Orders.NumberOf') }}</label>
                            <div class="d-flex gap-2">
                                <input :class="{ 'border-danger': errors.number_orders }" type="number"
                                    id="numberOfOrders" v-model="number_orders" class="form-control form-custom-control"
                                    placeholder="Enter number of orders">
                                <div class="d-flex flex-column gap-2 chevron-btns">
                                    <button type="button"
                                        class="border-none outline-none bg-green text-primary p-0 rounded"
                                        @click="increment('number_orders')"><i class='bx bx-chevron-up'></i></button>
                                    <button type="button"
                                        class="border-none outline-none bg-green text-primary p-0 rounded"
                                        @click="decrement('number_orders')"><i class='bx bx-chevron-down'></i></button>
                                </div>
                            </div>

                            <span class="text-danger">{{ errors.number_orders }}</span>

                        </div>

                        <!-- Number of Employers -->
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="numberOfEmployers">{{ $t('Sections.Employees.NumberOf')
                                }}</label>
                            <div class="d-flex gap-2">
                                <input :class="{ 'border-danger': errors.number_employers }" type="number"
                                    id="numberOfEmployers" v-model="number_employers"
                                    class="form-control form-custom-control" placeholder="Enter number of employers">

                                <div class="d-flex flex-column gap-2 chevron-btns">
                                    <button type="button"
                                        class="border-none outline-none bg-green text-primary p-0 rounded"
                                        @click="increment('number_employers')"><i class='bx bx-chevron-up'></i></button>
                                    <button type="button"
                                        class="border-none outline-none bg-green text-primary p-0 rounded"
                                        @click="decrement('number_employers')"><i
                                            class='bx bx-chevron-down'></i></button>
                                </div>
                            </div>

                            <span class="text-danger">{{ errors.number_employers }}</span>

                        </div>

                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn login-button btn-primary me-3" :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Save') }}</span>
                            </button>

                            <button type="button" class="btn  btn-label-secondary" @click="closeModel"
                                aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n.js';
import { toast } from 'vue3-toastify';

import { editPlan } from '@/services/plan';

const emit = defineEmits(['close-edit']);
const props = defineProps({
    showEdit: { type: Boolean, default: true },
    row: {
        type: Object,
        required: true,
    },
    onEdit: { type: Function, required: true }
});
const ERow = ref(props.row)

const loading = ref(false);
// Pre-fill the form with existing data

const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        plan_name: yup.string().required(i18n.global.t('Validation.Required.plan_name')),
        price: yup.number().required(i18n.global.t('Validation.Required.Price')).positive(i18n.global.t('Positive.Price')),
        description: yup.string().required(i18n.global.t('Validation.Required.Description')),
        duration: yup.number().required(i18n.global.t('Validation.Required.Duration')).min(0),
        free_days: yup.number().required(i18n.global.t('Validation.Required.FreeDays')).min(0),
        number_orders: yup.number().required(i18n.global.t('Validation.Required.NumberOrders')).min(0),
        number_employers: yup.number().required(i18n.global.t('Validation.Required.NumberEmployers')).min(0),
    })
});

const { value: plan_name } = useField('plan_name');
plan_name.value = ERow.value.plan_name;
const { value: price } = useField('price');
price.value = ERow.value.price;
const { value: description } = useField('description');
description.value = ERow.value.description;
const { value: duration } = useField('duration');
duration.value = ERow.value.duration ?? 0;
const { value: free_days } = useField('free_days');
free_days.value = ERow.value.free_days ?? 0;
const { value: number_orders } = useField('number_orders');
number_orders.value = ERow.value.number_orders ?? 0;
const { value: number_employers } = useField('number_employers');
number_employers.value = ERow.value.number_employers ?? 0;

const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        await editPlan(props.row.id, values);
        emit('close-edit', false);
        toast.success(i18n.global.t('Messages.Success.Updated'), { autoClose: 3000 });

        props.onEdit();

    } catch (e) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-edit', false);
}



function increment(field) {
    if (field === 'number_orders') number_orders.value += 1;
    if (field === 'number_employers') number_employers.value += 1;
    if (field === 'duration') duration.value += 1;
    if (field === 'free_days') free_days.value += 1;
}

function decrement(field) {
    if (field === 'number_orders' && number_orders.value > 0) number_orders.value -= 1;
    if (field === 'number_employers' && number_employers.value > 0) number_employers.value -= 1;
    if (field === 'duration' && duration.value > 0) duration.value -= 1;
    if (field === 'free_days' && free_days.value > 0) free_days.value -= 1;
}

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
  if (modal.value && !modal.value.contains(event.target) ) {
    closeModel();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});

</script>

<style scoped>
.duration-container {
    display: flex;
    align-items: center;
    gap: 8px;
}


.btn-round {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 50%;
    color: #444;
    width: 60px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.btn-round:hover {
    background-color: #24A259;
    color: #FFFFFF;
}



.input-group-text {
    /* font-family: MadaniArabic; */
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #24A259;
}

.DurationPerMonth {
    margin-right: 20px !important;
}

.form-textarea {
    height: 120px !important;
}
</style>
