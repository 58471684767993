<template>
    <div class="modal fade" :class="{ 'show': showSamsaSteps }" id="addStore" tabindex="-1" aria-modal="true"
        role="dialog" style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-store">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <div class="text-center pb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('General.Step') + " (1)" }}</h4>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <img src="@/assets/dashboard/img/branding/samsa1.png" alt="Branding Image"
                                class="img-fluid">
                        </div>
                    </div>
                    <div class="text-center pb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('General.Step') + " (2)" }}</h4>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <img src="@/assets/dashboard/img/branding/samsa2.png" alt="Branding Image"
                                class="img-fluid">
                        </div>
                    </div>
                    <div class="text-center pb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('General.ENDPOINT') }}</h4>
                        <input type="text" class="form-control" value="https://dqq.app/smsa-shipment-webhook" readonly
                            @click="copyToClipboard('https://dqq.app/smsa-shipment-webhook')">
                    </div>
                    <div class="text-center pb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('General.HEADERKEY') }}</h4>
                        <input type="text" class="form-control" value="X-Signature" readonly
                            @click="copyToClipboard('X-Signature')">
                    </div>
                    <div class="text-center pb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('General.HEADERVALUE') }}</h4>
                        <input type="text" class="form-control"
                            value="5a95f6f12e5c8f08fe827b54158a0f91e5c9d82e53b0a39e8578609b4343e03f" readonly
                            @click="copyToClipboard('5a95f6f12e5c8f08fe827b54158a0f91e5c9d82e53b0a39e8578609b4343e03f')">
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';

const emit = defineEmits(['close-add']);

const { t } = useI18n();

defineProps({
    showSamsaSteps: {
        type: Boolean,
        default: true,
    },

});

function closeModel() {
    emit('close-add', false);
}
function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
        toast.success(t('General.CopiedToClipboard'));

    }).catch(err => {
        console.error(t('General.CouldNotCopyText'), err);
    });
}

const modal = ref(null);

// Handle click outside modal to close it
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);
    }, 500);
});

onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});
</script>


<style scoped>
.add-btn {
    width: 278px;
    /*height: 64px;*/
}

.cancel-btn {
    height: 64px;
    /*height: 64px;*/
}
</style>