import { buildUrl } from "@/utils/buildUrl";
import axiosClient from "./axiosClient";

export function getPrintNode(page = 1, searchQuery = null) {
    const url = buildUrl("v1/dashboard/print-node", {
        page,
        per_page: 10,
        all: searchQuery,
    });

    return axiosClient({ url });
}

export function deletePrintNode(id) {
    return axiosClient({
        url: `v1/dashboard/print-node/delete/${id}`,
        method: 'DELETE',
    })
}
export function createChildPrintNode(data) {
    return axiosClient({
        url: `v1/dashboard/print-node/create/child-account`,
        method: 'POST', 
        data
    })
}

export function showChildPrintNode(id) {
  return axiosClient({
    url: `v1/dashboard/print-node/computers/${id}`,
    method: 'get',
  })
}
export function syncComputers(id) {
  return axiosClient({
    url: `v1/dashboard/print-node/sync-computers/${id}`,
    method: 'get',
  })
}
export function getPrintNoteByClient(id) {
  return axiosClient({
    url: `v1/dashboard/print-node/get_print_by_client/${id}`,
    method: 'get',
  })
}
