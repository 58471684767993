<template>
  <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
    v-if="authStore.isLoggedIn" data-bs-theme="dark" id="layout-navbar">
    <div class="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none" @click="expandMenu">
      <a class="nav-item nav-link px-0 me-xl-6" href="javascript:void(0)">
        <i class="bx bx-menu bx-md"></i>
      </a>
    </div>

    <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
      <!-- Search -->
      <div class="navbar-nav align-items-center">
        <div class="nav-item navbar-search-wrapper mb-0">
          <a class="nav-item nav-link search-toggler px-0" href="javascript:void(0);">
            <!-- <i class="bx bx-search bx-md"></i> -->
            <!-- <span class="d-none d-md-inline-block text-muted fw-normal ms-4">Search (Ctrl+/)</span> -->
          </a>
        </div>
      </div>
      <!-- /Search -->

      <ul class="navbar-nav flex-row align-items-center ms-auto">

        <!-- LoginAs -->
        <li class="nav-item dropdown-language dropdown me-3 me-xl-2" v-if="checkLoginAs || havePrev">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" @click.prevent="fetchLoginAs">
            {{ $t('General.LoginAs') }}
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li v-if="havePrev">
              <a class="dropdown-item" href="#" @click.prevent="BackToUser()">
                {{ $t('General.Back') }}
              </a>
            </li>
            <!-- Product list -->
            <li v-if="filteredUsers.length < 1" class="text-center p-3">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </li>
            <li v-else v-for="(user, index) in filteredUsers" :key="index">
              <a class="dropdown-item" href="#" @click.prevent="SelectUser(user.id)">
              {{ user.name }}
              </a>
              <button v-if="user.client && user.client.employees && user.client.employees.length" class="btn btn-link dropdown-toggle" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse-${index}`" aria-expanded="false" aria-controls="collapseExample" @click.stop>
                {{ $t('General.ShowEmployees') }}
              </button>
              <ul :id="`collapse-${index}`" class="collapse">
              <li v-for="(employee, empIndex) in user.client?.employees" :key="`emp-${index}-${empIndex}`">
                <a class="dropdown-item" href="#" @click.prevent.stop="SelectUser(employee.user_id)">
                - {{ employee.user.name }}
                </a>
              </li>
              </ul>
            </li>
          </ul>
        </li>
        <!-- /LoginAs -->

        <!-- Notification -->
        <!-- <li class="nav-item dropdown-notifications navbar-dropdown dropdown me-2 me-xl-0">
          <a class="nav-link dropdown-toggle hide-arrow color-green " href="javascript:void(0);"
            data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
            <span class="position-relative">
              <i class="bx bx-bell bx-md"></i>
              <span class="badge rounded-pill bg-danger badge-dot badge-notifications border"></span>
            </span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end p-0">
            <li class="dropdown-menu-header border-bottom">
              <div class="dropdown-header d-flex align-items-center py-3">
                <h6 class="mb-0 me-auto">Notification</h6>
                <div class="d-flex align-items-center h6 mb-0">
                  <span class="badge bg-label-primary me-2">8 New</span>
                  <a href="javascript:void(0)" class="dropdown-notifications-all p-2" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Mark all as read"><i
                      class="bx bx-envelope-open text-heading"></i></a>
                </div>
              </div>
            </li>
            <li class="dropdown-notifications-list scrollable-container">
              <ul class="list-group list-group-flush">
                <li class="list-group-item list-group-item-action dropdown-notifications-item">
                  <div class="d-flex">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar">
                        <img src="@/assets/dashboard/img/avatar-sm.png" alt class="rounded-circle" />
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="small mb-0">Congratulation Lettie 🎉</h6>
                      <small class="mb-1 d-block text-body">Won the monthly best seller gold badge</small>
                      <small class="text-muted">1h ago</small>
                    </div>
                    <div class="flex-shrink-0 dropdown-notifications-actions">
                      <a href="javascript:void(0)" class="dropdown-notifications-read"><span
                          class="badge badge-dot"></span></a>
                      <a href="javascript:void(0)" class="dropdown-notifications-archive"><span
                          class="bx bx-x"></span></a>
                    </div>
                  </div>
                </li>
                <li class="list-group-item list-group-item-action dropdown-notifications-item">
                  <div class="d-flex">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar">
                        <span class="avatar-initial rounded-circle bg-label-danger">CF</span>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="small mb-0">Charles Franklin</h6>
                      <small class="mb-1 d-block text-body">Accepted your connection</small>
                      <small class="text-muted">12hr ago</small>
                    </div>
                    <div class="flex-shrink-0 dropdown-notifications-actions">
                      <a href="javascript:void(0)" class="dropdown-notifications-read"><span
                          class="badge badge-dot"></span></a>
                      <a href="javascript:void(0)" class="dropdown-notifications-archive"><span
                          class="bx bx-x"></span></a>
                    </div>
                  </div>
                </li>

              </ul>
            </li>
          </ul>
        </li> -->
        <!--/ Notification -->
        <!-- Language -->
        <li class="nav-item dropdown-language dropdown me-3 me-xl-2">
          <a class="nav-link dropdown-toggle hide-arrow color-green " href="javascript:void(0);"
            data-bs-toggle="dropdown">
            <i class="bx bx-globe bx-md"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li v-show="$i18n.locale != 'en'">
              <button class="dropdown-item" @click="changeLanguage" data-language="en" data-text-direction="ltr">
                <span>English </span>
              </button>
            </li>

            <li v-show="$i18n.locale != 'ar'">
              <button class="dropdown-item" @click="changeLanguage" data-language="ar" data-text-direction="rtl">
                <span>Arabic</span>
              </button>
            </li>

          </ul>
        </li>
        <!-- /Language -->



        <!-- User -->
        <li class="nav-item navbar-dropdown dropdown-user dropdown">
          <a class="nav-link dropdown-toggle hide-arrow p-0" href="javascript:void(0);" data-bs-toggle="dropdown">
            <div class="avatar avatar-online">
              <img :src="authStore.user.logo" alt class="w-px-40 h-auto rounded" />
            </div>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <a class="dropdown-item" href="javascript:void(0);">
                <div class="d-flex">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar avatar-online">
                      <img :src="authStore.user.logo" alt class="w-px-40 h-auto rounded" />
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0">{{ authStore.user.name }}
                      <span class="badge badge-light-success bg-green text-primary fw-bolder fs-8 px-2 py-1 ms-2"
                        v-for="role in authStore.user.roles" :key="role.id">{{ role.alise_name }}</span>
                    </h6>
                    <small class="text-muted">{{ authStore.user.email }}</small>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <div class="dropdown-divider my-1"></div>
            </li>
            <li>
              <router-link class="dropdown-item" :to="{ name: 'Profile' }">
                <i class="bx bx-user bx-md me-3"></i><span>{{ $t('General.MyProfile') }}</span>
              </router-link>
            </li>


            <li v-if="authStore.hasPermission(['admin-settings', 'view-order-settings', 'view-notify-setting'])">
              <div class="dropdown-divider my-1"></div>
            </li>
            <li v-if="authStore.hasPermission(['admin-settings', 'view-order-settings', 'view-notify-setting'])">
              <router-link class="dropdown-item" :to="{ name: 'DqqSetting' }">
                <i class="bx bx-cog bx-md me-3"></i><span>{{ $t('General.Settings') }}</span>
              </router-link>
            </li>

            <li>
              <div class="dropdown-divider my-1"></div>
            </li>
            <li>
              <button @click="logout" class="dropdown-item">
                <i class="bx bx-power-off bx-md mke-3"></i><span>{{ $t('Auth.Logout') }}</span>
              </button>
            </li>
          </ul>
        </li>
        <!--/ User -->
      </ul>
    </div>

    <!-- Search Small Screens -->
    <div class="navbar-search-wrapper search-input-wrapper d-none">
      <input type="text" class="form-control search-input container-xxl border-0" placeholder="Search..."
        aria-label="Search..." />
      <i class="bx bx-x bx-md search-toggler cursor-pointer"></i>
    </div>
    <div v-if="loading" class="overlay-loader">
      <!-- Your overlay loader content -->
      <div class="loader"></div>
    </div>
  </nav>
</template>
<script setup>


import router from '@/router/routes';
import {  loginAsGroup } from '@/services/auth';
import { useAuthStore } from '@/stores/auth';
import { useLoadingStore } from '@/stores/loading';
import { useReloadStore } from '@/stores/reload';
import { computed, watch } from 'vue';



import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { updateGlobalOptions } from 'vue3-toastify';

const loading = ref(false);

// Use the i18n instance
const { t, locale } = useI18n();

const route = useRoute();

// Declare a reactive reference for the current language
const currentLang = ref(locale.value);

watch(locale, (newLocale) => {
  currentLang.value = newLocale;
});
// Function to change the language
const changeLanguage = () => {
  loadingStore.setLoading(true)
  currentLang.value = currentLang.value === 'en' ? 'ar' : 'en';
  locale.value = currentLang.value; // Update the locale in vue-i18n
  localStorage.setItem('lang', locale.value);
  updateGlobalOptions(
    {
      rtl: currentLang.value === 'ar',
      ltr: currentLang.value != 'ar'

    });

  setTimeout(() => {
    loadingStore.setLoading(false);
  }, 200);


};

const authStore = useAuthStore();
const loadingStore = useLoadingStore();

const logout = async () => {
  // loading.value = true;
  loadingStore.setLoading(true)
  try {
    await authStore.handleLogout();
    await router.push({ name: 'Login' });
  } catch (error) {
    console.error('Logout error:', error);
  } finally {
    loadingStore.setLoading(false)

    // loading.value = false; // Hide the loader when done
  }
}
const expandMenu = () => {
  document.documentElement.classList.add('layout-menu-expanded');
};

const errorMessage = computed(() => t('Messages.Error.FetchData'));

const reload = useReloadStore();

const UsersData = ref([]);
const searchUser = ref('')

const fetchLoginAs = async () => {
  try {

    const response = await loginAsGroup();
    UsersData.value = response.data.data;

  } catch (error) {
    console.error(errorMessage.value, error);
  }
};


const SelectUser = async (id) => {

  loadingStore.setLoading(true);
  try {
    if (id) {
      const data = { id: id }
      await authStore.registerId(data);
      if (!authStore.hasPermission(route.meta.requiredRoles)) {
        await router.push({ name: 'NotFound' });

      } else {

        reload.triggerReload()
      }


      // await router.push({ name: 'Dashboard' });
    }
  } catch (error) {
    console.error(errorMessage.value, error);
  } finally {
    // window.location.reload();
    loadingStore.setLoading(false);

  }
}
const havePrev = computed(() => authStore.isPrevToken);

const BackToUser = async () => {
  loadingStore.setLoading(true);

  try {
    await authStore.backUser()
    // await router.push({ name: 'Dashboard' });
    if (!authStore.hasPermission(route.meta.requiredRoles)) {
      await router.push({ name: 'NotFound' });

    } else {

      reload.triggerReload()
    }


  } catch (error) {
    console.error(errorMessage.value, error);
  } finally {
    // await nextTick(); // Wait for DOM update
    // window.location.reload();
    loadingStore.setLoading(false);

  }
}

const filteredUsers = computed(() => {
  if (searchUser.value.length > 0) {

    return UsersData.value
      .filter(user =>
        user.name.toLowerCase().includes(searchUser.value.toLowerCase())
      )
      .slice(0, 10); // Limit to the first 10 items
  } else {

    return UsersData.value // Limit to the first 10 items
  }
});

const checkLoginAs = computed(() =>
  authStore.isAdmin
  // || authStore.isClient
);

</script>
<style scope>
.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* Ensure it takes up the full viewport width */
  height: 100vh;
  /* Ensure it takes up the full viewport height */
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  /* Horizontally center the loader */
  align-items: center;
  /* Vertically center the loader */
  z-index: 9999;
  /* Ensure it stays on top of other content */
  opacity: 1;
  transition: opacity 0.3s ease;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 60px;
  /* Adjust size as needed */
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>