<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.Products') }}</h3>
            <DataTable v-model:rows="ProductsData" :headers="headers" :fields="fields" :total="total" :loading="loading"
                @page-changed="onPageChanged">
                <template v-slot:head-contant="{ }">

                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <!-- <i class="fa-solid fa-bag-shopping color-green"></i> -->
                            <svg width="26" height="29" viewBox="0 0 26 29" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.00019 7.01986C8.00006 7.01326 8 7.00664 8 7V5.66667C8 2.90525 10.2386 0.666672 13 0.666672C15.7614 0.666672 18 2.90525 18 5.66667V7C18 7.00664 17.9999 7.01326 17.9998 7.01987C19.7161 7.07138 20.7664 7.25654 21.5681 7.92197C22.6791 8.84393 22.9717 10.4045 23.5569 13.5257L24.5569 18.859C25.3798 23.248 25.7913 25.4424 24.5916 26.8879C23.392 28.3333 21.1593 28.3333 16.6939 28.3333H9.30606C4.84064 28.3333 2.60794 28.3333 1.40831 26.8879C0.208691 25.4424 0.620153 23.248 1.44308 18.859L2.44308 13.5257C3.0283 10.4045 3.32091 8.84393 4.43181 7.92197C5.23361 7.25653 6.28384 7.07138 8.00019 7.01986ZM10 5.66667C10 4.00982 11.3431 2.66667 13 2.66667C14.6569 2.66667 16 4.00982 16 5.66667V7C16 6.99999 16 7.00002 16 7C15.8996 6.99997 15.7976 7 15.6939 7H10.3061C10.2024 7 10.1004 7.00001 10 7.00004C10 7.00003 10 7.00005 10 7.00004V5.66667ZM8.99998 16.5956C8.99998 17.9082 10.5531 19.2951 11.7238 20.1541C12.2835 20.5647 12.5633 20.7701 13 20.7701C13.4367 20.7701 13.7165 20.5648 14.2762 20.1541C15.4468 19.2951 17 17.9082 17 16.5956C17 14.3647 14.7999 13.5317 13 15.2551C11.2 13.5317 8.99998 14.3647 8.99998 16.5956Z"
                                    fill="#24A259" />
                            </svg>

                            <h3 class="table-title">{{ $t('Sections.Product.List') }}</h3>

                        </div>
                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31"
                                v-model.lazy="searchQuery">
                        </div>
                    </div>
                    <div class="card-header flex-column flex-md-row pb-0">


                        <div class="dt-action-buttons text-end pt-6 pt-md-0 d-flex justify-content-end ">
                            <div class="dt-buttons btn-group text-start pt-6 pt-md-0"
                                v-if="authStore.hasPermission('create-products')">
                                <div class="dropdown ">
                                    <a class="btn  btn-primary add-btn  text-white dropdown-toggle" href="#"
                                        role="button" id="dropdownExportMenuLink" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        {{ $t('Sections.Product.AddNew') }}
                                        <i class="bx bx-plus bx-m mx-2 "></i>
                                    </a>

                                    <ul class="dropdown-menu w-100" aria-labelledby="dropdownExportMenuLink">
                                        <li class="mb-2">
                                            <button class="btn create-new  w-100" tabindex="0" @click="goToAddProduct()"
                                                aria-controls="DataTables_Table_0" type="button"><span><span
                                                        class="d-none d-sm-inline-block  me-sm-3">
                                                        {{ $t('Sections.Product.AddNew') }}
                                                    </span>
                                                </span>
                                            </button>
                                        </li>

                                        <li>
                                            <button class="btn  create-new  w-100" tabindex="0" :disabled="false"
                                                @click="goToAddGProduct()" aria-controls="DataTables_Table_0"
                                                type="button"><span><span class="d-none d-sm-inline-block  me-sm-3">
                                                        {{ $t('Sections.Product.AddNewGroup') }}
                                                    </span>
                                                </span>
                                            </button>
                                        </li>

                                    </ul>


                                </div>
                            </div>
                            <div class="dt-buttons btn-group text-start pt-6 pt-md-0">
                                <button class="btn  btn-primary add-btn  text-white  " tabindex="0"
                                    @click="onImportRow()" aria-controls="DataTables_Table_0" type="button"><span><span
                                            class="d-none d-sm-inline-block  me-sm-3">
                                            {{ $t('Sections.Product.Import') }}
                                        </span><i class="bx bxs-bullseye bx-sm "></i>
                                    </span>
                                </button>
                            </div>
                        </div>




                    </div>
                </template>
                <template v-slot:action-buttons="{ rowColum }">
                    <td class="text-center  align-middle p-0 m-0">
                        <span>{{ rowColum.ids }} </span>

                        <button @click="toggleExpandRow(rowColum, rowColum.id)" v-if="rowColum.is_group"
                            class="btn btn-link d-flex align-items-center ">
                            <i
                                :class="['ms-2', 'bi', { 'bx bxs-up-arrow': expandedRows.has(rowColum.id), 'bx bxs-down-arrow': !expandedRows.has(rowColum.id) }]"></i>
                        </button>
                    </td>
                    <td class="text-center align-middle" :class="{ 'bg-green': rowColum.type == 'Item' }">
                        <img :src="rowColum.image" class="img-fluid" width="40px">
                    </td>
                    <td class="text-center align-middle" :class="{ 'bg-green': rowColum.type == 'Item' }">
                        {{ rowColum.name }}
                    </td>
                    <td class="text-center align-middle" :class="{ 'bg-green': rowColum.type == 'Item' }">
                        {{ rowColum.barcode }}
                    </td>
                    <td class="text-center align-middle col" style="max-width: 150px;"
                        :class="{ 'bg-green': rowColum.type == 'Item' }">
                        {{ rowColum.sku }}
                    </td>
                    <td class="text-center align-middle" :class="{ 'bg-green': rowColum.type == 'Item' }">
                        {{ rowColum.price }}
                    </td>
                    <td class="text-center align-middle" :class="{ 'bg-green': rowColum.type == 'Item' }">

                        <button type="button" class="btn rounded"
                            :class="[rowColum.is_group ? 'succ-btn ' : 'blue-btn']">
                            {{ rowColum.type }}
                        </button>
                    </td>


                    <td class="text-center align-middle" :class="{ 'bg-green': rowColum.type == 'Item' }">
                        {{ rowColum.orders_count }}
                    </td>
                    <td class="text-center align-middle" :class="{ 'bg-green': rowColum.type == 'Item' }">
                        {{ rowColum.inventory?.quantity ?? 0 }}
                    </td>
                    <td class="text-center align-middle" :class="{
                        'text-danger': rowColum.has_serial_number,
                        'bg-green': rowColum.type == 'Item'
                    }">
                        <!-- {{ rowColum.is_valid }} -->
                        <div class="form-check form-switch  toggle-large" style="justify-self: center;">
                            <input class="form-check-input" type="checkbox" :checked="rowColum.has_serial_number === 1"
                                @change="toggleStatus(rowColum, $event.target.checked)" />
                        </div>
                    </td>
                    <td class="p-0 col-auto text-center align-middle">
                        <div class="d-flex me-2 justify-content-center">


                            <button type="button" class="btn btn-sm edit-btn mx-1 "
                                v-if="authStore.hasPermission('update-products')" @click="onEditRow(rowColum)">
                                <i class='bx bxs-edit-alt'></i>
                            </button>
                            <button v-if="authStore.hasPermission('delete-products')"
                                @click="triggerDeleteModal(rowColum)" class="btn btn-sm mx-1 delete-btn">
                                <svg width="13" height="15" viewBox="0 0 13 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.5 3.74921C0.5 3.48622 0.717806 3.27302 0.986484 3.27302H4.17858C4.18292 2.7122 4.2437 1.94336 4.80025 1.41112C5.23824 0.992261 5.83872 0.733337 6.49999 0.733337C7.16127 0.733337 7.76174 0.992261 8.19974 1.41112C8.75629 1.94336 8.81707 2.7122 8.82141 3.27302H12.0135C12.2822 3.27302 12.5 3.48622 12.5 3.74921C12.5 4.0122 12.2822 4.2254 12.0135 4.2254H0.986484C0.717806 4.2254 0.5 4.0122 0.5 3.74921Z"
                                        fill="#EE1D52" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M6.2304 14.0667H6.7696C8.62474 14.0667 9.55231 14.0667 10.1554 13.4761C10.7585 12.8854 10.8202 11.9166 10.9436 9.97901L11.1214 7.18707C11.1884 6.13574 11.2219 5.61008 10.9193 5.27697C10.6167 4.94386 10.1058 4.94386 9.08397 4.94386H3.91603C2.89418 4.94386 2.38325 4.94386 2.0807 5.27697C1.77815 5.61008 1.81163 6.13574 1.87858 7.18707L2.05639 9.979C2.1798 11.9166 2.2415 12.8854 2.84459 13.4761C3.44769 14.0667 4.37526 14.0667 6.2304 14.0667ZM5.33085 7.52566C5.30337 7.23642 5.05835 7.0254 4.78358 7.05432C4.50881 7.08325 4.30834 7.34116 4.33581 7.6304L4.66915 11.1392C4.69662 11.4284 4.94165 11.6394 5.21642 11.6105C5.49119 11.5816 5.69166 11.3237 5.66419 11.0344L5.33085 7.52566ZM8.21642 7.05432C8.49119 7.08325 8.69166 7.34116 8.66419 7.6304L8.33085 11.1392C8.30338 11.4284 8.05835 11.6394 7.78358 11.6105C7.50881 11.5816 7.30834 11.3237 7.33581 11.0344L7.66915 7.52566C7.69663 7.23642 7.94165 7.0254 8.21642 7.05432Z"
                                        fill="#EE1D52" />
                                </svg>
                            </button>
                            <!-- <button :disabled="rowColum.inventories_count" class="btn btn-sm succ-btn mx-1"
                                v-if="authStore.hasPermission('view-inventory')">
                                <router-link :to="{ name: 'ProductInventory', params: { id: rowColum.id } }">
                                    <i class='fa-solid fa-eye'></i>
                                </router-link>
                            </button> -->
                            <button type="button" class="btn btn-bg btn-primary" @click="onTransactionRow(rowColum)"
                                :disabled="!rowColum.inventory?.transactions.length > 0">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M4.83001 1.33334H11.17C11.9426 1.33334 12.3289 1.33334 12.6404 1.44175C13.2312 1.64731 13.695 2.1248 13.8947 2.73298C14 3.05371 14 3.45138 14 4.24672V13.5828C14 14.155 13.3433 14.4585 12.9279 14.0784C12.6839 13.8551 12.3161 13.8551 12.0721 14.0784L11.75 14.3731C11.3223 14.7645 10.6777 14.7645 10.25 14.3731C9.82228 13.9817 9.17772 13.9817 8.75 14.3731C8.32228 14.7645 7.67772 14.7645 7.25 14.3731C6.82228 13.9817 6.17772 13.9817 5.75 14.3731C5.32228 14.7645 4.67772 14.7645 4.25 14.3731L3.92794 14.0784C3.68388 13.8551 3.31612 13.8551 3.07206 14.0784C2.65666 14.4585 2 14.155 2 13.5828V4.24672C2 3.45138 2 3.05371 2.1053 2.73298C2.30499 2.1248 2.76881 1.64731 3.35959 1.44175C3.67114 1.33334 4.05743 1.33334 4.83001 1.33334ZM10.0396 5.66636C10.2236 5.46037 10.2057 5.14429 9.99967 4.96038C9.79369 4.77646 9.47761 4.79436 9.2937 5.00034L7.28571 7.24928L6.7063 6.60034C6.52239 6.39436 6.20631 6.37646 6.00033 6.56038C5.79434 6.74429 5.77645 7.06037 5.96036 7.26636L6.91275 8.33302C7.00761 8.43927 7.14328 8.50001 7.28571 8.50001C7.42815 8.50001 7.56382 8.43927 7.65868 8.33302L10.0396 5.66636ZM5 9.83335C4.72386 9.83335 4.5 10.0572 4.5 10.3333C4.5 10.6095 4.72386 10.8333 5 10.8333H11C11.2761 10.8333 11.5 10.6095 11.5 10.3333C11.5 10.0572 11.2761 9.83335 11 9.83335H5Z"
                                        fill="white" />
                                </svg>
                            </button>
                        </div>
                    </td>
                </template>

            </DataTable>

            <EditProduct v-if="showEdit" :row="editRow" @close-edit="onCloseEdit" :onEdit="fetchData" />
            <ImportProduct v-if="showImport" @close-edit="onCloseImport" :onEdit="fetchData" />

            <DeleteModal ref="deleteModal" :row="editRow" :onDelete="handleDelete"
                :title="$t('Are you sure for delete this product?')" />

            <TransactionsInventory v-if="showTransaction" :row="editRow?.inventory" @close-edit="onCloseTransaction" />




        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';

import i18n from '@/i18n.js';

import { deleteProduct, getProducts, updateProduct } from '@/services/product';
import EditProduct from './componants/EditProduct.vue';
import DeleteModal from '@/components/pages/DeleteModal.vue';
import { toast } from 'vue3-toastify';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';

import img from '@/assets/dashboard/img/dqqicon.png'
import TransactionsInventory from '../inventory/componants/TransactionsInventory.vue';
import ImportProduct from './componants/ImportProduct.vue';

const router = useRouter();

// State
const ProductsData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const authStore = useAuthStore();

const searchQuery = ref('');

//for Action btn
const editRow = ref({});


const expandedRows = ref(new Set());
function toggleExpandRow(row, rowIndex) {
    if (expandedRows.value.has(row.id)) {
        expandedRows.value.delete(row.id);
        // Remove group rows from ProductsData
        ProductsData.value = ProductsData.value.filter(item => item.parent_id !== row.id);
    } else {
        expandedRows.value.add(row.id);
        const newItems = row.groups.map(group => ({
            ...group.sub_product,
            is_group: false,
            type: 'Item',
            orders_count: '--',
            parent_id: row.id


        }));
        const rowIndexFind = ProductsData.value.findIndex(rowFind => rowFind.id === rowIndex);

        // If the row was found, insert new items directly after it
        if (rowIndex !== -1) {
            ProductsData.value.splice(rowIndexFind + 1, 0, ...newItems);
        }
    }
}




// Table headers and fields
const headers = computed(() => [
    '#',
    i18n.global.t('General.Image'),
    i18n.global.t('Sections.Product.Name'),
    i18n.global.t('Sections.Product.Barcode'),
    i18n.global.t('Sections.Product.SKU'),
    i18n.global.t('Sections.Product.PriceSar'),
    i18n.global.t('General.Type'),
    i18n.global.t('Entities.Orders'),
    i18n.global.t('Entities.Inventory'),
    i18n.global.t('General.SerialNumber'),
    '']);
const fields = [];

// Fetch users with pagination
const fetchData = async () => {

    try {
        loading.value = true;
        ProductsData.value = [];

        const response = await getProducts(currentPage.value, searchQuery.value);
        let count = 1;

        for (const row of response.data.data.data) {
            let invetory = row.inventories_count ?? 0;

            ProductsData.value.push({
                ...row,
                ids: (((currentPage.value ?? 1) - 1) * 10) + count++,
                inventories_count: invetory > 0 ? false : true,

                image: row.images && row.images.length > 0 ? row.images[0].url : img,

                is_group: row.groups && Array.isArray(row.groups) && row.groups.length === 0 ? false : true,
                type: row.type_name && row.type_name.name ? row.type_name.name : 'NAN',
                is_valid: row.has_serial_number ? i18n.global.t('Valid') : '--',


            });

        }
        // ProductsData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};

// Fetch users on mount
onMounted(fetchData);

// Watch for changes on searchQuery
watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});
const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};



function goToAddProduct() {
    router.push({ name: 'Product-Add' });
}
function goToAddGProduct() {
    router.push({ name: 'GProduct-Add' });
}


//Transaction
const showTransaction = ref(false);
function onTransactionRow(row) {
    showTransaction.value = !showTransaction.value;

    editRow.value = row;

}
function onCloseTransaction() {
    showTransaction.value = !showTransaction.value;

}
//edit
const showEdit = ref(false);
function onEditRow(row) {
    showEdit.value = !showEdit.value;

    editRow.value = row;

}
function onCloseEdit() {
    showEdit.value = !showEdit.value;

}
//Import
const showImport = ref(false);
function onImportRow() {
    showImport.value = !showImport.value;
}
function onCloseImport() {
    showImport.value = !showImport.value;

}

//delete
const deleteModal = ref(null);
const handleDelete = async () => {
    try {
        loading.value = true;
        await deleteProduct(editRow.value.id);
        fetchData();
        toast.success(i18n.global.t('Messages.Success.Deleted'), {
            autoClose: 3000, // Adjust time if needed
        });


        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error('Error fetching users:', error);
    }
};
const triggerDeleteModal = (row) => {
    editRow.value = row;

    deleteModal.value.openModal();
};

const editedMessage = computed(() => i18n.global.t('Messages.Success.Edited'));

const toggleStatus = async (value, isChecked) => {

    try {
        const newStatus = isChecked ? 1 : 0;
        const formData = {
            sku: value.sku,
            quantity: value.quantity,
            price: value.price,
            name: value.name,
            barcode: value.barcode,
            has_serial_number: newStatus
        };
        await updateProduct(formData, value.id)
        toast.success(editedMessage.value, {
            autoClose: 2000,
        });

    } catch (error) {
        console.error(error);
    }
};






</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.btn-warning {
    background-color: #ffc !important;
    color: gold;
}

.btn-warning:hover {
    background-color: gold !important;
    color: #212529;
}


.table-striped .bg-green {
    position: relative;
}

.table-striped .bg-green::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(148, 242, 187, 0.2);
}

.add-btn:focus,
.add-btn {
    background-color: #24a259 !important;

}

.toggle-large .form-check-input {
    width: 3rem;
    height: 1.5rem;
    border-radius: 1rem;
}
</style>