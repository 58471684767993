<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <h3 class="page-head">{{ $t('Entities.ReOrders') }}</h3>
            <DataTable v-model:rows="ReOrderData" :headers="headers" :fields="fields" :total="total" :loading="loading"
                @page-changed="onPageChanged">
                <template v-slot:head-contant="{ }">


                    <div class="d-flex  table-custom-head">
                        <div class="d-flex table-h  ">
                            <i class="fa-solid fa-thumbs-up  color-green"></i>
                            <h3 class="table-title">{{ $t('Sections.ReOrders.List') }}</h3>

                        </div>
                        <div class="input-group input-group-merge input-group-search">
                            <span class="input-group-text" id="basic-addon-search31"><svg width="40" height="40"
                                    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.5 10.75C14.6675 10.75 10.75 14.6675 10.75 19.5C10.75 24.3325 14.6675 28.25 19.5 28.25C24.3325 28.25 28.25 24.3325 28.25 19.5C28.25 14.6675 24.3325 10.75 19.5 10.75ZM9.25 19.5C9.25 13.8391 13.8391 9.25 19.5 9.25C25.1609 9.25 29.75 13.8391 29.75 19.5C29.75 22.0605 28.8111 24.4017 27.2589 26.1982L30.5303 29.4697C30.8232 29.7626 30.8232 30.2374 30.5303 30.5303C30.2374 30.8232 29.7626 30.8232 29.4697 30.5303L26.1982 27.2589C24.4017 28.8111 22.0605 29.75 19.5 29.75C13.8391 29.75 9.25 25.1609 9.25 19.5Z"
                                        fill="#24A259" />
                                </svg>
                            </span>
                            <input type="text" class="form-control box-search" placeholder="Search..."
                                aria-label="Search..." aria-describedby="basic-addon-search31"
                                v-model.lazy="searchQuery">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row g-3">
                                <div class="col-12 col-sm-6 col-lg-4" v-if="authStore.hasPermission('view-clients')">
                                    <label class="form-label" for="selectClient">{{ $t('General.Select.Client') }}
                                        :</label>
                                    <select id="selectClient" v-model="client_id"
                                        class="form-select form-custom-select-control select-w">
                                        <option></option>
                                        <option v-for="client in clientsData" :key="client.id" :value="client.id">{{
                                            client.name }}</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>

                </template>

                <template v-slot:action-buttons="{ rowColum }">

                    
                    <td class="text-center align-middle">
                        <img :src="rowColum.product?.first_image?.url ??img" width="75px" @error="replaceByDefault">
                    </td>

                    <td class="text-center align-middle">
                        {{ rowColum.product_name }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.user_name }}
                    </td>

                    <td class="text-center align-middle">
                        {{ rowColum.warehouse_name }}
                    </td>

                    <td class="text-center align-middle">
                        {{ rowColum.quantity }}
                    </td>
                    <td class="text-center align-middle">
                        {{ rowColum.emp_name }}
                    </td>


                    <td class="text-center align-middle">
                        {{ rowColum.created_at }}

                    </td>


                </template>

            </DataTable>




        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import DataTable from '@/components/pages/DataTable.vue';

import i18n from '@/i18n.js';
import { formatDateToYMDHMS } from '@/utils/dateFormatter';

import { getReOrder } from '@/services/shipping';
import { SelectClients } from '@/services/client';

import img from "@/assets/dashboard/img/dqqicon.png"
import { useAuthStore } from '@/stores/auth';

// State
// Create a reference to the DeleteModal component
const ReOrderData = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const total = ref(0);

const searchQuery = ref('');
const client_id = ref('');
const clientsData = ref([]);
//for Action btn
const authStore = useAuthStore();




const replaceByDefault = (e) => {
    e.target.src = img
    // You can now perform actions based on the page change, like refetching data.
};


// Table headers and fields
const headers = computed(() => ["#",
    i18n.global.t('General.Image'), 
    i18n.global.t('Sections.Product.Name'),
    authStore.hasPermission('view-clients') ? i18n.global.t('Sections.Clients.Name') : null
    , i18n.global.t('Sections.Warehouses.Name')
    , i18n.global.t('General.Quantity')
    , i18n.global.t('Sections.Employees.Name'), i18n.global.t('General.CreatedAt')]);
const fields = ['ids'];

const fetchData = async () => {

    try {
        loading.value = true;

        ReOrderData.value = [];

        const response = await getReOrder(currentPage.value, searchQuery.value, client_id.value);
        let count = 1;

        for (const row of response.data.data.data) {

            ReOrderData.value.push({
                ...row,
                ids: (((currentPage.value ?? 1) - 1) * 10) + count++,

                created_at: formatDateToYMDHMS(row.created_at),
                updated_at: formatDateToYMDHMS(row.updated_at),
                product_name: row.product && row.product.name ? row.product.name : "NAN",
                quantity: row.product && row.product.quantity ? row.product.quantity : "NAN",
                user_name: row.client_basic_data && row.client_basic_data.client_name ? row.client_basic_data.client_name : "NAN",
                emp_name: row.user && row.user.name ? row.user.name : "NAN",
                warehouse_name: row.warehouse && row.warehouse.name ? row.warehouse.name : "NAN",
            });

        }
        if (clientsData.value.length < 1) {
            setTimeout(() => {
                fetchClient();
            }, 2000);

        }
        // ReOrderData.value = response.data.data;
        total.value = response.data.data.total;
        currentPage.value = response.data.current_page;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};
const fetchClient = async () => {
    if (authStore.hasPermission('view-clients')) {
        try {
            const [clientsResponse] = await Promise.all([SelectClients()]);
            clientsData.value = clientsResponse.data.data;
        } catch (error) {
            loading.value = false;
            console.error(i18n.global.t('fetching.data.Error'), error);
        }
    }
};


// Fetch users on mount
onMounted(fetchData);
// Watch for changes on searchQuery
watch(searchQuery, () => {
 currentPage.value = 1 ;

    fetchData();
});
watch(client_id, () => {
    fetchData();
});
const onPageChanged = (pageNumber) => {
    currentPage.value = pageNumber;
    fetchData();
    // You can now perform actions based on the page change, like refetching data.
};








</script>

<style scoped>
.index-page {
    padding-top: 30px;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>