<template>
    <div class="modal fade" :class="{ 'show': showEdit }" id="addEmployee" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" aria-label="Close"></button>
                    <div class="text-center mb-6">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Clients.Edit') }}</h4>
                    </div>
                    <form class="row g-6" @submit.prevent="onSubmit">
                        <!-- SerialNumber -->
                        <div class="col-12 ">
                            <label class="form-label" for="serial_number">{{ $t('General.SerialNumber') }}</label>
                            <input type="text" id="serial_number" v-model="serial_number" class="form-control"
                                :class="{ 'border-danger': errors.SerialNumber }" placeholder="Enter SerialNumber">
                            <span class="text-danger">{{ errors.SerialNumber }}</span>
                        </div>

                        <!-- Username -->
                        <div class="col-12 ">
                            <label class="form-label" for="name">{{ $t('App.Dashboard') }} {{ $t('Auth.Username')
                                }}</label>
                            <input type="text" id="name" v-model="name" class="form-control"
                                :class="{ 'border-danger': errors.name }" placeholder="Enter username">
                            <span class="text-danger">{{ errors.name }}</span>
                        </div>

                        <!-- Email -->
                        <div class="col-12">
                            <label class="form-label" for="email">{{ $t('General.Email') }}</label>
                            <input type="email" id="email" v-model="email" class="form-control"
                                :class="{ 'border-danger': errors.email }" placeholder="Enter email">
                            <span class="text-danger">{{ errors.email }}</span>
                        </div>

                        <!-- mobile -->
                        <div class="col-12">
                            <label for="mobile" class="form-label">{{ $t('General.Phone') }}</label>
                            <div class="d-flex align-items-center   mobile form-control">
                                <img src="@/assets/dashboard/img/ar.svg" alt="Saudi Flag" />
                                <span class="ms-2 text-primary">+966</span>
                                <input type="text" id="mobile" v-model="mobile"
                                    class="form-control custom-form-control border-0"
                                    :class="{ 'border-danger': errors.mobile }" placeholder="Mobile Number"
                                    style="flex: 1;" />
                            </div>
                            <span class="text-danger">{{ errors.mobile }}</span>
                        </div>
                        <input type="hidden" v-model="code">
                        <!-- Store Name -->
                        <div class="col-12">
                            <label class="form-label" for="StoreName">{{ $t('General.StoreName') }}</label>
                            <input type="StoreName" id="StoreName" v-model="store_name" class="form-control"
                                :class="{ 'border-danger': errors.StoreName }" placeholder="Enter StoreName">
                            <span class="text-danger">{{ errors.StoreName }}</span>
                        </div>


                        <div class="col-12 text-center el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else>{{ $t('General.Submit') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5" @click="closeModel"
                                aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n.js';
import { toast } from 'vue3-toastify';
import { editClient } from '@/services/client';
import { SelectRoles } from '@/services/role';
import { onBeforeUnmount } from 'vue';

const emit = defineEmits(['close-add']);



const props = defineProps({
    row: {
        type: Object,
        required: true,
    },
    onEdit: {
        type: Function,
        required: true
    },

});


// const clientsData = ref([]);
const rolesData = ref([]);

const showEdit = ref(true);
const loading = ref(false);
const eRow = ref(props.row);

const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        serial_number: yup.string().required(i18n.global.t('Validation.Required.SerialNumber')),
        name: yup.string().required(i18n.global.t('Validation.Required.Username')),
        code: yup.string().required(),
        email: yup.string().email(i18n.global.t('Validation.Invalid.Email')).required(i18n.global.t('Validation.Required.Email')),
        store_name: yup.string().required(i18n.global.t('Validation.Required.store_name')),

    })
});

const { value: serial_number } = useField('serial_number');
serial_number.value = eRow.value.serial_number;
const { value: name } = useField('name');
name.value = eRow.value.user.user_name;
const { value: email } = useField('email');
email.value = eRow.value.email;
const { value: mobile } = useField('mobile');
mobile.value = eRow.value.mobile;
const { value: code } = useField('code');
code.value = 966;

const { value: store_name } = useField('store_name');
store_name.value = eRow.value.store_name;


const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        await editClient(values, eRow.value.id);
        emit('close-edit', false);
        toast.success(i18n.global.t('Messages.Success.Edited'), { autoClose: 3000 });

        props.onEdit();

    } catch (e) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-edit', false);
}

const fetchData = async () => {
    try {
        loading.value = true;
        const 
        [
            // clientsResponse,
             rolesResponse] = await Promise.all([
                // SelectClients(),
                 SelectRoles()]);
        // clientsData.value = clientsResponse.data.data;
        rolesData.value = rolesResponse.data.data;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('fetching.data.Error'), error);
    }
};

onMounted(fetchData);

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
  if (modal.value && !modal.value.contains(event.target) ) {
    closeModel();
  }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});

</script>
