import { buildUrl } from "@/utils/buildUrl";
import axiosClient from "./axiosClient"

export function getEmployees(page = 1 , searchQuery = null ,perPage = 10) {
  const url = buildUrl("v1/dashboard/employees", {
    page,
    per_page: perPage,
    all: searchQuery,
  });
  return axiosClient({ url });

}
export function getAllEmployees() {
  return axiosClient({
                   url   : `v1/dashboard/employees`,
                 })
}
export function editEmployee(data , id) {
  return axiosClient({
                   url   : `v1/dashboard/employee/update/${id}`,
                   method: 'post',
                   data
                 })
}
export function addEmployee(data) {
  return axiosClient({
                   url   : `v1/dashboard/employee/store`,
                   method: 'post',
                   data
                 })
}

export function deleteEmployee(id) {
  return axiosClient({
                   url   : `v1/dashboard/employee/delete/${id}`,
                   method: 'DELETE',
                 })
}
export function statusEmployee(data , id) {
  return axiosClient({
                   url   : `v1/dashboard/employee/status/update/${id}`,
                   method: 'put',
                   data
                 })
}