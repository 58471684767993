<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
                <div class="col-12">
                    <div class="card mb-6">

                        <div class="user-profile-header d-flex flex-column flex-lg-row text-sm-start text-center mb-8">
                            <div class="flex-shrink-0 mt-1 mx-sm-0 mx-auto">
                                <img src="@/assets/dashboard/img/avatar-la.png" alt="user image"
                                    class="d-block h-auto ms-0 ms-sm-6 rounded-3 user-profile-img">
                            </div>
                            <div class="flex-grow-1 mt-3 mt-lg-5">
                                <div
                                    class="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-5 flex-md-row flex-column gap-4">
                                    <div class="user-profile-info">
                                        <h4 class="mb-2 mt-lg-7">{{ user.name }}</h4>
                                        <!-- <ul
                                            class="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-4 mt-4">
                                            <li class="list-inline-item">
                                                <i class="bx bx-palette me-2 align-top"></i><span class="fw-medium">UX
                                                    Designer</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <i class="bx bx-map me-2 align-top"></i><span class="fw-medium">Vatican
                                                    City</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <i class="bx bx-calendar me-2 align-top"></i><span class="fw-medium">
                                                    Joined April 2021</span>
                                            </li>
                                        </ul> -->
                                    </div>
                                    <a href="javascript:void(0)" class="btn btn-primary mb-1">
                                        <i class="bx bx-user-check bx-sm me-2"></i>Update
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-4 col-lg-5 col-md-5">
                    <!-- About User -->
                    <div class="card mb-6">
                        <div class="card-body">
                            <small class="card-text text-uppercase text-muted small">About</small>
                            <ul class="list-unstyled my-3 py-1">
                                <li class="d-flex align-items-center mb-4">
                                    <i class="bx bx-user"></i><span class="fw-medium mx-2">Full Name:</span>
                                    <span>{{ user.name }}</span>
                                </li>
                                <li class="d-flex align-items-center mb-4">
                                    <i class="bx bx-user"></i><span class="fw-medium mx-2">Username:</span>
                                    <span>{{ user.userName }}</span>
                                </li>
                                <li class="d-flex align-items-center mb-4">
                                    <i class="bx bx-envelope"></i><span class="fw-medium mx-2">Email:</span>
                                    <span>{{ user.email }}</span>
                                </li>
                                <li class="d-flex align-items-center mb-4" v-for="role in user.roles" :key="role">
                                    <i class="bx bx-crown"></i><span class="fw-medium mx-2">Role:</span>
                                    <span>{{ role.alise_name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--/ About User -->

                </div>
                <div class="col-xl-8 col-lg-7 col-md-7">
                    <!-- Activity Timeline -->
                    <div class="card card-action mb-6">
                        <div class="card-header align-items-center">
                            <h5 class="card-action-title mb-0">
                                <i class="bx bx-bar-chart-alt-2 bx-lg text-body me-4"></i>User Info
                            </h5>
                        </div>
                        <div class="card-body pt-3">

                            <form @submit.prevent="onSubmit" autocomplete="off">
                                <div class="col-auto mb-4">
                                    <label class="mb-1" for="name">{{ $t('General.Name') }}</label>
                                    <input type="text" v-model="name" :class="{ 'border-danger': errors.name }"
                                        class="form-control" id="name">
                                    <div v-if="errors.name" class="text-danger">{{ errors.Name }}</div>
                                </div>
                                <div class="col-auto mb-4">
                                    <label class="mb-1" for="username">{{ $t('Auth.Username') }}</label>
                                    <input type="text" v-model="user_name"
                                        :class="{ 'border-danger': errors.user_name }" class="form-control"
                                        id="username" autocomplete="off">
                                    <div v-if="errors.user_name" class="text-danger">{{ errors.user_name }}</div>
                                </div>
                                <div class="col-auto mb-4">
                                    <label class="mb-1" for="email">{{ $t('General.Email') }}</label>
                                    <input type="email" v-model="email" :class="{ 'border-danger': errors.email }"
                                        class="form-control" id="email" placeholder="email@example.com">
                                    <div v-if="errors.email" class="text-danger">{{ errors.email }}</div>
                                </div>

                                <div class="col-auto mb-4 position-relative">
                                    <div class="d-flex justify-content-between align-items-center ">
                                        <label class="mb-1" for="password">{{ $t('Auth.Password') }}</label>
                                        <span class=" text-capitalize curser-pointer text-primary" @click="togglePasswordVisibility">
                                            {{ showPassword ? $t('General.Show') : $t('General.Hide')
                                            }}

                                            <button type="button"
                                                class="border-none text-primary outline-none bg-transparent">
                                                <i :class="showPassword ? 'bx bx-hide' : 'bx bx-show'"></i>
                                            </button>

                                        </span>
                                    </div>
                                    <input autocomplete="new-password" :type="showPassword ? 'text' : 'password'"
                                        v-model="password" :class="{ 'border-danger': errors.password }"
                                        class="form-control" id="password">

                                    <div v-if="errors.password" class="text-danger">{{ errors.password }}</div>
                                </div>
                                <div class="col-auto mb-4 position-relative">
                                    <div class="d-flex justify-content-between align-items-center ">
                                        <label class="mb-1" for="password_confirmation">{{
                                            $t('Auth.PasswordConfirmation')
                                            }}</label>
                                        <span class="curser-pointer text-capitalize text-primary"
                                            @click="togglePasswordConfirmationVisibility">
                                            {{ !showPasswordConfirmation ?
                                                $t('General.Show') : $t('General.Hide')
                                            }}

                                            <button type="button"
                                                class="border-none text-primary outline-none bg-transparent">
                                                <i :class="showPasswordConfirmation ? 'bx bx-hide' : 'bx bx-show'"></i>
                                            </button>

                                        </span>
                                    </div>
                                    <input :type="showPasswordConfirmation ? 'text' : 'password'"
                                        v-model="password_confirmation"
                                        :class="{ 'border-danger': errors.password_confirmation }" class="form-control"
                                        id="password_confirmation" autocomplete="off">

                                    <div v-if="errors.password_confirmation" class="text-danger">{{
                                        errors.password_confirmation }}
                                    </div>
                                </div>


                                <div class="col-12 text-center el-form-item__content">
                                    <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                        :disabled="loading">
                                        <span v-if="loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            <span class="visually-hidden">Loading...</span>
                                        </span>
                                        <span v-else>{{ $t('General.Save') }}</span>
                                    </button>

                                    <button type="button" class="btn cancel-btn bg-green text-primary px-5"
                                        @click="closeModel" aria-label="Close">
                                        {{ $t('General.Cancel') }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!--/ Activity Timeline -->

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>

const user = JSON.parse(localStorage.getItem('user'));

import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { toast } from "vue3-toastify";
import { computed, ref } from 'vue';
import { editUser } from '@/services/user';

// Form schema
const validationSchema = computed(() => yup.object({
    name: yup.string().required(t('Validation.Required.Name')),
    user_name: yup.string().required(t('Validation.Required.Username')),
    email: yup
        .string()
        .email(t('Validation.Invalid.Email'))
        .required(t('Validation.Required.Email')),
    password: yup
        .string(),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], t('Validation.Password.ConfirmationMismatch'))
}));

// VeeValidate setup
const { handleSubmit, errors } = useForm({
    validationSchema
});
const { value: name } = useField('name');
name.value = user.name;
const { value: user_name } = useField('user_name');
user_name.value = user.userName;
const { value: email } = useField('email');
email.value = user.email;
const { value: password } = useField('password');
password.value = '';
const { value: password_confirmation } = useField('password_confirmation');
password_confirmation.value = '';

const loading = ref(false);

const errorMessage = computed(() => t('Messages.Error.SubmitError'));
const successMessage = computed(() => t('Messages.Success.Created'));

const onSubmit = handleSubmit(async (values) => {

    try {
        loading.value = true;
        const id = user.id
        toast.success(successMessage.value);
        await editUser(values, id);
        // window.location.href = '/login';
    } catch (e) {
        loading.value = false;
        console.error(errorMessage.value, e);
    }
});

// Example function to handle missing fields


// Show password toggle
const showPassword = ref(false);
const showPasswordConfirmation = ref(false);
const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
};
const togglePasswordConfirmationVisibility = () => {
    showPasswordConfirmation.value = !showPasswordConfirmation.value;
};



</script>