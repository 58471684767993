<template>
    <div class="modal fade" :class="{ 'show': showAdd }" id="addUser" tabindex="-1" aria-modal="true" role="dialog"
        style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-lg modal-simple modal-add-user">
            <div class="modal-content" ref="modal">
                <div class="modal-body">
                    <button type="button" @click="closeModel" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <div class="text-center pb-6 ">
                        <h4 class="mb-2 sub-page-title">{{ $t('Sections.Users.AddNew') }}</h4>
                    </div>
                    <form class="row g-6 pt-4" @submit.prevent="onSubmit">
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="modalAddUserName">{{ $t('General.Name') }} </label> <span
                                class="required text-danger fw-bold ">*</span>
                            <input type="text" id="modalAddUserName" v-model="name" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('General.Name')"
                                :class="{ 'border-danger': errors.name }">
                            <span class="text-danger">{{ errors.name }}</span>
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="modalAddUserName">{{ $t('Auth.Username') }}</label> <span
                                class="required text-danger fw-bold ">*</span>
                            <input type="text" id="modalAddUserName" v-model="user_name" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('Auth.Username')"
                                :class="{ 'border-danger': errors.user_name }">
                            <span class="text-danger">{{ errors.user_name }}</span>
                        </div>
                        <div class="col-12">
                            <label class="form-label" for="modalAddUserEmail">{{ $t('General.Email') }}</label><span
                                class="required text-danger fw-bold ">*</span>
                            <input type="email" id="modalAddUserEmail" v-model="email" class="form-control"
                                :placeholder="$t('General.Enter') + ' ' + $t('General.Email')"
                                :class="{ 'border-danger': errors.email }">
                            <span class="text-danger">{{ errors.email }}</span>
                        </div>

                        <div class="col-12 mt-5">
                            <div class="d-flex justify-content-between align-items-center ">
                                <label class="mb-1  fw-medium  text-capitalize" for="password">{{ $t('Auth.Password')
                                    }}<span class="required text-danger fw-bold ">*</span></label>
                                <span   @click="togglePasswordVisibility" class=" text-capitalize text-primary "> {{ !showPassword ? $t('General.Show' ): $t('General.Hide') }}

                                    <button type="button" class="border-none text-primary outline-none bg-transparent"
                                       >
                                        <i :class="showPassword ? 'bx bx-hide' : 'bx bx-show'"></i>
                                    </button>

                                </span>
                            </div>
                            <input :type="showPassword ? 'text' : 'password'" v-model="password"
                                :class="{ 'border-danger': errors.password }" class="form-control" id="password"
                                autocomplete="current-password">
                            <div v-if="errors.password" class="text-danger">{{ errors.password }}</div>
                        </div>
                        <div class="col-12">
                            <label for="defaultSelect" class="form-label">{{ $t('General.Roles') }}</label><span
                                class="required text-danger fw-bold ">*</span>
                            <select id="defaultSelect" v-model="role" class="form-select select-w form-custom-select-control select-w"
                                :class="{ 'border-danger': errors.role }">
                                <option value="" disabled>{{ $t('General.Select.Roles') }}</option>
                                <option disabled v-if="rolesData.length < 1">loading ...</option>
                                <option v-else v-for="role in rolesData" :key="role.id" :value="role.id">{{ role.name }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.role }}</span>
                        </div>
                        <div class="col-12 text-center d-flex justify-content-center gap-2 el-form-item__content">
                            <button type="submit" class="btn add-btn shadow-none login-button btn-primary me-3"
                                :disabled="loading">
                                <span v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                                <span v-else class="px-6">{{ $t('Sections.Users.Add') }}</span>
                            </button>

                            <button type="button" class="btn cancel-btn bg-green text-primary px-5"
                                data-bs-dismiss="modal" @click="closeModel" aria-label="Close">
                                {{ $t('General.Cancel') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getRoles } from '@/services/role';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { addUser } from '@/services/user';
import i18n from '@/i18n.js';
import { toast } from 'vue3-toastify';

const emit = defineEmits(['close-add']);

const props = defineProps({
    showAdd: {
        type: Boolean,
        default: true,
    },
    onAdd: {
        type: Function,
        required: true
    },
});

const rolesData = ref([]);
const loading = ref(false);
const showPassword = ref(false);

// Form fields and validation schema using VeeValidate and yup
const { handleSubmit, errors } = useForm({
    validationSchema: yup.object({
        name: yup
            .string()
            .required(i18n.global.t('Validation.Required.Name'))
            .min(3, i18n.global.t('Validation.Length.Min3'))
            .max(50, i18n.global.t('Validation.Length.Max50')),
        user_name: yup
            .string()
            .required(i18n.global.t('Validation.Required.Username'))
            .min(3, i18n.global.t('Validation.Length.Min3'))
            .max(50, i18n.global.t('Validation.Length.Max50')),
        email: yup
            .string()
            .email(i18n.global.t('Validation.Invalid.Email'))
            .required(i18n.global.t('Validation.Required.Email')),
        password: yup
            .string()
            .min(8, i18n.global.t('Validation.Password.MinLength8'))
            .required(i18n.global.t('Validation.Required.Password')),
        role: yup
            .string()
            .required(i18n.global.t('Validation.Required.Roles'))
    })
});

// Use `useField` to bind individual form fields
const { value: name } = useField('name');
const { value: user_name } = useField('user_name');
const { value: email } = useField('email');
const { value: role } = useField('role');
const { value: password } = useField('password');

const onSubmit = handleSubmit(async (values) => {
    try {
        loading.value = true;
        await addUser(values);
        emit('close-add', false);
        props.onAdd();

        toast.success(i18n.global.t('Messages.Success.Created')
            , {
                autoClose: 3000, // Adjust time if needed
            });
    } catch (e) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.SubmitError'), e);
    }
});

function closeModel() {
    emit('close-add', false);
}
const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
};

const fetchRoles = async () => {

    try {
        loading.value = true;
        const response = await getRoles();
        rolesData.value = response.data.data;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.error(i18n.global.t('Messages.Error.FetchData'), error);
    }
};

onMounted(fetchRoles);

const modal = ref(null);
// Function to handle click outside the modal
const handleClickOutside = (event) => {
    if (modal.value && !modal.value.contains(event.target)) {
        closeModel();
    }
};

// Set up event listener when component is mounted
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', handleClickOutside);

    }, 500);
});

// Clean up the event listener when component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});

</script>

<style scoped>
.add-btn {
    width: 278px;
    /*height: 64px;*/
}

.cancel-btn {
    height: 64px;
    /*height: 64px;*/
}
</style>